/* eslint-disable no-loop-func */
import React, { useEffect, useState, useRef } from 'react';

//* images
import moreVertical from "../../assets/images/more-vertical.svg";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ConstructionIcon from '@mui/icons-material/Construction';

//* css
import cssClasses from "./ExamDetailsScreen.module.css";

//* import MUI
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//* import components
import HallTicket from "../../Components/HallTicket/HallTicket";
import Applicants from "../../Components/Applicants/Applicants";
import EligibleStudents from "../../Components/EligibleStudents/EligibleStudents";
import Merit from "../../Components/Merit/Merit";
import ExamCenter from '../../Components/ExamCenter/ExamCenter';
import Details from "../../Components/ExamDetails/Details";
import ExamForm from "../../Components/ExamDetails/ExamForm";
import ExamSchedule from "../../Components/ExamDetails/ExamSchedule";
import AdminStatus from "../../Components/AdminStatus/AdminStatus";

//* import Sub-components
import TabsMenu from "../../Subcomponent/TabsMenu";

// * import Service
import { getExamByExamShortName, getExamScheduleById, createModel } from "../../services/ExamService";
import { getAllExamCentersInExam } from "../../services/ExamCenterService";
import { presentLoader, dismissLoader } from "../../services/loaderService";
import { getAllStudentsByExam, getToken, uploadStudentPhotos, uploadStudents, getStudentByApplicant } from "../../services/StudentService";
import { uploadFilesAWS } from "../../services/AwsBucket"

//* import Modals
import GenerateHallTicketModal from '../../Modals/GenerateHallTicketModal/GenerateHallTicketModal';
import { useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from '../../services/Toast';
import { useSelector } from 'react-redux';
import ProgressBarDataUpload from '../../Modals/ProgressBarDataUpload/ProgressBarDataUpload';
import ProgressImagesUpload from '../../Modals/ProgressImagesUpload/ProgressImagesUpload';
import PhotoUploadLoadingStepper from "../../Modals/PhotoUploadLoadingStepper/PhotoUploadLoadingStepper"

//* imports npm
import { Timestamp, doc, onSnapshot, getFirestore, updateDoc, collection, getDocs, query, limit, startAfter, orderBy, getDoc } from "firebase/firestore";
import * as XLSX from "xlsx";
import { Buffer } from 'buffer';
import FailedEntires from '../../Modals/FailedEntries/FailedEntries';
import ModelExamCenterStatus from '../../Components/ModelExamCenterStatus/ModelExamCenterStatus';

const mainTabs = [
  { text: "Details", value: "Details" },
  { text: "Exam Form", value: "Exam Form" },
  { text: "Applicants", value: "Applicants" },
  { text: "Merit List", value: "Merit List" },
  { text: "Exam Schedule", value: "Exam Schedule" },
  { text: "Exam centers", value: "Exam centers" },
  { text: "Hall Tickets", value: "Hall Tickets" },
  { text: "Admin Status", value: "Admin Status" },
  { text: "Models", value: "Models" },
];

const menuItem = {
  fontSize: "1.4rem",
  ".MuiSvgIcon-root": {
    width: '2rem',
    height: '2rem',
    marginRight: "1rem",
    color: '#6941C6'
  }
}
export default function ExamDetail() {

  // * For Exam Details
  // let [searchParams, setSearchParams] = useSearchParams();
  // let tabValue = searchParams.get("tabValue");
  let params = useParams();
  let examShortName = params.examShortName;
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.Auth.user);
  // console.log(examShortName);

  const examFormRef = useRef();
  const detailsRef = useRef();
  const examScheduleRef = useRef();
  const myRefname = useRef(null);

  const [examSchedule, setExamSchedule] = useState([]);
  const [subExams, setSubExams] = useState([]);
  const [examForm, setExamForm] = useState({
    name: "",
    unitName: "",
    description: "",
    applicationOpenDate: "",
    applicationCloseDate: "",
    fee: "",
    category: "",
    image: "",
    examCode: "",
    date: "",
    paymentType: "",
    appliedStudentCount: 0,
    examCenterAddedCount: 0,
    formFields: [],
    isProforma: false,
    isFaceRecognition: false,
    isStoreThumbImpression: false,
    isStoreIdentityDocument: false,
    examDate: "",
  });
  const [helperText, setHelperText] = useState({
    isNameFilled: "",
    isUnitName: "",
    isOpenDateFilled: "",
    isCloseDateFilled: "",
    isFeeFilled: '',
    isTypeFilled: "",
    isImgUploaded: "",
    isPaymentTypeFilled: "",
    isExamCodeFilled: "",
    isBankNameFilled: "",
    isBankBranchFilled: "",
    isBankAccNoFilled: "",
    isBankAccNameFilled: "",
    isBankIFSCFilled: "",
  });

  const [selectedMainTabs, setSelectedMainTabs] = useState(mainTabs[0].value);
  const [generateHallTicketModalToggle, setGenerateHallTicketModalToggle] = useState(false);

  const [expanded, setExpanded] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [failedEntriesModal, setFailedEntriesModal] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // * For upload  
  const [progressDataModal, setProgressDataModal] = useState(false);
  const [handleNext, setHandleNext] = useState(0);
  const [totalDataUploading, setTotalDataUploading] = useState(0);
  const [addedCandidates, setAddedCandidates] = useState(0);
  const [duplicateCandidatesCount, setDuplicateCandidatesCount] = useState(0);
  const [failedCandidatesCount, setFailedCandidatesCount] = useState(0);
  const [failedCandidatesArray, setFailedCandidatesArray] = useState([]);
  const [duplicateCandidates, setDuplicateCandidates] = useState([]);

  const [progressUploadImages, setProgressUploadImages] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(0);

  const [totalImagesUploading, setTotalImagesUploading] = useState(0);
  const [photoUploadLoadingStepperToggle, setPhotoUploadLoadingStepperToggle] = useState(false);
  const [photoUploadStepValue, setPhotoUploadStepValue] = useState(null);
  const [updatingStudentPhotoCount, setUpdatingStudentPhotoCount] = useState(0);
  const [errorUploadedListApplicant, setErrorUploadedListApplicant] = useState([]);

  const allExamCenters = useRef([]);

  const handleExpansionPanelChange = (panel) => {
    // console.log(panel);
    setExpanded(pre => pre === panel ? "" : panel);
  };

  const fileSelectForUploadStudent = (e) => {
    const _file = e.target.files[0];
    console.log({ "upload Student": _file });
    readExcel(e);
  }

  const generateHallTicket = () => {
    setGenerateHallTicketModalToggle(true);
  }

  let examDetailsSubscribe;

  useEffect(() => {
    presentLoader();
    getExamFromServer();
    return () => {
      examDetailsSubscribe();
    }
  }, [])

  async function getExamFromServer() {
    // presentLoader()
    getExamByExamShortName(examShortName).then(res => {
      // console.log(res);
      if (res.empty) {
        errorToast("Invalid exam.");
        navigate('/home/exams');
      } else {
        let _data = res.docs.map(m => ({ docID: m.id, ...m.data() }))[0]
        setExamForm({ ...examForm, ..._data });
        console.log(_data);
        console.log("createdAt => ", _data.createdAt.toDate());
        getSubExamsFromServer(_data.docID);
        unsubscribe(_data.docID);
        dismissLoader();
      }
    }).catch(err => {
      console.log(err);
      dismissLoader();
    })
  }

  const unsubscribe = (_examDocID) => {
    examDetailsSubscribe = onSnapshot(doc(getFirestore(), "exams", _examDocID), (doc) => {
      let _data = doc.data()
      console.log("onSnapshot=>", _data);
      if (examForm) {
        setExamForm(pre => {
          pre.image = _data.image;
          pre.examCenterAddedCount = _data.examCenterAddedCount;
          pre.appliedStudentCount = _data?.appliedStudentCount !== undefined ? _data?.appliedStudentCount : 0;
          pre.examShortName = _data.examShortName;
          pre.name = _data.name;
          pre.examDate = _data.examDate;
          pre.finishedModelCount = _data.finishedModelCount || 0;
          pre.totalModelCount = _data.totalModelCount || 0;
          pre.hallTicketStatus = _data.hallTicketStatus;

          return Object.assign({}, pre);
        })
      }
    });
  }

  function getSubExamsFromServer(_examDocID) {
    getExamScheduleById(_examDocID).then(res => {
      let _subExams = res.docs.map(m => ({ docID: m.id, ...m.data() }));
      console.log({ _subExams });
      setSubExams([..._subExams]);
    });
  }
  async function readExcel(event) {
    if (event.nativeEvent.target.files.length === 1) {
      setHandleNext(0);
      setProgressDataModal(true);

      //todo get ExamCenters
      const getAllExamCentersInExamResponse = await getAllExamCentersInExam(examForm.docID);
      if (!getAllExamCentersInExamResponse.empty) {
        allExamCenters.current = [...getAllExamCentersInExamResponse.docs.map(m => m.data())];
      }

      // setProgressDataModal(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        // excel.current.value = "";
        document.getElementById("fileSelectForUploadStudent").value = '';
        var data = e.target.result;
        data = new Uint8Array(data);
        var workbook = XLSX.read(data, {
          type: "array",
          cellText: false,
          cellDates: true,
        });
        var roa;
        workbook.SheetNames.forEach(function (sheetName) {
          roa = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
            { raw: false, dateNF: "yyyy-mm-dd" }
          );
        });
        setHandleNext(1);
        console.log(roa);
        const invalidDataArr = [];
        const validDataArr = [];
        for (let i = 0; i < roa.length; i++) {
          const element = roa[i];
          if (!element.Status || element.Status === "") {
            element.Status = "pending";
          }
          element.Applicant = element?.Applicant?.toString() ? element?.Applicant?.toString() : "";

          element.createdAt = Timestamp?.fromDate(new Date(element?.registrationDate)) ? Timestamp.fromDate(new Date(element.registrationDate)) : "";
          element.paymentMethod = "Offline"
          element.studentFullNameForSearch = element["Full Name"]?.toLowerCase() ? element["Full Name"].toLowerCase() : "";
          element.examDate = element?.examDate?.toString() ? element?.examDate?.toString() : "";
          element.examTime = element?.examTime?.toString() ? element?.examTime?.toString() : "";
          const validationResult = validateExcelObj(element);
          if (validationResult.validation.isValid) {
            validDataArr.push(element);
          } else {
            element.reason = validationResult.validation.reason;
            element.row = i + 2; // +1 for array index; +1 for row heading in excel
            // console.log('i: ', i);
            invalidDataArr.push(element);
          }
        }
        if (invalidDataArr.length > 0) {
          setHandleNext(2);

          // for modal close
          setHandleNext(0);
          setTotalDataUploading(0);
          setAddedCandidates(0);
          setDuplicateCandidatesCount(0);
          setDuplicateCandidates([]);
          setFailedCandidatesCount(0);
          setFailedCandidatesArray([]);
          setProgressDataModal(false);

          errorToast("Invalid data in excel sheet. Fix it and try again!");
          setFailedEntriesModal(true);
          setFailedCandidatesArray(invalidDataArr);
        } else {
          // console.log("%c Zala", "color: #ff0000; font-size: 20px");
          setHandleNext(2);
          getApiToken(validDataArr);
        }
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  }
  function validateExcelObj(excelObj) {
    const validation = {
      isValid: true,
      reason: ''
    };

    if (excelObj["Full Name"] === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty Full Name";
    }
    if (excelObj?.Applicant === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty applicant id";
    }
    if (excelObj?.UID === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty UID";
    }
    if (excelObj?.examName === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam name";
    }
    if (excelObj?.examName !== examForm?.name) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Exam name not match";
    }
    if (excelObj?.examCenterName === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam center name";
    }
    else if (!allExamCenters.current.some(s => s.examCenterName === excelObj?.examCenterName)) {
      validation.isValid = false;
      validation.reason = validation.reason + ", No exam center present in exam";
    }
    if (excelObj?.Gender === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty gender";
    }
    if (excelObj?.City === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty city";
    }
    if (excelObj?.Gender?.toLowerCase() !== "male" && excelObj?.Gender?.toLowerCase() !== "female" && excelObj?.Gender?.toLowerCase() !== "transgender") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid gender";
    }
    if (excelObj?.Disability?.toLowerCase() !== "yes" && excelObj?.Disability?.toLowerCase() !== "no") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid disability";
    }
    if (excelObj?.examDate === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam date";
    }
    if (excelObj?.examTime === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam time";
    }

    if (!excelObj["Full Name"]) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing Full Name";
    }
    if (!excelObj?.Applicant) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing Applicant id";
    }
    if (!excelObj?.examName) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing exam name";
    }
    if (!excelObj?.examCenterName) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing exam center name";
    }
    if (!excelObj?.Gender) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing gender";
    }
    if (!excelObj?.City) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing city";
    }
    if (excelObj?.examDate === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam date";
    }
    if (excelObj?.examTime === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam time";
    }
    if (excelObj?.Disability === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty disability";
    }
    if (excelObj?.registrationDate === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty disability";
    }
    if (new Date(excelObj?.registrationDate).toString().includes("Invalid Date")) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid Registration Date : formate(MM/DD/YYYY)";
    }
    validation.reason = validation.reason.split(",").filter(f => f !== "").join(",");

    return { validation, excelObj };
  }
  function getApiToken(result) {
    getToken().then((token) => {
      // setHandleNext(3);
      console.log("token -> ", token);
      addToFirebase(result, token);
    }).catch((err) => {
      console.log('err: ', err);
      errorToast("Something went wrong! Try again");

      setHandleNext(0);
      setTotalDataUploading(0);
      setAddedCandidates(0);
      setDuplicateCandidatesCount(0);
      setDuplicateCandidates([]);
      setFailedCandidatesCount(0);
      setFailedCandidatesArray([]);
      setProgressDataModal(false)
    });
  }
  function addToFirebase(result, token) {
    setTotalDataUploading(result.length);
    // ? listen for new entries/duplicates
    var source = new EventSource(process.env.REACT_APP_NODE_API_URL + 'candidates/getAddCandidatesResult?token=' + token);
    source.addEventListener('message', function (e) {

      let data = JSON.parse(e.data);
      // ? response - you will get this response obj repeatedly
      // ? show the counts on the progress bar modal 
      //   {
      //     "addedCount": 2,
      //     "failedCount": 0,
      //     "failedArray": [],
      //     "duplicateCount": 1,
      //     "duplicateArray": [],
      //     "processFinished": true
      // }
      console.log(data);
      setAddedCandidates(data.addedCount);
      setDuplicateCandidatesCount(data.duplicateCount);
      // setDuplicateCandidates(duplicateCandidatesArr);
      setFailedCandidatesCount(data.failedCount);
      if (data.processFinished) {
        source.removeEventListener('message', this);
        source.close();
        // successTost(resp.message);
        setHandleNext(3);
        // setProgressDataModal(false);
        // setHandleNext(0);
        //! getAllStudents();
      }
      // }
    }, false);
    // ? upload to firebase
    uploadStudents(result, examForm.docID).then(res => {
      console.log("res.duplicateArray => ", res.duplicateArray);
      setDuplicateCandidates([...res.duplicateArray])
      // setDuplicateCandidatesCount(duplicateCandidates+res.duplicateArray.length);
      console.log("res.failedArray => ", res.failedArray);
      setFailedCandidatesArray([...res.failedArray])
    }).catch(err => {
      console.log(err);
    })
  }
  var uploadMultipleImages = (e) => {
    myRefname.current.click();
  };
  function uploadPhotos(result) {
    if (!result.target.files.length) {
      return;
    }
    setTotalImagesUploading(result.target.files.length);
    setPhotoUploadStepValue(0);
    setPhotoUploadLoadingStepperToggle(true);

    let chunkArray = createChunkArray([...result.target.files]);

    console.log("chunkArray =>", chunkArray);

    createBatchGetStudent(chunkArray).then((values) => {

      console.log("Get Student -> ", values);

      let error_list = values.filter(f => f.status === "error");
      setErrorUploadedListApplicant([...error_list]);
      let success_list = values.filter(f => f.status === "success");
      console.log("success_list => ", success_list);

      let fileToUpload = [];

      for (let i = 0; i < success_list.length; i++) {
        let fileObjectToUpload = {};
        const imageName = examForm.docID + '_' + success_list[i].data.id + '.' + success_list[i].file.name.split(".").pop();

        fileObjectToUpload.name = success_list[i].file.name;
        fileObjectToUpload.id = success_list[i].data.id;
        fileObjectToUpload.size = success_list[i].file.size;
        fileObjectToUpload.key = process.env.REACT_APP_bucketRootKey + "/" + success_list[i].data.id + '/photos/' + imageName;
        fileObjectToUpload.arrayBuffer = success_list[i].file;
        fileToUpload.push(fileObjectToUpload);
      }
      console.log("fileToUpload -> ", fileToUpload);
      if (fileToUpload.length > 0) {
        Promise.all(fileToUpload.map(m => toBase64(m.arrayBuffer))).then((photosValues) => {
          console.log("photosValues => ", photosValues);
          photosValues.forEach((ele, photoIndex) => {
            fileToUpload[photoIndex].arrayBuffer = new Buffer.from(ele.replace(/^data:image\/\w+;base64,/, ""), 'base64');
          });
          setPhotoUploadStepValue(1);

          let photoChunkArray = createChunkArray([...fileToUpload]);

          console.log("photoChunkArray => ", photoChunkArray);

          createBatchUploadPhotos(photoChunkArray).then(res => {
            if (res?.success) {
              setPhotoUploadStepValue(2);
              let updateStudentPromise = [];
              for (let i = 0; i < fileToUpload.length; i++) {
                let studentID = fileToUpload[i].id;
                let imageURL = fileToUpload[i].key;
                updateStudentPromise.push({ examDocID: examForm.docID, studentID, imageURL });
              }


              //! Chunk here
              let chunkArrayForUpdate = createChunkArray([...updateStudentPromise]);

              console.log("chunkArrayForUpdate", chunkArrayForUpdate);

              createBatchUpdateStudent(chunkArrayForUpdate).then((UpdateValues) => {
                console.log("updateStudentPromise => ", UpdateValues);
                setPhotoUploadStepValue(3);
                // generateModels(false);
                // setProgressUploadImages(false);
                successToast("Images upload successfully.")
              }).catch(err => {
                console.log(err);
                errorToast("Something went wrong! Try again");
                // setProgressUploadImages(false);
                setPhotoUploadLoadingStepperToggle(true);
              })
            } else {
              errorToast("Something went wrong! Try again");
              setPhotoUploadLoadingStepperToggle(false);
              setPhotoUploadStepValue(0);
              setErrorUploadedListApplicant([]);
              setUploadingImages(0);
              setUpdatingStudentPhotoCount(0);
            }
          })
        })
      } else {
        setPhotoUploadStepValue(3);
      }
    })
  }

  async function createBatchGetStudent(array) {
    let resultArr = [];
    return await new Promise(async (resolve, reject) => {

      for (let item of array) {

        let reap = await Promise.all(item.map(m => getStudentByApplicant(examForm.docID, m.name.split(".")[0], m)));

        resultArr = [...resultArr, ...reap];
      }
      resolve(resultArr);

      // for (let i = 0; i < array.length; i++) {
      //   const item = array[i];        
      //   Promise.all(item.map(m=>getStudentByApplicant(examForm.docID, m.name.split(".")[0], m))).then(res=>{
      //     resultArr = [...resultArr ,...res];
      //     if(i === array.length -1 ) {
      //         // console.log("resultArr return",resultArr)
      //       resolve(resultArr);
      //     }
      //   })
      // }
    })
  }

  async function createBatchUpdateStudent(array) {
    let resultArr = [];
    return await new Promise(async (resolve, reject) => {

      for (let item of array) {

        let reap = await Promise.all(item.map(m => updateStudentImage(m.examDocID, m.studentID, m.imageURL)));

        resultArr = [...resultArr, ...reap];
      }
      resolve(resultArr);


      // for (let i = 0; i < array.length; i++) {
      //   const item = array[i];        
      //   Promise.all(item.map(m=>updateStudentImage(m.examDocID, m.studentID, m.imageURL))).then(res=>{
      //     resultArr = [...resultArr ,...res];
      //     if(i === array.length -1 ) {
      //         // console.log("resultArr return",resultArr)
      //       resolve(resultArr);
      //     }
      //   })
      // }
    })
  }

  async function createBatchUploadPhotos(array) {
    return await new Promise(async (resolve, reject) => {

      function tryRecursion(array, index) {

        uploadFilesAWS(array[index], (data) => {
          if (data?.result.includes("Files Uploaded")) {
            index = index + 1;
            if (index === array.length) {
              resolve({ success: true });
            } else {
              tryRecursion(array, index)
            }
          } else {
            resolve({ success: false });
          }
        }, (err) => {
          console.log(err);
          resolve({ success: false });
        }, (progress) => {
          console.log("progress => ", progress.uploadedPercent);
          let per = Math.floor((progress.uploadedPercent + (index * 100)) / array.length);
          // setUploadingImages(progress.uploadedPercent);
          setUploadingImages(per);
        }
        );
      }

      tryRecursion(array, 0)

    })

  }

  function createChunkArray(arr) {
    return arr.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / process.env.REACT_APP_CHUNK_FOR_PHOTO_UPLOAD)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  function downloadExcelTemplate() {
    presentLoader();
    let studentList = [];
    const obj = {
      Applicant: "1",
      examName: examForm.name,
      registrationDate: new Date().toLocaleDateString(),
      invoice: new Date().toLocaleDateString(),
      paymentStatus: "done",
      examCenterName: "",
      examDate: "",
      examTime: "01:30 PM",
      email: "",
      phone: "",
      rollNo: "",
      roomNo: ""
    };
    // ? get fields from form
    for (let i = 0; i < examForm.formFields.length; i++) {
      const element = examForm.formFields[i];
      obj[element.fieldName] = '';
    }
    obj["Disability"] = 'Yes/No';
    obj["Gender"] = 'Male/Female';
    delete obj["Photo"];
    console.log('obj: ', obj);
    studentList.push(obj);

    const worksheet = XLSX.utils.json_to_sheet(studentList);
    const workBook = new XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, worksheet, "Students List");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.writeFile(workBook, `${examForm.name} Students List Template.xlsx`);
    dismissLoader();
  }

  function downloadStudents() {
    presentLoader();
    getAllStudentsByExam(examForm.docID).then(res => {

      if (res.empty) {
        errorToast("Student data not found.");
        dismissLoader();
        return;
      }

      let _list = res.docs.map(m => ({ UID: m.id, ...m.data() }));
      // console.log(_list);
      // console.log(_list.map(m => m.createdAt.toDate().toLocaleDateString()));

      // let keys = Object.keys(Object.assign({}, ..._list));
      let keys = [...examForm.formFields.map(m => m.fieldName),];

      let _studentList = [];
      _list.forEach((element) => {
        let obj = {
          Applicant: "",
          examName: examForm.name,
          registrationDate: element?.createdAt?.toDate()?.toLocaleDateString(),
          // invoice: new Date().toLocaleDateString(),
          paymentStatus: element?.paymentStatus ? element.paymentStatus : "",
          examCenterName: "",
          examDate: "",
          examTime: "01:30 PM",
          email: element?.email ? element?.email : "",
          phone: element?.phone ? element?.phone : "",
          rollNo: element?.rollNo ? element?.rollNo : "",
          roomNo: element?.roomNo ? element?.roomNo : ""
        };

        keys.forEach((ele) => {
          obj[ele] = element[ele] ? element[ele] : ""
        });
        _studentList.push(obj);
      });

      console.log(_studentList);


      const worksheet = XLSX.utils.json_to_sheet(_studentList);
      const workBook = new XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, worksheet, `Applicant List`);
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.writeFile(workBook, `${examForm.name} Applicant List.xlsx`);

      dismissLoader();
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      dismissLoader();
    })
  }

  function generateModels(showAlert) {
    let postBody = {
      "examId": examForm.docID
    }
    if (showAlert) {
      presentLoader();
    }
    createModel(postBody).then(res => {
      console.log(res);
      successToast("Models generating start.");
      if (showAlert) {
        dismissLoader();
      }
    }).catch(err => {
      console.log(err);
      errorToast("Something went wrong on models generating! Try again");
      if (showAlert) {
        dismissLoader();
      }
    })
  }

  async function updateStudentImage(examId, studentUID, imgUrl) {
    // console.log({examId,studentUID,imgUrl});
    return new Promise((resolve, reject) => {
      const collectionRef = doc(getFirestore(), 'exams/' + examId + '/EligibleStudents', studentUID);
      updateDoc(collectionRef, { Photo: imgUrl }).then((response) => {
        // console.log(`exams/${examId}/EligibleStudents/${studentUID} response: `, response);

        //* update student image in exams/${examId}/EligibleStudents/${studentUID}
        const hallTicketCollectionRef = doc(getFirestore(), 'exams/' + examId + '/hallticket', studentUID);
        updateDoc(hallTicketCollectionRef, { studentPhoto: imgUrl }).then((result) => {
          // console.log(`exams/${examId}/hallticket/${studentUID} result: `, result);
          // resolve(studentUID);
          resolve({ status: "success", Applicant: studentUID });
          setUpdatingStudentPhotoCount(pre => { return pre + 1 });
        }).catch(error => {
          console.log("hallTicketCollectionRef update error", `exams/${examId}/hallticket/${studentUID} err: `, error);
          // resolve(studentUID);
          resolve({ status: "error", Applicant: studentUID });
        });

      }).catch((err) => {
        console.log('student update err on : ', `exams/${examId}/EligibleStudents/${studentUID} response:`, err);
        resolve({ status: "error", Applicant: studentUID });
      });
    })
  }
  // ! functions to update photo url from eligible students to written exam (sub exam) in APC-CAR-DAR KK exam
  // ! doc ids are hard coded
  // async function updatePhotoUrl() {
  //   let obj = {
  //     updateCount: 0,
  //     lastObj: null,
  //     matchArr: [],
  //     mismatchArr: []
  //   }
  //   let updateCount = 0;
  //   let lastObj = null;
  //   // let lastObj = "GUZnmbNitNcFKuTGFvSPXh7yz1Q2";
  //   let totalStudents = Math.ceil(73428 / 100);
  //   // let totalStudents = Math.ceil(1000 / 100);
  //   console.log('totalStudents: ', totalStudents);
  //   let pageLimit = 100;
  //   let matchArr = [];
  //   let mismatchArr = [];
  //   const collectionRef = collection(getFirestore(), 'exams/Gg5snhkZBEsCdRJuuydX/EligibleStudents');
  //   for (let i = 0; i < totalStudents; i++) {
  //     console.log('--------------------------');
  //     console.log('batch no: ', i);
  //     obj = await updateInFb(collectionRef, obj.lastObj, pageLimit, obj.updateCount, obj.matchArr, obj.mismatchArr)
  //     if (i === totalStudents - 1)
  //       console.log('obj: ', obj);
  //   }
  // }
  // function updateInFb(collectionRef, lastObj, pageLimit, updateCount, matchArr, mismatchArr) {
  //   return new Promise((resolve, reject) => {
  //     let localCount = 0;
  //     let _query = query(collectionRef, orderBy("Applicant", "asc"), startAfter(lastObj), limit(pageLimit));  //* for first time visit and without search
  //     getDocs(_query).then(async (eStudentsResp) => {
  //       // console.log('eStudentsResp: ', eStudentsResp);
  //       lastObj = eStudentsResp.docs[eStudentsResp.docs.length - 1];
  //       for (const eStudent of eStudentsResp.docs) {
  //         console.log('eStudent: ', eStudent.data());
  //         const meritcollectionRef = doc(getFirestore(), 'exams/Gg5snhkZBEsCdRJuuydX/subExam/p9PEUgW7oJKufBvt6uDw/meritStudents', eStudent.id);
  //         await getDoc(meritcollectionRef).then(async (resp) => {
  //           console.log('resp: ', resp.data());
  //           updateCount++;
  //           localCount++;
  //           console.log('Checking for: ', updateCount);
  //           if (eStudent.data().Photo !== resp.data().Photo) {
  //             console.log('Photo url mismatch for ', resp.data().Applicant, ' ', resp.id);
  //             await updateDoc(meritcollectionRef, { "Photo": eStudent.data().Photo }).then((response) => {
  //               console.log('response: ', response);
  //               mismatchArr.push(resp.data());
  //               if (localCount === eStudentsResp.docs.length) {
  //                 // console.log('mismatchArr: ', mismatchArr);
  //                 resolve({ updateCount, lastObj, matchArr, mismatchArr })
  //                 // resolve(updateCount)
  //               }
  //             }).catch((err) => {
  //               console.log('err: ', eStudent.id, err);
  //             })
  //           } else {
  //             console.log('Photo url matching for ', resp.data().Applicant, ' ', resp.id);
  //             matchArr.push(resp.data());
  //             if (localCount === eStudentsResp.docs.length)
  //               // resolve(updateCount)
  //               resolve({ updateCount, lastObj, matchArr, mismatchArr })
  //             // resolve({ updateCount, matchArr, mismatchArr })
  //           }
  //         })
  //         // await updateDoc(meritcollectionRef, { "Photo": eStudent.data().Photo }).then((response) => {
  //         //   console.log('response: ', response);
  //         //   updateCount++;
  //         //   console.log('updateCount: ', updateCount);
  //         //   localCount++;
  //         //   if (localCount === eStudentsResp.docs.length)
  //         //     resolve(updateCount)
  //         // }).catch((err) => {
  //         //   console.log('err: ', eStudent.id, err);
  //         // })
  //       }
  //     }).catch(err => {
  //       console.log('err: ', err);
  //     });
  //   })
  // }
  return (
    <>
      <Box className={cssClasses.pageWrapper}>
        {/* Header 📑  */}
        <div className={cssClasses.pageHeader}>

          <div className={cssClasses.logoWrapper}>
            <img src={examForm.image} alt="IAS LOGO" />
            <div>
              <div><p className={cssClasses.examHeading}>{examForm.name}</p></div>
              <div className={cssClasses.endExamWrapper}>
                <p>Exam Date</p>
                <p>{examForm.examDate}</p>
              </div>
            </div>
          </div>

          <div className={cssClasses.numberBoxWrapper}>
            <div className={cssClasses.numberBox}>
              <p>Number of Exam Centers</p>
              <p>{examForm.examCenterAddedCount ? examForm.examCenterAddedCount : "0"}</p>
            </div>

            <div className={cssClasses.numberBox}>
              <p>Number of Students Applied</p>
              <p>{examForm.appliedStudentCount ? examForm.appliedStudentCount : "0"}</p>
            </div>

            <div className={cssClasses.numberBox}>
              <p>Zip Password</p>
              <p>{examForm.docID}</p>
            </div>
          </div>

          <div className={cssClasses.headerButtonWrapper}>
            <div>
              <IconButton
                size="large"
                edge="start"
                aria-label="open drawer"
                tittle={"Message"}
                onClick={handleClick}
              >
                <img src={moreVertical} alt="3 dots icon" />
              </IconButton>
              <input
                type="file"
                ref={myRefname}
                onChange={uploadPhotos}
                name="image"
                id="images"
                accept="image/*"
                hidden
                multiple
              />
              <input
                type="file"
                onChange={fileSelectForUploadStudent}
                name="image"
                id="fileSelectForUploadStudent"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {loggedInUser.tabAccess.exams.some(s => s === 'add') &&
                  <MenuItem sx={[menuItem]}
                    onClick={() => { handleClose(); document.getElementById("fileSelectForUploadStudent").click(); }}
                  >
                    {/* <Button
                      style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
                      btnIcon={uploadIcon} btnName={"Upload\xa0Students"}
                      clicked={() => { document.getElementById("fileSelectForUploadStudent").click(); }}
                      disable={!loggedInUser.tabAccess.exams.some(s => s === 'add')}
                    /> */}
                    <FileUploadIcon /> Upload Students
                  </MenuItem>
                }

                {loggedInUser.tabAccess.exams.some(s => s === 'add') &&
                  <MenuItem sx={[menuItem]}
                    onClick={() => { handleClose(); uploadMultipleImages() }}
                  >
                    {/* <Button
                      style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
                      btnIcon={uploadIcon} btnName={"Upload\xa0Students\xa0Photos"}
                      clicked={uploadMultipleImages}
                      disable={!loggedInUser.tabAccess.exams.some(s => s === 'add')}
                    /> */}
                    <FileUploadIcon /> Upload Students Photos
                  </MenuItem>
                }

                {loggedInUser.tabAccess.exams.some(s => s === 'add') &&
                  <MenuItem sx={[menuItem]}
                    onClick={() => { handleClose(); downloadExcelTemplate() }}
                  >
                    {/* <Button
                      style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
                      btnIcon={uploadIcon} btnName={"Download\xa0Template"}
                      clicked={downloadExcelTemplate}
                      disable={!loggedInUser.tabAccess.exams.some(s => s === 'add')}
                    /> */}
                    <CloudDownloadIcon /> Download Template
                  </MenuItem>
                }
                {/* { selectedMainTabs === "Hall Tickets" && */}
                {/* <MenuItem sx={[menuItem]}
                  onClick={() => { handleClose(); generateHallTicket() }}
                >
                  disable={!loggedInUser.tabAccess.exams.some(s => s === 'add')}
                  <ReceiptIcon /> Generate Hall Ticket
                </MenuItem> */}
                {/* } */}
                {/* { selectedMainTabs === "Applicants" && */}

                <MenuItem sx={[menuItem]}
                  onClick={() => { handleClose(); downloadStudents() }}
                >
                  <CloudDownloadIcon /> Download Applicants
                </MenuItem>

                {/* <MenuItem sx={[menuItem]}
                  onClick={() => { handleClose(); generateModels(true); }}
                >
                  <ConstructionIcon /> Generate Models
                </MenuItem> */}

                {/* } */}
                {/* // ! update photo url in sub exam 1 */}
                {/* <MenuItem sx={[menuItem]}
                  onClick={() => { handleClose(); updatePhotoUrl() }}
                >
                  <CloudDownloadIcon /> Update Photo Url
                </MenuItem> */}
              </Menu>
            </div>
          </div>

        </div>
        {/* end Header 📑  */}

        {/* Main Tabs */}
        <Box className={cssClasses.TabsWrapper}>
          <TabContext value={selectedMainTabs}>
            <TabsMenu
              selectedColor={"#6941C6"}
              selectedBackgroundColor={"#F9F5FF"}
              stylesForTab={{
                color: '#344054',
                fontSize: '1.6rem',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '2.4rem',
                padding: "0.8rem 1.2rem",
                textTransform: 'unset'
              }}
              stylesForWrapper={{
                padding: "1.5rem",
                background: "#FFFFFF",
              }}
              list={mainTabs}
              value={selectedMainTabs}
              handleChange={(e, value) => {
                setSelectedMainTabs(value);
                // setSearchParams({ tabValue: value });
              }}
            />
            <TabPanel className={cssClasses.tabPanel} value={"Details"} index={0}>
              <Box className={cssClasses.mainTabPanelWrapper}>
                <Details helperText={helperText} examForm={examForm} setExamForm={setExamForm} setHelperText={setHelperText} visibleSaveButton={true} examDocID={examForm.docID} ref={detailsRef} />
              </Box>
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"Exam Form"} index={1}>
              <Box className={cssClasses.mainTabPanelWrapper}>
                <ExamForm helperText={helperText} examForm={examForm} setExamForm={setExamForm} setHelperText={setHelperText} visibleSaveButton={true} examDocID={examForm.docID} ref={examFormRef} />
              </Box>
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"Applicants"} index={2}>
              {/* <StudentsWithSubExam /> */}
              <Applicants examDocID={examForm.docID} />
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"Merit List"} index={3}>
              {/* <Merit /> */}
              <MeritListMultipleExam examForm={examForm} subExams={subExams} setSubExams={setSubExams} />
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"Exam Schedule"} index={4}>
              <Box className={cssClasses.mainTabPanelWrapper}>
                <ExamSchedule examSchedule={examSchedule} setExamSchedule={setExamSchedule} applicationCloseDate={examForm.applicationCloseDate} visibleSaveButton={true} examDocID={examForm.docID} ref={examScheduleRef} setSubExams={setSubExams} />
              </Box>
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"Exam centers"} index={5}>
              <ExamCenter examDocID={examForm?.docID} examName={examForm?.name} />
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"Hall Tickets"} index={6}>
              {/* <HallTickets /> */}
              <HallTicket examDocID={examForm.docID} examObject={examForm} />
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"Admin Status"} index={7}>
              <AdminStatus examDocID={examForm?.docID} examName={examForm?.name} />
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"Models"} index={8}>
              <ModelExamCenterStatus examDocID={examForm?.docID} examName={examForm?.name} />
            </TabPanel>
          </TabContext>
        </Box>

      </Box>

      {/* * Generate hall Ticket Modal */}
      {generateHallTicketModalToggle &&
        <GenerateHallTicketModal open={generateHallTicketModalToggle} subExams={subExams} examForm={examForm} handleClose={() => { setGenerateHallTicketModalToggle(false); }} />
      }
      {
        progressDataModal &&
        <ProgressBarDataUpload
          open={progressDataModal}
          handleClose={() => {
            setHandleNext(0);
            setTotalDataUploading(0);
            setAddedCandidates(0);
            setDuplicateCandidatesCount(0);
            setDuplicateCandidates([]);
            setFailedCandidatesCount(0);
            setFailedCandidatesArray([]);

            setProgressDataModal(false)
          }}
          value={handleNext}
          totalDataUploading={totalDataUploading}
          addedCandidates={addedCandidates}

          examId={examForm.docID}

          duplicateCandidatesCount={duplicateCandidatesCount}
          duplicateCandidates={duplicateCandidates}

          failedCandidatesCount={failedCandidatesCount}
          failedCandidatesArray={failedCandidatesArray}
        />
      }
      <ProgressImagesUpload open={progressUploadImages}
        handleClose={() => setProgressUploadImages(false)}
        totalImagesUploading={totalImagesUploading}
        uploadingImages={uploadingImages}
      />
      <PhotoUploadLoadingStepper
        open={photoUploadLoadingStepperToggle}
        value={photoUploadStepValue}
        totalDataUploading={totalImagesUploading}
        failedCandidatesArray={errorUploadedListApplicant}
        uploadingImagesProgress={uploadingImages}
        updatingStudentPhotoCount={updatingStudentPhotoCount}
        failedCandidatesCount={errorUploadedListApplicant.length}
        handleClose={() => {
          setPhotoUploadLoadingStepperToggle(false);
          setPhotoUploadStepValue(0);
          setErrorUploadedListApplicant([]);
          setUploadingImages(0);
          setUpdatingStudentPhotoCount(0);
        }}
      />
      <FailedEntires openModal={failedEntriesModal} closeModal={() => { setFailedEntriesModal(false); setFailedCandidatesArray([]) }} failedCandidatesArray={failedCandidatesArray} />

    </>
  )
}


//* Merit Tab  ~~~~~~~~~~~~~~~~~~~~~~~~~~ 👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓
const MeritListMultipleExam = ({ examForm, subExams, setSubExams }) => {

  const [expanded, setExpanded] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const handleExpansionPanelChange = (panel) => {
    // console.log(panel);
    setExpanded(pre => pre === panel ? "" : panel);
  }
  return (
    <Box className={cssClasses.mainTabPanelWrapperForMultiExamMeritList}>
      {/* Use loop on Exam List */}
      <Accordion
        expanded={expanded === `Merit Tab EligibleStudents`}
        // onChange={handleExpansionPanelChange(`panel_0`)}
        sx={[{
          "& .MuiButtonBase-root": {
            cursor: "default !important"
          }
        }]}
        key={`Merit Tab EligibleStudents`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ cursor: "pointer" }} onClick={() => { handleExpansionPanelChange(`Merit Tab EligibleStudents`) }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className={cssClasses.multiExamMeritListHeader}>
            <p>Eligible Students</p>
            {/* <div>
                <Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={uploadIconBlack} btnName={"Upload\xa0merit\xa0list"} clicked={() => { document.getElementById("fileSelectForMultiMeritList").click(); }} />
                <input hidden
                  type="file"
                  name=""
                  id="fileSelectForMultiMeritList"
                  onChange={fileSelectForMultiMeritList}
                />
              </div> */}
          </div>
        </AccordionSummary>
        <AccordionDetails className={cssClasses.multiExamMeritListDetails}>
          {expanded === `Merit Tab EligibleStudents` && <EligibleStudents height={"50rem"} examDocID={examForm?.docID} examName={examForm.name} />}
        </AccordionDetails>

      </Accordion>
      {
        subExams.map((_subExamItem, _subExamIndex) => (
          <Accordion
            expanded={expanded === `Merit Tab ${_subExamIndex}`}
            // onChange={handleExpansionPanelChange(`panel_0`)}
            sx={[{
              "& .MuiButtonBase-root": {
                cursor: "default !important"
              }
            }]}
            key={`Merit Tab ${_subExamIndex}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ cursor: "pointer" }} onClick={() => { handleExpansionPanelChange(`Merit Tab ${_subExamIndex}`) }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className={cssClasses.multiExamMeritListHeader}>
                <p>{_subExamIndex + 1}.  {_subExamItem.examName}</p>
                {/* <div>
                    <Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={uploadIconBlack} btnName={"Upload\xa0merit\xa0list"} clicked={() => { document.getElementById("fileSelectForMultiMeritList").click(); }} />
                    <input hidden
                      type="file"
                      name=""
                      id="fileSelectForMultiMeritList"
                      onChange={fileSelectForMultiMeritList}
                    />
                  </div> */}
              </div>
            </AccordionSummary>
            <AccordionDetails className={cssClasses.multiExamMeritListDetails}>
              {
                expanded === `Merit Tab ${_subExamIndex}` &&
                <Merit height={"50rem"} isProforma={_subExamItem.isProforma} examDocID={examForm?.docID} subExamDocID={_subExamItem.docID} examName={examForm.name} subExamName={_subExamItem.examName} subExamObj={_subExamItem} setSubExams={setSubExams} />
              }
            </AccordionDetails>

          </Accordion>
        ))
      }

    </Box>
  )
}
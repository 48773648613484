import React, { useEffect, useState } from 'react'
import Button from '../../Subcomponent/Button'
import SearchBar from '../../Subcomponent/SearchBar'
import Styles from './AllExam.module.css'
import addIconWhite from '../../assets/images/add-icon-white.svg'
import filterIconBlack from '../../assets/images/filter-icon.svg'
import civilServicesExamIcon from '../../assets/images/exam-icon.svg'
import bankOfIndiaExamIcon from '../../assets/images/reserve-bank-of-india.svg'
import dotIcon from '../../assets/images/Dot.svg'
import { Link, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../Redux Store/actions'
import { getAllExams, getExams } from '../../services/ExamService'
import defaultImage from '../../assets/images/IAS_exam.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

// * import Service
import { presentLoader, dismissLoader } from "../../services/loaderService";
import Typography from '@mui/material/Typography'
import { getHallTicketWithPublishedExam } from '../../services/Firebase'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
}));

export default function AllExam() {

    const dispatch = useDispatch();
    
    const loggedInUser = useSelector((state) => state.Auth.user);

    const [exams, setExams] = useState([])
    const [searchText, setSearchText] = useState("")
    const [lastObj, setLastObj] = useState(null);
    const [isLoadMoreDisable, setIsLoadMoreDisable] = useState(false);
    const [isLoadingForLoadMore, setIsLoadingForLoadMore] = useState(false);
    useEffect(() => {
        dispatch(actions.activeMenu('exams'));
        presentLoader()
        getAllExams().then((resp) => {
            // console.log(resp);
            let allexams = []
            if (resp.docs.length > 0) {
                setLastObj(resp.docs.pop())
                resp.docs.forEach(element => {
                    let exam = element.data()
                    exam.id = element.id
                    allexams.push(exam)
                });
                setExams([...allexams]);
                
                console.log(allexams);
                // console.log('lastObj: ', lastObj);
            }
            dismissLoader();
        })
    }, [])

    function searchExam(e) {
        setIsLoadingForLoadMore(true);
        if (e === '') {
            setSearchText("")
            setExams([])
            getExams("", null).then((resp) => {
                let allexams = []
                setIsLoadMoreDisable(resp.docs.length < 9);
                if (resp.docs.length > 0) {
                    setLastObj(resp.docs.pop())
                    resp.docs.forEach((element) => {
                        let exam = element.data()
                        exam.id = element.id
                        allexams.push(exam)
                    })
                    setExams([...allexams]);
                    setIsLoadingForLoadMore(false);
                    console.log(allexams);
                }
            })
        } else {
            setSearchText(e)
            setExams([])
            getExams(e, null).then((resp) => {
                let allexams = []
                setIsLoadMoreDisable(resp.docs.length < 9);
                if (resp.docs.length > 0) {
                    setLastObj(resp.docs.pop())
                    resp.docs.forEach((element) => {
                        let exam = element.data()
                        exam.id = element.id
                        allexams.push(exam)
                    })
                    setExams([...allexams]);
                    setIsLoadingForLoadMore(false);
                    console.log(allexams);
                }
            })
        }
    }
    function getExamsFromFirebase() {
        setIsLoadingForLoadMore(true);
        getExams(searchText, lastObj).then((resp) => {
            let allexams = []
            setIsLoadMoreDisable(resp.docs.length < 9);
            if (resp.docs.length > 0) {
                setLastObj(resp.docs.pop())
                resp.docs.forEach((element) => {
                    let exam = element.data()
                    exam.id = element.id
                    allexams.push(exam)
                })
                setExams(pre=>[...pre,...allexams]);
                setIsLoadingForLoadMore(false);
                console.log(allexams);
            }
        })
    }
    const navigate = useNavigate();
    function goToExamDetails(_examShortName) {
        navigate(`/home/exam-details/${_examShortName}`);
    }
    const examCard = exams.map((exam, index) => {
        return (

            <Grid item xs={4} sm={4} md={4} key={"examCard" + index}>
                <Item onClick={() => { goToExamDetails(exam.examShortName) }}>
                    <div className={Styles.examCard} key={exam.key}>
                        <div className={Styles.examCardFirstRow}>
                            <div className={Styles.paddingDiv}>
                                <div className={Styles.imageWrapper}>
                                    <div className={Styles.imageSection}>
                                        {exam.image == '' ?
                                            <img src={defaultImage} alt={""}/>
                                            :
                                            <img src={exam.image} alt={""}/>
                                        }
                                    </div>
                                    {new Date(exam.applicationCloseDate) > new Date() ?
                                        <div className={Styles.statusWrapperGreen}>
                                            <div className={Styles.dot}>
                                                <img src={dotIcon} alt={""}/>
                                            </div>
                                            <div className={Styles.statusTextGreen}>
                                                <p>Registration Open</p>
                                            </div>
                                        </div>
                                        :
                                        <div className={Styles.statusWrapperRed}>
                                            <div className={Styles.dot}>
                                                <FiberManualRecordIcon style={{ width: "1rem", height: "1rem", color: "red" }} />
                                            </div>
                                            <div className={Styles.statusTextRed}>
                                                <p>Registration Closed</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={Styles.nameWrapper}>
                                    <p className={Styles.examName}>{exam.name}</p>
                                    <p className={Styles.label}>{exam.examShortName}</p>
                                </div>
                            </div>
                        </div>
                        <div className={Styles.paddingDiv}>
                            <div className={Styles.info}>
                                <div className={Styles.examDetailsWrapper}>
                                    <p className={Styles.inputLabel}>Exam Fee</p>
                                    <p className={Styles.inputValue}>₹ {exam.fee}</p>
                                </div>
                                <div className={Styles.examDetailsWrapper}>
                                    <p className={Styles.inputLabel}>Exam Date</p>
                                    <p className={Styles.inputValue}>{exam.examDate}</p>
                                </div>
                                <div className={Styles.examDetailsWrapper}>
                                    <p className={Styles.inputLabel}>Payment type</p>
                                    <p className={Styles.inputValue}>{exam.paymentType === "Both" ? "Online/Offline" : exam.paymentType}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
            </Grid>

        )
    })

    return (
        <div className={Styles.examContainer}>
            <div className={Styles.titleContainer}>
                <div className={Styles.firstRow}>
                    <div className={Styles.titleWrapper}>
                        <div className={Styles.title}>
                            <p>All Exam</p>
                        </div>
                        <div className={Styles.subtitle}>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
                        </div>
                    </div>
                    <div className={Styles.btnWrapper}>
                        {/* <div className={Styles.uploadBtn}>
                            <Button style={{ color: "#000", backgroundColor: "#fff" }} btnIcon={uploadIconBlack} btnName={"Upload Exam"} clicked={UploadStudentData} />
                        </div> */}
                        {/* <Link to='/home/add-exam'> */}
                            <div className={Styles.addExamBtn}>
                                <Button style={{ color: "#fff", backgroundColor: "#7F56D9" }} btnIcon={addIconWhite} btnName={"Add Exam"}
                                    disable={!loggedInUser.tabAccess.exams.some(s=> s==='add')} clicked={() => {navigate("/home/add-exam")}}
                                />
                            </div>
                        {/* </Link> */}
                    </div>
                </div>
                <div className={Styles.secondRow}>
                    <div className={Styles.searchBarWrapper}>
                        <div className={Styles.searchBar}>
                            <SearchBar placeholder={'Search for Exam'} search={(_value) => {searchExam(_value)}} />
                        </div>
                    </div>
                    <div className={Styles.filterBtn}>
                        {/* <Button style={{ color: "#000", backgroundColor: "#fff" }} btnIcon={filterIconBlack} btnName={"Filters"} clicked={filter} /> */}
                    </div>
                </div>
            </div>
            <div className={Styles.examWrapper}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        { exams.length ? examCard : (
                            <Grid item xs={4} sm={8} md={12}>
                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography color={"#667085"} fontSize={"2rem"}> No exam found </Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </div>
            {(exams.length % 9 === 0 && !isLoadMoreDisable && exams.length) &&
                <div style={{ display: "flex", "justifyContent": "flex-end", marginTop: "1rem" }}>
                    <div>
                        <Button 
                            style={{ color: "#000", backgroundColor: "#fff" }} 
                            btnName={"Load More"} 
                            clicked={getExamsFromFirebase} 
                            isLoading={isLoadingForLoadMore}
                            disable={isLoadingForLoadMore}
                            loadingColor={"#667085"}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

import { Modal } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Button from '../../Subcomponent/Button'
import crossImage from '../../assets/images/cross.svg'
import shuffleIcon from '../../assets/images/shuffle.svg'
import cssClasses from './PreviewExamFormModal.module.css'
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@mui/material/TextField";
import Dropdown from '../../Subcomponent/Dropdown'
import * as _ from 'lodash'
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import uploadImageIcon from "../../assets/images/upload-image-icon.svg";
import DatePicker from "@mui/lab/DatePicker";
import { isEmailValid, isPhoneNumberValid, isRequiredFieldValid } from '../../DefaultData/Validations';

const useStyles = makeStyles((theme) => ({
    Modal: {
        borderRadius: "10px 10px 10px 10px",
    },
    textField: {
        width: "100%",
    },
    helperText: {
        fontSize: "1.2rem !important",
        margin: "0.2rem 0 0 0 !important",
        color : "red"
    },
    input: {
        fontSize: "1.6rem !important",
        lineHeight: "2.4rem !important",
        background: '#FFFFFF',
        border: '1px solid #D0D5DD',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        borderRadius: '8px',
        "& input": {
          padding: "1rem 1.4rem",
          border: "none !important",
          boxShadow: "none !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none"
        },
        "&.Mui-focused": {
          border: "1px solid #D6BBFB !important",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
        },
        "&.Mui-error":{
          border: "1px solid red !important"
        },
      },
    datePickerWrapper: {
        width: "100%",
    },
    datePicker: {
        "flex-direction": "row-reverse",
        fontSize: "1.4rem !important",
        "& fieldSet": {
            border: "none",
        },
        marginTop: "0.6rem",
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #D0D5DD !important",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
        borderRadius: "0.8rem",
        "& input": {
            width: "100%",
            fontSize: "1.4rem !important",
            fontSize: "1.4rem !important",
            padding: "1rem 1.4rem !important",
        },
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #D6BBFB !important",
                boxShadow:
                    "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
            },
        },
        "&:hover": {
            // border:"none",
            "& .MuiOutlinedInput-notchedOutline": {
                // border: '1px solid #D0D5DD !important'
            },
        },
    },
    calender: {
        "& div": {
            fontSize: "1.4rem !important",
        },
        "& span": {
            fontSize: "1.4rem !important",
        },
        "& button": {
            fontSize: "1.4rem !important",
        },
    },
}))

export default function PreviewExamFormModal({ open, handleClose, Form }) {
    const [form, setForm] = useState([])
    useEffect(() => {
        console.log('Form: ', Form);
        setForm(_.cloneDeep(Form))
    }, [Form])

    const classList = useStyles()
    const radioButtonStyle = {
        ".MuiFormControlLabel-label": {
            fontSize: "1.4rem!important",
            fontFamily: "'Inter-Medium'",
        },
    };
    return (
        <Modal
            className={classList.Modal}
            open={open}
            // open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={[
                {
                    ".MuiBackdrop-root": {
                        background: "rgba(193, 201, 210, 0.7)"
                    }
                }
            ]}
            disableAutoFocus={true}
            disableEnforceFocus={true}
            disableRestoreFocus={true}
        >
            <Box className={cssClasses.modalWrapper}>
                <div className={cssClasses.header}>
                    {/* <img src={shuffleIcon} alt="" /> */}
                    <h1>Exam Form Preview</h1>
                    <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={handleClose} />
                </div>
                <div className={cssClasses.mainContainer}>
                    {form.map((element, index) => (
                        <div key={`${element.fieldName}_preview_${index}`}>
                            {element.fieldType === "Single Line Text" &&
                                <div className={cssClasses.inputDiv}>
                                    <label>
                                        {element.fieldName}{element.isRequired === true && <span style={{ color: "red" }}> *</span>}
                                    </label>
                                    <TextField
                                        InputProps={{ className: classList.input }}
                                        FormHelperTextProps={{
                                            classes: {
                                                root: classList.helperText,
                                            },
                                        }}
                                        value={element.value || "" }
                                        onChange={(newValue) => {
                                            if (newValue.target.value === '' && element.isRequired) {
                                                setForm((preValue) => {
                                                    preValue[index].helperText = "Please enter " + element.fieldName;
                                                    return [...preValue];
                                                });
                                            } else {
                                                setForm((preValue) => {
                                                    preValue[index].helperText = ''
                                                    return [...preValue];
                                                });
                                                setForm((preValue) => {
                                                    preValue[index].value = newValue.target.value
                                                    return [...preValue];
                                                });
                                            }
                                            
                                        }}
                                        className={classList.textField}
                                        error={element.helperText && element.isRequired && element.helperText !== "" ? true : false}
                                        id="outlined-basic"
                                        sx={[
                                            {
                                                "&>div:first-of-type": {
                                                    border:
                                                        element.helperText && element.isRequired && element.helperText !== ""
                                                            ? "1px solid red !important"
                                                            : "1px solid #D0D5DD !important",
                                                },
                                                width: "100%",
                                            },
                                        ]}
                                        helperText={element.helperText}
                                        variant="outlined"
                                    />
                                </div>
                            }
                            {(element.fieldType === "Number" || element.fieldType === "Phone No.") &&
                                <div className={cssClasses.inputDiv}>
                                    <label>
                                        {element.fieldName}{element.isRequired === true && <span style={{ color: "red" }}> *</span>} 
                                    </label>
                                    <TextField
                                        type="text"
                                        InputProps={{ className: classList.input }}
                                        FormHelperTextProps={{
                                            classes: {
                                                root: classList.helperText,
                                            },
                                        }}
                                        value={element?.value || ""} 
                                        onChange={(newValue) => {
                                            var numbers = /^[0-9]*$/;
                                            if(newValue.target.value.match(numbers) || newValue.target.value === '') {
                                                if (element.fieldType === "Phone No.") {
                                                    if(newValue.target.value.length <= 10){
                                                        setForm((preValue) => {
                                                            preValue[index].value = newValue.target.value;
                                                            return [...preValue];
                                                        });
                                                        let isValid = isPhoneNumberValid(newValue.target.value,element.fieldName).helperText;
                                                        setForm((preValue) => {
                                                            preValue[index].helperText = isValid;
                                                            return [...preValue];
                                                        });
                                                    }
                                                } else{
                                                    setForm((preValue) => {
                                                        preValue[index].value = newValue.target.value;
                                                        return [...preValue];
                                                    });
                                                    let isValid = isRequiredFieldValid(newValue.target.value,element.fieldName).helperText;
                                                    setForm((preValue) => {
                                                        preValue[index].helperText = isValid;
                                                        return [...preValue];
                                                    });
                                                }
                                               
                                            }
                                        }}
                                        sx={[
                                            {
                                                "&>div:first-of-type": {
                                                    border:
                                                        element.helperText && element.isRequired && element.helperText !== ""
                                                            ? "1px solid red !important" :
                                                            element.helperText && element.helperText !== '' && !element.isRequired
                                                                ? "1px solid red !important"
                                                                : "1px solid #D0D5DD !important",
                                                },
                                                width: "100%",
                                            },
                                        ]}
                                        className={classList.textField}
                                        error={element.helperText && element.isRequired && element.helperText !== "" ? true : false}
                                        id="outlined-basic"
                                        helperText={element.helperText}
                                        variant="outlined"
                                    />
                                </div>
                            }
                            {element.fieldType === "Email" &&
                                <div className={cssClasses.inputDiv}>
                                    <label>
                                        {element.fieldName}{element.isRequired == true && <span style={{ color: "red" }}> *</span>}
                                    </label>
                                    <TextField
                                        type="email"
                                        InputProps={{ className: classList.input }}
                                        FormHelperTextProps={{
                                            classes: {
                                                root: classList.helperText,
                                            },
                                        }}
                                        // value={element.value}
                                        onChange={(newValue) => {
                                            if ((newValue.target.value == '' || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newValue.target.value))) && element.isRequired) {
                                                setForm((preValue) => {
                                                    preValue[index].helperText = "Please enter correct " + element.fieldName;
                                                    return [...preValue];
                                                });
                                            } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newValue.target.value)) && !element.isRequired && newValue.target.value !== '') {
                                                setForm((preValue) => {
                                                    preValue[index].helperText = 'Please enter correct ' + element.fieldName
                                                    return [...preValue];
                                                });
                                            } else {
                                                setForm((preValue) => {
                                                    preValue[index].helperText = ''
                                                    return [...preValue];
                                                });
                                            }
                                            // setHelperText((prevalue) => {
                                            //     prevalue.isNameFilled = "";
                                            //     return { ...prevalue };
                                            // });
                                        }}
                                        className={classList.textField}
                                        error={element.helperText && element.isRequired && element.helperText !== "" ? true : element.helperText && element.helperText !== '' && !element.isRequired ? true : false}
                                        id="outlined-basic"
                                        sx={[
                                            {
                                                "&>div:first-of-type": {
                                                    border:
                                                        element.helperText && element.isRequired && element.helperText !== ""
                                                            ? "1px solid red !important" :
                                                            element.helperText && element.helperText !== '' && !element.isRequired
                                                                ? "1px solid red !important"
                                                                : "1px solid #D0D5DD !important",
                                                },
                                                width: "100%",
                                            },
                                        ]}
                                        helperText={element.helperText}
                                        variant="outlined"
                                    />
                                </div>
                            }
                            {element.fieldType === "Dropdown" &&
                                <div className={cssClasses.inputDiv}>
                                    <label>
                                        {element.fieldName}{element.isRequired == true && <span style={{ color: "red" }}> *</span>}
                                    </label>
                                    <Dropdown
                                        // isError={helperText.isTypeFilled}/
                                        list={element.options}
                                        selectedValue={element.selectedValue}
                                        onChangeSelect={(e) => element.selectedValue = e}
                                    />
                                </div>
                            }
                            {element.fieldType === 'Multiple choice' &&
                                <div className={cssClasses.inputDiv}>
                                    <FormControl style={{ fontSize: "1.4rem" }}>
                                        <label>
                                            {element.fieldName}{element.isRequired == true && <span style={{ color: "red" }}> *</span>}
                                        </label>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            // value={examForm.paymentType}
                                            style={{ display: "unset" }}
                                            onChange={(e) => {
                                                // setExamForm((preValue) => {
                                                //     preValue.paymentType = e.target.value;
                                                //     return { ...preValue };
                                                // });
                                                // setHelperText((prevalue) => {
                                                //     prevalue.isPaymentTypeFilled = "";
                                                //     return { ...prevalue };
                                                // });
                                            }}
                                        >
                                            {element.options.map((element, index) => (
                                                <FormControlLabel
                                                    key={`${element.fieldName}_preview_MultipleChoice_${index}_${element}_${index}`}
                                                    value={element}
                                                    sx={[radioButtonStyle]}
                                                    control={
                                                        <Radio
                                                            size="medium"
                                                            sx={{
                                                                color: "default",
                                                                "&.Mui-checked": {
                                                                    color: "#7F56D9",
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={element}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                    {/* {helperText.isPaymentTypeFilled !== "" && (
                                        <p style={{ color: "red", marginTop: "0.2rem", fontSize: "1rem" }}>
                                            {helperText.isPaymentTypeFilled}
                                        </p>
                                    )} */}
                                </div>
                            }
                            {element.fieldType === 'Checkboxes' &&
                                <div className={cssClasses.inputDiv}>
                                    <FormControl style={{ fontSize: "1.4rem" }}>
                                        <label>
                                            {element.fieldName}{element.isRequired == true && <span style={{ color: "red" }}> *</span>}
                                        </label>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            // value={examForm.paymentType}
                                            style={{ display: "unset" }}
                                            onChange={(e) => {
                                                // setExamForm((preValue) => {
                                                //     preValue.paymentType = e.target.value;
                                                //     return { ...preValue };
                                                // });
                                                // setHelperText((prevalue) => {
                                                //     prevalue.isPaymentTypeFilled = "";
                                                //     return { ...prevalue };
                                                // });
                                            }}
                                        >
                                            {element.options.map((element, index) => (
                                                <FormControlLabel
                                                    key={`${element.fieldName}_preview_Checkboxes_${index}_${element}_${index}`}
                                                    value={element}
                                                    sx={[radioButtonStyle]}
                                                    control={
                                                        <Checkbox
                                                            size="medium"
                                                            sx={{
                                                                color: "default",
                                                                "&.Mui-checked": {
                                                                    color: "#7F56D9",
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={element}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                    {/* {helperText.isPaymentTypeFilled !== "" && (
                                        <p style={{ color: "red", marginTop: "0.2rem", fontSize: "1rem" }}>
                                            {helperText.isPaymentTypeFilled}
                                        </p>
                                    )} */}
                                </div>
                            }
                            {element.fieldType === 'Date' &&
                                <div className={cssClasses.inputDiv} style={{ display: "flex", flexDirection: "column" }}>
                                    <label>
                                        {element.fieldName}{element.isRequired == true && <span style={{ color: "red" }}> *</span>}
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            // value={new Date(examForm.applicationCloseDate)}
                                            minDate={new Date()}
                                            PopperProps={{ placement: "bottom-start" }}
                                            PaperProps={{ className: classList.calender }}
                                            InputProps={{ className: classList.datePicker, error: true }}
                                            onChange={(newValue) => {
                                                // setExamForm((preValue) => {
                                                //     preValue.applicationCloseDate = newValue == null ? '' : new Date(newValue).toLocaleDateString();
                                                //     return { ...preValue };
                                                // });
                                                // setHelperText((prevalue) => {
                                                //     prevalue.isCloseDateFilled = "";
                                                //     return { ...prevalue };
                                                // });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    // helperText={helperText.isCloseDateFilled}
                                                    FormHelperTextProps={{
                                                        classes: {
                                                            root: classList.helperText,
                                                        },
                                                    }}
                                                    // sx={[
                                                    //     {
                                                    //         "&>div:first-of-type": {
                                                    //             border:
                                                    //                 helperText.isCloseDateFilled !== ""
                                                    //                     ? "1px solid red !important"
                                                    //                     : "1px solid #D0D5DD !important",
                                                    //         },
                                                    //         width: "100%",
                                                    //     },
                                                    // ]}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            }
                            {(element.fieldType === 'Upload Image' || element.fieldType === "Upload PDF" ) &&
                                <div className={cssClasses.inputDiv}>
                                    <label>
                                        {element.fieldName}{element.isRequired == true && <span style={{ color: "red" }}> *</span>}
                                    </label>
                                    {/* {element.image === "" && */}
                                    <div
                                        className={cssClasses.imageUploadWrapper
                                            // helperText.isImgUploaded == ""
                                            //     ? cssClasses.imageUploadWrapper
                                            //     : cssClasses.redImageUploadWrapper
                                        }
                                        onClick={() => {
                                            document.getElementById("imageInput").click();
                                        }}
                                    // onDrop={(e) => { console.log(e);e.preventDefault() }}
                                    // onDragEnd={(e) => { e.preventDefault(); console.log(e) }}
                                    // onDragOver={(e) => { e.preventDefault() }}
                                    >

                                        <img src={uploadImageIcon} />
                                        <p className={cssClasses.uploadImageText}>
                                            <span className={cssClasses.uploadImageSpan}>Click to upload </span>or
                                            drag and drop
                                        </p>
                                        <p className={cssClasses.uploadImageText}>
                                            { element.fieldType === 'Upload Image' ? 'SVG, PNG, JPG or GIF (max. 400x400px)' : 'PDF (max. 400x400px)'}
                                        </p>
                                    </div>
                                    {/* } */}
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        accept="image/*"
                                        id="imageInput"
                                    // onChange={}
                                    />
                                    {/* {element.image !== "" &&
                                        // <div>
                                        //   <img src={examForm.image} alt="" srcset="" />
                                        // </div>
                                        <div className={cssClasses.row}>
                                            <div className={cssClasses.column} id={"caption"}>
                                                <span className={cssClasses.text}>
                                                    <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Change Image"} clicked={() => { document.getElementById("imageInput").click() }} />
                                                </span>
                                                <img src={form.image} />
                                            </div>
                                        </div>

                                    } */}
                                    {/* {helperText.isImgUploaded !== "" && (
                                        <p style={{ color: "red", marginTop: "0.2rem", fontSize: "1.4rem" }}>
                                            {helperText.isImgUploaded}
                                        </p>
                                    )} */}
                                </div>
                            }
                        </div>
                    ))
                    }
                </div>
                <div className={cssClasses.footer}>
                    <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Close"} clicked={handleClose} /></div>
                    {/* <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Save"} /></div> */}
                </div>
            </Box>
        </Modal>
    )
}

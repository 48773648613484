import React, { useEffect } from "react"
import MainPage from "./pages/MainPage";
import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";
import AllStudents from "./pages/StudentsScreen/StudentsScreen";
import AllExamCenters from "./pages/ExamCentersScreen/ExamCentersScreen";
import AllExams from "./pages/Exams/AllExam";
import AddExam from "./Modals/AddExam/AddExam";
import HallTicketWrapper from "./pages/HallTicketScreen/HallTicketScreen";
import ExamDetail from "./pages/ExamDetailsScreen/ExamDetailsScreen";
import Dashboard from "./pages/Dashboard/Dashboard";
import AdminScreen from "./pages/AdminScreen/AdminScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogIn from "./pages/LogIn/LogIn";

//* store
import action from "./Redux Store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Cities } from "./DefaultData/Cities";

//* import modals
import Loading from "./Modals/Loader/Loading";

function App() {
  const dispatch = useDispatch();
  dispatch(action.authCheck());
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
  
  const AppWrapper = () => { 
    const loggedInUser = useSelector((state) => state.Auth.user);
    
    let routes = useRoutes([
      {
        path: '/', element: <LogIn />,
      },
      {
        path: '/home',
        element: isAuthenticated ? <MainPage /> : <Navigate to="/" />,
        children: [
          {
            path: '', 
            element: isAuthenticated ? <Navigate to='/home/exams' /> : <Navigate to="/" />
          },
          {
            path: 'admins',
            element: loggedInUser?.tabAccess?.admins?.length > 0 ? <AdminScreen /> : <Navigate to="/" />,
          },
          {
            path: 'students',
            element: loggedInUser?.tabAccess?.student?.length > 0 ? <AllStudents /> : <Navigate to="/" />,
          },
          {
            path: 'exam-centers',
            element: loggedInUser?.tabAccess?.examCenter?.length > 0 ? <AllExamCenters /> : <Navigate to="/" />
          },
          {
            path: 'exams',
            element: loggedInUser?.tabAccess?.exams?.length > 0 ? <AllExams /> : <Navigate to="/" />
          },
          {
            path: 'add-exam',
            element: loggedInUser?.tabAccess?.exams?.some(s => s === 'add') ? <AddExam /> : <Navigate to="/" />
          },
          {
            path: 'exam-details/:examShortName',
            element: (loggedInUser?.tabAccess?.exams?.some(s => s === 'view') || loggedInUser?.tabAccess?.exams?.some(s => s === 'edit')) ? <ExamDetail /> : <Navigate to="/" />
          },
          {
            path: 'hall-tickets',
            element: loggedInUser?.tabAccess?.hallTickets?.length > 0 ? <HallTicketWrapper /> : <Navigate to="/" />
          },
          {
            path: 'dashboard',
            element: loggedInUser?.tabAccess?.dashboard?.length > 0 ? <Dashboard /> : <Navigate to="/" />
          },
        ]
      },
    ]);
    return routes;
  }
  return (
    <div style={{height: "100%"}}>
      <Router>
        <AppWrapper />
      </Router>
      <ToastContainer style={{ fontSize: "1.5rem" }} />
      {/* {isLoading && } */}<Loading />
    </div>
  );
}

export default App;

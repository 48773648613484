import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function TabsMenu({ borderColor = "none", stylesForTab, stylesForWrapper, selectedColor, selectedBackgroundColor, list, value, handleChange }) {
  // selectedColor ,
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        style={stylesForWrapper}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        sx={[
          {
            "& .Mui-selected": {
              color: `${selectedColor} !important`,
              background: selectedBackgroundColor,
              borderRadius: "0.8rem",
            },
            "& 	.MuiTabs-indicator": {
              display: borderColor === "none" ? "none" : "",
              backgroundColor: borderColor === "none" ? "white" : borderColor,
            },
            // "& .MuiTabs-flexContainer": {
            //   flexWrap: "wrap",
            // },
          },
        ]}
      >
        {list &&
          list.map((item, index) => (
            <Tab
              style={{ ...stylesForTab, fontFamily: "Inter-Medium" }}
              key={index + item.value}
              value={item.value}
              label={item.text}
            />
          ))}
      </Tabs>
    </Box>
  );
}

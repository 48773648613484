import React, { useEffect, useState } from 'react'

//* import MUI
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from "@mui/material/Switch";

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

//* import Images
import shuffleIcon from "../../assets/images/shuffle.svg";
import crossImage from "../../assets/images/cross.svg";

//* import sub-component
import Button from "../../Subcomponent/Button";
import Dropdown from "../../Subcomponent/Dropdown";
import DropdownCheckBox from "../../Subcomponent/DropdownCheckBox";

//* import Css
import cssClasses from "./AddAdmin.module.css";

//* import Service
import { presentLoader, dismissLoader } from "../../services/loaderService";
import { successToast, errorToast } from "../../services/Toast"
import { isEmailValid, isPhoneNumberValid, isRequiredFieldValid, isPasswordFieldValid, isConfirmPasswordFieldValid } from '../../DefaultData/Validations';
import { adminAuthCreate, setAdmin, getAdminByID, updateAdmin } from '../../services/AdminsService';
import { resetAuthPassword, updatePassword } from '../../services/Firebase';
import { getAllExamForHallTicket, getExamScheduleById } from "../../services/ExamService";
import { getAllExamCentersInExam, getExamCenters } from "../../services/ExamCenterService"
import Typography from '@mui/material/Typography'
import { cloneDeep, sortBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    fontSize: "1.4rem !important",
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "&.Mui-error": {
      border: "1px solid red !important"
    },
    "& input": {
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      // border: "unset",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&.MuiSelect-outlined": {
      border: "1px solid #D0D5DD !important",
    },
    "& fieldSet": {
      border: "none",
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
    "& p": {
      fontSize: "1.4rem",
    },
    "&.MuiSelect-select": {
      border: "1px solid #D6BBFB !important",
    },
  },
  helperText: {
    fontSize: "1.4rem !important",
    margin: "0.2rem 0 0 0 !important",
  },
}));

const checkBoxStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: "1.4rem!important",
    fontFamily: "'Inter-Medium'",
    textTransform: 'capitalize',
  },
};
const ParentCheckBoxStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: "1.5rem!important",
    fontFamily: "'Inter-Medium'",
    textTransform: 'capitalize',
  },
};

const switchStyle = {
  ".MuiSwitch-thumb": {
    color: "#7F56D9"
  },
  ".MuiSwitch-track": {
    background: "#7F56D9",
  }
}

export default function AddAdmin({ open, handleClose, adminDocId = null }) {
  const classList = useStyles();

  const [adminField, setAdminField] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    examAccess: [],
    isActive: true,
    isModelAccess: false,
    tabAccess: {
      "dashboard": [],
      "student": [],
      "examCenter": [],
      "exams": [],
      "hallTickets": [],
      "admins": [],
    },
    menuAccess: {
      "Upload Offline Data": false,
      "Add Students": false,
      "Add Student Photos": false,
      "Download Local Saved Data": false,
      "Merge Data": false,
      "Download Student Reports": false,
      "Download Present Student Reports": false,
      "Download Absent Student Reports": false,
      "Download Student Reports With Photos": false,
      "Download Final ResultSheet Reports": false,
      "Download Candidate Report As Per Unit": false,
      "Sync Data": false,
      "Qualified Report Sheet": false,
      "Clear Cache": false,
    }
  });
  const [errorField, setErrorField] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

  const [loadingForEditDataFetch, setLoadingForEditDataFetch] = useState(false);

  const [ExamsList, setExamsList] = useState([]);
  const [examsCenterList, setExamsCenterList] = useState([]);

  const [resetPasswordModalToggler, setResetPasswordModalToggler] = useState(false)

  useEffect(() => {
    if (adminDocId) {
      setLoadingForEditDataFetch(true);
      getAdminByID(adminDocId).then(res => {
        // console.log(res);
        if (res.exists) {
          let obj = cloneDeep(adminField.menuAccess)
          let _admin = res.data();
          let _adminCreate = adminField;
          // _admin.examAccess = [];
          Object.keys(_admin).forEach((ele) => {
            _adminCreate[ele] = _admin[ele];
          })
          console.log(_adminCreate)
          for (const iterator of Object.keys(obj)) {
            if (!_adminCreate.menuAccess[iterator]) {
              _adminCreate.menuAccess[iterator] = false
            }
          }
          setAdminField(Object.assign({}, _adminCreate));
          console.log({_adminCreate});

          // if(_adminCreate?.examAccess && _adminCreate?.examAccess !== "") {
          //   onSelectExam(_adminCreate?.examAccess);
          // }
          getExamsFromServer();
          setLoadingForEditDataFetch(false);
        } else {
          handleClose(false);
        }
      }).catch(err => {
        console.log(err);
        handleClose(false);
      })
    } else {
      getExamsFromServer();
    }
  }, [adminDocId]);

  // useEffect(() => {
  //   getExamsFromServer();
  // }, [])


  function getExamsFromServer() {
    getAllExamForHallTicket().then(res => {
      // console.log(res);
      let _list = res.docs.map(m => ({ label: m.data().name, value: m.id }));
      Promise.all(_list.map(m => getSubExamsFromServer(m))).then(result => {
        // console.log(result);
        // console.log(adminField.examAccess);

        // console.log(result.filter(f=> adminField.examAccess.some(s=>s.value === f.value)))

        setExamsList([...result])
      }).catch(err => {
        console.log(err);
        errorToast("Something went wrong! Try again");
      })
    })
  }

  function getSubExamsFromServer(obj) {
    return new Promise((resolve, reject) => {
      getExamScheduleById(obj.value).then(res => {
        let subExamList = res.docs.map(m => ({ label: m.data().examName, value: m.id }));
        getAllExamCentersInExam(obj.value).then(response => {
          let examCenterList = response.docs.map(m => ({ label: m.data().examCenterName, value: m.id }));
          resolve({ ...obj, subExamList, examCenterList })
        })
      })
    })
  }
  function onSelectExam(_selectedExams) {
    if (_selectedExams.length > adminField.examAccess.length) {
      let _addElement = ExamsList.filter(m => _selectedExams.filter(f => !adminField.examAccess.some(s => s.value === f)).find(s => m.value === s));
      setAdminField(pre => {
        pre.examAccess = [...pre.examAccess, ..._addElement];
        return { ...pre };
      });
    } else {
      let _deleteElement = _selectedExams.filter(f => adminField.examAccess.some(s => s.value === f))
      let filterArray = adminField.examAccess.filter(f => _deleteElement.some(s => s === f.value))
      setAdminField(pre => {
        pre.examAccess = [...filterArray];
        return { ...pre };
      });
    }
  }

  function onSelectSubExam(_selectedSubExams, examUid) {
    let pre = JSON.parse(JSON.stringify(adminField));
    let _examIndex = pre?.examAccess?.findIndex(f => f?.value === examUid);
    let _subExamList = ExamsList.filter(f => adminField?.examAccess?.some(s => s.value === f.value)).find(f => f.value === examUid)?.subExamList;
    _subExamList = JSON.parse(JSON.stringify(_subExamList));
    pre.examAccess[_examIndex].subExamList = _subExamList.filter(f => _selectedSubExams?.some(s => s===f?.value)).map(m => ({...m, isAccess: true}));

    setAdminField(Object.assign({}, JSON.parse(JSON.stringify(pre))));
  }

  function onSelectExamCenter(_selectedExamCenters, examUid) {
    let pre = JSON.parse(JSON.stringify(adminField));
    let _examIndex = pre?.examAccess?.findIndex(f => f?.value === examUid);
    let _subExamList = ExamsList.filter(f => adminField?.examAccess?.some(s => s.value === f.value)).find(f => f.value === examUid)?.examCenterList;
    _subExamList = JSON.parse(JSON.stringify(_subExamList));
    pre.examAccess[_examIndex].examCenterList = _subExamList.filter(f => _selectedExamCenters?.some(s => s===f?.value)).map(m => ({...m, isAccess: true}));

    setAdminField(Object.assign({}, JSON.parse(JSON.stringify(pre))));
  }

  function onSelectCenterExam(_examCenterID) {
    console.log(_examCenterID);
    setAdminField(pre => {
      pre.examCenterAccess = _examCenterID;
      pre.examCenterNameAccess = examsCenterList.find(f => f.value === _examCenterID)?.label;
      return { ...pre };
    });
  }

  function checkAllValidation(skipPassword) {
    let name = isRequiredFieldValid(adminField.name, "name");
    let phone = isPhoneNumberValid(adminField.phone, "mobile number");
    let email = isEmailValid(adminField.email);
    let password = isPasswordFieldValid(adminField.password);
    let confirmPassword = isConfirmPasswordFieldValid(adminField.confirmPassword, adminField.password);

    let validation = {}

    if (skipPassword) {
      validation = { name, phone, email }
    } else {
      validation = { name, phone, email, password, confirmPassword }
    }

    let flag = true;

    Object.keys(validation).forEach((validKey) => {
      let _isValid = validation[validKey];
      if (!_isValid.flag) {
        flag = false;
      }
      let _errorField = errorField;
      _errorField[validKey] = _isValid.helperText;
      setErrorField(Object.assign({}, _errorField));
    });

    return flag;
  }

  async function saveAdmin() {
    let isAllFieldValid = checkAllValidation(false);
    if (isAllFieldValid) {
      presentLoader();
      adminAuthCreate(adminField.email, adminField.password).then((userRes) => {
        let postBody = Object.assign({}, adminField);
        postBody.nameForSearch = postBody.name.toLowerCase();
        setAdmin(userRes.user.uid, postBody).then(res => {
          successToast("Admin added successfully.");
          dismissLoader();
          handleClose(true);
        }).catch(err => {
          console.log("set err => ", err);
          errorToast(err?.code)
          dismissLoader();
        })
      }).catch(err => {
        console.log("auth err => ", err);
        console.log("auth err => ", err?.code);
        errorToast(err?.code)
        dismissLoader();
      })
    }
  }

  async function editAdmin() {
    let isAllFieldValid = checkAllValidation(true);
    if (isAllFieldValid) {
      presentLoader();
      let postBody = Object.assign({}, adminField);
      postBody.nameForSearch = postBody.name.toLowerCase();
      updateAdmin(adminDocId, postBody).then(res => {
        console.log(res);
        dismissLoader();
        handleClose(true);
        successToast("Admin edited successfully.");
      }).catch(err => {
        console.log("set err => ", err);
        errorToast(err.toString())
        dismissLoader();
      })
    }
  }

  async function resetPassword() {
    // resetAuthPassword(adminField.email).then(res=>{
    //   console.log(res);
    //   successToast("email send for reset password.");
    //   handleClose(false);
    // }).catch(err=>{
    //   console.log(err);
    //   errorToast(err.toString())
    // })

    setResetPasswordModalToggler(true)
  }

  // useEffect(() => {
  //   console.log("adminField => ", adminField.examAccess);
  //   console.log("ExamsList => ", ExamsList);
  // }, [adminField, ExamsList])


  return (
    <>
      <Modal
        open={open}
        onClose={() => { handleClose(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={[
          {
            ".MuiBackdrop-root": {
              background: "rgba(193, 201, 210, 0.7)"
            }
          }
        ]}
        disableAutoFocus={true}
      >
        <Box className={cssClasses.modalWrapper}>
          <div className={cssClasses.header}>
            {/* <img src={shuffleIcon} alt="" /> */}
            <h1>{adminDocId ? "Edit" : "Add"} Admin</h1>
            <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={() => { handleClose(false) }} />
          </div>

          <div className={cssClasses.mainContainer}>
            {loadingForEditDataFetch ?
              <div style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress style={{ color: "#7F56D9" }} />
              </div> :
              <>
                <div className={cssClasses.inputWrapper}>
                  <label>Name</label>
                  <TextField
                    InputProps={{ className: classList.input }}
                    className={classList.textField}
                    id="outlined-basic"
                    FormHelperTextProps={{
                      classes: {
                        root: classList.helperText,
                      },
                    }}
                    value={adminField.name}
                    onChange={(e) => {
                      let _helperText = isRequiredFieldValid(e.target.value, "name").helperText;
                      setAdminField(pre => {
                        pre.name = e.target.value;
                        return Object.assign({}, pre);
                      });
                      setErrorField(pre => {
                        pre.name = _helperText;
                        return { ...pre }
                      })
                    }}
                    helperText={errorField.name}
                    error={errorField.name !== ""}
                  />
                </div>

                <div className={cssClasses.inputWrapper}>
                  <label>Mobile Number</label>
                  <TextField
                    InputProps={{ className: classList.input }}
                    type={"number"}
                    className={classList.textField}
                    id="outlined-basic"
                    FormHelperTextProps={{
                      classes: {
                        root: classList.helperText,
                      },
                    }}
                    value={adminField.phone}
                    onChange={(e) => {
                      let _helperText = isPhoneNumberValid(e.target.value, "phone").helperText;
                      setAdminField(pre => {
                        pre.phone = e.target.value;
                        return Object.assign({}, pre);
                      });
                      setErrorField(pre => {
                        pre.phone = _helperText;
                        return { ...pre }
                      })
                    }}
                    helperText={errorField.phone}
                    error={errorField.phone !== ""}
                  />
                </div>

                <div className={cssClasses.inputWrapper}>
                  <label>Email</label>
                  <TextField
                    InputProps={{ className: classList.input }}
                    className={classList.textField}
                    id="outlined-basic"
                    FormHelperTextProps={{
                      classes: {
                        root: classList.helperText,
                      },
                    }}
                    value={adminField.email}
                    onChange={(e) => {
                      let _helperText = isEmailValid(e.target.value, "email").helperText;
                      setAdminField(pre => {
                        pre.email = e.target.value;
                        return Object.assign({}, pre);
                      });
                      setErrorField(pre => {
                        pre.email = _helperText;
                        return { ...pre }
                      })
                    }}
                    helperText={errorField.email}
                    error={errorField.email !== ""}
                  />
                </div>

                {!adminDocId &&
                  <>
                    <div className={cssClasses.inputWrapper}>
                      <label>Password</label>
                      <TextField
                        className={classList.textField}
                        id="outlined-basic"
                        type={passwordShow ? "text" : "password"}
                        FormHelperTextProps={{
                          classes: {
                            root: classList.helperText,
                          },
                        }}
                        value={adminField.password}
                        InputProps={{
                          endAdornment: <InputAdornment position="start" onClick={() => { setPasswordShow(!passwordShow) }} style={{ cursor: "pointer" }}>
                            {
                              !passwordShow ? <VisibilityOffOutlinedIcon style={{ fontSize: "2.5rem" }} /> : <VisibilityOutlinedIcon style={{ fontSize: "2.5rem" }} />
                            }
                          </InputAdornment>,
                          className: classList.input
                        }}

                        onChange={(e) => {
                          setAdminField(pre => {
                            pre.password = e.target.value;
                            return Object.assign({}, pre);
                          });
                          let _helperText = isPasswordFieldValid(e.target.value, "password").helperText;
                          setErrorField(pre => {
                            pre.password = _helperText;
                            return { ...pre }
                          })
                        }}
                        helperText={errorField.password}
                        error={errorField.password !== ""}
                      />
                    </div>

                    <div className={cssClasses.inputWrapper}>
                      <label>Re-enter Password</label>
                      <TextField
                        className={classList.textField}
                        id="outlined-basic"
                        FormHelperTextProps={{
                          classes: {
                            root: classList.helperText,
                          },
                        }}
                        value={adminField.confirmPassword}
                        type={confirmPasswordShow ? "text" : "password"}
                        InputProps={{
                          endAdornment: <InputAdornment position="start" onClick={() => { setConfirmPasswordShow(!confirmPasswordShow) }} style={{ cursor: "pointer" }}>
                            {
                              !confirmPasswordShow ? <VisibilityOffOutlinedIcon style={{ fontSize: "2.5rem" }} /> : <VisibilityOutlinedIcon style={{ fontSize: "2.5rem" }} />
                            }
                          </InputAdornment>,
                          className: classList.input
                        }}
                        onChange={(e) => {
                          setAdminField(pre => {
                            pre.confirmPassword = e.target.value;
                            return Object.assign({}, pre);
                          });
                          let _helperText = isConfirmPasswordFieldValid(e.target.value, adminField.password).helperText;
                          setErrorField(pre => {
                            pre.confirmPassword = _helperText;
                            return { ...pre }
                          })
                        }}
                        helperText={errorField.confirmPassword}
                        error={errorField.confirmPassword !== ""}
                      />
                    </div>
                  </>
                }

                {/* <div className={cssClasses.inputWrapper}>
                  <label>Exam Access</label>
                  <Dropdown 
                    list={ExamsList} 
                    selectedValue={adminField.examAccess}
                    onChangeSelect={onSelectExam}
                  />
                </div> */}

                <div className={cssClasses.inputWrapper}>
                  <label>Exam Access</label>
                  <DropdownCheckBox
                    list={ExamsList}
                    value={adminField.examAccess.map(m => m.value)}
                    onChangeSelect={onSelectExam}
                  />
                  {adminField.examAccess.length > 0 &&
                    <div className={cssClasses.inputWrapper} style={{ marginTop: "1rem" }}>
                      <label>Sub-exams & Center Access</label>
                      <div className={cssClasses.checkBoxWrapper} style={{width: "100%"}}>
                        {ExamsList.length > 0 &&
                          // adminField.examAccess.map((examItem,index) => (
                          ExamsList.filter(f => adminField.examAccess.some(s => s.value === f.value)).map((examItem, index) => (
                            <div key={`${examItem.value}_${index}`} style={{width: "100%"}}>
                              
                              <Typography fontSize={"1.5rem"} fontWeight={600}>{examItem.label}</Typography>
                              <Box ml={2}>
                                <Typography fontSize={"1.5rem"} >Sub-exams</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} ml={3}>
                                  {adminField.examAccess?.find(f => f.value === examItem.value)?.subExamList.length > 0 ?
                                    <>
                                      <DropdownCheckBox
                                        list={examItem?.subExamList || []}
                                        value={adminField?.examAccess?.find(s => s?.value === examItem?.value)?.subExamList?.filter(f => f?.isAccess)?.map(m => m?.value) || []}
                                        onChangeSelect={(_val) => onSelectSubExam(_val, examItem?.value)}
                                        isSearchable={true}
                                      />
                                    </>
                                    :
                                    <Typography fontSize={"1.2rem"} >Sub exams not found</Typography>
                                  }
                                </Box>
                              </Box>

                              <Box ml={2}>
                                <Typography fontSize={"1.5rem"} >Exams Centers</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} ml={3}>
                                  {(adminField.examAccess?.find(f => f.value === examItem.value)?.examCenterList.length > 0) ?
                                    <>
                                      {/* <Box> */}
                                        <DropdownCheckBox
                                          list={ExamsList.filter(f => adminField?.examAccess?.some(s => s.value === f.value))?.find(f => f.value === examItem.value)?.examCenterList}
                                          value={adminField?.examAccess?.find(s => s?.value === examItem?.value)?.examCenterList?.filter(f => f?.isAccess)?.map(m => m?.value) || []}
                                          onChangeSelect={(_val) => onSelectExamCenter(_val, examItem?.value)}
                                          isSearchable={true}
                                          customDropdownMenu={{
                                            "& .MuiPaper-root": {
                                              minWidth: "80rem !important",
                                              width: "80rem !important",
                                              height: "40rem",
                                              "& .MuiMenuItem-root": {
                                                whiteSpace: 'break-spaces'
                                              }
                                            }
                                          }}
                                        />
                                      {/* </Box> */}
                                    </>
                                    :
                                    <Typography fontSize={"1.2rem"} >Exam centers not found.</Typography>
                                  }
                                </Box>
                              </Box>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  }
                </div>

                <div className={cssClasses.inputWrapper}>
                  <label>Active Status</label>
                  <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
                    <div>In Active</div>
                    <Switch
                      sx={[switchStyle]}
                      edge="end"
                      onChange={(e) => {
                        let _admin = adminField;
                        _admin.isActive = e.target.checked;
                        setAdminField({ ..._admin })
                      }}
                      checked={adminField.isActive}
                      color="default"
                    />
                    <div>Active</div>
                  </Box>
                </div>

                <div className={cssClasses.inputWrapper}>
                  <label>Model Access</label>
                  <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
                    <div>No</div>
                    <Switch
                      sx={[switchStyle]}
                      edge="end"
                      onChange={(e) => {
                        let _admin = adminField;
                        _admin.isModelAccess = e.target.checked;
                        setAdminField({ ..._admin })
                      }}
                      checked={adminField.isModelAccess}
                      color="default"
                    />
                    <div>Yes</div>
                  </Box>
                </div>

                <div className={cssClasses.inputWrapper}>
                  <label>Tab Access</label>
                  <div className={cssClasses.checkBoxWrapper}>
                    {
                      Object.keys(adminField.tabAccess).map((tab, index) => (
                        <div key={`${tab}_${index}`}>
                          <FormControlLabel
                            label={tab}
                            sx={[ParentCheckBoxStyle]}
                            id={`${tab}_${index}`}
                            control={
                              <Checkbox
                                checked={adminField.tabAccess[tab].length !== 0}
                                onChange={(e) => {
                                  let pre = adminField;
                                  if (e.target.checked) {
                                    if (tab === "dashboard") {
                                      pre.tabAccess[tab].push("view");
                                    } if (tab === "hallTickets") {
                                      pre.tabAccess[tab].push("view");
                                      // pre.tabAccess[tab].push("add");
                                    }
                                    else {
                                      pre.tabAccess[tab].push("view");
                                      pre.tabAccess[tab].push("add");
                                      pre.tabAccess[tab].push("edit");
                                      // pre.tabAccess[tab].push("delete");
                                    }
                                  } else {
                                    pre.tabAccess[tab] = [];
                                  }
                                  setAdminField(Object.assign({}, pre));
                                }}
                              />
                            }
                          />
                          <Box sx={{ display: 'flex', flexDirection: 'row', ml: 3 }}>
                            {tab === "dashboard" &&

                              <FormControlLabel
                                key={`dashboard View`}
                                id={`dashboard View`}
                                label={'view'}
                                sx={[checkBoxStyle]}
                                control={<Checkbox checked={adminField.tabAccess[tab].some(s => s === 'view')} onChange={(e) => {
                                  let pre = adminField;
                                  if (e.target.checked) {
                                    pre.tabAccess[tab].push("view");
                                  } else {
                                    var index = pre.tabAccess[tab].indexOf("view");
                                    console.log(index);
                                    if (index > -1) {
                                      pre.tabAccess[tab].splice(index, 1);
                                    }
                                  }
                                  setAdminField(Object.assign({}, pre));
                                }} />}
                              />
                            }
                            {tab === "hallTickets" &&
                              ['view'].map((parameters, innerIndex) => (
                                <FormControlLabel
                                  key={`${parameters}_${innerIndex}`}
                                  id={`${parameters}_${innerIndex}`}
                                  label={parameters}
                                  sx={[checkBoxStyle]}
                                  control={<Checkbox checked={adminField.tabAccess[tab].some(s => s === parameters)} onChange={(e) => {
                                    let pre = adminField;
                                    if (e.target.checked) {
                                      pre.tabAccess[tab].push(parameters);
                                    } else {
                                      var index = pre.tabAccess[tab].indexOf(parameters);
                                      console.log(index);
                                      if (index > -1) {
                                        pre.tabAccess[tab].splice(index, 1);
                                      }
                                    }
                                    setAdminField(Object.assign({}, pre));
                                  }} />}
                                />
                              ))
                            }
                            {(tab !== "dashboard" && tab !== "hallTickets") &&
                              ['view', 'add', 'edit'].map((parameters, innerIndex) => (
                                <FormControlLabel
                                  key={`${parameters}_${innerIndex}`}
                                  id={`${parameters}_${innerIndex}`}
                                  label={parameters}
                                  sx={[checkBoxStyle]}
                                  control={<Checkbox checked={adminField.tabAccess[tab].some(s => s === parameters)} onChange={(e) => {
                                    let pre = adminField;
                                    if (e.target.checked) {
                                      pre.tabAccess[tab].push(parameters);
                                    } else {
                                      var index = pre.tabAccess[tab].indexOf(parameters);
                                      console.log(index);
                                      if (index > -1) {
                                        pre.tabAccess[tab].splice(index, 1);
                                      }
                                    }
                                    setAdminField(Object.assign({}, pre));
                                  }} />}
                                />
                              ))
                            }

                          </Box>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className={cssClasses.inputWrapper}>
                  <label>Menu Access</label>
                  {sortBy(Object.keys(adminField.menuAccess)).map((m,mi) => (
                    <div
                      key={`menu-access-${mi}`}
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox id={m}
                        checked={adminField.menuAccess[m]}
                        onChange={(e) => {
                          console.log(e.target.checked)
                          setAdminField((pre) => {
                            if (e.target.checked) {
                              pre.menuAccess[m] = true
                            } else {
                              pre.menuAccess[m] = false
                            }
                            console.log(pre.menuAccess)
                            return { ...pre }
                          })
                        }}
                      />
                      <label style={{ cursor: 'pointer' }} htmlFor={m}>{m}</label>
                    </div>
                  ))}
                </div>
              </>
            }
          </div>



          <div className={cssClasses.footer}>
            {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
            <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Cancel"} clicked={() => { handleClose(false) }} /></div>
            {adminDocId && <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Reset Password"} clicked={() => { resetPassword() }} /></div>}
            <div>
              <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Save"}
                clicked={() => {
                  if (adminDocId) {
                    editAdmin()
                  } else {
                    saveAdmin();
                  }
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      <ResetPasswordModal
        open={resetPasswordModalToggler}
        handleClose={() => {
          setResetPasswordModalToggler(false)
        }}
        adminDocId={adminDocId}
      />
    </>
  )
}


function ResetPasswordModal({ open, handleClose, adminDocId = null }) {

  const classList = useStyles();

  const [password, setPassword] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");

  function resetPassword() {
    let _valid = isPasswordFieldValid(password);
    setPasswordHelperText(_valid.helperText)

    if (adminDocId && password && _valid.flag) {
      presentLoader();
      updatePassword({ uid: adminDocId, password: password }).then(res => {
        console.log(res);
        if (res.data.success) {
          successToast("Password updated successfully.");
          dismissLoader();
          handleClose(true)
        } else {
          errorToast("Something went wrong! Try again");
          dismissLoader();
        }
      }).catch(err => {
        console.log(err);
        errorToast("Something went wrong! Try again");
        dismissLoader();
      })
      // updatePassword({uid: adminDocId, password: password}).then(res=>{
      //   console.log(res);
      // }).catch(err=> {
      //   console.log(err);
      // })
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => { handleClose(false) }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root": {
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper} width={'80rem'} height={'auto'}>
        <div className={cssClasses.header}>
          {/* <img src={shuffleIcon} alt="" /> */}
          <h1>Reset Admin Password</h1>
          <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={() => { handleClose(false) }} />
        </div>

        <div className={cssClasses.mainContainer}>
          <div className={cssClasses.inputWrapper}>
            <label>New Password</label>
            <TextField
              InputProps={{ className: classList.input }}
              className={classList.textField}
              id="outlined-basic"
              FormHelperTextProps={{
                classes: {
                  root: classList.helperText,
                },
              }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                let _valid = isPasswordFieldValid(e.target.value);
                setPasswordHelperText(_valid.helperText)
              }}
              helperText={passwordHelperText}
              error={passwordHelperText !== ""}
            />
          </div>

        </div>

        <div className={cssClasses.footer}>
          <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Reset Password"} clicked={resetPassword} disable={!isPasswordFieldValid(password).flag} /></div>
          <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Cancel"} clicked={() => { handleClose(false) }} /></div>
        </div>
      </Box>
    </Modal>
  )
}
import React, { useEffect, useState, useRef } from "react";
//* images
import filterIcon from "../../assets/images/filter-icon.svg";
import avatar from "../../assets/images/Avatar.svg";
import uploadIconBlack from "../../assets/images/uploadIconBlack.svg";
import ListAltIcon from '@mui/icons-material/ListAlt';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import DownloadIcon from '@mui/icons-material/Download';
import uploadFileCloud from "../../assets/images/uploadFileCloud.svg";
import man from "../../assets/images/man.png";
import woman from "../../assets/images/woman.png";
import user_icon from "../../assets/images/user_icon.png"
import moreVertical from "../../assets/images/more-vertical.svg";
import FileUploadIcon from '@mui/icons-material/FileUpload';

//* css
import cssClasses from "./Merit.module.css";

//* import MUI
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import { CircularProgress, Modal, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//* import Sub-components
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";
import MeritStudentUploadLoadingStepper from "../../Modals/MeritStudentUploadLoadingStepper/MeritStudentUploadLoadingStepper";
import MeritStudentAddLoadingStepper from "../../Modals/MeritStudentAddLoadingStepper/MeritStudentAddLoadingStepper";
import DownloadMeritStudentExcelSheet from "../../Modals/DownloadMeritStudentExcelSheet/DownloadMeritStudentExcelSheet";

//* import services
import { getMeritStudents, setMeritStudent, getAllMeritStudents, getAllMeritStudentsByUploadedForm, getAllQualifiedMeritStudents, getAllMeritStudentsByExamCenterName, getAllMeritStudentsWithLimit, deleteMeritStudent, setDuplicateMeritStudent, getMeritStudentsForExcelDownloadByPagination, setDownloadedExamCenterExcel } from "../../services/Merit";
import { updateExamSchedule } from "../../services/ExamService";
import { uploadFilesAWS } from "../../services/AwsBucket";
import { successToast, errorToast } from "../../services/Toast";
import { presentLoader, dismissLoader } from "../../services/loaderService";

import * as XLSX from "xlsx";
import { forEach, groupBy, uniqBy, zip } from "lodash";
import axios from "axios";
import { Buffer } from 'buffer';

import { Workbook } from 'exceljs';
import * as fileSaver from 'file-saver';
import { useSelector } from "react-redux";
import { collection, getCountFromServer, getFirestore, query, where } from "firebase/firestore"
import SelectExamCenterForDownloadResultExcelSheet from "../../Modals/SelectExamCenterForDownloadResultExcelSheet/SelectExamCenterForDownloadResultExcelSheet";

import { getAllExamCentersInExam } from "../../services/ExamCenterService";
import { Merge } from "@mui/icons-material";
import SelectExcelForDownloadStudentExcelSheet from "../../Modals/SelectExcelForDownloadStudentExcelSheet/SelectExcelForDownloadStudentExcelSheet";
const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected": {
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
    paddingRight: '1rem'
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
    paddingRight: '1rem'
  },
  "& .MuiButtonBase-root": {
    borderRadius: '0'
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "hidden !important"
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "7.2rem !important",
    minHeight: "7.2rem !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",
    minHeight: "7.2rem !important",
    maxHeight: "7.2rem !important",
    outline: 'none !important',
  },
  "& .MuiButtonBase-root": {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '0 1rem'
  },
  "& .MuiTablePagination-actions": {
    margin: '0 2rem',
  }
}

const chipStatusStyle = {
  fontSize: "1.5rem",
  textTransform: 'capitalize',
  // fontWeight : "600",
  padding: "0.8rem",
  height: "auto",
  "& .MuiChip-icon": {
    fontSize: '1rem',
  }
}
const blueChip = {
  // border: "1px solid #EEF4FF",
  color: "#175CD3",
  background: "#EFF8FF",
}
const greenChip = {
  // border: "1px solid #EEF4FF",
  color: "#027A48",
  background: "#ECFDF3",
}
const redChip = {
  // border: "1px solid #EEF4FF",
  color: "#B42318",
  background: "#FEF3F2",
}
const menuItem = {
  fontSize: "1.4rem",
  ".MuiSvgIcon-root": {
    width: '2rem',
    height: '2rem',
    marginRight: "1rem",
    color: '#6941C6'
  }
}
export default function Merit({ height = false, examDocID = null, subExamDocID = null, examName = "", subExamName = "", isProforma, subExamObj = null, setSubExams }) {

  const loggedInUser = useSelector((state) => state.Auth.user);

  const meritColumns = [
    {
      field: 'fileName', headerName: 'File Name', width: 300, sortable: false,
      renderCell: (params) => (
        <div className={cssClasses.imageCellWrapper}>
          <img src={process.env.REACT_APP_bucketAccessRootPath + params.value.img} alt="" onError={(e) => { e.target.onerror = null; e.target.src = params.value.onErrorImg; }} />
          <div>
            <p>{params.value.name}</p>
            {params.value.email && <p>{new Array(params.value.email?.split('@')[0].length).join('*') + "@" + params.value.email?.split('@')[1]}</p>}
          </div>
        </div>
      )
    },
    { field: 'Applicant', headerName: 'Applicant no.', sortable: false, width: 131 },
    { field: 'Gender', headerName: 'Gender', sortable: false, width: 131 },
    // { field: 'examName', headerName: 'Exam Name', sortable: false, width: 250 },
    // { field: 'createdAt', headerName: 'Registration Date', sortable: false, width: 150, 
    //   renderCell : (params) => {
    //     // console.log(new Date(params['value'].toDate()).toDateString());
    //     return (
    //       <div>{new Date(params['value']?.toDate())?.toDateString()}</div>
    //     )
    //   }
    // },
    { field: 'paymentMethod', headerName: 'Payment Method', sortable: false, width: 150 },
    {
      field: 'paymentStatus',
      headerName: 'Payment',
      width: 150,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api.getAllColumns().filter((c) => c.field !== '__check__' && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)),);
          return onClickDoneApplicant(thisRow);
        };

        // return <p onClick={onClick} className={ params.row[params.field] === "pending" ? cssClasses.pendingButton : cssClasses.doneButton}>{params.row[params.field]}</p>;
        return <Chip label={params['value']} size={"medium"} sx={[{ ...chipStatusStyle, ...params['value'] === "done" ? greenChip : params['value'] === "pending" ? redChip : blueChip }]} />;
      },
    },
    {
      field: 'isVerified', headerName: 'Verified Status', sortable: false, width: 150,
      renderCell: (params) => {
        return <Chip label={params?.value === true ? "Verified" : "Not Verified"} size={"medium"} sx={[{ ...chipStatusStyle, ...params?.value === true ? blueChip : redChip }]} />;

      }
    },
    { field: 'invoice', headerName: 'Invoice', sortable: false, width: 150 },
    {
      field: '', headerName: 'Download Fingerprint', sortable: false, width: 200,
      renderCell: (params) => {
        function onDownloadClick() {
          const element = document.createElement("a");

          let text = `Fingerprint Template 1 = ${params?.row?.fingerprintTemplate1}`;

          if (params?.row?.fingerprintTemplate2 && params?.row?.fingerprintTemplate2 !== "") {
            text = `${text} \n\n\n\nFingerprint Template 2 = ${params?.row?.fingerprintTemplate2}`
          }

          const file = new Blob([text], {
            type: "text/plain",
          });
          element.href = URL.createObjectURL(file);
          element.download = `${params?.row["Full Name"]}_Fingerprint Template.txt`;
          document.body.appendChild(element);
          element.click();
        }

        return (
          <>
            {(params?.row?.fingerprintTemplate1 || params?.row?.fingerprintTemplate2) && <Typography fontSize={"1.4rem"} color={"#6941c6"} style={{ cursor: "pointer", textDecoration: "underline" }} onClick={onDownloadClick}> Download Template </Typography>}
          </>
        )
      }
    },
  ]

  const [anchorEl, setAnchorEl] = useState(null);

  const [isDataPresent, setIsDataPresent] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [totalMeritStudent, setTotalMeritStudent] = useState(0);
  const [totalStudentsVerified, setTotalStudentsVerified] = useState(0);

  const [meritStudentList, setMeritStudentList] = useState([]);
  const [serverMetaDataList, setServerMetaDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageMaintain, setPageMaintain] = useState(0);
  const [meritStudentSearchValue, setMeritStudentSearchValue] = useState("");

  const [dataGridLoading, setDataGridLoading] = useState(true);
  const [searchField, setSearchField] = useState("studentFullNameForSearch");

  //* for upload
  const [progressBarDataUploadModalToggle, setProgressBarDataUploadModalToggle] = useState(false);

  const [meritStudentUploadLoadingStepperValue, setMeritStudentUploadLoadingStepperValue] = useState(0);

  const [totalDataUploading, setTotalDataUploading] = useState(0);
  const [invalid_EntriesModalToggle, setInvalid_EntriesModalToggle] = useState(false)
  const [invalid_Entries, setInvalid_Entries] = useState([])
  const [addedMeritStudentCount, setAddedMeritStudentCount] = useState(0);

  const [uploadingPdfProgress, setUploadingPdfProgress] = useState(null);

  const [addStudentExcelFile, setAddStudentExcelFile] = useState(null);

  //* result sheet download
  const [stepperValueForResultSheet, setStepperValueForResultSheet] = useState(0);
  const [stepperToggleForResultSheet, setStepperToggleForResultSheet] = useState(false);
  const [stepperPhotoProgressValueForResultSheet, setStepperPhotoProgressValueForResultSheet] = useState(0);
  const [selectExamCenterForDownloadResultExcelSheetModal, setSelectExamCenterForDownloadResultExcelSheetModal] = useState(false);
  const [selectExamCenterForDownloadResultExcelSheetForOnlyCurrentExamModal, setSelectExamCenterForDownloadResultExcelSheetForOnlyCurrentExamModal] = useState(false);
  const [SelectExcelForDownloadStudentExcelSheetModalToggle, setSelectExcelForDownloadStudentExcelSheetModalToggle] = useState(false);
  const DownloadMeritStudentExcelSheetExamCenterName = useRef("");
  const currentDownloading = useRef(0);
  const totalCentersDownloading = useRef(0);

  useEffect(() => {
    setAddStudentExcelFile(null);
  }, [])

  useEffect(() => {
    if (examDocID && subExamDocID) {
      getCountFromServer(collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`)).then((getCountFromServerResponse) => {
        // console.log("getCountFromServerResponse => ", getCountFromServerResponse);
        setTotalMeritStudent(getCountFromServerResponse.data().count);
      })
      getCountFromServer(query(collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`), where("isVerified", "==", true))).then((getCountFromServerResponse) => {
        // console.log("getCountFromServerResponse => ", getCountFromServerResponse);
        setTotalStudentsVerified(getCountFromServerResponse.data().count);
      })
    }
  }, [])


  useEffect(() => {
    if (examDocID && subExamDocID) {
      setDataGridLoading(true);
      getDataFromServer(searchField, null, meritStudentSearchValue === "" ? undefined : meritStudentSearchValue, false);
    }
  }, [examDocID, subExamDocID, meritStudentSearchValue, searchField]);

  function getDataFromServer(_searchField, _lastDoc, _studentSearchValue, _merge) {
    getMeritStudents(searchField, _lastDoc, _studentSearchValue, examDocID, subExamDocID).then(res => {
      let _studentList = convertFirebaseObjToDataGridObj(res.docs.map(m => ({ docID: m.id, ...m.data() })));
      // console.log(_studentList);

      if (!isDataPresent) {
        setIsDataPresent(!res.empty)
      }

      if (_merge) {
        setServerMetaDataList(pre => [...pre, ...res.docs])
        setMeritStudentList(pre => [...pre, ..._studentList]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList(res.docs);
        setMeritStudentList(_studentList);
      }
      setDataGridLoading(false);
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      if (_merge) {
        setServerMetaDataList(pre => [...pre])
        setMeritStudentList(pre => [...pre]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList([]);
        setMeritStudentList([]);
      }
      setDataGridLoading(false);
    })
  }

  //* call on Next Button
  function getMoreData() {
    setDataGridLoading(true);
    getDataFromServer(searchField, serverMetaDataList[serverMetaDataList.length - 1], meritStudentSearchValue === "" ? undefined : meritStudentSearchValue, true);
  }

  const onClickDoneApplicant = (_applicant) => {
    console.log(_applicant);
  }

  function fileSelectForMultiMeritList(e) {

    let _file = e.target.files[0];
    if (_file) {
      setProgressBarDataUploadModalToggle(true);
      setMeritStudentUploadLoadingStepperValue(0);

      const reader = new FileReader();
      reader.onload = function (event) {
        var excelData = event.target.result;
        excelData = new Uint8Array(excelData);
        var workbook = XLSX.read(excelData, {
          type: "array",
          cellText: false,
          cellDates: true,
        });

        var arrayOfRows;
        workbook.SheetNames.forEach(function (sheetName) {
          arrayOfRows = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
            { raw: false, dateNF: "yyyy-mm-dd" }
          );
        });

        arrayOfRows.forEach(element => {
          if (element.hasOwnProperty("previousSubExamHistory")) {
            element.previousSubExamHistory = JSON.parse(element.previousSubExamHistory);
          }
        });

        // console.log(arrayOfRows);
        if (arrayOfRows.length > 0) {
          // console.log("%c Zala", "color: #6941C6; font-size: 20px");
          meritStudentsAddToFirebase(arrayOfRows)
        } else {
          errorToast("Data not found in file.")
        }
        document.getElementById("fileSelectForMultiMeritList").value = null;
      };
      reader.readAsArrayBuffer(_file);
    }
  }

  async function meritStudentsAddToFirebase(meritStudentExcelList) {
    // setProgressBarDataUploadModalToggle(true);
    setTotalDataUploading(meritStudentExcelList.length);
    setMeritStudentUploadLoadingStepperValue(1)

    let setCount = 1;


    const invalid_Entries = [];
    const valid_Entries = [];

    // let invalid_Entries = meritStudentExcelList.filter(f=> !f.hasOwnProperty("UID") || f.UID.trim() === "" || !f.UID);

    for (let i = 0; i < meritStudentExcelList.length; i++) {
      const element = meritStudentExcelList[i];
      const validationResult = validateExcelObj(element);
      if (validationResult.validation.isValid) {
        valid_Entries.push(element);
      } else {
        element.reason = validationResult.validation.reason;
        invalid_Entries.push(element);
      }
    }
    // console.log("invalid_Entries =>>", { invalid_Entries, valid_Entries });


    if (invalid_Entries.length > 0) {
      setInvalid_Entries([...invalid_Entries.map(m => ({ ...m, reason: "UID data invalid." }))]);
      errorToast("Invalid data in excel sheet. Fix it and try again!");
      setInvalid_EntriesModalToggle(true);
      setProgressBarDataUploadModalToggle(false);
    } else {
      setMeritStudentUploadLoadingStepperValue(2)
      meritStudentExcelList.forEach(async (elementMeritStudent) => {

        let studentDocID = elementMeritStudent.UID;
        let postBody = elementMeritStudent

        //! check duplicate if want

        setMeritStudent(examDocID, subExamDocID, studentDocID, postBody).then(res => {
          setAddedMeritStudentCount(pre => pre + 1);
          if (meritStudentExcelList.length === setCount) {
            setMeritStudentUploadLoadingStepperValue(3);
            successToast("Merit list upload successfully.");
            getDataFromServer(searchField, null, meritStudentSearchValue === "" ? undefined : meritStudentSearchValue, false);
          }
          setCount++;
        }).catch(err => {
          invalid_Entries.push({ ...elementMeritStudent, reason: err?.toString() });
          if (meritStudentExcelList.length === setCount) {
            setMeritStudentUploadLoadingStepperValue(3);
            successToast("Merit list upload successfully.");
            getDataFromServer(searchField, null, meritStudentSearchValue === "" ? undefined : meritStudentSearchValue, false);
          }
          setCount++;
        })


      })

    }
  }

  function validateExcelObj(excelObj) {
    const validation = {
      isValid: true,
      reason: ''
    };
    if (!excelObj.hasOwnProperty("UID") || excelObj.UID.trim() === "" || !excelObj.UID) {
      validation.isValid = false;
      validation.reason = "UID data invalid.";
    } else if (excelObj.Applicant && excelObj.examName && excelObj.registrationDate && excelObj.invoice && excelObj.paymentStatus && excelObj.examCenterName && excelObj.examDate && excelObj.examTime && excelObj.Gender && excelObj.City && excelObj["Full Name"] && excelObj.Disability) {
      if (excelObj["Full Name"] === "") {
        validation.isValid = false;
        validation.reason = "Empty Full Name";
      } else if (excelObj.Applicant === "") {
        validation.isValid = false;
        validation.reason = "Empty applicant id";
      } else if (excelObj.UID === "") {
        validation.isValid = false;
        validation.reason = "Empty UID";
      } else if (excelObj.examName === "") {
        validation.isValid = false;
        validation.reason = "Empty exam name";
      } else if (excelObj.examName !== examName) {
        validation.isValid = false;
        validation.reason = "Exam name not match.";
      } else if (excelObj.examCenterName === "") {
        validation.isValid = false;
        validation.reason = "Empty exam center name";
      } else if (excelObj.Gender === "") {
        validation.isValid = false;
        validation.reason = "Empty gender";
      } else if (excelObj.City === "") {
        validation.isValid = false;
        validation.reason = "Empty city";
      } else if (excelObj.Gender.toLowerCase() !== "male" && excelObj.Gender.toLowerCase() !== "female" && excelObj?.Gender?.toLowerCase() !== "transgender") {
        validation.isValid = false;
        validation.reason = "Invalid gender";
      } else if (excelObj.Disability.toLowerCase() !== "yes" && excelObj.Disability.toLowerCase() !== "no") {
        validation.isValid = false;
        validation.reason = "Invalid disability";
      } else if (excelObj.examDate === "") {
        validation.isValid = false;
        validation.reason = "Empty exam date";
      } else if (excelObj.examTime === "") {
        validation.isValid = false;
        validation.reason = "Empty exam time";
      }
    } else {
      if (!excelObj["Full Name"]) {
        validation.isValid = false;
        validation.reason = "Missing Full Name";
      } else if (!excelObj.Applicant) {
        validation.isValid = false;
        validation.reason = "Missing Applicant id";
      } else if (!excelObj.examName) {
        validation.isValid = false;
        validation.reason = "Missing exam name";
      } else if (!excelObj.examCenterName) {
        validation.isValid = false;
        validation.reason = "Missing exam center name";
      } else if (!excelObj.Gender) {
        validation.isValid = false;
        validation.reason = "Missing gender";
      } else if (!excelObj.City) {
        validation.isValid = false;
        validation.reason = "Missing city";
      } else if (excelObj.examDate === "") {
        validation.isValid = false;
        validation.reason = "Empty exam date";
      } else if (excelObj.examTime === "") {
        validation.isValid = false;
        validation.reason = "Empty exam time";
      } else if (excelObj.Disability === "") {
        validation.isValid = false;
        validation.Disability = "Empty disability";
      } else if (excelObj?.registrationDate === "") {
        validation.isValid = false;
        validation.reason = validation.reason + ", Empty disability";
      } else if (new Date(excelObj?.registrationDate).toString().includes("Invalid Date")) {
        validation.isValid = false;
        validation.reason = validation.reason + ", Invalid Registration Date : formate(MM/DD/YYYY)";
      }
    }
    return { validation, excelObj };
  }

  function downloadStudents() {
    presentLoader();
    getAllMeritStudents(examDocID, subExamDocID).then(res => {

      if (res.empty) {
        errorToast("Student data not found.");
        dismissLoader();
        return;
      }
      // console.log('get successful!');
      let _list = res.docs.map(m => ({ UID: m.id, ...m.data() }));
      // console.log({_list});

      // let keys = Object.keys(Object.assign({}, ..._list));
      // console.log(keys);

      let _studentList = [];
      _list.forEach((element) => {
        let obj = { ...element };
        // keys.forEach((ele) => {
        //   obj[ele] = element[ele]
        // });
        delete obj.ET;
        delete obj.ET_IsQualified;
        delete obj.ET_height_height;
        delete obj.ET_height_remarks;
        delete obj.ET_height_result;
        delete obj.PST_IsQualified;
        delete obj.PST;
        delete obj.chest_no;
        delete obj.dataURL;
        delete obj.examType;
        delete obj.idCardBase64;
        delete obj.idCardImage;
        delete obj.invoice;
        delete obj.lastUpdateAt;
        delete obj.name;
        delete obj.realTimeBase64;
        delete obj.realTimePhoto;
        delete obj.selectedID_Card;
        delete obj.showInGrid;
        delete obj.uploadedForm;
        delete obj.checkingOfficer;
        delete obj.checkingOfficerDesignation;
        delete obj.isSubmitted;
        delete obj.attendanceDateTime;
        delete obj.isWildCardEntry;
        obj.Status = "pending";
        obj.isVerified = false;

        let currentSubExamHistory = {
          subExamName: subExamName,
          fingerprintImage1: element?.fingerprintImage1 ? element.fingerprintImage1 : "",
          fingerprintImage2: element?.fingerprintImage2 ? element.fingerprintImage2 : "",
          realTimePhoto: element?.realTimePhoto ? element.realTimePhoto : "",
          idCardImage: element?.idCardImage ? element.idCardImage : "",
          attendanceDateTime: element?.attendanceDateTime ? element.attendanceDateTime : ""
        }
        if (obj.hasOwnProperty("previousSubExamHistory") && Array.isArray(obj.previousSubExamHistory)) {
          obj.previousSubExamHistory.push(currentSubExamHistory);
        } else {
          obj.previousSubExamHistory = [currentSubExamHistory];
        }

        obj.previousSubExamHistory = JSON.stringify(obj.previousSubExamHistory);

        _studentList.push(obj);
      });

      // console.log(_studentList);

      const worksheet = XLSX.utils.json_to_sheet(_studentList);
      const workBook = new XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, worksheet, `Eligible List`);
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.writeFile(workBook, `${subExamName} Students List.xlsx`);

      dismissLoader();
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      dismissLoader();
    })
  }
  async function processToDownloadExcelWithChunkFiles() {
    presentLoader();
    const docLimit = 10000;

    let lastDoc = null;
    let paginationCount = 0;

    await childProcessFunction([], false);
    console.log("DONE");
    dismissLoader();

    async function childProcessFunction(dataArray = []) {
      try {
        const getMeritStudentsForExcelDownloadByPaginationResponse = await getMeritStudentsForExcelDownloadByPagination(lastDoc, examDocID, subExamDocID, docLimit);
        // if ((getMeritStudentsForExcelDownloadByPaginationResponse.docs.length < docLimit) || getMeritStudentsForExcelDownloadByPaginationResponse.empty) {
        //   isLast = true;
        // }

        // if (dataArray.length === (docLimit * 5)) {
        //   paginationCount++;
        //   await downloadStudentsExcelByDocsData(paginationCount, dataArray);
        //   dataArray = [];
        // }

        if (!getMeritStudentsForExcelDownloadByPaginationResponse.empty) {
          lastDoc = getMeritStudentsForExcelDownloadByPaginationResponse.docs[getMeritStudentsForExcelDownloadByPaginationResponse.docs.length - 1];
          dataArray = dataArray.concat(getMeritStudentsForExcelDownloadByPaginationResponse.docs.map(m => ({ UID: m.id, ...m.data() })))
          await childProcessFunction(dataArray);
        } else {
          paginationCount++;
          await downloadStudentsExcelByDocsData(paginationCount, dataArray);
          dataArray = [];
        }
      } catch (error) {
        dismissLoader();
        console.error("Error => ", error);
      }
    }
  }

  async function downloadStudentsExcelByDocsData(paginationCount, _list) {
    // let _list = responseDocs.docs.map(m => ({ UID: m.id, ...m.data() }));

    let keyObject = {};
    _list.forEach((ele) => {
      Object.assign(keyObject, ele)
    })

    let _studentList = [];
    _list.forEach((element) => {
      let obj = {};

      Object.keys(keyObject).forEach((ele) => {
        obj[ele] = element[ele]
      });

      delete obj.ET;
      delete obj.ET_IsQualified;
      delete obj.ET_height_height;
      delete obj.ET_height_remarks;
      delete obj.ET_height_result;
      delete obj.PST_IsQualified;
      delete obj.PST;
      delete obj.chest_no;
      delete obj.dataURL;
      delete obj.examType;
      delete obj.idCardBase64;
      delete obj.idCardImage;
      delete obj.invoice;
      delete obj.lastUpdateAt;
      delete obj.name;
      delete obj.realTimeBase64;
      delete obj.realTimePhoto;
      delete obj.selectedID_Card;
      delete obj.showInGrid;
      delete obj.uploadedForm;
      delete obj.checkingOfficer;
      delete obj.checkingOfficerDesignation;
      delete obj.isSubmitted;
      delete obj.attendanceDateTime;
      delete obj.isWildCardEntry;
      obj.Status = "pending";
      obj.isVerified = false;

      let currentSubExamHistory = {
        subExamName: subExamName,
        fingerprintImage1: element?.fingerprintImage1 ? element.fingerprintImage1 : "",
        fingerprintImage2: element?.fingerprintImage2 ? element.fingerprintImage2 : "",
        realTimePhoto: element?.realTimePhoto ? element.realTimePhoto : "",
        idCardImage: element?.idCardImage ? element.idCardImage : "",
        attendanceDateTime: element?.attendanceDateTime ? element.attendanceDateTime : ""
      }
      if (obj.hasOwnProperty("previousSubExamHistory") && Array.isArray(obj.previousSubExamHistory)) {
        obj.previousSubExamHistory.push(currentSubExamHistory);
      } else {
        obj.previousSubExamHistory = [currentSubExamHistory];
      }

      obj.previousSubExamHistory = JSON.stringify(obj.previousSubExamHistory);

      _studentList.push(obj);
    });

    _studentList = createChunkArrayCustomIndex(_studentList, 100000);

    let index = 1;
    for (const _studentListItemArray of _studentList) {
      const worksheet = XLSX.utils.json_to_sheet(_studentListItemArray);
      const workBook = new XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, worksheet, `Student List`);
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.writeFile(workBook, `${subExamName} Students List ${index}.xlsx`);
      index++;
    }

    // const worksheet = XLSX.utils.json_to_sheet(_studentList);
    // const workBook = new XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workBook, worksheet, `Eligible List`);
    // XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // XLSX.writeFile(workBook, `${subExamName} Students List ${paginationCount}.xlsx`);
  }
  function downloadResultSheetsZip() {
    presentLoader()
    // process.env.REACT_APP_NODE_API_URL
    axios.post(process.env.REACT_APP_NODE_API_URL + 'candidates/downloadResultSheets', { examDocID, subExamDocID }).then((resp) => {
      // axios.post('http://192.168.2.115:7000/' + 'candidates/downloadResultSheets', { examDocID, subExamDocID }).then((resp) => {
      if (resp.data.message === "No Result Sheets available") {
        errorToast(resp?.data?.message);
        dismissLoader();
      } else if (resp.data?.content) {
        const a = document.createElement('a');
        a.href = 'data:application/zip;base64,' + resp.data.content;
        a.download = examDocID + '_' + subExamDocID + '_ResultSheet.zip';
        a.click();
        dismissLoader();
      } else {
        errorToast("Something went wrong! Try again");
        dismissLoader();
      }
    }).catch((err) => {
      // console.log("downloadResultSheetsZip => ", err);
      console.log("downloadResultSheetsZip => ", err.toString());
      errorToast("Something went wrong! Try again");
      dismissLoader();
    })
  }

  function fileSelectForResultSheet(e) {  //* Changed
    let _file = e.target.files[0];
    if (!_file) {
      return;
    }
    if (!_file.type.includes("pdf")) {
      errorToast("Please upload PDF formate only.");
      return;
    }

    getBase64(_file).then(data => {
      // https://exam-management.s3.ap-south-1.amazonaws.com/exam-management/examdemo1Jun2022.jpeg
      let fileNamePrefix = examDocID + '_' + examName + "_" + subExamDocID + "_" + subExamName;
      let fileObj = convertBase64ToFile(data, fileNamePrefix + '_rawResultSheet.pdf', `${process.env.REACT_APP_bucketRootKey}/`);
      // console.log("fileObj", fileObj);


      uploadFilesAWS([fileObj],
        (data) => {
          if (data?.result.includes("Files Uploaded")) {
            updateExamSchedule(examDocID, subExamDocID, { rawResultSheet: fileObj.key });
            setSubExams(pre => {
              pre[pre.findIndex(f => f.docID === subExamDocID)].rawResultSheet = fileObj.key;
              return [...pre];
            });
            setUploadingPdfProgress(null);
            successToast("Result sheet upload successfully.")
          } else {
            errorToast("Something went wrong,Please try again later");
            setUploadingPdfProgress(null);
            // console.log(data)
          }
        },
        (err) => {
          errorToast("Something went wrong,Please try again later");
          setUploadingPdfProgress(null);
          console.log(err)
        },
        (progress) => {
          setUploadingPdfProgress(progress.uploadedPercent);
        }
      );
    })


  }

  async function uploadOfflineZip(e) {
    const _file = e.target.files[0];
    // console.log({ "upload Student": _file });
    const formData = new FormData();
    formData.append("file", _file);
    axios.post("http://localhost:8000/uploadOfflineDataZip", formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
      console.log(res);
    })
  }

  async function goTODownloadResultExcelSheetOneByOne(_selectedExamCenters = [], isPreviousExamPhotosAdd = true) {
    let failed = [];
    totalCentersDownloading.current = _selectedExamCenters.length;
    currentDownloading.current = 1;
    for (const _selectedExamCenterItem of _selectedExamCenters) {
      DownloadMeritStudentExcelSheetExamCenterName.current = _selectedExamCenterItem?.examCenterName;

      const downloadResultExcelSheetResponse = await downloadResultExcelSheet(_selectedExamCenterItem, isPreviousExamPhotosAdd);
      console.log("downloadResultExcelSheetResponse => ", downloadResultExcelSheetResponse);
      currentDownloading.current = currentDownloading.current + 1;

      if (!downloadResultExcelSheetResponse) {
        failed.push(_selectedExamCenterItem);
      }
    }

    console.log("failed => ", failed);

    if (failed.length > 0) {
      errorToast(`Failed to download ${failed.length} Exam center result sheets.`);
    } else {
      successToast(`Downloaded ${_selectedExamCenters.length - failed.length} Exam center result sheets.`);
    }
  }

  async function downloadResultExcelSheet(_selectedExamCenter, isPreviousExamPhotosAdd = true) {
    return new Promise((resolve, reject) => {
      // presentLoader();
      setStepperValueForResultSheet(0);
      setStepperToggleForResultSheet(true);

      // if (!_selectedExamCenter?.examCenterName) {
      //   errorToast("Please Select exam center first.");
      //   setStepperToggleForResultSheet(false);
      //   setStepperValueForResultSheet(0);
      //   setStepperPhotoProgressValueForResultSheet(0);
      //   dismissLoader();
      //   reject(false);
      //   return;
      // }

      // getAllMeritStudents(examDocID,subExamDocID).then(res=>{
      getAllMeritStudentsByExamCenterName(examDocID, subExamDocID, _selectedExamCenter?.examCenterName).then(res => {

        if (res.empty) {
          errorToast(`Student data not found for ${_selectedExamCenter?.examCenterName}.`);
          setStepperToggleForResultSheet(false);
          setStepperValueForResultSheet(0);
          setStepperPhotoProgressValueForResultSheet(0);
          dismissLoader();
          resolve(false);
          // return;
        }

        let _list = res.docs.map((m, index) => ({
          UID: m.id,
          "sNo": (index + 1).toString(),
          "Applicant": m.data().Applicant,
          "Full Name": m.data()["Full Name"],
          "Gender": m.data().Gender,
          "MobileNo": m.data().phone,
          "Attendance": (m.data().realTimePhoto && m.data().realTimePhoto !== "") ? "P" : "A",
          "Date": m.data().examDate,
          "CenterName": m.data().examCenterName,
          "ExamName": examName ? examName : m.data().examName,
          "AddedFromEXE": m.data().hasOwnProperty("addedFromEXE"),

          previousSubExamHistory: (isPreviousExamPhotosAdd && m.data()?.previousSubExamHistory) ? m.data()?.previousSubExamHistory : [],

          "AppPhoto": m.data()?.Photo ? m.data().Photo : "",

          [`${subExamName}_LivePhoto`]: m.data()?.realTimePhoto ? m.data().realTimePhoto : "",

          [`${subExamName}_FPPhoto1`]: m.data()?.fingerprintImage1 ? m.data().fingerprintImage1 : "",
          [`${subExamName}_FPPhoto2`]: m.data()?.fingerprintImage2 ? m.data().fingerprintImage2 : "",

          [`${subExamName}_idCardPhoto`]: m.data()?.idCardImage ? m.data().idCardImage : "",

          [`${subExamName}_Attendance_Date_Time`]: (m.data()?.attendanceDateTime && m.data()?.attendanceDateTime !== "") ? new Date(m.data().attendanceDateTime)?.toLocaleString() : "",
        }));

        let keys = ["sNo", "Applicant", "Full Name", "Gender", "MobileNo", "Attendance", "Date", "CenterName", "ExamName", "AddedFromEXE", "AppPhoto"];

        // console.log("_list => ",_list);

        let studentHistoryData = _list.map(m => ({
          ...m,
          previousSubExamHistory: m.previousSubExamHistory.map(ele => {
            let obj = {
              [`${ele.subExamName}_Attendance_Date_Time`]: (ele?.attendanceDateTime && ele?.attendanceDateTime !== "") ? new Date(ele?.attendanceDateTime)?.toLocaleString() : "",
              [`${ele.subExamName}_FPPhoto1`]: ele?.fingerprintImage1 ? ele?.fingerprintImage1 : "",
              [`${ele.subExamName}_FPPhoto2`]: ele?.fingerprintImage2 ? ele?.fingerprintImage2 : "",
              [`${ele.subExamName}_idCardPhoto`]: ele?.idCardImage ? ele?.idCardImage : "",
              [`${ele.subExamName}_LivePhoto`]: ele?.realTimePhoto ? ele?.realTimePhoto : "",
            };
            return obj;
          })
        }));

        // console.log("studentHistoryData",studentHistoryData);

        let Keys2 = []

        studentHistoryData.forEach(element => {
          element.previousSubExamHistory.forEach(ele => {

            if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_LivePhoto"))).length === 0) {
              Keys2.push(Object.keys(ele).find(f => f.includes("_LivePhoto")))
            }
            if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_FPPhoto1"))).length === 0) {
              Keys2.push(Object.keys(ele).find(f => f.includes("_FPPhoto1")))
            }
            if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_FPPhoto2"))).length === 0) {
              Keys2.push(Object.keys(ele).find(f => f.includes("_FPPhoto2")))
            }
            if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_idCardPhoto"))).length === 0) {
              Keys2.push(Object.keys(ele).find(f => f.includes("_idCardPhoto")))
            }
            if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_Attendance_Date_Time"))).length === 0) {
              Keys2.push(Object.keys(ele).find(f => f.includes("_Attendance_Date_Time")))
            }
          });
        })

        keys = [...keys, ...Keys2, `${subExamName}_LivePhoto`, `${subExamName}_FPPhoto1`, `${subExamName}_FPPhoto2`, `${subExamName}_idCardPhoto`, `${subExamName}_Attendance_Date_Time`];

        console.log({ keys });

        studentHistoryData = studentHistoryData.map(m => {
          if (m.hasOwnProperty("previousSubExamHistory") && m.previousSubExamHistory.length > 0) {
            return { ...m, ...Object?.assign(...m?.previousSubExamHistory) }
          } else {
            return m;
          }
        })
        studentHistoryData.forEach(function (v) { delete v?.previousSubExamHistory });

        // console.log("studentHistoryData", studentHistoryData);

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Data');
        worksheet.properties.defaultColWidth = 30;
        worksheet.properties.defaultRowHeight = 100;

        worksheet.addRow(keys);

        studentHistoryData.map((item) => {
          const temp = [];
          keys.map((ind) => {
            temp.push(item[ind]);
            return ind;
          });
          worksheet.addRow(temp);
          return item;
        });

        const row1 = worksheet.getRow(1);
        const row1Data = row1.model.cells.filter(f => {
          if (f.value.includes("AppPhoto") || f.value.includes("LivePhoto") || f.value.includes("FPPhoto1") || f.value.includes("FPPhoto2") || f.value.includes("idCardPhoto")) {
            return true;
          }
        }).map(m => ({ ...m, columnId: m.address.replace(/[^A-Za-z]/g, '') }))
        // console.log("row1",row1Data);

        let allImagesURLs = [];

        row1Data.forEach((rowData, rowIndex) => {
          let rawColumn = worksheet.getColumn(rowData.columnId);
          allImagesURLs.push(...rawColumn.values.slice(2).map((c, i) => ({ url: c, cellId: `${rowData.columnId}${i + 2}` })));
        })

        worksheet.getRows(2, studentHistoryData.length + 1).forEach(ele => {
          row1Data.forEach(ele1 => {
            ele.getCell(ele1.columnId).value = "";
          })
        })

        // console.log("allImagesURLs length => ", allImagesURLs.length);
        // const testArr = allImagesURLs.splice(0, 100);
        // let chunkArrayForImageBase64 = createChunkArray([...testArr]);
        let chunkArrayForImageBase64 = createChunkArray([...allImagesURLs], 100);

        setStepperValueForResultSheet(1);

        // Promise.all(allImagesURLs.map(m=>getBase64FromImageUrlForExcel(m))).then(imageUrlResponse => {
        createBatchImageBase64(chunkArrayForImageBase64).then(imageUrlResponse => {
          // console.log("imageUrlResponse => ", imageUrlResponse);
          // console.log("Error Images => ", imageUrlResponse.filter(f => f.dataURL === ""));

          setStepperValueForResultSheet(2);

          imageUrlResponse.forEach((ele, index) => {
            if (ele.dataURL && ele.dataURL !== "") {
              const imageId = workbook.addImage({
                base64: ele.dataURL,
                extension: 'png',
              });
              worksheet.addImage(imageId, `${ele.cellId}:${ele.cellId}`);
            }
          });


          //* Downloading Excel
          try {
            workbook.xlsx.writeBuffer().then(async (buffer) => {
              console.log("Downloading Start");
              const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
              fileSaver.saveAs(blob, `${examName} (${subExamName}) Merit Students List - center code - ${_selectedExamCenter?.examCenterCode}.xlsx`);
              // dismissLoader();
              if (isPreviousExamPhotosAdd) {
                const setDownloadedExamCenterExcelResponse = await setDownloadedExamCenterExcel(_selectedExamCenter?.docId, examDocID, subExamDocID);
                console.log("setDownloadedExamCenterExcelResponse", setDownloadedExamCenterExcelResponse);
              }
              resolve(true);

              setStepperValueForResultSheet(3);
            }).catch((ee) => {
              errorToast("Something went wrong! Try again");
              setStepperValueForResultSheet(0);
              setStepperToggleForResultSheet(false);
              setStepperPhotoProgressValueForResultSheet(0);
              // dismissLoader();
              console.log("dd", ee)
              resolve(false);
            });
          } catch (e) {
            console.log(e);
            errorToast("Something went wrong! Try again");
            setStepperValueForResultSheet(0);
            setStepperToggleForResultSheet(false);
            dismissLoader();
            resolve(false);
          }

        });
      }).catch(err => {
        console.log(err);
        errorToast(err.toString());
        setStepperValueForResultSheet(0);
        setStepperToggleForResultSheet(false);
        setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        resolve(false);
      })
    })
  }

  async function downloadResultExcelSheetBy_100K_Chunks() {
    presentLoader();
    // const docLimit = 10000;
    const docLimit = 5000;

    let lastDoc = null;
    let paginationCount = 1;

    await childProcessFunction([], false);
    console.log("DONE");
    dismissLoader();

    async function childProcessFunction(dataArray = []) {
      try {
        console.log('start fetching', new Date());
        const getMeritStudentsForExcelDownloadByPaginationResponse = await getMeritStudentsForExcelDownloadByPagination(lastDoc, examDocID, subExamDocID, docLimit);

        if (!getMeritStudentsForExcelDownloadByPaginationResponse.empty) {
          lastDoc = getMeritStudentsForExcelDownloadByPaginationResponse.docs[getMeritStudentsForExcelDownloadByPaginationResponse.docs.length - 1];
          // dataArray = dataArray.concat(getMeritStudentsForExcelDownloadByPaginationResponse.docs.map(m => ({ UID: m.id, ...m.data() })))
          dataArray = getMeritStudentsForExcelDownloadByPaginationResponse.docs.map(m => ({ UID: m.id, ...m.data() }))
          console.log("downloading images now => ", new Date());
          // console.log("downloading images now => ", new Date(), dataArray);
          await downloadResultExcelSheetChildProcess(dataArray, paginationCount);
          await childProcessFunction(dataArray);
          paginationCount++;
        } else {
          paginationCount++;
          // await goToArrayChunksAndPassToDownloadQualifiedReportExcelSheet(dataArray);
          dataArray = [];
        }
      } catch (error) {
        dismissLoader();
        errorToast(error.toString());
        console.error("Error => ", error);
      }
    }

    async function goToArrayChunksAndPassToDownloadQualifiedReportExcelSheet(dataArray = []) {
      if (dataArray.length === 0) {
        errorToast("Student data not found.");
        dismissLoader();
        return;
      }
      const _dataArray = createChunkArrayCustomIndex(dataArray, 50000);

      let count = 0;
      for (const chuckItemDataArray of _dataArray) {
        count++;
        await downloadResultExcelSheetChildProcess(chuckItemDataArray, count);
      }
    }

    async function downloadResultExcelSheetChildProcess(dataArray = [], excelCreationCount) {
      let _list = dataArray.map((m, index) => ({
        UID: m.id,
        "sNo": (index + 1).toString(),
        "Applicant": m.Applicant,
        "Full Name": m["Full Name"],
        "Gender": m.Gender,
        "MobileNo": m.phone,
        "Attendance": (m.realTimePhoto && m.realTimePhoto !== "") ? "P" : "A",
        "Date": m.examDate,
        "CenterName": m.examCenterName,
        "ExamName": examName ? examName : m.examName,
        "AddedFromEXE": m.hasOwnProperty("addedFromEXE"),

        previousSubExamHistory: m?.previousSubExamHistory ? m?.previousSubExamHistory : [],

        "AppPhoto": m?.Photo ? m.Photo : "",

        [`${subExamName}_LivePhoto`]: m?.realTimePhoto ? m.realTimePhoto : "",

        [`${subExamName}_FPPhoto1`]: m?.fingerprintImage1 ? m.fingerprintImage1 : "",
        [`${subExamName}_FPPhoto2`]: m?.fingerprintImage2 ? m.fingerprintImage2 : "",

        [`${subExamName}_idCardPhoto`]: m?.idCardImage ? m.idCardImage : "",

        [`${subExamName}_Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
      }));

      let keys = ["sNo", "Applicant", "Full Name", "Gender", "MobileNo", "Attendance", "Date", "CenterName", "ExamName", "AddedFromEXE", "AppPhoto"];

      // console.log("_list => ",_list);

      let studentHistoryData = _list.map(m => ({
        ...m,
        previousSubExamHistory: m.previousSubExamHistory.map(ele => {
          let obj = {
            [`${ele.subExamName}_Attendance_Date_Time`]: (ele?.attendanceDateTime && ele?.attendanceDateTime !== "") ? new Date(ele?.attendanceDateTime)?.toLocaleString() : "",
            [`${ele.subExamName}_FPPhoto1`]: ele?.fingerprintImage1 ? ele?.fingerprintImage1 : "",
            [`${ele.subExamName}_FPPhoto2`]: ele?.fingerprintImage2 ? ele?.fingerprintImage2 : "",
            [`${ele.subExamName}_idCardPhoto`]: ele?.idCardImage ? ele?.idCardImage : "",
            [`${ele.subExamName}_LivePhoto`]: ele?.realTimePhoto ? ele?.realTimePhoto : "",
          };
          return obj;
        })
      }));

      // console.log("studentHistoryData",studentHistoryData);

      let Keys2 = []

      studentHistoryData.forEach(element => {
        element.previousSubExamHistory.forEach(ele => {

          if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_LivePhoto"))).length === 0) {
            Keys2.push(Object.keys(ele).find(f => f.includes("_LivePhoto")))
          }
          if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_FPPhoto1"))).length === 0) {
            Keys2.push(Object.keys(ele).find(f => f.includes("_FPPhoto1")))
          }
          if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_FPPhoto2"))).length === 0) {
            Keys2.push(Object.keys(ele).find(f => f.includes("_FPPhoto2")))
          }
          if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_idCardPhoto"))).length === 0) {
            Keys2.push(Object.keys(ele).find(f => f.includes("_idCardPhoto")))
          }
          if (Keys2.filter(f => f === Object.keys(ele).find(f => f.includes("_Attendance_Date_Time"))).length === 0) {
            Keys2.push(Object.keys(ele).find(f => f.includes("_Attendance_Date_Time")))
          }
        });
      })

      keys = [...keys, ...Keys2, `${subExamName}_LivePhoto`, `${subExamName}_FPPhoto1`, `${subExamName}_FPPhoto2`, `${subExamName}_idCardPhoto`, `${subExamName}_Attendance_Date_Time`];

      // console.log({ keys });

      studentHistoryData = studentHistoryData.map(m => {
        if (m.hasOwnProperty("previousSubExamHistory") && m.previousSubExamHistory.length > 0) {
          return { ...m, ...Object?.assign(...m?.previousSubExamHistory) }
        } else {
          return m;
        }
      })
      studentHistoryData.forEach(function (v) { delete v?.previousSubExamHistory });

      // console.log("studentHistoryData", studentHistoryData);

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30;
      worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(keys);

      studentHistoryData.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });

      const row1 = worksheet.getRow(1);
      const row1Data = row1.model.cells.filter(f => {
        if (f.value.includes("AppPhoto") || f.value.includes("LivePhoto") || f.value.includes("FPPhoto1") || f.value.includes("FPPhoto2") || f.value.includes("idCardPhoto")) {
          return true;
        }
      }).map(m => ({ ...m, columnId: m.address.replace(/[^A-Za-z]/g, '') }))
      // console.log("row1",row1Data);

      let allImagesURLs = [];

      row1Data.forEach((rowData, rowIndex) => {
        let rawColumn = worksheet.getColumn(rowData.columnId);
        allImagesURLs.push(...rawColumn.values.slice(2).map((c, i) => ({ url: c, cellId: `${rowData.columnId}${i + 2}` })));
      })

      worksheet.getRows(2, studentHistoryData.length + 1).forEach(ele => {
        row1Data.forEach(ele1 => {
          ele.getCell(ele1.columnId).value = "";
        })
      })

      // console.log("allImagesURLs length => ", new Date(), allImagesURLs.length);
      // const testArr = allImagesURLs.splice(0, 100);
      // let chunkArrayForImageBase64 = createChunkArray([...testArr]);
      let chunkArrayForImageBase64 = createChunkArray([...allImagesURLs], 100);

      try {
        const imageUrlResponse = await createBatchImageBase64(chunkArrayForImageBase64)
        // console.log("imageUrlResponse => ", new Date(), imageUrlResponse);
        // console.log("Error Images => ", imageUrlResponse.filter(f => f.dataURL === ""));

        setStepperValueForResultSheet(2);

        imageUrlResponse.forEach((ele, index) => {
          if (ele.dataURL && ele.dataURL !== "") {
            const imageId = workbook.addImage({
              base64: ele.dataURL,
              extension: 'png',
            });
            worksheet.addImage(imageId, `${ele.cellId}:${ele.cellId}`);
          }
        });


        //* Downloading Excel Sheet
        console.log("Downloading Start", new Date());
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
        fileSaver.saveAs(blob, `${examName} Merit Students List - ${excelCreationCount}.xlsx`);
      } catch (error) {
        console.error(error);
        errorToast("Something went wrong! Try again");
        dismissLoader();
      }
    }
  }

  async function downloadResultEntryExcelSheet() {
    presentLoader();
    getAllMeritStudents(examDocID, subExamDocID).then(res => {

      if (res.empty) {
        errorToast("Student data not found.");
        setStepperToggleForResultSheet(false);
        setStepperValueForResultSheet(0);
        setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        return;
      }

      let _list = res.docs.map((m, index) => ({
        "Full Name": m.data()["Full Name"],
        "Applicant": m.data().Applicant,
        "MobileNo": m.data()?.phone ? m.data()?.phone : "",
        "Gender": m.data().Gender,
        "AppliedAs": m.data()?.AppliedAs ? m.data()?.AppliedAs : "",
        "GroundName": m.data().examCenterName,
        "City": m.data().City,

        // "sNo":        (index+1).toString(),
        // "Attendance": (m.data().realTimePhoto && m.data().realTimePhoto !== "") ? "P" : "A",
        // "Date":       m.data().examDate,
        // "ExamName":   examName ? examName : m.data().examName,
        // "AddedFromEXE": m.data().hasOwnProperty("addedFromEXE"),

        // previousSubExamHistory :  m.data()?.previousSubExamHistory ? m.data()?.previousSubExamHistory : [],

        // "AppPhoto":   m.data()?.Photo ? m.data().Photo : "",

        // [`${subExamName}_LivePhoto`]:  m.data()?.realTimePhoto ? m.data().realTimePhoto : "",

        // [`${subExamName}_FPPhoto1`]:    m.data()?.fingerprintImage1 ? m.data().fingerprintImage1 : "",
        // [`${subExamName}_FPPhoto2`]:    m.data()?.fingerprintImage2 ? m.data().fingerprintImage2 : "",

        // [`${subExamName}_idCardPhoto`]: m.data()?.idCardImage ? m.data().idCardImage : "",

        // [`${subExamName}_Attendance_Date_Time`]: (m.data()?.attendanceDateTime && m.data()?.attendanceDateTime !== "") ? new Date(m.data().attendanceDateTime)?.toLocaleString() : "",
      }));

      let keys = ["POST",
        "City",
        "GroundName",
        "ETPST_SNo",
        "ETPSTScheduledDate",
        "RunningMetre",
        "AppliedAs",
        "Applicant",
        "MobileNo",
        "Full Name",
        "DOB",
        "Gender",
        "Category",
        "Reservation",
        "OtherReservation",
        "Year_of_Pass",
        "Degree",
        "Percentage",
        "IsArmyGraduation",
        "ET RESULT",
        "PST RESULT",
        "FINAL RESULT",
        "EXS HEIGHT",
        "REMARKS"
      ];

      let studentHistoryData = _list.map(m => ({ ...m }));

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(keys);

      studentHistoryData.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });

      const row1 = worksheet.getRow(1);

      // console.log(row1);
      row1.model.cells.forEach(element => {
        worksheet.getCell(element.address).font = {
          bold: true
        };
      });


      //* Downloading Excel
      try {
        workbook.xlsx.writeBuffer().then((buffer) => {
          console.log("Downloading Start");
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          fileSaver.saveAs(blob, `${subExamName} Final Result Entry Sheets.xlsx`);
          dismissLoader();
        }).catch((ee) => {
          errorToast("Something went wrong! Try again");
          dismissLoader();
          console.log("error in downloading excel", ee)
        });
      } catch (e) {
        console.log('error in downloading excel', e);
        errorToast("Something went wrong! Try again");
        dismissLoader();
      }


    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      dismissLoader();
    })

  }


  async function downloadSummaryReportExcelSheet(dataArray = []) {
    presentLoader();

    if (!dataArray.length) {
      errorToast("Student data not found.");
      setStepperToggleForResultSheet(false);
      setStepperValueForResultSheet(0);
      setStepperPhotoProgressValueForResultSheet(0);
      dismissLoader();
      return;
    }

    let _list = dataArray.map((m, index) => ({
      "Exam Unit": subExamName,
      "GroundName": m?.examCenterName,
      "Date": subExamObj.examDates[0].date,
      "Full Name": m?.["Full Name"],
      "Qualified": m?.Status ? m?.Status : "",
      "Attendance": (m?.realTimePhoto && m?.realTimePhoto !== "") ? "P" : "A",

      "Sr no": index + 1,
      "Roll no": m?.rollNo,
      "Applicant": m?.Applicant,
      "Exam center": m?.examCenterName,
      "District": m?.City,

      // "MobileNo":   m?.phone ? m?.phone : "",
      // "Gender":     m.Gender,
      // "AppliedAs":  m?.AppliedAs ? m?.AppliedAs : "",
      // "City": m.City,

      // "sNo":        (index+1).toString(),
      // "Date":       m.examDate,
      // "ExamName":   examName ? examName : m.examName,
      // "AddedFromEXE": m.hasOwnProperty("addedFromEXE"),

      // previousSubExamHistory :  m?.previousSubExamHistory ? m?.previousSubExamHistory : [],

      // "AppPhoto":   m?.Photo ? m.Photo : "",

      // [`${subExamName}_LivePhoto`]:  m?.realTimePhoto ? m.realTimePhoto : "",

      // [`${subExamName}_FPPhoto1`]:    m?.fingerprintImage1 ? m.fingerprintImage1 : "",
      // [`${subExamName}_FPPhoto2`]:    m?.fingerprintImage2 ? m.fingerprintImage2 : "",

      // [`${subExamName}_idCardPhoto`]: m?.idCardImage ? m.idCardImage : "",

      // [`${subExamName}_Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
    }));

    let keys = [
      "Sr no",
      "Roll no",
      "Applicant",
      "Exam center",
      "District",
      "Attendance",

      "Exam Unit",
      "GroundName",
      "Date",
      "Full Name",
      "Qualified",
      "Attendance",

      // "City",
      // "ETPST_SNo",
      // "ETPSTScheduledDate",
      // "RunningMetre",
      // "AppliedAs",
      // "Applicant",
      // "MobileNo",
      // "DOB",
      // "Gender",
      // "Category",
      // "Reservation",
      // "OtherReservation",
      // "Year_of_Pass",
      // "Degree",
      // "Percentage",
      // "IsArmyGraduation",
      // "ET RESULT",
      // "PST RESULT",
      // "FINAL RESULT",
      // "EXS HEIGHT",
      // "REMARKS"
    ];

    let studentHistoryData = _list.map(m => ({ ...m }));
    studentHistoryData = createChunkArrayCustomIndex(studentHistoryData, 100000);

    let index = 1;
    for (const _studentListItemArray of studentHistoryData) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      // worksheet.addRow(["", "", "Summary Report for Admin"]);
      // worksheet.addRow([""]);
      // worksheet.addRow(["", "", `Exam Name : ${examName}`]);
      // worksheet.addRow(["","", "Summary Report for Admin"]);

      // worksheet.addRow([""]);


      // worksheet.getCell("C1").font = {
      //   bold: true,
      //   underline: true,
      // };

      worksheet.addRow(keys);

      _studentListItemArray.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });

      // const row1 = worksheet.getRow(5);

      // console.log(row1);
      // row1.model.cells.forEach(element => {
      //   worksheet.getCell(element.address).font = {
      //     bold: true,
      //     color: { argb: 'FFFFFFFF' }
      //   };
      //   worksheet.getCell(element.address).fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: 'FF478778' }
      //   }
      // });


      //* Downloading Excel

      try {
        const buffer = await workbook.xlsx.writeBuffer()
        // .then((buffer) => {
        console.log("Downloading Start");
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
        fileSaver.saveAs(blob, `${subExamName} Summary Report Sheets ${index}.xlsx`);
        // dismissLoader();
        // }).catch((ee) => {
        //   errorToast("Something went wrong! Try again");
        //   dismissLoader();
        //   console.log("dd", ee)
        // });
        index++;
      } catch (e) {
        console.log(e);
        errorToast("Something went wrong! Try again");
        dismissLoader();
      }

    }
    dismissLoader();
  }
  async function processToDownloadQualifiedReportExcelSheetChunkFiles() {
    presentLoader();
    const docLimit = 10000;

    let lastDoc = null;
    let paginationCount = 0;

    await childProcessFunction([], false);
    console.log("DONE");
    dismissLoader();

    async function childProcessFunction(dataArray = []) {
      try {
        // const getMeritStudentsForExcelDownloadByPaginationResponse = await getQualifiedMeritStudentsForExcelDownloadByPagination(lastDoc, examDocID, subExamDocID, docLimit);
        const getMeritStudentsForExcelDownloadByPaginationResponse = await getMeritStudentsForExcelDownloadByPagination(lastDoc, examDocID, subExamDocID, docLimit);
        // if ((getMeritStudentsForExcelDownloadByPaginationResponse.docs.length < docLimit) || getMeritStudentsForExcelDownloadByPaginationResponse.empty) {
        //   isLast = true;
        // }

        // if (dataArray.length === (docLimit * 5)) {
        //   paginationCount++;
        //   await downloadStudentsExcelByDocsData(paginationCount, dataArray);
        //   dataArray = [];
        // }

        if (!getMeritStudentsForExcelDownloadByPaginationResponse.empty) {
          lastDoc = getMeritStudentsForExcelDownloadByPaginationResponse.docs[getMeritStudentsForExcelDownloadByPaginationResponse.docs.length - 1];
          dataArray = dataArray.concat(getMeritStudentsForExcelDownloadByPaginationResponse.docs.map(m => ({ UID: m.id, ...m.data() })))
          await childProcessFunction(dataArray);
        } else {
          paginationCount++;
          await downloadQualifiedReportExcelSheet(dataArray);
          dataArray = [];
        }
      } catch (error) {
        dismissLoader();
        errorToast(error.toString());
        console.error("Error => ", error);
      }
    }
  }
  async function downloadQualifiedReportExcelSheet(_studentDataArr = []) {
    presentLoader();
    // getQualifiedMeritStudentsForExcelDownloadByPagination
    // getAllQualifiedMeritStudents(examDocID, subExamDocID).then(res => {

    getAllExamCentersInExam(examDocID).then(res => {
      let _examCenterList = res.docs.map(m => ({ docId: m.id, ...m.data() }));
      // console.log("_examCenterList -> ", _examCenterList);
      // setExamCenterOriginalList(_list);
      // setExamCenterList(_list.map(m=>({label: m.examCenterName,value: m.docId , sideValue: m?.examCenterCode})));


      if (!_studentDataArr.length) {
        errorToast("Student data not found.");
        setStepperToggleForResultSheet(false);
        setStepperValueForResultSheet(0);
        setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        return;
      }
      const _studentDataArrGroupByExamCenterName = groupBy(_studentDataArr, "examCenterName");
      const _examCenterListForAddRow = _examCenterList.map(((m, i) => {
        let obj = {
          "Sl No.": i + 1,
          "Exam Center Name": m?.examCenterName,
          "Center Code": m?.examCenterCode,
          "City": m?.City,
          "Total Number Alloted": _studentDataArrGroupByExamCenterName[m?.examCenterName]?.length || 0,
          "Present Count": _studentDataArrGroupByExamCenterName[m?.examCenterName]?.filter(f => (f?.realTimePhoto && f?.realTimePhoto !== ""))?.length || 0,
          "Absent Count": _studentDataArrGroupByExamCenterName[m?.examCenterName]?.filter(f => !f?.realTimePhoto)?.length || 0
        }
        return obj;
      }));

      // console.log("_examCenterListForAddRow => ", _examCenterListForAddRow);



      let keys = [
        "Sl No.",
        "Exam Center Name",
        "Center Code",
        "City",
        "Total Number Alloted",
        "Present Count",
        "Absent Count"
      ];

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(keys);

      _examCenterListForAddRow.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });

      worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
        cell.font = { bold: true };
      });

      //* Downloading Excel
      try {
        workbook.xlsx.writeBuffer().then((buffer) => {
          console.log("Downloading Start");
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          fileSaver.saveAs(blob, `${subExamName} Qualified Report Sheets.xlsx`);
          dismissLoader();
        }).catch((ee) => {
          errorToast("Something went wrong! Try again");
          dismissLoader();
          console.log("error in downloading excel", ee)
        });
      } catch (e) {
        console.log('error in downloading excel', e);
        errorToast("Something went wrong! Try again");
        dismissLoader();
      }
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      dismissLoader();
    })

    // }).catch(err => {
    //   console.log(err);
    //   errorToast(err.toString());
    //   dismissLoader();
    // })

  }

  async function createBatchImageBase64(array) {
    let resultArr = [];
    console.log("Chunk Array length => ", new Date(), array.length);
    let count = 1;
    return await new Promise(async (resolve, reject) => {

      for (let item of array) {

        // Promise.all(allImagesURLs.map(m=>getBase64FromImageUrlForExcel(m)))

        let reap = await Promise.all(item.map(m => getBase64FromImageUrlForExcel(m)));
        console.log("resolve Chunk Array => ", new Date(), count);
        setStepperPhotoProgressValueForResultSheet(Math.floor((count / array.length) * 100));
        count++;

        resultArr = [...resultArr, ...reap];
      }
      resolve(resultArr);


      // for (let i = 0; i < array.length; i++) {
      //   const item = array[i];        
      //   Promise.all(item.map(m=>updateStudentImage(m.examDocID, m.studentID, m.imageURL))).then(res=>{
      //     resultArr = [...resultArr ,...res];
      //     if(i === array.length -1 ) {
      //         // console.log("resultArr return",resultArr)
      //       resolve(resultArr);
      //     }
      //   })
      // }
    })
  }

  function addStudentFileSelect() {
    let ele = document.createElement("input");
    ele.type = "file";
    ele.accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    ele.onchange = selectedFile;
    ele.click();
  }

  function selectedFile(event) {
    if (event?.target?.files.length > 0 && event?.target?.files[0]) {
      setAddStudentExcelFile(event?.target?.files[0]);
    }
  }
  async function processToDownloadSummaryReportExcelSheetChunkFiles() {
    presentLoader();
    const docLimit = 10000;

    let lastDoc = null;
    let paginationCount = 0;

    await childProcessFunction([], false);
    console.log("DONE");
    dismissLoader();

    async function childProcessFunction(dataArray = []) {
      try {
        const getMeritStudentsForExcelDownloadByPaginationResponse = await getMeritStudentsForExcelDownloadByPagination(lastDoc, examDocID, subExamDocID, docLimit);
        // if ((getMeritStudentsForExcelDownloadByPaginationResponse.docs.length < docLimit) || getMeritStudentsForExcelDownloadByPaginationResponse.empty) {
        //   isLast = true;
        // }

        // if (dataArray.length === (docLimit * 5)) {
        //   paginationCount++;
        //   await downloadStudentsExcelByDocsData(paginationCount, dataArray);
        //   dataArray = [];
        // }

        if (!getMeritStudentsForExcelDownloadByPaginationResponse.empty) {
          lastDoc = getMeritStudentsForExcelDownloadByPaginationResponse.docs[getMeritStudentsForExcelDownloadByPaginationResponse.docs.length - 1];
          dataArray = dataArray.concat(getMeritStudentsForExcelDownloadByPaginationResponse.docs.map(m => ({ UID: m.id, ...m.data() })))
          await childProcessFunction(dataArray);
        } else {
          paginationCount++;
          await downloadSummaryReportExcelSheet(dataArray);
          dataArray = [];
        }
      } catch (error) {
        dismissLoader();
        console.error("Error => ", error);
      }
    }
  }
  return (
    <>
      <Box className={cssClasses.mainTabPanelWrapper}>
        {!isDataPresent ?
          <>
            <div className={cssClasses.uploadFileWrapper}>
              <img src={uploadFileCloud} alt="" onClick={() => { document.getElementById("fileSelectForMultiMeritList").click() }} />
              <p>No data found please upload</p>
              <input hidden
                type="file"
                name=""
                id="fileSelectForMultiMeritList"
                onChange={fileSelectForMultiMeritList}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </>
          :
          <>
            <div className={cssClasses.mainTabPanelHeader}>
              <div>
                <p>Exam Merit List</p>
                <p>Total Students Applied - <span>{totalMeritStudent}</span></p>
                <p>Total Students Verified - <span>{totalStudentsVerified}</span></p>
              </div>
              <div>
                <div>
                  <div>
                    {uploadingPdfProgress === null ?
                      <Button style={{ color: "#6941C6", backgroundColor: "#FFFFFF" }} btnName={(subExamObj?.rawResultSheet && subExamObj?.rawResultSheet !== "") ? "Replace\xa0Result\xa0Sheet" : "Upload\xa0Result\xa0Sheet"} clicked={() => { document.getElementById("RefForInputResultSheet").click(); }} />
                      :
                      <CircularProgressWithLabel value={uploadingPdfProgress} />
                    }

                  </div>
                  <div hidden>
                    <input
                      type="file"
                      name=""
                      id="RefForInputResultSheet"
                      onChange={fileSelectForResultSheet}
                      accept="application/pdf"
                    />
                  </div>
                </div>
                <Box style={{ marginLeft: "2rem" }}>
                  <IconButton
                    size="large"
                    edge="start"
                    aria-label="open drawer"
                    tittle={"Message"}
                    onClick={handleClick}
                    style={{ cursor: "pointer !important" }}
                  >
                    <img src={moreVertical} alt="3 dots icon" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >

                    {loggedInUser.tabAccess.exams.some(s => s === 'add') &&
                      <MenuItem sx={[menuItem]}
                        onClick={() => { handleClose(); addStudentFileSelect(); }}
                      >
                        <FileUploadIcon /> Upload Students
                      </MenuItem>
                    }
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleClose(); processToDownloadExcelWithChunkFiles(); }}
                    // onClick={() => { handleClose(); downloadStudents(); }}
                    >
                      <DownloadIcon /> Download Students list
                    </MenuItem>
                    {isProforma &&
                      <MenuItem sx={[menuItem]}
                        onClick={() => { handleClose(); downloadResultSheetsZip(); }}
                      >
                        <FolderZipIcon /> Download Result Sheets
                      </MenuItem>
                    }
                    <MenuItem sx={[menuItem]}
                      onClick={() => {
                        handleClose();
                        // downloadResultExcelSheet();
                        // setSelectExamCenterForDownloadResultExcelSheetModal(true);
                        downloadResultExcelSheetBy_100K_Chunks();
                      }}
                    >
                      <ListAltIcon /> Download All Result Excel Sheet
                    </MenuItem>
                    <MenuItem sx={[menuItem]}
                      onClick={() => {
                        handleClose();
                        // downloadResultExcelSheet();
                        setSelectExamCenterForDownloadResultExcelSheetModal(true);
                      }}
                    >
                      <ListAltIcon /> Download Result Excel Sheet Center Wise
                    </MenuItem>
                    <MenuItem sx={[menuItem]}
                      onClick={() => {
                        handleClose();
                        // downloadResultExcelSheet();
                        setSelectExamCenterForDownloadResultExcelSheetForOnlyCurrentExamModal(true);
                      }}
                    >
                      <ListAltIcon /> Download Result Excel Sheet Center Wise For Current Exam
                    </MenuItem>

                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleClose(); downloadResultEntryExcelSheet(); }}
                    >
                      <ListAltIcon /> Download Final Result Entry Sheets
                    </MenuItem>

                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleClose(); processToDownloadSummaryReportExcelSheetChunkFiles(); }}
                    // onClick={() => { handleClose(); downloadSummaryReportExcelSheet(); }}
                    >
                      <ListAltIcon /> Download Summary Report Sheets
                    </MenuItem>

                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleClose(); processToDownloadQualifiedReportExcelSheetChunkFiles(); }}
                    // onClick={() => { handleClose(); downloadQualifiedReportExcelSheet(); }}
                    >
                      <ListAltIcon /> Download Qualified Report Sheets
                    </MenuItem>
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleClose(); setSelectExcelForDownloadStudentExcelSheetModalToggle(true); }}
                    >
                      <Merge /> Merge Selected Applicants
                    </MenuItem>
                  </Menu>
                  <input
                    type="file"
                    onChange={uploadOfflineZip}
                    name="image"
                    id="uploadOfflineZip"
                    accept="application/zip"
                    hidden
                  />
                </Box>
              </div>
            </div>
            <div className={cssClasses.searchAndFilterWrapper}>
              <div>
                <ToggleButtonGroup
                  color="primary"
                  value={searchField}
                  onChange={(e) => { setSearchField(e.target.value); }}
                  exclusive
                  style={{ filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))" }}
                // onChange={handleChange}
                >
                  <ToggleButton value="studentFullNameForSearch" sx={[toggleButtonStyle]}>Name</ToggleButton>
                  <ToggleButton value="Applicant" sx={[toggleButtonStyle]}>Applicant</ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div>
                <div><SearchBar placeholder={"Search"} search={(_value) => setMeritStudentSearchValue(_value)} /></div>
                {/* <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={filterIcon} btnName={"Filters"} /></div> */}
              </div>
            </div>
            <div className={cssClasses.examCenterWrapper} style={{ height: height ? height : "unset", flex: height ? "unset" : 1 }}>
              <DataGrid
                rows={dataGridLoading ? [] : meritStudentList}
                columns={meritColumns}
                hideFooterPagination={false}
                hideFooter={false}
                pageSize={10}
                rowsPerPageOptions={[10]}
                page={pageMaintain}
                sx={[dataGridStyles, {
                  " .MuiDataGrid-footerContainer > div:first-of-type:after": {
                    content: '"' + `${pageMaintain + 1} Page` + '"',
                    paddingLeft: "1rem"
                  }
                }]}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                disableVirtualization
                loading={dataGridLoading}
                onPageChange={(_page) => {
                  // console.log({ _page });
                  setPageMaintain(_page)
                  if (pageNumber < _page) {
                    // * Next When Data need
                    setPageNumber(_page);
                    getMoreData()
                  } else {
                    // *previous
                  }
                }}
              />
            </div>
          </>
        }

        <MeritStudentUploadLoadingStepper
          open={progressBarDataUploadModalToggle}
          value={meritStudentUploadLoadingStepperValue}
          totalDataUploading={totalDataUploading}
          failedCandidatesCount={invalid_Entries.length}
          failedCandidatesArray={invalid_Entries}
          addingStudentCount={addedMeritStudentCount}
          handleClose={() => {
            setProgressBarDataUploadModalToggle(false);
            setMeritStudentUploadLoadingStepperValue(0);
            setTotalDataUploading(0);
            setInvalid_Entries([]);
            setAddedMeritStudentCount(0);
          }}
        />
        <FieldMeritEntries
          openModal={invalid_EntriesModalToggle}
          failedCandidatesArray={invalid_Entries}
          closeModal={() => {
            setInvalid_EntriesModalToggle(false);
            setInvalid_Entries([]);
            setProgressBarDataUploadModalToggle(false);
            setMeritStudentUploadLoadingStepperValue(0);
            setTotalDataUploading(0);
            setInvalid_Entries([]);
            setAddedMeritStudentCount(0);
          }}
        />

        {addStudentExcelFile &&
          <MeritStudentAddLoadingStepper
            excelFile={addStudentExcelFile}
            examName={examName}
            examDocID={examDocID}
            subExamDocID={subExamDocID}
            handleClose={() => {
              setAddStudentExcelFile(null);
            }}
          />
        }

        {
          <DownloadMeritStudentExcelSheet
            examCenterName={DownloadMeritStudentExcelSheetExamCenterName.current}
            currentDownloading={currentDownloading?.current}
            totalCentersDownloading={totalCentersDownloading?.current}
            isOpenModal={stepperToggleForResultSheet}
            photosProgress={stepperPhotoProgressValueForResultSheet}
            StepperValue={stepperValueForResultSheet}
            handleClose={() => {
              setStepperValueForResultSheet(0);
              setStepperPhotoProgressValueForResultSheet(0);
              setStepperToggleForResultSheet(false);
            }}
          />
        }

      </Box>

      {selectExamCenterForDownloadResultExcelSheetModal &&
        <SelectExamCenterForDownloadResultExcelSheet
          open={selectExamCenterForDownloadResultExcelSheetModal}
          examDocID={examDocID}
          subExamDocID={subExamDocID}
          getDownloadedInfo={true}
          handleClose={(_selectedExamCenter) => {
            if (_selectedExamCenter) {
              console.log("_selectedExamCenter => ", _selectedExamCenter);
              setSelectExamCenterForDownloadResultExcelSheetModal(false);
              if (_selectedExamCenter && Array.isArray(_selectedExamCenter) && _selectedExamCenter?.length > 0) {
                totalCentersDownloading.current = 0;
                currentDownloading.current = 0;
                DownloadMeritStudentExcelSheetExamCenterName.current = "";
                goTODownloadResultExcelSheetOneByOne(_selectedExamCenter, true);
              }
            }
          }}
        />
      }
      {selectExamCenterForDownloadResultExcelSheetForOnlyCurrentExamModal &&
        <SelectExamCenterForDownloadResultExcelSheet
          open={selectExamCenterForDownloadResultExcelSheetForOnlyCurrentExamModal}
          examDocID={examDocID}
          subExamDocID={subExamDocID}
          getDownloadedInfo={false}
          handleClose={(_selectedExamCenter) => {
            if (_selectedExamCenter) {
              console.log("_selectedExamCenter => ", _selectedExamCenter);
              setSelectExamCenterForDownloadResultExcelSheetForOnlyCurrentExamModal(false);
              if (_selectedExamCenter && Array.isArray(_selectedExamCenter) && _selectedExamCenter?.length > 0) {
                totalCentersDownloading.current = 0;
                currentDownloading.current = 0;
                DownloadMeritStudentExcelSheetExamCenterName.current = "";
                goTODownloadResultExcelSheetOneByOne(_selectedExamCenter, false);
              }
            }
          }}
        />
      }
      {SelectExcelForDownloadStudentExcelSheetModalToggle &&
        <SelectExcelForDownloadStudentExcelSheet
          open={SelectExcelForDownloadStudentExcelSheetModalToggle}
          examDocID={examDocID}
          subExamDocID={subExamDocID}
          subExamName={subExamName}
          handleClose={() => {
            setSelectExcelForDownloadStudentExcelSheetModalToggle(false);
          }}
        />
      }
    </>
  );
}
function convertFirebaseObjToDataGridObj(_serverList) {
  return _serverList.map(m => ({
    id: m.docID,
    fileName: {
      img: m.hasOwnProperty("image") ? m?.image : m.hasOwnProperty("Photo") ? m?.Photo : "",
      name: m["Full Name"],
      email: m?.email || m?.Email,
      onErrorImg: user_icon
    },
    gender: m?.gender,
    examName: "",
    name: m["Full Name"],
    email: m?.email || m?.Email,
    phone: m?.phone,
    ...m,
  }))
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function convertBase64ToFile(base64, fileName, key) {
  let fileObj = {};
  if (base64.includes("data:image")) {
    fileObj = {
      name: fileName,
      size: new Buffer(base64, 'base64').length,
      key: key + fileName,
      arrayBuffer: new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')
    }
  } else if (base64.includes("data:application")) {
    fileObj = {
      name: fileName,
      size: new Buffer(base64, 'base64').length,
      key: key + fileName,
      arrayBuffer: new Buffer.from(base64.replace(/^data:application\/\w+;base64,/, ""), 'base64')
    }
  }
  return fileObj
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={"1.3rem"}

        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function imageArrayPromise(imgArr) {
  let promises = []
  imgArr.forEach(ele => {
    promises.push(getBase64FromImageUrl(ele))
  });
  return new Promise((resolve, reject) => {
    Promise.all(promises).then(res => {
      // console.log(res);
      resolve(res)
    }).catch(err => {
      reject(err)
    });
  })
}

function getBase64FromImageUrl(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/jpeg");
      // 
      // console.log("dataURL",dataURL);
      resolve(dataURL)
    };
    img.onerror = function (err) {
      console.log(err);
      resolve("")
    }

    if (url === "") {
      resolve("");
    } else {
      img.crossOrigin = '';
      img.crossOrigin = 'anonymous'
      img.src = url;

      // img.setAttribute('crossOrigin', 'anonymous');
      // setTimeout(() => {
      //   resolve("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAQtSURBVFiFtZfbbxRVHMc/Z2Z3Z9vddrfLau9ggIrGKCViJPDAG8FIQuIF44soJvpSCVa0tURSEi4lMfFCfDGo8R8wRkOEREMiCcTogxKFiBFr5dIA7ZZetjszZ87xYXch2un0bJVfcpKdM9/z+332N+fyO4IabKhvKHN5Yviz64Xi+qLrJ7Uu9wsBTiLmNaWd3/NL0q8ffPvdY6Y+hamwf9fOwXN/XN+r9e0xGo2SARqNRmAB+ab0eHdXtqt38J3x/w1gd0/P0V9Hxl6sPgdBgO95BCqYo3USNp3NTZNL23KnOvPJl7f3Hrj8nwAG+1/d9MMvoydERel5Lr7vm3CTa6xTG9feu+uVPfuPzKexF3KydNn935U8WV8O7hkHB5h1pbh4aeyx3h1PjRz75tsfwzRWlIMDAwPNhanSEgClAnzfMw5eNc+XjIwW3pjvfSxq8PjNm4eqv+9py7Gn50nyuSyWHc6tAsWN8QLvfXKM789eBMCJx1i+rH3rfDEiMzBd8h8BSNU5vD+0m9bWFuKOgx2Lh7a449Da2sLQmztY++ByADpbspMvvTZwYVEAnpQ5gOef2YwVT4IVmbDbJgQ7X3gcgExD/fUoaSSADsqTdOWKzmqPGQCQz2UqLEItGqBqtr3gYpljQhi5NgO4kxYJ0Jh2RpRS5HPZRQdI1cevRL2PnFVrVjVvyjYmvkrVJ9cBYJhWAEsItaF7+U8tDflno3RGZ4GeHJaImI0sgSyaESh/SjQ/3LggqJGzQM4AYCfMz0+tR01khgD+l0D5E9j1ZgAq+NREZvYJtLaZHHGxKutRzoB0o4JPcXd3Rgix4MZhlAEhRIA38QS6cv7HUuU2T3Tk7GaT4MYAAOKu1V8wc3MbWkoA7JAFpGUBNbtRtK8/bezXVHgrhtaC4a8vgWgjFgc0BC74/kXRtWVFrf5q3gmFEBpZLOIWYPoqTI9CaRIIqc/uBIDeu+9jTv21EicDyVy5ORm8E+e7Znb2Hq3V34KfQB853Ma12UNIdZ8u+auEFBm0Rrsz0F7eooM/xxi/egMtIJlNT8TSdRdEMnGOjsY9qb7ByK04EkD37z3DlLcO61+J0hp95RoE5awrrRnzSnMOa8uyqOvIn2748MiGmgF031s/UwweuNXh+VAqoX0Jrg+VxVC1QGs8FaDQuEoh1e0yINWeP9vw0QerjQH0/n1bGXU/R1T+7fgEzMzOxxpqs0oy7ftoyjen9EMrtqQOH5xzYwqfhFPyQBVNjxVqDg5QZ8VoiCfKPjSosYlDYbpwAD9oA8B1oViqOXjVkpZNonKEy6LfEaYJrwdUuRbUtQZvysLStvJ8Of8bAAnbxpMKoYLQui56H5CR9eRce3QN4rmnEdu3QdIBwF5gpYdmQMeYFr5qRJpfwwA4fhJ9/OQ/umzLQiAQidh02JC/AaETktmE9IJ5AAAAAElFTkSuQmCC")
      // }, 1000);

      // imageToBase64(url).then((response) => {
      //     console.log(response); // "cGF0aC90by9maWxlLmpwZw=="
      //     resolve(response)
      // }).catch(
      // (error) => {
      //   console.log(error); // Logs an error if there was one
      //   reject(error)
      // })
    }
  })
}

function getBase64FromImageUrlForExcel(obj) {

  return new Promise((resolve, reject) => {

    if (obj?.url && obj.url !== "") {
      // let photoUrl = "http://192.168.2.62:8080/"+process.env.REACT_APP_bucketAccessRootPath + obj?.url;
      let photoUrl = process.env.REACT_APP_CORS_ANYWHERE_URL + process.env.REACT_APP_bucketAccessRootPath + obj?.url;
      // let photoUrl = 'https://cors-anywhere.herokuapp.com/' + process.env.REACT_APP_bucketAccessRootPath + obj?.url;
      // let photoUrl = "https://images.unsplash.com/photo-1508921912186-1d1a45ebb3c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80";
      // console.log(photoUrl);
      fetch(photoUrl).then((res) => { return res.arrayBuffer() }).then(res => window.btoa([].slice.call(new Uint8Array(res)).map(function (bin) { return String.fromCharCode(bin) }).join(""))).then(res => {
        // console.log({data: res,photoUrl});
        resolve({ dataURL: res.includes("data:image") ? res : "data:image/png;base64," + res, ...obj });
      }).catch(err => {
        console.log(err);
        resolve({ dataURL: "", ...obj })
      })

    } else {
      resolve({ ...obj });
    }
  })
}

function getBase64FromImageUrlForExcelTry(obj) {

  return new Promise((resolve, reject) => {

    var img = new Image();
    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/jpeg");
      // 
      // console.log("dataURL",dataURL);
      resolve({ dataURL: dataURL, ...obj })
    };
    img.onerror = function (err) {
      console.log(err);
      resolve({ dataURL: "", ...obj })
    }

    if (obj.url === "") {
      resolve({ dataURL: "", ...obj })
    } else {
      img.crossOrigin = '';
      img.crossOrigin = 'anonymous'
      img.src = obj.url;
    }
  })
}


const FieldMeritEntries = ({ openModal, closeModal, failedCandidatesArray }) => {


  const [StudentList, setStudentList] = useState([]);

  useEffect(() => {
    setStudentList(failedCandidatesArray?.map((m, index) => ({ id: m.reason + index, ...m })))
  }, [failedCandidatesArray])


  const columns = [
    { field: 'UID', headerName: 'UID', width: 150 },
    {
      field: 'Photo', headerName: 'Image', width: 100,
      renderCell: (params) => {
        return (
          <img src={process.env.REACT_APP_bucketAccessRootPath + params.value} alt="" style={{ width: "50px", height: "60px" }} />
        )
      }
    },
    { field: 'reason', headerName: 'Reason', width: 400 }
  ];

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "min(80rem, 90%)",
        background: "#ffffff",
        borderRadius: "0.6rem",
        boxShadow: "0px 7px 14px rgba(64, 68, 82, 0.08)",
        height: "min(600px , 90%)",
      }]}>
        <Box sx={[{
          display: "inline-block",
          width: "100%",
          height: "100%",
        }]}>
          {/* <div className={Styles.heading}>Failed Upload Candidates List</div> */}
          <div style={{
            display: "inline-block",
            width: "100%",
            height: "85%",
            maxHeight: "85%",
          }}>
            <DataGrid
              rows={StudentList}
              columns={columns}
              pageSize={10}
              sx={[dataGridStyles]}
            />
          </div>
          <div style={{
            display: "inline-block",
            width: "calc(100% - 2rem)",
            height: "calc(12% - 2rem)",
            textAlign: "right",
            padding: "1rem",
          }}>
            <div style={{
              display: "inline-block",
              width: "20%"
            }}>
              <Button
                style={{
                  color: "#ffffff",
                  backgroundColor: "#6941c6",
                }}
                btnName={"OK"}
                btnIcon={false}
                clicked={closeModal}
              />
            </div>
          </div>
        </Box>
      </Box>
    </Modal>
  )
}

function createChunkArray(arr, chunkSize) {
  if (!chunkSize) {
    chunkSize = process.env.REACT_APP_CHUNK_FOR_PHOTO_UPLOAD || 200;
  }
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)
    // const chunkIndex = Math.floor(index/20)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}
function createChunkArrayCustomIndex(arr, _index) {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / _index)
    // const chunkIndex = Math.floor(index/20)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}
import React, { useEffect, useState } from "react";

//* images
import uploadIconBlack from "../../assets/images/uploadIconBlack.svg";
import addIcon from "../../assets/images/add icon.svg";
import uploadIcon from "../../assets/images/uploadIcon.svg";

//* import component
import ExamCenter from "../../Components/ExamCenter/ExamCenter";

//* import sub-component
import Button from "../../Subcomponent/Button";

//* modals
import AddExamCenter from "../../Modals/AddExamCenter/AddExamCenter";
import ProgressBarDataUploadForExamCenter from "../../Modals/ProgressBarDataUploadForExamCenter/ProgressBarDataUploadForExamCenter"
import ExamCenterAddFromExcelModal from "../../Modals/ExamCenterAddFromExcelModal/ExamCenterAddFromExcelModal";

//* Import css
import cssClasses from "./ExamCentersScreen.module.css";

//* Import store
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux Store/actions";
import * as XLSX from "xlsx";

//* import services
import { getAllExamCentersByCode, AddDocExamCenter } from "../../services/ExamCenterService";
import { errorToast } from "../../services/Toast";

export const ExamCentersWrapper = () => {

  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.Auth.user);

  const [addExamCenterModalToggle, setAddExamCenterModalToggle] = useState(false);
  const [examCenterAddFromExcelModalToggle, setExamCenterAddFromExcelModalToggle] = useState(false);

  const [ProgressBarDataUploadModalToggle, setProgressBarDataUploadModalToggle] = useState(false);

  const [totalDataUploading, setTotalDataUploading] = useState(0);
  const [addedCandidates, setAddedCandidates] = useState(0);
  const [duplicateCandidatesCount, setDuplicateCandidatesCount] = useState(0);
  const [failedCandidatesCount, setFailedCandidatesCount] = useState(0);
  const [failedCandidatesArray, setFailedCandidatesArray] = useState([]);

  const [reflectToggle, setReflectToggle] = useState(0);

  useEffect(() => {
    dispatch(actions.activeMenu('exam-center'));
  }, [])


  const fileSelectForUploadExamCenter = (e) => {
    var _file = e.target.files[0];
    // console.log({fileSelectForUploadExamCenter:_file});
    if (_file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        var excelData = event.target.result;
        excelData = new Uint8Array(excelData);
        var workbook = XLSX.read(excelData, {
          type: "array",
          cellText: false,
          cellDates: true,
        });

        var arrayOfRows;
        workbook.SheetNames.forEach(function (sheetName) {
          arrayOfRows = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
            { raw: false, dateNF: "yyyy-mm-dd" }
          );
        });
        console.log("arrayOfRows => ", arrayOfRows);
        let invalidExamCenter = [];

        const isValidExcel = checkValidationExcel(arrayOfRows);

        arrayOfRows.forEach(element => {

        });

        if (arrayOfRows.length > 0) {
          examCentersAddToFirebase(arrayOfRows)
        } else {
          errorToast("Data not found in file.")
        }
      };
      reader.readAsArrayBuffer(_file);
    }
  }

  function checkValidationExcel(_readExcelData = []) {
    // let validateExcelFlag = true;
    // let invalidExcelArr = [];

    // for (let i = 0; i < _readExcelData.length; i++) {
    //   const element = _readExcelData[i];
    //   let errorArr = [];
    //   if (!element?.Applicant) {
    //     validateExcelFlag = false;
    //     errorArr.push(`Please enter Applicant.`);
    //   }

    //   if (!element?.District) {
    //     validateExcelFlag = false;
    //     errorArr.push(`Please enter District.`);
    //   }

    //   if (!element?.examCenterName) {
    //     validateExcelFlag = false;
    //     errorArr.push(`Please enter examCenterName.`);
    //   } else if (!examCenterOriginalList.some(f => f?.examCenterName === element?.examCenterName)) {
    //     validateExcelFlag = false;
    //     errorArr.push(`ExamCenterName not found in Exam.`);
    //   }

    //   invalidExcelArr.push({
    //     ...element,
    //     row: i + 1,
    //     errorArr
    //   })
    // }

    // setInvalidExcelStudent([...invalidExcelArr]);
    // return validateExcelFlag;
  }

  async function examCentersAddToFirebase(examCentersList) {
    setProgressBarDataUploadModalToggle(true);
    const processCount = 0;
    examCentersList.forEach(async (elementExamCenter) => {
      console.log(elementExamCenter);
      getAllExamCentersByCode(elementExamCenter.examCenterCode).then(result => {
        if (result.empty) {
          AddDocExamCenter(elementExamCenter).then(res => {
            console.log("add -> ", res);

          }).catch(err => {
            console.log(err);
            setFailedCandidatesCount(pre => { return pre++ });
          })
        } else {
          //! duplicate Count Increase
          setDuplicateCandidatesCount(pre => { return pre++ });
          processCount++;
          console.log(elementExamCenter.examCenterCode, " Duplicate ", result.docs[0].id);
        }
      })
    })
  }

  return (
    <>
      <div className={cssClasses.pageWrapper}>
        <div className={cssClasses.headerWrapper}>
          <div className={cssClasses.headingWrapper}>
            <p>All Exam Centers</p>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
          </div>
          <div className={cssClasses.headerButtonWrapper}>
            {/* { loggedInUser.tabAccess.exams.some(s=> s==='add') && */}
            <>
              {/* <div>
                <Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnIcon={uploadIconBlack} btnName={"Upload\xa0Exam\xa0Center"} clicked={() => { document.getElementById("fileSelectForUploadExamCenter").click(); }} />
                <input hidden type="file" name="" id="fileSelectForUploadExamCenter" onChange={fileSelectForUploadExamCenter} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
              </div> */}
              <div>
                <Button
                  style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
                  btnIcon={addIcon}
                  btnName={"Add\xa0Exam\xa0Center"}
                  clicked={() => { setAddExamCenterModalToggle(true) }}
                  disable={!loggedInUser.tabAccess.examCenter.some(s => s === 'add')}
                />
              </div>
              <div>
                  <Button 
                    style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} 
                    btnIcon={uploadIcon} 
                    btnName={"Upload\xa0Exam\xa0Center\xa0Excel"} 
                    clicked={() => {setExamCenterAddFromExcelModalToggle(true)}}
                    disable={!loggedInUser.tabAccess.examCenter.some(s=> s==='add')}
                  />
                </div>
            </>
            {/* } */}
          </div>
        </div>

        <div className={cssClasses.breaker}></div>

        <ExamCenter reflectToggle={reflectToggle} />

      </div>
      {addExamCenterModalToggle &&
        <AddExamCenter
          open={addExamCenterModalToggle}
          handleClose={(_value) => {
            setAddExamCenterModalToggle(false);
            if (_value) {
              setReflectToggle(pre => pre + 1);
            }
          }}
          isEditable={loggedInUser.tabAccess.exams.some(s => s === 'add')}
        />
      }

      { examCenterAddFromExcelModalToggle &&
        <ExamCenterAddFromExcelModal
          open={examCenterAddFromExcelModalToggle}
          handleClose={() => setExamCenterAddFromExcelModalToggle(false)}
        />
      }

      {
        ProgressBarDataUploadModalToggle &&
        <ProgressBarDataUploadForExamCenter
          open={ProgressBarDataUploadModalToggle}
          handleClose={() => { setProgressBarDataUploadModalToggle(false) }}
          value={0}
          totalDataUploading={totalDataUploading}
          addedCandidates={addedCandidates}
          duplicateCandidatesCount={duplicateCandidatesCount}
          failedCandidatesCount={failedCandidatesCount}
          failedCandidatesArray={failedCandidatesArray}
          completed={false}
        />
      }



    </>
  );
};
export default ExamCentersWrapper;

import Auth from "./auth";
import isLoading from "./isLoading";

import { combineReducers } from "redux"
import ACTIVE_MENU from "./activeMenu";

const rootReducer = combineReducers({
    Auth:Auth,
    IsLoading:isLoading,
    ActiveMenu: ACTIVE_MENU
});

export default rootReducer;
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import cssModule from "./ProgressBarDataUploadForExamCenter.module.css";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
// import socketIOClient from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../../Subcomponent/Button";
import { getFailedArray, replace } from "../../services/StudentService";
import FailedEntires from "../FailedEntries/FailedEntries";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  outline: 0,
};

const useStyles = makeStyles((theme) => ({
  ButtonStyle: {
    width: "7rem",
    marginLeft: "auto",
    marginRight: "2rem",
    marginBottom: "1rem",
    marginTop: "1rem",
  },

  ProgressStyle: {
    backgroundColor: "#F9FAFB",
    padding: "2rem",
    border: "1px solid #EAECF0",
  },
  verticalLine: {
    borderLeft: "2px solid #D0D5DD",
    height: "30px",
    margin: "9px 0px 12px 13px ",
  },
  gridContainer: {
    display: "flex",
  },
  QuestionStyle: {
    margin: "1.2rem 1.8rem 1.5rem 3.5rem",
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.3rem",
    fontWeight: "800",
  },
  Title: {
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.6rem",
    color: "#344054",
    padding: "1.5rem 0rem 1.7rem 3rem",
    display: "flex",
    alignItems: "center",
  },
  ReplaceDiv: {
    padding: "1.5rem 0",
  },
  gridChild: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0.9rem",
  },
  loader: {
    marginLeft: "2rem",
  },
  TextStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.5rem",
    fontWeight: "800",
    lineHeight: "2.4rem",
    color: "#101828",
  },
  numberStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.4rem",
    fontWeight: "100",
    lineHeight: "2rem",
    color: "#667085",
  },
  iconStyle: {
    color: "#7F56D9",
    width: "4rem",
    height: "5rem",
  },
  tickIcon: {
    // marginLeft:"17px",
  },
  UploadIcons: {
    margin: "0.3rem 0.5rem",
  },

  failedEntriesText: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  },
  wrapperFailedEntries: {
    display: "flex",
    padding: "0rem 3.6rem 2rem 3.6rem",
    fontFamily: "Inter-Regular",
    fontSize: "1.3rem",
    fontWeight: "800",
    lineHeight: "2rem",
    marginTop: "1rem",
  },
  wrapperOpenModal: {
    marginLeft: "2rem",
  },
}));

export default function ProgressBarDataUploadForExamCenter({
  open,
  handleClose,
  value,
  totalDataUploading,
  addedCandidates,
  duplicateCandidatesCount,
  failedCandidatesCount,
  failedCandidatesArray,
  completed,
}) {
  const [replacingData, setReplacingData] = useState(false);
  const [addedDataCount, setAddedDataCount] = useState(0);
  const [failedEntriesModal, setFailedEntriesModal] = useState(false);
  const [showOkay, setShowOkay] = useState(false);
  const [replaceDataMsg, setReplaceDataMsg] = useState(0);


  useEffect(() => {
    // window.addEventListener("beforeunload", alertUser);
    // return () => {
    //   window.removeEventListener("beforeunload", alertUser);
    // };


    // window.addEventListener('popstate', (event) => {
    //   event.preventDefault();
    //   alert("You message");
    // });
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  
  function openFailedEntriesModal() {
    setFailedEntriesModal(true);
  }
  const classes = useStyles();
  function cleanupAndClose() {
    getFailedArray().then((resp) => {
      console.log('resp: ', resp);
      handleClose();
    });
  }
  function replaceStudents() {
   
    setReplacingData(true);
    const token = sessionStorage.getItem('token');
    // var source = new EventSource("http://192.168.2.62:7000/candidates/getReplaceCandidatesResult");
    var source = new EventSource(process.env.REACT_APP_NODE_API_URL + 'candidates/getReplaceCandidatesResult?token=' + token)
    source.addEventListener(
      "message",
      function (e) {
        console.log(JSON.parse(e.data));
        let data = JSON.parse(e.data);
        // ? response - you will get this response obj repeatedly
        // ? show the counts on the progress bar modal
        //   {
        //     "addedCount": 2,
        //     "failedCount": 0,
        //     "failedArray": [],
        //     "duplicateCount": 1,
        //     "duplicateArray": [],
        //     "processFinished": true
        // }
        setAddedDataCount(data.addedCount);
        setReplaceDataMsg(data.addedCount);
        if (data.processFinished) {
          source.removeEventListener("message", this);
          source.close();
          setShowOkay(true);
          // getFailedArray().then((resp) => {
          //   console.log('resp: ', resp);
          // });
          // handleClose();
          // successTost(resp.message);
        }
        // }
      },
      false
    );
    // ? upload to firebase
    replace();
  }
  return (
    <>
      <Modal open={open} aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className={classes.Title}>
            {" "}
            <div className={classes.UploadIcons}></div> Uploading Data
          </div>

          <div className={classes.ProgressStyle}>
            <div
              className={classes.gridContainer}
              style={{ opacity: value >= 0 ? 1 : 0.7 }}
            >
              {value >= 1 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : value < 0 ? (
                <CheckCircleIcon
                  sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                />
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>Reading EXCEL</span>
              </div>
            </div>

            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: value >= 2 ? 1 : 0.7 }}
            >
              {value >= 3 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : value < 2 ? (
                <CheckCircleIcon
                  sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                />
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}
              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>
                  {" "}
                  Uploading Data to Database
                </span>
                <div className={classes.numberStyle}>
                  {/* { filesUploading?  value : "Error: Something went wrong!"}  */}
                  Total Entries: {totalDataUploading} New Entries:{" "}
                  {addedCandidates} : Duplicate Entries:{" "}
                  {duplicateCandidatesCount}
                  <div>Failed Entries: {failedCandidatesCount}</div>
                </div>
              </div>
            </div>
          </div>
          {/* { Duplicate Entries ?  */}
          {value >= 3 &&
            <>
              {duplicateCandidatesCount > 0 &&
                <div className={classes.ReplaceDiv}>
                  <div className={classes.gridContainer}>
                    {replacingData === true ? (
                      <>
                        <div className={classes.QuestionStyle}>
                          Replacing entries: {replaceDataMsg} of{" "}
                          {duplicateCandidatesCount}
                        </div>
                        {showOkay === true ? (
                          <div className={classes.ButtonStyle}>
                            <Button
                              style={{
                                color: "#ffffff",
                                backgroundColor: "#6941c6",
                              }}
                              btnName={"OK"}
                              btnIcon={false}
                              clicked={handleClose}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <div className={classes.QuestionStyle}>
                          Replace duplicate Students?
                        </div>

                        <div className={classes.gridChild}>
                          <Button
                            style={{ color: "#6941c6", backgroundColor: "#FFFFFF" }}
                            btnName={"No"}
                            btnIcon={false}
                            clicked={cleanupAndClose}
                          />
                        </div>
                        <div className={classes.gridChild}>
                          <Button
                            style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                            btnName={"Yes"}
                            btnIcon={false}
                            clicked={replaceStudents}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              }
              {
                failedCandidatesCount > 0 &&
                <div className={classes.wrapperFailedEntries}>
                  <div className={classes.failedEntriesText}>View Failed Entries
                    <div className={classes.wrapperOpenModal}>
                      <Button
                        style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                        btnName={"View"}
                        btnIcon={false}
                        clicked={openFailedEntriesModal}
                      />
                    </div>
                  </div></div>

              }
              {
                (duplicateCandidatesCount === 0 && failedCandidatesCount > 0) &&
                <div className={classes.ButtonStyle}>
                  <Button
                    style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                    btnName={"OK"}
                    btnIcon={false}
                    clicked={cleanupAndClose}
                  />
                </div>
              }
              {
                (duplicateCandidatesCount === 0 && failedCandidatesCount === 0) &&
                <div className={classes.ButtonStyle}>
                  <Button
                    style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                    btnName={"OK"}
                    btnIcon={false}
                    clicked={cleanupAndClose}
                  />
                </div>
              }
            </>
          }
          <FailedEntires openModal={failedEntriesModal} closeModal={() => { setFailedEntriesModal(false) }} failedCandidatesArray={failedCandidatesArray} />
        </Box>
      </Modal>

    </>
  );
}


/* eslint-disable no-unused-vars */
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import classes from './SideMenu.module.css';
import dashboard from '../assets/images/side-menu-icons/dashboard.svg';
import dashboardActive from '../assets/images/side-menu-icons/dashboard-active.svg';
import students from '../assets/images/side-menu-icons/students.svg';
import studentsActive from '../assets/images/side-menu-icons/students-active.svg';
import examCenter from '../assets/images/side-menu-icons/exam-center.svg';
import examCenterActive from '../assets/images/side-menu-icons/exam-center-active.svg';
import exams from '../assets/images/side-menu-icons/exams.svg';
import examsActive from '../assets/images/side-menu-icons/exams-active.svg';
import hallTicket from '../assets/images/side-menu-icons/hall-tickets.svg';
import hallTicketActive from '../assets/images/side-menu-icons/hall-tickets-active.svg';
import admins from '../assets/images/side-menu-icons/admins.svg';
import adminsActive from '../assets/images/side-menu-icons/admins.svg';
import settings from '../assets/images/side-menu-icons/settings.svg';
import settingsActive from '../assets/images/side-menu-icons/settings-active.svg';
import support from '../assets/images/side-menu-icons/support.svg';
import supportActive from '../assets/images/side-menu-icons/support-active.svg';
import logout from '../assets/images/side-menu-icons/logout.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import action from "../Redux Store/actions";
import { signout } from '../services/Firebase';
import { useEffect, useRef } from 'react';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip'
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    menu_item: {
        marginBottom: "0.8rem",
        "&:hover": {
            background: "#F9F5FF",
        }
    },
    icon: {
        minWidth: "1.8rem !important",
        marginRight: "1.5rem"
    },
    menu: {
        margin: "0",
        "& span": {
            display: "inline-block",
            fontFamily: "Inter-Medium",
            fontSize: "1.6rem",
            lineHeight: "2.4rem",
            color: "#344054"
        }
    },
    active: {
        background: "#F9F5FF",
        color: "#6941C6",
        "& span": {
            color: "#6941C6",
            // background: "#F9F5FF"
        },
        "&:hover": {
            background: "#F9F5FF",
        }
    },
    user_avatar: {
        display: "inline-block !important",
        marginRight: "1.2rem !important",
        verticalAlign: "middle !important",
        width: "4rem !important",
        height: "4rem !important",
        "& svg": {
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            position: "relative",
        }
    }


}));
function SideMenu() {
    const classList = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeMenu = useSelector((state) => state.ActiveMenu.activeMenu);
    let loggedInUser = {};
    loggedInUser = useSelector((state) => state.Auth.user);
    
    const menuArray = [
        {
            icon: dashboard,
            activeIcon: dashboardActive,
            name: 'Dashboard',
            tabName: 'dashboard',
            isVisible: false,
            path: '/home/dashboard',
            isEnabled: true,
            show: true,
            accessKey : "dashboard"
        },
        {
            icon: students,
            activeIcon: studentsActive,
            name: 'Students',
            tabName: 'students',
            isVisible: false,
            path: '/home/students',
            isEnabled: true,
            show: true,
            accessKey : "student"
        },
        {
            icon: examCenter,
            activeIcon: examCenterActive,
            name: 'Exam Center',
            tabName: 'exam-center',
            isVisible: false,
            path: '/home/exam-centers',
            isEnabled: true,
            show: true,
            accessKey : "examCenter"
        },
        {
            icon: exams,
            activeIcon: examsActive,
            name: 'Exams',
            tabName: 'exams',
            isVisible: false,
            path: '/home/exams',
            isEnabled: true,
            show: true,
            accessKey : "exams"
        },
        {
            icon: hallTicket,
            activeIcon: hallTicketActive,
            name: 'Hall Tickets',
            tabName: 'hall-tickets',
            isVisible: false,
            path: '/home/hall-tickets',
            isEnabled: true,
            show: true,
            accessKey : "hallTickets"
        },
        {
            icon: admins,
            activeIcon: admins,
            name: 'Admins',
            tabName: 'admins',
            isVisible: false,
            path: '/home/admins',
            isEnabled: true,
            show: true,
            accessKey : "admins"
        }
    ];
    const settingsArray = [
        {
            icon: support,
            activeIcon: supportActive,
            name: 'Support',
            tabName: 'support',
            isVisible: false,
            path: '/home/support',
            isEnabled: true,
            show: true
        },
        {
            icon: settings,
            activeIcon: settingsActive,
            name: 'Settings',
            tabName: 'settings',
            isVisible: false,
            path: '/home/settings',
            isEnabled: true,
            show: true
        },
    ];
    
    function logoutUser() {
        signout().then(() => {
            navigate('/');
        });
    }
    return (
        <div className={classes.wrapper}>
            <div className={classes.app_info}>
                <div className={classes.app_logo}>
                    {/* <img src=''/> */}
                </div>
                <div className={classes.app_name}>Exam</div>
            </div>
            <div className={classes.menu_array}>
                <List>
                    {menuArray.map((menu,index) => ( (loggedInUser.tabAccess[menu.accessKey]?.length > 0) &&
                        <Link to={menu.path} key={`MenuArray_${index}`} tabIndex={index+1}>
                            <ListItem button key={menu.tabName} className={classList.menu_item}
                                selected={activeMenu === menu.tabName.toLowerCase()} 
                                classes={{ selected: classList.active }}
                                tabIndex={-1}
                            >
                                <ListItemIcon className={classList.icon}>
                                    <img src={activeMenu === menu.tabName.toLowerCase() ? menu.activeIcon : menu.icon} alt={menu.name} />
                                </ListItemIcon>
                                <ListItemText primary={menu.name} className={classList.menu} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </div>
            {/* <div className={classes.menu_array} style={{ height: "12%" }}>
                <List>
                    {settingsArray.map((menu) => (
                        <ListItem button key={menu.tabName} className={classList.menu_item} selected={activeMenu === menu.tabName.toLowerCase()}
                            classes={{ selected: classList.active }}>
                            <ListItemIcon className={classList.icon}>
                                <img src={activeMenu === menu.tabName.toLowerCase() ? menu.activeIcon : menu.icon} alt={menu.name} />
                            </ListItemIcon>
                            <ListItemText primary={menu.name} className={classList.menu} />
                        </ListItem>
                    ))}
                </List>
            </div> */}
            <Divider />
            <div className={classes.user_profile}>
                <Avatar alt={loggedInUser.name} src={""}  sx={{ bgcolor: deepPurple[500] }} className={classList.user_avatar} />
                
                <div className={classes.user_info}>
                    {loggedInUser &&
                        <>
                            <p className={classes.user_name}>{loggedInUser?.name}</p>
                            <p className={classes.user_email}>{loggedInUser?.email}</p>
                        </>
                    }
                </div>
                <div>
                    <div className={classes.logout} onClick={logoutUser} tabIndex={menuArray.length+1}>
                        <Tooltip title={<Typography fontSize={"1.4rem"}> Logout </Typography>} arrow>
                            <img src={logout} alt="Logout" />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SideMenu;
import React, { forwardRef, useImperativeHandle, useState } from "react";

//* import css
import Styles from "./ExamForm.module.css";

//* import MUI
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Switch from '@mui/material/Switch';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { Box } from "@mui/material";

//* images
import dragInputIcon from "../../assets/images/drag-input-icon.svg";

//* import Sub-components
import Dropdown from "../../Subcomponent/Dropdown";
import Button from "../../Subcomponent/Button";

// import service
import { updateExamDetails } from "../../services/ExamService";
import { errorToast, successToast } from "../../services/Toast";
import PreviewExamFormModal from "../../Modals/PreviewExamFormModal/PreviewExamFormModal";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  helperText: {
    fontSize: "1.4rem !important",
    margin: "0.2rem 0 0 0 !important",
  },
  ckeditor: {
    "&.ck.ck-editor": {
      fontSize: "1.4rem",
      marginTop: "0.6rem",
    },
  },
  textField: {
    width: "100%",
  },
  input: {
    fontSize: "1.4rem !important",
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "& input": {
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      // border: "unset",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&.MuiSelect-outlined": {
      border: "1px solid #D0D5DD !important",
    },
    "& fieldSet": {
      border: "none",
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
    "& p": {
      fontSize: "1.4rem",
    },
    "&.MuiSelect-select": {
      border: "1px solid #D6BBFB !important",
    },
  },
  datePickerWrapper: {
    width: "100%",
  },
  datePicker: {
    "flex-direction": "row-reverse",
    fontSize: "1.4rem !important",
    "& fieldSet": {
      border: "none",
    },
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "& input": {
      width: "100%",
      fontSize: "1.4rem !important",
      fontSize: "1.4rem !important",
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
  },
  calender: {
    "& div": {
      fontSize: "1.4rem !important",
    },
    "& span": {
      fontSize: "1.4rem !important",
    },
    "& button": {
      fontSize: "1.4rem !important",
    },
  },
  deleteIcon: {
    fontSize: "2.5rem",
    color: "#7F56D9"
  },
  switchButton: {
    color: "#7F56D9",
    "& .Mui-checked > .MuiSwitch-thumb": {
      color: "#7F56D9",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#D6C8F4",
    }
  }
}));

const fieldTypeList = ["Single Line Text", "Dropdown", "Link", "Number", "Email", "Phone No.", "Multiple choice", "Checkboxes", "Upload Image", "Date", "Upload PDF"];
const ExamForm = forwardRef(({ helperText, examForm, setExamForm, setHelperText, visibleSaveButton = false, examDocID = false }, ref) => {
  const classes = useStyles();
  const loggedInUser = useSelector((state) => state.Auth.user);

  const [showPreview, setShowPreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const arr = Array.from(examForm.formFields);
    const [removed] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, removed);
    setExamForm((preValue) => {
      preValue.formFields = arr;
      console.log("preValue: ", preValue);
      return { ...preValue };
    });
  }
  function selectExamField(type, index) {
    setExamForm((preValue) => {
      preValue.formFields[index].fieldType = type;
      if (!preValue.formFields[index].options) {
        console.log("fieldType => ", preValue.formFields[index].fieldType);
        console.log("options: ", preValue.formFields[index].options);
        preValue.formFields[index].options = ["Option 1"]
      }

      return { ...preValue };
    });
  }

  function checkField() {
    let _formFieldArray = examForm.formFields.map(m => m.fieldName) || [];
    let isFieldNameDuplicateFlag = false;
    let isFieldNameEmptyFlag = false;
    let globalFlag = true;
    _formFieldArray.forEach(element => {
      if (element === "") {
        isFieldNameEmptyFlag = true;
      }
      if (_formFieldArray.filter(f => f.toLowerCase() === element.toLowerCase()).length > 1) {
        isFieldNameDuplicateFlag = true;
      }
    });

    const getOptionsForm = (val) => {
      return ["Dropdown", "Multiple choice", "Checkboxes"].some(s => s === val)
    }
    let _examOptionsForms = examForm.formFields.filter(m => getOptionsForm(m.fieldType));
    let isOptionsDuplicateFlag = false;
    let isOptionEmptyFlag = false;
    _examOptionsForms.forEach(element => {
      element.options.forEach(_ele => {
        if (_ele === "") {
          isOptionEmptyFlag = true;
        }
        if (element.options.filter(f => f.toLowerCase() === _ele.toLowerCase()).length > 1) {
          isOptionsDuplicateFlag = true;
        }
      });
    });
    globalFlag = isFieldNameDuplicateFlag || isFieldNameEmptyFlag || isOptionsDuplicateFlag || isOptionEmptyFlag;

    let _return = { isFieldNameDuplicateFlag, isFieldNameEmptyFlag, isOptionsDuplicateFlag, isOptionEmptyFlag, globalFlag }
    console.log(_return);
    return _return;
  }

  useImperativeHandle(ref, () => ({

    checkField

  }));


  function SaveDetails() {
    var flag = checkField().globalFlag;
    console.log(!flag, examDocID);
    if (!flag && examDocID) {
      setIsLoading(true);
      updateExamDetails(examForm, examDocID).then(res => {
        console.log(res);
        successToast("Exam Details updated Successfully");
        setIsLoading(false);
      }).catch(err=>{
        console.log(err);
        errorToast("Something went wrong! Try again");
        setIsLoading(false);
      })
    }
  }

  return (
    <div className={Styles.secondForm}>
      <div className={Styles.form}>
        <div className={Styles.formTitle}>
          <p>Enter exam form details</p>
        </div>
        <div className={Styles.inputRow}>
          <DragDropContext onDragEnd={onDragEnd.bind(this)}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {examForm.formFields.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={Styles.formCard}>
                            <div className={Styles.cardWrapperDrag}>
                              <img
                                // className={Styles.draggableIcon}
                                src={dragInputIcon}
                                alt=""
                              />
                            </div>
                            <div className={Styles.inputsWrapper}>
                              <div className={Styles.cardInput}>
                                <label>Field Label<span style={{ color: "red" }}> *</span></label>
                                <TextField
                                  InputProps={{ className: classes.input }}
                                  FormHelperTextProps={{
                                    classes: {
                                      root: classes.helperText,
                                    },
                                  }}
                                  value={examForm.formFields[index].fieldName}
                                  disabled={!examForm.formFields[index].isEditable}
                                  onChange={(newValue) => {
                                    setExamForm((preValue) => {
                                      preValue.formFields[index].fieldName =
                                        newValue.target.value;
                                      preValue.formFields[index].inputHelperText = '';
                                      return { ...preValue };
                                    });
                                  }}
                                  className={classes.textField}
                                  error={
                                    examForm.formFields[index].fieldName === "" ? true : examForm.formFields.filter((s, i) => i <= index && s.fieldName.toLowerCase() === examForm.formFields[index].fieldName.toLowerCase()).length > 1 ?
                                      true : false
                                  }
                                  id="outlined-basic"
                                  sx={[
                                    {
                                      "&>div:first-of-type": {
                                        border:
                                          examForm.formFields[index].fieldName === "" ? "1px solid red !important" : examForm.formFields.filter((s, i) => i <= index && s.fieldName.toLowerCase() === examForm.formFields[index].fieldName.toLowerCase()).length > 1 ?
                                            "1px solid red !important" : "1px solid #D0D5DD !important"
                                      },
                                      width: "100%",
                                    },
                                  ]}
                                  helperText={
                                    examForm.formFields[index].fieldName === "" ? "Please Enter Field Label." : examForm.formFields.filter((s, i) => i <= index && s.fieldName.toLowerCase() === examForm.formFields[index].fieldName.toLowerCase()).length > 1 ?
                                      examForm.formFields[index].fieldName + " is already exist." : ""
                                  }
                                  variant="outlined"
                                />
                              </div>
                              <div className={Styles.cardInput}>
                                <label>Field Type<span style={{ color: "red" }}> *</span></label>
                                <div className={Styles.flexDiv}>
                                  <Dropdown
                                    errorField={helperText.isTypeFilled}
                                    list={fieldTypeList}
                                    selectedValue={
                                      examForm.formFields[index].fieldType
                                    }
                                    disable={!examForm.formFields[index].isEditable}
                                    onChangeSelect={(value) =>
                                      selectExamField(value, index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {(examForm.formFields[index].fieldType === "Dropdown" || examForm.formFields[index].fieldType === "Multiple choice" || examForm.formFields[index].fieldType === "Checkboxes") &&
                              <div className={Styles.optionsWrapper}>
                                {examForm.formFields[index]?.options &&
                                  examForm.formFields[index]?.options.map((option, optionIndex) =>
                                    <div className={Styles.optionWrapper} key={"options_" + index + "_" + optionIndex}>
                                      <div>
                                        {examForm.formFields[index].fieldType === "Dropdown" && <div style={{ fontSize: "1.4rem" }}>{optionIndex + 1} .</div>}
                                        {examForm.formFields[index].fieldType === "Multiple choice" && <RadioButtonUncheckedIcon style={{ fontSize: "2rem" }} />}
                                        {examForm.formFields[index].fieldType === "Checkboxes" && <CheckBoxOutlineBlankIcon style={{ fontSize: "2rem" }} />}
                                      </div>
                                      <div>
                                        <TextField
                                          InputProps={{ className: classes.input }}
                                          FormHelperTextProps={{
                                            classes: {
                                              root: classes.helperText,
                                            },
                                          }}
                                          disabled={!examForm.formFields[index].isEditable}
                                          value={examForm.formFields[index].options[optionIndex]}
                                          onChange={(newValue) => {
                                            setExamForm((preValue) => {
                                              preValue.formFields[index].options[optionIndex] = newValue.target.value;
                                              return { ...preValue };
                                            });
                                          }}
                                          className={classes.textField}
                                          error={
                                            examForm.formFields[index].options[optionIndex] === "" ? true : examForm.formFields[index].options.filter((s, i) => i <= optionIndex && s.toLowerCase() === examForm.formFields[index].options[optionIndex].toLowerCase()).length > 1 ?
                                              true : false
                                          }
                                          id="outlined-basic"
                                          sx={[
                                            {
                                              "&>div:first-of-type": {
                                                marginTop: "0",
                                                border:
                                                  examForm.formFields[index].options[optionIndex] === "" ? "1px solid red !important" : examForm.formFields[index].options.filter((s, i) => i <= optionIndex && s.toLowerCase() === examForm.formFields[index].options[optionIndex].toLowerCase()).length > 1 ?
                                                    "1px solid red !important" : "1px solid #D0D5DD !important"
                                              },
                                              width: "100%",
                                            },
                                          ]}
                                          helperText={
                                            examForm.formFields[index].options[optionIndex] === "" ? "Please Enter Option." : examForm.formFields[index].options.filter((s, i) => i <= optionIndex && s.toLowerCase() === examForm.formFields[index].options[optionIndex].toLowerCase()).length > 1 ?
                                              examForm.formFields[index].options[optionIndex] + " is already exist." : false
                                          }
                                          variant="outlined"
                                        />
                                      </div>
                                    </div>
                                  )
                                }
                                {examForm.formFields[index].isEditable &&
                                  <div className={Styles.optionWrapper}>
                                    {/* <div><RadioButtonUncheckedIcon style={{fontSize:"2rem"}}/></div> */}
                                    <div style={{ fontSize: "1.5rem" }}>
                                      <button
                                        onClick={() => {
                                          let _arr = examForm.formFields[index]?.options;
                                          _arr.push(`Option ${_arr.length + 1}`);
                                          console.log(_arr);
                                          setExamForm((preValue) => {
                                            preValue.formFields[index].options = _arr;
                                            return Object.assign({}, preValue);
                                          });
                                        }}
                                        className={Styles.addBtn}
                                      >
                                        + Add One Option
                                      </button>
                                    </div>
                                  </div>
                                }
                              </div>
                            }
                            {examForm.formFields[index].isEditable &&
                              <div className={Styles.cardFooter}>
                                <div className={Styles.requiredWrapper}>
                                  <div>Required</div>
                                  <div>
                                    <Switch
                                      checked={examForm.formFields[index].isRequired}
                                      className={classes.switchButton}
                                      onChange={(_value) => {
                                        setExamForm((preValue) => {
                                          preValue.formFields[index].isRequired = _value.target.checked;
                                          return { ...preValue };
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      size="large"
                                      edge="start"
                                      aria-label="open drawer"
                                      onClick={() => {
                                        console.log({ index });
                                        setExamForm((preValue) => {
                                          preValue.formFields.splice(index, 1);
                                          return { ...preValue };
                                        });
                                      }}
                                    >
                                      <DeleteOutlineOutlinedIcon className={classes.deleteIcon} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                            }
                          </div>

                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={Styles.addInputRowBtn}>
            <button
              onClick={() => {
                let _arr = examForm.formFields;
                _arr.push({
                  fieldType: "Single Line Text",
                  fieldName: "",
                  isRequired: false,
                  isEditable: true,
                  id: (Math.max.apply(Math, _arr.map(function (o) { return o.id; })) + 1).toString()
                });
                setExamForm((preValue) => {
                  preValue.formFields = _arr;
                  return { ...preValue };
                });
              }}
              className={Styles.addBtn}
            >
              + Add Another Input Field
            </button>
          </div>
          <div>
            <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Preview Form"} clicked={() => { setShowPreview(true); }} />
          </div>
        </div>
        {visibleSaveButton &&
          <Box display={"flex"} justifyContent={"flex-end"}>
            <div style={{ marginTop: "1.5rem" }}>
              <Button 
                style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} 
                btnName={"Save"} clicked={SaveDetails} 
                disable={!loggedInUser.tabAccess.exams.some(s=> s==='edit') || isLoading}
                isLoading={isLoading}
                loadingColor={"#FFFFFF"}
              />
            </div>
          </Box>
        }
      </div>
      {showPreview &&
        < PreviewExamFormModal open={showPreview} handleClose={() => { setShowPreview(false); }} Form={examForm.formFields} />
      }
    </div>
  );
})

export default ExamForm;
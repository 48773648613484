/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Styles from "./HallTicketPDF.module.css";

import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import html2pdf from "html2pdf.js";

//* imports services
import { presentLoader, dismissLoader } from "../../services/loaderService";

import { getStudentByDocId } from "../../services/StudentService"

//* images
import man from "../../assets/images/man.png";
import woman from "../../assets/images/woman.png";
import user_icon from "../../assets/images/user_icon.png"
import { errorToast } from "../../services/Toast";
import logo from "./logo (1).png"

export default function HallTicketPDF({ hallTicket, examDocID, setObject }) {
  
  const [qr_Code, setQr_Code] = useState("");
  const [bar_Code, setBar_Code] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null)

  useEffect(async () => {
    presentLoader();
    try {
      console.log(hallTicket?.studentPhoto);
      let barCode = generateApplicantBarCode(hallTicket?.hallTicketNumber ? hallTicket?.hallTicketNumber : "");
      let qrCode = await generateQrCode(hallTicket?.hallTicketNumber ? hallTicket?.hallTicketNumber : "")
      getBase64FromImageUrl("https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url="+process.env.REACT_APP_bucketAccessRootPath+hallTicket?.studentPhoto);
      setBar_Code(barCode);
      setQr_Code(qrCode);
    } catch (error) {
      errorToast("Something went wrong! Try again")
      dismissLoader();  
    }
  }, [hallTicket]);

  useEffect(() => {
    if (qr_Code !== "" && bar_Code !== "" && profilePhoto) {
      var id = "demoPDF";
      const element = document.getElementById(id);
      const opt = {
        margin: [0.2, 0.2],
        filename: `${hallTicket["Full Name"]} hall ticket.pdf`,
        html2canvas: {
          useCORS: false,
          allowTaint: true,
          scale: 2,
        },
        image: { type: "jpg", quality: 0.5 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: { avoid: ".avoid" },
      };
      presentLoader();
      html2pdf().from(element).set(opt).save().then((res) => {
        console.log(res);
        dismissLoader();
        setObject();
      }).catch((err) => {
        errorToast("Something went wrong! Try again")
        console.log(err);
        dismissLoader();
        setObject();
      });
    }
  }, [qr_Code,bar_Code,profilePhoto])
  

  function generateApplicantBarCode(appId) {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, appId.toString(), {
      lineColor: "#000000",
      width: 3,
      height: 70,
      background: "#ffffff",
      displayValue: false,
    });
    return canvas.toDataURL();
  }  
  function generateQrCode(appId) {
    return new Promise((resolve, reject) => {
      var opts = {
        margin: 1,
      };
      QRCode.toDataURL(appId.toString(), opts).then((url) => {
        resolve(url);
      }).catch((err) => {
        reject(err);
      });
    });
  }
  
  function getBase64FromImageUrl(url) {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      // console.log(dataURL);
      setProfilePhoto(dataURL);
    };
    img.onerror= function(e) {
      setProfilePhoto(user_icon);
    }
    img.src = url;
  }

  return (
    <>
      <div style={{ width: "100%" }} hidden>
      {/* <div style={{ width: "100%" }} > */}
        {/* <div id="demoPDF">
          <div className={Styles.examName}>
            <h1>{hallTicket?.examName} Exam HallTicket</h1>
          </div>
          <div className={Styles.hallTicketDetails}>
            <div className={Styles.info}>
              <table>
                <tbody className={Styles.infoTable}>
                  <tr>
                    <td>Student Name  </td>
                    <td>: {hallTicket["Full Name"] ? hallTicket["Full Name"] : ""} </td>
                  </tr>
                  <tr>
                    <td>Exam Date  </td>
                    <td>: {new Date(hallTicket?.examDate).toDateString()} </td>
                  </tr>
                  <tr>
                    <td>Exam Time  </td>
                    <td>: {new Date(hallTicket?.examTime).toLocaleTimeString() === "Invalid Date" ? hallTicket?.examTime : new Date(hallTicket?.examTime).toLocaleTimeString()} </td>
                  </tr>
                  <tr>
                    <td>HallTicket Number  </td>
                    <td>: {hallTicket?.hallTicketNumber} </td>
                  </tr>
                  <tr>
                    <td>Exam Center   </td>
                    <td>: {hallTicket?.examCenter.name} </td>
                  </tr>
                  <tr>
                    <td>State   </td>
                    <td>: {hallTicket?.examCenter.state} </td>
                  </tr>
                  <tr>
                    <td>City   </td>
                    <td>: {hallTicket?.examCenter.City} </td>
                  </tr>
                  <tr>
                    <td>Pincode   </td>
                    <td>: {hallTicket?.examCenter.pincode} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div 
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'flex-end',
                alignItems: 'flex-end'
              }}
            >
              <img src={bar_Code} alt="" style={{width: "250px", height: "60px"}}/>
              <img 
                src={profilePhoto} alt="" 
                onError={(e) => { e.target.onerror = null; e.target.src = user_icon}}
                style={{width: '150px', height: '170px', margin: "20px"}}
              />
              <img src={qr_Code} alt="" style={{width: "150px", height: "150px"}}/>
            </div>
          </div>
        </div> */}

        <div id="demoPDF">
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
              <img src={bar_Code} alt="" style={{width: "250px", height: "60px"}}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '2rem', marginTop: "20px"}}>
              <img style={{width: "15rem"}} src={logo} alt="" />
              <div>
                Exam Name
              </div>
              <img src={qr_Code} alt="" style={{width: "150px", height: "150px"}}/>
            </div>

            <div style={{marginTop: "20px"}} className={Styles.hallTicketDetails}>
              <div className={Styles.info}>
                <table>
                  <tbody className={Styles.infoTable}>
                    <tr>
                      <td>01</td>
                      <td>Student Name  </td>
                      <td>: {hallTicket["Full Name"] ? hallTicket["Full Name"] : ""} </td>
                    </tr>
                    <tr>
                      <td>02</td>
                      <td>Exam Date  </td>
                      <td>: {new Date(hallTicket?.examDate).toDateString()} </td>
                    </tr>
                    <tr>
                      <td>03</td>
                      <td>Exam Time  </td>
                      <td>: {new Date(hallTicket?.examTime).toLocaleTimeString() === "Invalid Date" ? hallTicket?.examTime : new Date(hallTicket?.examTime).toLocaleTimeString()} </td>
                    </tr>
                    <tr>
                      <td>04</td>
                      <td>HallTicket Number  </td>
                      <td>: {hallTicket?.hallTicketNumber} </td>
                    </tr>
                    <tr>
                      <td>05</td>
                      <td>Exam Center   </td>
                      <td>: {hallTicket?.examCenter.name} </td>
                    </tr>
                    <tr>
                      <td>06</td>
                      <td>State   </td>
                      <td>: {hallTicket?.examCenter.state} </td>
                    </tr>
                    <tr>
                      <td>07</td>
                      <td>City   </td>
                      <td>: {hallTicket?.examCenter.City} </td>
                    </tr>
                    <tr>
                      <td>08</td>
                      <td>Pincode   </td>
                      <td>: {hallTicket?.examCenter.pincode} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'flex-end',
                  alignItems: 'flex-end'
                }}
              >
                {/* <img src={bar_Code} alt="" style={{width: "250px", height: "60px"}}/> */}
                <img 
                  src={profilePhoto} alt="" 
                  onError={(e) => { e.target.onerror = null; e.target.src = user_icon}}
                  style={{width: '150px', height: '170px', margin: "20px"}}
                />
                {/* <img src={qr_Code} alt="" style={{width: "150px", height: "150px"}}/> */}
              </div>
            </div>

            <div style={{marginTop: "20px"}} className={Styles.secondTable}>
              <table border={0}>
                <tbody>
                  <tr>
                    <td>jh bvxlvhilhvxkj</td>
                    <td>jh bvxlvhilhvxkj</td>
                    <td>jh dsdal kjdfm l; mncv ;</td>
                  </tr>
                  <tr>
                    <td>
                      <p>14-oct-2022</p>
                      <p>07:00 AM</p>
                    </td>
                    <td>disjf kmno ic jjkncv;o jvnc;o vij vn;o js;vncxkn ;sjvjknp ijvnc. v nv; 0jkdm ;cjvo;mfsmclojk ;vmx;vmxocjkvxmk;xcvoi kmvcm v;jvvc. </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{marginTop: "20px"}} className={Styles.addressWrapper}>
              <div>
                <table>
                  <tbody>
                  <tr>
                      <td>ADDRESS</td>
                      <td style={{width: "250px"}}>ldish ijnvl jvcnvolcj n; vocjvnf vojc vnp;o jvncx; jvndij[cj vcxnvpcij vn</td>
                    </tr>
                    <tr>
                      <td>MOBILE NUMBER</td>
                      <td>99955555555</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{width: "300px"}}>
                <div style={{height: "50px"}}></div>
                <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quasi numquam maiores sunt et </div>
              </div>
            </div>

            <div style={{marginTop: "20px"}} className={Styles.rulesWrapper}>
              <p><u>Rules: </u></p>
              <table>
                <tbody>
                  <tr>
                    <td><p>1)</p></td>
                    <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, commodi repellat distinctio rem optio quisquam, quis explicabo ut id libero veritatis debitis quibusdam dolorem eos porro laboriosam, placeat ad minus!</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    </>
  );
}

import React from 'react';

//* import MUI
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

const useStyles = makeStyles((theme) => ({
  calender: {
    "& div": {
      fontSize: "1.4rem !important",
    },
    "& span": {
      fontSize: "1.4rem !important",
    },
    "& button": {
      fontSize: "1.4rem !important",
    },
  },
  datePicker: {
    "flex-direction": "row-reverse",
    fontSize: "1.4rem !important",
    "& fieldSet": {
      border: "none",
    },
    // marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "& input": {
      width: "100%",
      fontSize: "1.4rem !important",
      fontSize: "1.4rem !important",
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
  },
  helperText: {
    fontSize: "1rem !important",
    margin: "0.2rem 0 0 0 !important",
  },
}));

export default function DateComponent({ value, minDate, onChangeDate, helperText, isDisabled=false }) {

  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={isDisabled}
        value={value}
        minDate={minDate}
        PaperProps={{ className: classes.calender }}
        PopperProps={{ placement: "bottom-start" }}
        InputProps={{ className: classes.datePicker }}
        onChange={onChangeDate}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helperText}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
            sx={[
              {
                "&>div:first-of-type": {
                  border: helperText !== "" ? "1px solid red !important" : "1px solid #D0D5DD !important",
                },
                width: "100%",
              },
            ]}
          />
        )}
      />
    </LocalizationProvider>
  )
}

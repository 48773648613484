// import React, { useState } from "react";
// import Dialog from "@mui/material/Dialog";
// import cssClasses from "./Loading.module.css";
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// const dialogStyle = {
//   "& .MuiDialog-container":{
//     background: "rgba(193, 201, 210, 0.7)"
//   }
// }
// export default function Loading() {
//   return (
// 		<Dialog 
//       sx={[dialogStyle]} 
//       // onClose={() => {setOpen(false)}} 
//       open={true}
//     >
// 			<div className={cssClasses.wrapper}>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <CircularProgress />
//           <p style={{margin: '0',fontSize: '2rem', marginLeft:"1rem"}}>Loading...</p>
//         </Box>
// 			</div>
// 		</Dialog>
// 	);
// }


import React from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from "@mui/material/Modal";
import { useSelector } from 'react-redux';

export default function Loading() {
    const isLoading = useSelector((state) => state.IsLoading.isLoading);
    return (
        <Modal open={isLoading}
            sx={[
                {
                    ".MuiBackdrop-root":{
                        background: "rgba(193, 201, 210, 0.7)"
                    }
                }
            ]}
            disableAutoFocus={true}
        >
            {/* <Box
                sx={{
                    width: 200,
                    height: 60,
                    backgroundColor: 'white',
                    '&:hover': {
                        backgroundColor: 'white',
                    },
                }}
            > */}
            <CircularProgress style={{ color:"#7F56D9" ,verticalAlign: "middle",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)" }} />
            {/* <p style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "20px", fontSize: "20px" }}>Loading</p> */}
            {/* </Box> */}
        </Modal>
    )
}


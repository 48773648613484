/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import * as _ from 'lodash'

//* import MUI
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

//* import sub component
import Button from "../../Subcomponent/Button";
import Dropdown from "../../Subcomponent/Dropdown"

//* import Images
import shuffleIcon from "../../assets/images/shuffle.svg";
import crossImage from "../../assets/images/cross.svg";

//* import Css
import cssClasses from "./GenerateHallTicketModal.module.css"
import { getAllExamCentersInExam } from "../../services/ExamCenterService";
import { getAllStudentsByExam } from "../../services/StudentService";
import { errorToast, successToast } from "../../services/Toast";
import { addLogs, setHallTicket } from "../../services/HallTicketService";
import { DataObject } from "@mui/icons-material";
import { updateExamDetails } from "../../services/ExamService";
import { dismissLoader, presentLoader } from "../../services/loaderService";
import { useSelector } from "react-redux";

export default function GenerateHallTicketModal({ open, handleClose, subExams, examForm }) {
  const [ExamsList, setExamsList] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null)
  let allExamCenters = [];
  let allStudents = [];
  useEffect(() => {
    const _ExamsList = subExams.map(ele => ele.examName);
    setExamsList([..._ExamsList])
    setSelectedExam(_ExamsList[0])
  }, [subExams])

  const loggedInUser = useSelector((state) => state.Auth.user);


  function generate() {
    presentLoader()
    const exam = subExams.filter((e) => { return e.examName == selectedExam })[0]
    console.log('exam: ', exam);
    const centers = []
    const students = []
    getAllExamCentersInExam(examForm.docID).then((resp) => {
      resp.docs.forEach((ele) => {
        const center = ele.data()
        center.id = ele.id
        centers.push(center)
      })
      const totalCapacity = _.sumBy(centers, function (o) { return o.capacity; });
      console.log('totalCapacity: ', totalCapacity);
      console.log('centers: ', centers);
      // setAllExamCenters([...centers])
      allExamCenters = centers;
      getAllStudentsByExam(examForm.docID).then((resp) => {
        if (totalCapacity == 0) {
          dismissLoader()
          errorToast("Please add exam centers to continue")
        }
        else if (resp.docs.length == 0) {
          dismissLoader()
          errorToast("Please add students to continue")
        }
        else if (resp.docs.length <= totalCapacity) {
          resp.docs.forEach((ele) => {
            const student = ele.data()
            student.id = ele.id
            students.push(student)
          })
          // setAllStudents([...students])
          allStudents = students;
          console.log('students: ', students);
          generateHallTicket(exam)
        } else {
          dismissLoader()
          errorToast("Exam centers capacity is less than students")
        }
      }).catch((err) => {
        dismissLoader()
        console.log(err);
        errorToast("Something went wrong please try again later")
      })
    })
  }

  function generateHallTicket(exam) {
    if (exam.examDates.length == 1 && exam.examDates[0].slots.length == 1) {
      singleDaySingleSlot(exam)
    } else if (exam.examDates.length == 1 && exam.examDates[0].slots.length > 1) {
      singleDayMultipleSlots(exam)
    } else if (exam.examDates.length > 1) {
      multipleDays(exam)
    }
  }

  function singleDaySingleSlot(exam) {
    let rollNumber = 1
    for (let i = 0; i < allExamCenters.length; i++) {
      for (let j = 0; j < allExamCenters[i].capacity; j++) {
        let students = allStudents.filter((e) => { return (!e.isAllocated || e.isAllocated != true) && allExamCenters[i].City == e.City })
        let student = _.shuffle(students)[0]
        if (student) {
          let prefix = calculatePrefix(rollNumber)
          const obj = {
            examCenterId: allExamCenters[i].id,
            examCenter: {
              address: allExamCenters[i].address,
              pincode: allExamCenters[i].pincode,
              City: allExamCenters[i].City,
              state: allExamCenters[i].state,
              name: allExamCenters[i].examCenterName
            },
            examName: examForm.name,
            "Full Name": student["Full Name"] ? student["Full Name"] : student["Name"],
            studentDetails : student,
            studentFullNameForSearch: student["Full Name"].toLowerCase(),
            studentPhoto : student?.Photo ? student?.Photo : "",
            gender : student?.gender || student?.Gender,
            examDate: exam.examDates[0].date,
            examTime: exam.examDates[0].slots[0].time,
            hallTicketNumber: examForm.examCode + prefix + rollNumber
          }
          addHallTicket(student.id, obj).then((resp) => {
            student.isAllocated = true
            rollNumber++
            if (rollNumber == allStudents.length + 1) {
              updateExamDetails({ hallTicketStatus: "generated" }, examForm.docID).then((resp) => {
                addLogs(examForm.docID, { userId: loggedInUser.id, userName: loggedInUser.name, action: "generate" }).then((resp) => {
                  handleClose()
                  dismissLoader()
                  successToast("Hall ticket generated successfully")
                })
              })
            }
          }).catch((err) => {
            dismissLoader()
            console.log(err);
            errorToast("Something went wrong please try again later")
          })
        }
      }
    }
  }

  function singleDayMultipleSlots(exam) {
    let rollNumber = 1
    for (let i = 0; i < exam.examDates[0].slots.length; i++) {
      for (let j = 0; j < allExamCenters.length; j++) {
        for (let k = 0; k < allExamCenters[j].capacity; k++) {
          let students = allStudents.filter((e) => { return (!e.isAllocated || e.isAllocated != true) && allExamCenters[j].City == e.City })
          let student = _.shuffle(students)[0]
          if (student) {
            let prefix = calculatePrefix(rollNumber)
            const obj = {
              examCenterId: allExamCenters[j].id,
              examCenter: {
                address: allExamCenters[j].address,
                pincode: allExamCenters[j].pincode,
                City: allExamCenters[j].City,
                state: allExamCenters[j].state,
                name: allExamCenters[i].examCenterName
              },
              examName: examForm.name,
              "Full Name": student["Full Name"] ? student["Full Name"] : student["Name"],
              studentDetails : student,
              studentFullNameForSearch: student["Full Name"].toLowerCase(),
              studentPhoto : student?.Photo ? student?.Photo : "",
              gender : student?.gender || student?.Gender,
              examDate: exam.examDates[0].date,
              examTime: exam.examDates[0].slots[i].time,
              hallTicketNumber: examForm.examCode + prefix + rollNumber
            }
            addHallTicket(student.id, obj).then((resp) => {
              student.isAllocated = true
              rollNumber++
              if (rollNumber == allStudents.length + 1) {
                updateExamDetails({ hallTicketStatus: "generated" }, examForm.docID).then((resp) => {
                  addLogs(examForm.docID, { userId: loggedInUser.id, userName: loggedInUser.name, action: "generate" }).then((resp) => {
                    handleClose()
                    dismissLoader()
                    successToast("Hall ticket generated successfully")
                  })
                })
              }
            }).catch((err) => {
              dismissLoader()
              console.log(err);
              errorToast("Something went wrong please try again later")
            })
          }
        }
      }
    }
  }

  function multipleDays(exam) {
    let type = 'single'
    for (let i = 0; i < exam.examDates.length; i++) {
      if (exam.examDates[i].slots.length > 1) {
        type = 'multiple'
        break
      }
    }
    if (type == 'single') {
      multipleDaySingleSlot(exam)
    } else {
      multipleDayMultipleSlot(exam)
    }
  }

  function multipleDaySingleSlot(exam) {
    let rollNumber = 1
    for (let i = 0; i < exam.examDates.length; i++) {
      for (let j = 0; j < allExamCenters.length; j++) {
        for (let k = 0; k < allExamCenters[j].capacity; k++) {
          let students = allStudents.filter((e) => { return (!e.isAllocated || e.isAllocated != true) && allExamCenters[j].City == e.City })
          let student = _.shuffle(students)[0]
          if (student) {
            let prefix = calculatePrefix(rollNumber)
            const obj = {
              examCenterId: allExamCenters[j].id,
              examCenter: {
                address: allExamCenters[j].address,
                pincode: allExamCenters[j].pincode,
                City: allExamCenters[j].City,
                state: allExamCenters[j].state,
                name: allExamCenters[i].examCenterName
              },
              examName: examForm.name,
              "Full Name": student["Full Name"] ? student["Full Name"] : student["Name"],
              studentDetails : student,
              studentFullNameForSearch: student["Full Name"].toLowerCase(),
              studentPhoto : student?.Photo ? student?.Photo : "",
              gender : student?.gender || student?.Gender,
              examDate: exam.examDates[i].date,
              examTime: exam.examDates[i].slots[0].time,
              hallTicketNumber: examForm.examCode + prefix + rollNumber
            }
            addHallTicket(student.id, obj).then((resp) => {
              student.isAllocated = true
              rollNumber++
              if (rollNumber == allStudents.length + 1) {
                updateExamDetails({ hallTicketStatus: "generated" }, examForm.docID).then((resp) => {
                  addLogs(examForm.docID, { userId: loggedInUser.id, userName: loggedInUser.name, action: "generate" }).then((resp) => {
                    handleClose()
                    dismissLoader()
                    successToast("Hall ticket generated successfully")
                  })
                })
              }
            }).catch((err) => {
              dismissLoader()
              console.log(err);
              errorToast("Something went wrong please try again later")
            })
          }
        }
      }
    }
  }

  function multipleDayMultipleSlot(exam) {
    let rollNumber = 1
    for (let i = 0; i < exam.examDates.length; i++) {
      for (let j = 0; j < exam.examDates[i].slots.length; j++) {
        for (let k = 0; k < allExamCenters.length; k++) {
          for (let l = 0; l < allExamCenters[k].capacity; l++) {
            let students = allStudents.filter((e) => { return (!e.isAllocated || e.isAllocated != true) && allExamCenters[k].City == e.City })
            let student = _.shuffle(students)[0]
            if (student) {
              let prefix = calculatePrefix(rollNumber)
              const obj = {
                examCenterId: allExamCenters[k].id,
                examCenter: {
                  address: allExamCenters[k].address,
                  pincode: allExamCenters[k].pincode,
                  City: allExamCenters[k].City,
                  state: allExamCenters[k].state,
                  name: allExamCenters[i].examCenterName
                },
                examName: examForm.name,
                "Full Name": student["Full Name"] ? student["Full Name"] : student["Name"],
                studentDetails : student,
                studentFullNameForSearch: student["Full Name"].toLowerCase(),
                studentPhoto : student?.Photo ? student?.Photo : "",
                gender : student?.gender || student?.Gender,
                examDate: exam.examDates[i].date,
                examTime: exam.examDates[i].slots[j].time,
                hallTicketNumber: examForm.examCode + prefix + rollNumber
              }
              addHallTicket(student.id, obj).then((resp) => {
                student.isAllocated = true
                rollNumber++
                if (rollNumber == allStudents.length + 1) {
                  updateExamDetails({ hallTicketStatus: "generated" }, examForm.docID).then((resp) => {
                    addLogs(examForm.docID, { userId: loggedInUser.id, userName: loggedInUser.name, action: "generate" }).then((resp) => {
                      handleClose()
                      dismissLoader()
                      successToast("Hall ticket generated successfully")
                    })
                  })
                }
              }).catch((err) => {
                dismissLoader()
                console.log(err);
                errorToast("Something went wrong please try again later")
              })
            }
          }
        }
      }
    }
  }

  function calculatePrefix(rollNumber) {
    let prefix = ''
    for (let index = 0; index < (allStudents.length - rollNumber.toString().length); index++) {
      prefix = '0' + prefix
    }
    return prefix
  }

  async function addHallTicket(studentId, obj) {
    return await setHallTicket(examForm.docID, studentId, obj)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root": {
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>
        <div className={cssClasses.header}>
          {/* <img src={shuffleIcon} alt="" /> */}
          <h1>Generate hall Ticket</h1>
          <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>

        <div className={cssClasses.mainContainer}>
          <div>
            <p>Select exam </p>
            <Dropdown
              list={ExamsList.map(m => ({ label: m, value: m }))}
              selectedValue={selectedExam}
              onChangeSelect={(value) => { console.log(value); setSelectedExam(value) }}
            />
          </div>
        </div>

        <div className={cssClasses.footer}>
          {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
          <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Cancel"} clicked={handleClose} /></div>
          <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Generate"} clicked={generate} /></div>
        </div>
      </Box>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import cssModule from "./MeritStudentAddLoadingStepper.module.css";
import Box from "@mui/material/Box";

import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
// import socketIOClient from "socket.io-client";
import { adminAuthCreate } from '../../services/AdminsService';
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../../Subcomponent/Button";
import { getFailedArray, replace, setStudentInStudentsCollection } from "../../services/StudentService";
import FailedEntires from "../FailedEntries/FailedEntries";
import * as XLSX from "xlsx";
import Styles from "./FailedModel.module.css"
import { Timestamp } from "firebase/firestore";
import { errorToast } from "../../services/Toast";
import * as _ from 'lodash';  
import { getMeritStudentById, getStudentByEmailIDFromParentCollection, getStudentByIDEligibleCollection, setMeritStudent, setStudentInEligibleCollection, setStudentInHallTicketCollection, setStudentInMeritCollection } from "../../services/Merit";
import { getAllExamCentersByName } from "../../services/ExamCenterService";
import { setEligibleStudents } from "../../services/EligibleStudentsService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  outline: 0,
};

const useStyles = makeStyles((theme) => ({
  ButtonStyle: {
    width: "7rem",
    marginLeft: "auto",
    marginRight: "2rem",
    marginBottom: "1rem",
    marginTop: "1rem",
  },

  ProgressStyle: {
    backgroundColor: "#F9FAFB",
    padding: "2rem",
    border: "1px solid #EAECF0",
  },
  verticalLine: {
    borderLeft: "2px solid #D0D5DD",
    height: "30px",
    margin: "9px 0px 12px 13px ",
  },
  gridContainer: {
    display: "flex",
  },
  QuestionStyle: {
    margin: "1.2rem 1.8rem 1.5rem 3.5rem",
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.3rem",
    fontWeight: "800",
  },
  Title: {
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.6rem",
    color: "#344054",
    padding: "1.5rem 0rem 1.7rem 3rem",
    display: "flex",
    alignItems: "center",
  },
  ReplaceDiv: {
    padding: "1.5rem 0",
  },
  gridChild: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0.9rem",
  },
  loader: {
    marginLeft: "2rem",
  },
  TextStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.5rem",
    fontWeight: "800",
    lineHeight: "2.4rem",
    color: "#101828",
  },
  numberStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.4rem",
    fontWeight: "100",
    lineHeight: "2rem",
    color: "#667085",
  },
  iconStyle: {
    color: "#7F56D9",
    width: "4rem",
    height: "5rem",
  },
  tickIcon: {
    // marginLeft:"17px",
  },
  UploadIcons: {
    margin: "0.3rem 0.5rem",
  },

  failedEntriesText: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  },
  wrapperFailedEntries: {
    display: "flex",
    padding: "0rem 3.6rem 2rem 3.6rem",
    fontFamily: "Inter-Regular",
    fontSize: "1.3rem",
    fontWeight: "800",
    lineHeight: "2rem",
    marginTop: "1rem",
  },
  wrapperOpenModal: {
    marginLeft: "2rem",
  },
}));
const dataGridStyles = {
    fontWeight: "400",
    fontSize: "1.4rem",
    lineHeight: "2rem",
    color: "#667085",
    fontFamily:"Inter-Medium",
    border: "none !important",
    '& div[data-colindex="1"]': {
      color: "#101828",
      fontWeight: "500",
    },
    '& div[data-StepperValue-colindex="7"]': {
      color: "#fff",
      fontWeight: "500",
    },
    "&  .MuiDataGrid-columnHeaders": {
      background: "#F9FAFB",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader": {
      padding: "0 4rem",
  
      "&:focus": {
        outline: "none",
      },
    },
    "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
    },
    "& .MuiDataGrid-cell": {
      padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
      "&:focus": {
        outline: "none",
      },
    },
    "& .MuiButtonBase-root": {
      fontSize: "2rem",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
    "& .MuiDataGrid-footerContainer": {
      "& .MuiTablePagination-displayedRows ": {
        display: "none",
      },
      "& .MuiDataGrid-selectedRowCount": {
        visibility: "hidden ",
      },
    },
    "& .MuiPaginationRoot": {
      "& .MuiPagination-ul": {
        flexWrap: "nowrap",
        li: {
          "&:first-of-type": {
            flexBasis: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "> button::after": {
              marginLeft: "10px",
              content: "'previous'",
            },
          },
          "&:last-of-type": {
            flexBasis: "100%",
            display: "flex",
            justifyCcontent: "flex-end",
            alignItems: "center",
            "> button::before": {
              marginRight: "10px",
              content: "'next'",
            },
          },
        },
      },
    },
  };
export default function MeritStudentAddLoadingStepper({ handleClose, excelFile, examName, examDocID, subExamDocID }) {

  const classes = useStyles();

  const [addStudentModalToggle, setAddStudentModalToggle] = useState(false);

  const [StepperValue, setStepperValue] = useState(0);
  const [totalDataUploading, setTotalDataUploading] = useState(0);
  const [addingStudentCount, setAddingStudentCount] = useState(0);
  const [alreadyAddedStudentCount, setAlreadyAddedStudentCount] = useState(0);
  const [failedCandidatesArray, setFailedCandidatesArray] = useState([]);
  const [duplicateCandidatesArray, setDuplicateCandidatesArray] = useState([]);

  const [failedEntriesModal, setFailedEntriesModal] = useState(false);

  function openFailedEntriesModal() {
    setFailedEntriesModal(true);
  }

  function cleanupAndClose() {
    setAddStudentModalToggle(false);
    setStepperValue(0);
    setTotalDataUploading(0);
    setAddingStudentCount(0);
    setFailedCandidatesArray([]);
    setDuplicateCandidatesArray([]);
    handleClose();
  }

  // function closeModal() {
  //   setStepperValue(0);
  //   setTotalDataUploading(0);
  //   setAddingStudentCount(0);
  //   setFailedCandidatesArray([]);
  //   setDuplicateCandidatesArray([]);
  //   setFailedEntriesModal(false);
  // }

  useEffect(() => {
    if (excelFile) {
      readExcel(excelFile)
    }
  
    return () => {}
  }, [excelFile])
  

  function readExcel(file) {
    setAddStudentModalToggle(true)
    setStepperValue(0);

    const reader = new FileReader();
    reader.onload = function (e) {
      
      var data = e.target.result;
      data = new Uint8Array(data);
      var workbook = XLSX.read(data, {
        type: "array",
        cellText: false,
        cellDates: true,
      });
      var roa;
      workbook.SheetNames.forEach(function (sheetName) {
        roa = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName],
          { raw: false, dateNF: "yyyy-mm-dd" }
        );
      });
      setStepperValue(1);
      console.log(roa);
      setTotalDataUploading(roa?.length);
      const invalidDataArr = [];
      const validDataArr = [];

      roa.forEach(element => {
        if (element.hasOwnProperty("previousSubExamHistory")) {
          element.previousSubExamHistory = JSON.parse(element.previousSubExamHistory);
        }
      });

      for (let i = 0; i < roa.length; i++) {
        const element = roa[i];
        if (!element.Status || element.Status === "") {
          element.Status = "pending";
        }
        element.Applicant = element?.Applicant?.toString() ? element?.Applicant?.toString() : "" ;
        
        element.createdAt = Timestamp?.fromDate(new Date(element?.registrationDate)) ? Timestamp.fromDate(new Date(element.registrationDate)) : "";
        element.paymentMethod = "Offline"
        element.studentFullNameForSearch = element["Full Name"]?.toLowerCase() ? element["Full Name"].toLowerCase() : "";
        element.examDate = element?.examDate?.toString() ? element?.examDate?.toString() : "";
        element.examTime = element?.examTime?.toString() ? element?.examTime?.toString() : "";
        const validationResult = validateExcelObj(element);
        if (validationResult.validation.isValid) {
          validDataArr.push(element);
        } else {
          element.reason = validationResult.validation.reason;
          element.row = i + 2; // +1 for array index; +1 for row heading in excel
          // console.log('i: ', i);
          invalidDataArr.push(element);
        }
      }
      if (invalidDataArr.length > 0) {
        setStepperValue(2);
        setStepperValue(3);

        // cleanupAndClose();

        errorToast("Invalid data in excel sheet. Fix it and try again!");
        setFailedEntriesModal(true);
        setFailedCandidatesArray(invalidDataArr);
      } else {
        console.log("%c Zala", "color: #ff0000; font-size: 20px");
        setStepperValue(2);

        addStudentsInDataBase(validDataArr);
      }
    };
    reader.readAsArrayBuffer(file); 
    
  }
  function validateExcelObj(excelObj) {
    const validation = {
      isValid: true,
      reason: ''
    };

    if(!excelObj.hasOwnProperty("UID") || excelObj.UID.trim() === "" || !excelObj.UID) {
      validation.isValid = false;
      validation.reason = "UID data invalid.";
    }    
    if (excelObj["Full Name"] === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty Full Name";
    } 
    if (excelObj?.Applicant === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty applicant id";
    } 
    if (excelObj?.UID === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty UID";
    } 
    if (excelObj?.examName === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam name";
    } 
    if (excelObj?.examName !== examName) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Exam name not match";
    } 
    if (excelObj?.examCenterName === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam center name";
    } 
    if (excelObj?.Gender === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty gender";
    } 
    if (excelObj?.City === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty city";
    } 
    if (excelObj?.Gender?.toLowerCase() !== "male" && excelObj?.Gender?.toLowerCase() !== "female" && excelObj?.Gender?.toLowerCase() !== "transgender") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid gender";
    } 
    if (excelObj?.Disability?.toLowerCase() !== "yes" && excelObj?.Disability?.toLowerCase() !== "no") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid disability";
    } 
    if (excelObj?.examDate === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam date";
    } 
    if (excelObj?.examTime === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam time";
    }
  
    if (!excelObj["Full Name"]) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing Full Name";
    } 
    if (!excelObj?.Applicant) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing Applicant id";
    } 
    if (!excelObj?.examName) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing exam name";
    } 
    if (!excelObj?.examCenterName) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing exam center name";
    } 
    if (!excelObj?.Gender) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing gender";
    } 
    if (!excelObj?.City) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing city";
    } 
    if (excelObj?.examDate === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam date";
    } 
    if (excelObj?.examTime === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam time";
    } 
    if (excelObj?.Disability === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty disability";
    }
    if (excelObj?.registrationDate === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty disability";
    }
    if (new Date(excelObj?.registrationDate).toString().includes("Invalid Date")) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid Registration Date : formate(MM/DD/YYYY)";
    }
    validation.reason = validation.reason.split(",").filter(f=>f!=="").join(",");
    
    return { validation, excelObj };
  }

  async function addStudentsInDataBase(studentArray) {

    let _examCenters = _.uniqBy(studentArray, 'examCenterName').map(m=>m.examCenterName);

    getAllExamCentersByNameFromServer(_examCenters).then(examCenterRes=>{
      console.log("examCenterRes",examCenterRes);
      
      let _studentChunkArray = createChunkArray([...studentArray]);
      createBatchAddStudent(_studentChunkArray, examCenterRes).then(response => {
        console.log("response",response);
        let _alreadyAddedCount = response.filter(f=> f?.success && f?.isAlready)?.length;
        let failedAddedCount = response.filter(f=> !f?.success)?.length;
        setAlreadyAddedStudentCount(_alreadyAddedCount);
        if(failedAddedCount > 0) {
          setFailedEntriesModal(true);
          setFailedCandidatesArray(response.filter(f=> !f?.success)?.map(m => m.studentObj));
        }
        setStepperValue(3);
      });

    }).catch(err=>{
      console.log(err);
      if (err.status === false && err.message !== "") {
        errorToast(err.message);
        cleanupAndClose();
      }
    })   
  }

  async function getAllExamCentersByNameFromServer(_arr) {
    let resultArr = [];
    return await new Promise(async (resolve,reject) => {
      for (let item of _arr) {     
        let reap = await getAllExamCentersByName(item);
        if (!reap.empty) {
          resultArr.push({...reap.docs[0].data(), id: reap.docs[0].id});
        } else {
          reject({status: false, message: item+" Exam Center not found in database."});
        }
      }
      resolve(resultArr);
    })
  }

  async function createBatchAddStudent(array, examCenters) {
    let resultArr = [];
    return await new Promise(async (resolve,reject) => {
      for (let item of array) {     
        let reap = await Promise.all(item.map(m=>addOneStudent(m, examCenters)));
        resultArr =[...resultArr,...reap];
      }
      resolve(resultArr);
    })
  }

  function addOneStudent(obj, examCenters) {
    let studentDocID = obj.UID;
    let postBody = {...obj}
    postBody.isWildCardEntry = true;
    return new Promise((resolve, reject) => {
      getMeritStudentById(examDocID, subExamDocID, studentDocID).then(meritRes => {
        if (!meritRes.exists()) {
          setMeritStudent(examDocID, subExamDocID, studentDocID, postBody).then((res) => {
            setAddingStudentCount(pre=> {
              return pre+1;
            });
            resolve({success: true});
          }).catch((err) => {
            resolve({success: false, message: err.toString(), studentObj: {...obj, reason: err.toString()}});
          })
        } else {
          resolve({success: true, isAlready: true});
        }
      });
    })
  }

  function addOneStudent1(obj, examCenters) {
    let selectedExamCenter = examCenters.find(f=>f.examCenterName === obj.examCenterName)
    return new Promise((resolve, reject) => {

      

      adminAuthCreate(obj.email, obj["Full Name"].split(' ')[0] + '123456').then((userRes) => {
        console.log("userRes.user.uid",userRes.user.uid);
        let _uid = userRes.user.uid;

        let _studentObj = {
          Address : "",
          City : obj?.City,
          "Full Name" : obj["Full Name"],
          Pincode : "",
          State : "",
          aadharCard : "",
          aadharCardBackPhoto : "",
          aadharCardFrontPhoto : "",
          email : obj.email,
          gender : obj.Gender,
          name : obj["Full Name"],
          phone : "9900990099",
          profilePicture : "",
          studentFullNameForSearch : "megharaja c",
        }

        Promise.all([setEligibleStudents(examDocID, _uid, obj), setMeritStudent(examDocID, subExamDocID, _uid, obj), setStudentInStudentsCollection(_uid, _studentObj) ]).then(setRes=> {
          resolve({success: true, increment: true});
        }).catch(err=>{
          resolve({success: false, message: err.toString()});
        })
      }).catch(err=>{
        console.log("auth err => ",err);
        console.log("auth err => ",err?.code);
        if (err?.code === "auth/email-already-in-use") {
          getStudentByEmailIDFromParentCollection(obj.email).then(parentRes=>{
            if(!parentRes.empty) {
              let userUID = parentRes.docs[0].id;
              // getStudentByIDEligibleCollection(userUID, examDocID).then(meritRes => {
              //   if (meritRes.exists()) {
              //     //! check applicant id 
              //     //TODO addStudentIn_Eligible_HallTicket_Merit(userUID)
                  
              //   } else {
              //     addStudentIn_Eligible_HallTicket_Merit(userUID);
              //   }
              // })
            }
          })
        } else {
          resolve({success: false, message: err?.code});
        }
      })

      function addStudentIn_Eligible_HallTicket_Merit(userUID) {
        let postBody = {
          ...obj,
          studentFullNameForSearch: obj["Full Name"].toLowerCase(),
        };
        let postBodyForHallTicket = {
          "Full Name" : obj["Full Name"],
          examCenter: {
            City: selectedExamCenter.City,
            address: selectedExamCenter.address,
            pincode: selectedExamCenter.pincode,
            state: selectedExamCenter.state,
          },
          examCenterId : "",
          examDate: obj.examDate,
          examName: obj.examName,
          examTime: obj.examTime,
          gender: obj?.gender ? obj?.gender : obj?.Gender,
          studentFullNameForSearch: obj["Full Name"].toLowerCase(),
          //!!!! hallTicketNumber ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        }
        Promise.all([setStudentInEligibleCollection(userUID, examDocID, postBody), setStudentInHallTicketCollection(userUID, examDocID, postBodyForHallTicket), setStudentInMeritCollection(userUID, examDocID, subExamDocID, postBody)]).then(res=>{
          setAddingStudentCount(pre=> {
            return pre+1;
          })
        })
      }
    })
  }
  
  return (
    <>
      <Modal open={addStudentModalToggle} aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className={classes.Title}>
            {" "}
            <div className={classes.UploadIcons}></div> Reading Excel
          </div>

          <div className={classes.ProgressStyle}>
            <div
              className={classes.gridContainer}
              style={{ opacity: StepperValue >= 0 ? 1 : 0.7 }}
            >
              {StepperValue > 0 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>Reading Excel</span>
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: StepperValue >= 1 ? 1 : 0.7 }}
            >
              {StepperValue > 1 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <p className={classes.TextStyle}>Validating Data in Excel</p>
                {/* <p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>Progress {uploadingImagesProgress} %</p> */}
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: StepperValue >= 2 ? 1 : 0.7 }}
            >
              {StepperValue > 2 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}
              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>
                  {" "}
                  Updating Data to Database
                </span>
                <div className={classes.numberStyle}>
                  {/* { filesUploading?  StepperValue : "Error: Something went wrong!"}  */}
                  Total Entries: {totalDataUploading} 
                  <br />
                  Adding Entries:{" "}{addingStudentCount} 
                  <div>Failed Entries: {failedCandidatesArray.length}</div>
                  <div>Already added Entries: {alreadyAddedStudentCount}</div>
                </div>
              </div>
            </div>
          </div>
          {/* { Duplicate Entries ?  */}
          {StepperValue >= 3 &&
            <>
              {
                failedCandidatesArray.length > 0 &&
                <div className={classes.wrapperFailedEntries}>
                  <div className={classes.failedEntriesText}>View Failed Entries
                    <div className={classes.wrapperOpenModal}>
                      <Button
                        style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                        btnName={"View"}
                        btnIcon={false}
                        clicked={openFailedEntriesModal}
                      />
                    </div>
                    <div className={classes.ButtonStyle} style={{marginLeft: "1rem"}}>
                        <Button
                            style={{ color: "#6941c6", backgroundColor: "#ffffff" }}
                            btnName={"Close"}
                            btnIcon={false}
                            clicked={() => {cleanupAndClose()}}
                        />
                    </div>
                  </div></div>

              }
              {
                (duplicateCandidatesArray.length === 0 && failedCandidatesArray.length > 0) &&
                <div className={classes.ButtonStyle}>
                  <Button
                    style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                    btnName={"OK"}
                    btnIcon={false}
                    clicked={cleanupAndClose}
                  />
                </div>
              }
              {
                (duplicateCandidatesArray.length === 0 && failedCandidatesArray.length === 0) &&
                <div className={classes.ButtonStyle}>
                  <Button
                    style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                    btnName={"OK"}
                    btnIcon={false}
                    clicked={cleanupAndClose}
                  />
                </div>
              }
            </>
          }
          {/* <FieldMeritEntries openModal={failedEntriesModal} closeModal={() => { setFailedEntriesModal(false) }} failedCandidatesArray={failedCandidatesArray} /> */}
          <FailedEntires openModal={failedEntriesModal} closeModal={() => { setFailedEntriesModal(false); }} failedCandidatesArray={failedCandidatesArray} />
        </Box>
      </Modal>

    </>
  );
}


function createChunkArray(arr) {
  return arr.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/process.env.REACT_APP_CHUNK_FOR_PHOTO_UPLOAD)
  
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
  
    resultArray[chunkIndex].push(item)
  
    return resultArray
  }, [])
}
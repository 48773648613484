// * for Auth
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//* for loading
export const IS_LOADING = 'IS_LOADING';

//* for sidebar
export const ACTIVE_MENU = 'ACTIVE_MENU';

import * as actionTypes from "../actionType/action-type";

const initialState = {
  isAuthenticated: false,
  user: {},
};

const Auth = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.AUTH_LOGIN:
      return authLogin(state, payload);
    case actionTypes.AUTH_CHECK:
      return checkAuth(state);
    case actionTypes.AUTH_LOGOUT:
      return logout(state);
    default:
      return state;
  }
};

const authLogin = (state, payload) => {
  // localStorage.setItem("user", JSON.stringify(payload));
  sessionStorage.setItem("user", JSON.stringify(payload));
  let newState = {
    isAuthenticated: true,
    user: payload,
  };
  state = Object.assign({}, state, newState);
  return state;
};

const logout = (state) => {
  // localStorage.removeItem("user");
  sessionStorage.removeItem("user");
  state = Object.assign({}, state, {
    isAuthenticated: false,
    user: null,
  });
  return state;
};

const checkAuth = (state) => {
  // const _user = JSON.parse(localStorage.getItem("user"));
  const _user = JSON.parse(sessionStorage.getItem("user"));
  if (_user && _user !== "") {
    state = Object.assign({}, state, {
      isAuthenticated: true,
      user: _user,
    });
  } else {
    state = Object.assign({}, state, {
      isAuthenticated: !!state.isAuthenticated,
    });
  }
  return state;
};

export default Auth;

import { query, orderBy, limit, getFirestore, collection, getDocs, startAfter, where, addDoc, endBefore, limitToLast, deleteDoc, doc, serverTimestamp, updateDoc, setDoc } from "firebase/firestore";

async function AddDocExamCenter(postData) {
    postData.createdAt = serverTimestamp();
    postData.lastUpdateAt = serverTimestamp();
    return await addDoc(collection(getFirestore(), "ExamCenters"), postData);
}

async function updateExamCenter(docID, postBody) {
    postBody.lastUpdateAt = serverTimestamp();
    const docRef = doc(getFirestore(), 'ExamCenters', docID);
    return await updateDoc(docRef, postBody);
}

async function getExamCenters(searchField = "examCenterNameForSearch", lastDoc, examCenterSearchValue, examDocID) {
    // return await getDocs(collection(getFirestore(), "ExamCenters"));
    if(searchField === "examCenterNameForSearch" && examCenterSearchValue) {
        examCenterSearchValue = examCenterSearchValue.toLowerCase();
    }
    console.log({ searchField, lastDoc, examCenterSearchValue, examDocID });
    let students = collection(getFirestore(), 'ExamCenters');
    if (examDocID) {
        students = collection(getFirestore(), `exams/${examDocID}/ExamCenters`);
    }

    //* for first time visit and without search
    let _query = query(students, orderBy(searchField, "asc"), limit(20));

    if (lastDoc && !examCenterSearchValue) {
        //* for next docs without Search
        _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), limit(11))
    }
    if (examCenterSearchValue && !lastDoc) {
        //* for search first time without last
        _query = query(students, orderBy(searchField, "asc"), where(searchField, ">=", examCenterSearchValue), where(searchField, "<=", examCenterSearchValue + "\uf8ff"), limit(20));
    }
    if (lastDoc && examCenterSearchValue) {
        //* for search time with last doc
        _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), where(searchField, ">=", examCenterSearchValue), where(searchField, "<=", examCenterSearchValue + "\uf8ff"), limit(20));
    }

    let getStudents = await getDocs(_query);
    
    return getStudents;
}

async function getAllExamCentersInExam(id) {
    const exam = collection(getFirestore(), 'exams/' + id + '/ExamCenters');
    return await getDocs(exam)
}

async function getAllExamCenters() {
    let examCenters = collection(getFirestore(), `ExamCenters`);
    let _query = query(examCenters, orderBy("examCenterName", "asc"));
    let examCentersList = await getDocs(_query);
    return examCentersList;
}

async function setExamCenterInExam(docID,examDocID,postData) {
    console.log({docID,examDocID,postData});
    let examCenters = doc(getFirestore(), `exams/${examDocID}/ExamCenters`,docID);
    return await setDoc(examCenters,postData);
}

async function getAllExamCentersByCode(examCenterCodeValue) {
    let examCenters = collection(getFirestore(), `ExamCenters`);
    return await  getDocs(query(examCenters, where('examCenterCode', "==", examCenterCodeValue), limit(1)));
}
async function getAllExamCentersByName(examCenterCodeValue) {
    let examCenters = collection(getFirestore(), `ExamCenters`);
    return await  getDocs(query(examCenters, where('examCenterName', "==", examCenterCodeValue), limit(1)));
}


export { AddDocExamCenter, updateExamCenter, getExamCenters, getAllExamCentersInExam, getAllExamCenters, setExamCenterInExam, getAllExamCentersByCode, getAllExamCentersByName };
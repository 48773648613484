import * as actionTypes from "../actionType/action-type";

const initialState = { isLoading: false }

const IS_LOADING = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.IS_LOADING:
            return isLoading(state, payload);
        default:
            return state;
    }
};

const isLoading = (state, payload) => {
    let newState = {
        isLoading: payload,
    };
    state = Object.assign({}, state, newState);
    return state;
};

export default IS_LOADING;

import React,{ useState, useEffect } from "react";

//* images
import addIcon from "../../assets/images/add icon.svg";
import filterIcon from "../../assets/images/filter-icon.svg";
import crossArrow from "../../assets/images/cross arrow.svg";

//* import sub-component
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";

//* Import css
import cssClasses from "./ModelExamCenterStatus.module.css";

//* modals
import AddExamCenter from "../../Modals/AddExamCenter/AddExamCenter";
import AddExamCentersInExam from "../../Modals/AddExamCentersInExam/AddExamCentersInExam"
import ShiftStudentsModal from "../../Modals/ShiftStudents/ShiftStudentsModal";

//* import MUI
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid } from '@mui/x-data-grid';

//* import services
import { getExamCenters } from "../../services/ExamCenterService";
import { errorToast } from "../../services/Toast";

import { useSelector } from "react-redux";
import { Chip } from "@mui/material";

const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected":{
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',
  
  '& div[data-colindex="1"]' :{
    color : "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders':{
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator':{
    display :"none"
  },
  "& .MuiDataGrid-checkboxInput":{
    "& svg":{
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows":{
    display: "none"
  },
  "& [title='Go to previous page']:after":{
    content: "'Previous'",
    marginLeft: "1rem",
    paddingRight: '1rem'
  },
  "& [title='Go to next page']:before":{
    content: "'Next'",
    marginRight: "1rem",
    paddingRight: '1rem'
  },
  "& .MuiButtonBase-root":{
    borderRadius: '0'
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility:"hidden !important"
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "7.2rem !important",
    minHeight: "7.2rem !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",    
    minHeight: "7.2rem !important",
    maxHeight: "7.2rem !important",
    outline: 'none !important',
  },
  "& .MuiButtonBase-root": {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '0 1rem'
  },
  "& .MuiTablePagination-actions": {
    margin: '0 2rem',
  }
}
const chipStatusStyle = {
  fontSize: "1.5rem",
  textTransform: 'capitalize',
  // fontWeight : "600",
  padding: "0.8rem",
  height: "auto",
  "& .MuiChip-icon": {
    fontSize: '1rem',
  }
}
const blueChip = {
  // border: "1px solid #EEF4FF",
  color: "#175CD3",
  background: "#EFF8FF",
}
const greenChip = {
  // border: "1px solid #EEF4FF",
  color: "#027A48",
  background: "#ECFDF3",
}
const redChip = {
  // border: "1px solid #EEF4FF",
  color: "#B42318",
  background: "#FEF3F2",
}
export default function ModelExamCenterStatus({examDocID=null, examName=null, reflectToggle}) {

  const columns = [
    { field: 'examCenterName', headerName: 'Exam Center Name', sortable: false, width: 300 },
    { field: 'modelStatus', headerName: 'Model Status', sortable: false, width: 300 , 
    renderCell: (params) => {  
      return <>
        { !params?.row?.hasOwnProperty("modelStatus") ? 
          <Chip label={"Not Generated"} size={"medium"} sx={[{ ...chipStatusStyle, ...redChip }]} />
          :
            params.value === "processing" ? 
            <Chip label={"Processing"} size={"medium"} sx={[{ ...chipStatusStyle, ...blueChip }]} />
            :
              <Chip label={"Model Generated"} size={"medium"} sx={[{ ...chipStatusStyle, ...greenChip }]} />
        }
      </>
    }
    },
    { field: 'City', headerName: 'City', sortable: false, width: 150},
    // { field: 'examCenterCode', headerName: 'Exam Center Code', sortable: false, width: 300 },
   
  ]

  const [examCenterList, setExamCenterList] = useState([]);
  const [serverMetaDataList, setServerMetaDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageMaintain, setPageMaintain] = useState(0);
  const [examCenterSearchValue, setExamCenterSearchValue] = useState("");
  const [selectedExamCenterForEdit, setSelectedExamCenterForEdit] = useState(null);
  
  const [dataGridLoading, setDataGridLoading] = useState(true);
  const [searchField, setSearchField] = useState("examCenterNameForSearch");

  const [addExamCenterInExamModalToggle, setAddExamCenterInExamModalToggle] = useState(false);
  const [editExamCenterModalToggle, setEditExamCenterModalToggle] = useState(false);
  const [shiftStudentsModalToggle, setShiftStudentsModalToggle] = useState(false);

  const loggedInUser = useSelector((state) => state.Auth.user);

  useEffect(() => {
    console.log("reflectToggle",reflectToggle);
    setDataGridLoading(true);
    getDataFromServer(searchField,null,examCenterSearchValue==="" ? undefined : examCenterSearchValue,false);
  }, [ examCenterSearchValue, searchField, reflectToggle]);

  function getDataFromServer(_searchField,_lastDoc,_studentSearchValue,_merge) {
    getExamCenters(_searchField,_lastDoc,_studentSearchValue,examDocID).then(res=> {
      let _examCenterList = convertFirebaseObjToDataGridObj(res.docs.map(m=>({ docId:m.id, ...m.data(), lastUpdateAtLocal : m.data().lastUpdateAt.toDate() })));
      console.log(_examCenterList);
      if (_merge) {
        setServerMetaDataList(pre=>[...pre, ...res.docs])
        setExamCenterList(pre=>[...pre, ..._examCenterList]);
      }else{
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList(res.docs);
        setExamCenterList(_examCenterList);
      }
      setDataGridLoading(false);
    }).catch(err=>{
      console.error(err);
      errorToast(err.toString());
      if (_merge) {
        setServerMetaDataList(pre=>[...pre])
        setExamCenterList(pre=>[...pre]);
      }else{
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList([]);
        setExamCenterList([]);
      }
      setDataGridLoading(false);
    })
  }

  //* call on Next Button
  function getMoreData(){
    setDataGridLoading(true);
    getDataFromServer(searchField, serverMetaDataList[serverMetaDataList.length - 1], examCenterSearchValue === "" ? undefined : examCenterSearchValue, true);
  }

  const onClickViewCenter = (_center) => {
    const _selectedExamCenter = serverMetaDataList.map(m=>({docID:m.id,...m.data()})).find(f=>f.examCenterName === _center.examCenterName);
    console.log(_selectedExamCenter);
    setSelectedExamCenterForEdit(_selectedExamCenter);
    setEditExamCenterModalToggle(true);
  }
  
  return (
    <>
      <Box className={cssClasses.mainTabPanelWrapper}>
        <div className={cssClasses.mainTabPanelHeader}>
          <div>
            <p>Models Status</p>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
          </div>
          <div>
            { examDocID &&
              <>
                <div>
                  <Button 
                    style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }} 
                    // btnIcon={addIcon} 
                    btnName={"Reload"}
                    // clicked={() => {setAddExamCenterInExamModalToggle(true);}}
                    clicked={() => {setDataGridLoading(true); getDataFromServer(searchField,null,examCenterSearchValue==="" ? undefined : examCenterSearchValue,false);}}
                    disable={!loggedInUser.tabAccess.examCenter.some(s=> s==='add')}
                  />
                </div>
              </>
            }
          </div>
        </div>

        <div className={cssClasses.searchAndFilterWrapper}>
          <div>
            <ToggleButtonGroup
              color="primary"
              value={searchField}
              onChange={(e) => {setSearchField(e.target.value);}}
              exclusive
              style={{filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))"}}
              // onChange={handleChange}
            >
              <ToggleButton value="examCenterNameForSearch" sx={[toggleButtonStyle]}>Name</ToggleButton>
              <ToggleButton value="examCenterCode" sx={[toggleButtonStyle]}>By Exam Center Code</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div>
            <div><SearchBar placeholder={"Search"} search={(_value) => setExamCenterSearchValue(_value)}/></div>
            {/* <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={filterIcon} btnName={"Filters"} /></div> */}
          </div>
        </div>

        <div className={cssClasses.examCenterWrapper}>
          <DataGrid
            rows={dataGridLoading ? [] : examCenterList}
            columns={ columns}
            hideFooterPagination={false}
            hideFooter={false}            
            pageSize={10}
            rowsPerPageOptions={[10]}
            page={pageMaintain}
            sx={[dataGridStyles,{
              " .MuiDataGrid-footerContainer > div:first-of-type:after" : {
                content: '"'+`${pageMaintain+1} Page`+'"',
                paddingLeft: "1rem"
              }
            }]}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            disableVirtualization
            loading={dataGridLoading}
            onPageChange={(_page)=>{
              console.log({_page});
              setPageMaintain(_page)
              if (pageNumber < _page) {
                // * Next When Data need
                setPageNumber(_page);
                getMoreData()
              }else{
                // *previous
              }
            }}
          />
        </div>

      </Box>

      {/* * for Exam Center Add In One Exam*/}
      { addExamCenterInExamModalToggle && 
        <AddExamCentersInExam open={addExamCenterInExamModalToggle} examDocID={examDocID} 
          handleClose={(flag) => {
            if(flag){
              setDataGridLoading(true);
              getDataFromServer(searchField,null,examCenterSearchValue==="" ? undefined : examCenterSearchValue,false);
            }
            setAddExamCenterInExamModalToggle(false);
          }}
          examName={examName}
        />
      }
      
      {/* * for Exam Center Edit */}
      { editExamCenterModalToggle &&
        <AddExamCenter
          isEditable={loggedInUser.tabAccess.examCenter.some(s=> s==='edit')} 
          open={editExamCenterModalToggle} examCenterObject={selectedExamCenterForEdit} 
          docID={selectedExamCenterForEdit?.docID ? selectedExamCenterForEdit?.docID : null } 
          handleClose={(_value) => {
            setEditExamCenterModalToggle(false);
            if (_value) {
              setDataGridLoading(true);
              getDataFromServer(searchField,null,examCenterSearchValue==="" ? undefined : examCenterSearchValue,false);
            }
          }} 
        />
      }

      {/* * for Shift Students */}
      {shiftStudentsModalToggle && <ShiftStudentsModal open={shiftStudentsModalToggle} examDocID={examDocID} handleClose={() => {setShiftStudentsModalToggle(false)}}/>}
    </>
  )
}

function convertFirebaseObjToDataGridObj(_serverList){
  return _serverList.map(m=> ({
    id : m.docId,
    ...m
  }))
}
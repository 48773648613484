import React, { useEffect, useRef, useState } from 'react'

//* import MUI
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@mui/material/TextField";

//* import sub component
import Button from "../../Subcomponent/Button";
import Dropdown from "../../Subcomponent/Dropdown"

//* import Images
import shuffleIcon from "../../assets/images/shuffle.svg";
import crossImage from "../../assets/images/cross.svg";

//* import Css
import cssClasses from "./SelectExamCenterForDownloadResultExcelSheet.module.css";

//* imports services
import { getAllExamCentersInExam } from '../../services/ExamCenterService';
import { getDownloadedExamCenterExcel } from '../../services/Merit';
import DropdownCheckBox from "../../Subcomponent/DropdownCheckBox";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { Checkbox, MenuItem } from '@mui/material';



const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    fontSize: "1.4rem !important",
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "&.Mui-error": {
      border: "1px solid red !important"
    },
    "& input": {
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      // border: "unset",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&.MuiSelect-outlined": {
      border: "1px solid #D0D5DD !important",
    },
    "& fieldSet": {
      border: "none",
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
    "& p": {
      fontSize: "1.4rem",
    },
    "&.MuiSelect-select": {
      border: "1px solid #D6BBFB !important",
    },
  },
  helperText: {
    fontSize: "1.4rem !important",
    margin: "0.2rem 0 0 0 !important",
  },
}));
export default function SelectExamCenterForDownloadResultExcelSheet({ open, handleClose, examDocID = null, subExamDocID, getDownloadedInfo = true }) {

  const [downloadedExcelCenters, setDownloadedExcelCenters] = useState([]);

  // const [examCenterList, setExamCenterList] = useState([]);
  const examCenterList = useRef([]);
  const [examCenterOriginalList, setExamCenterOriginalList] = useState([]);

  const [selectedFromExamCenter, setSelectedFromExamCenter] = useState([]);

  useEffect(() => {
    console.log({ examDocID });
    if (examDocID) {
      getAllExamCentersInExam(examDocID).then(res => {
        let _list = res.docs.map(m => ({ docId: m.id, ...m.data() }));
        console.log(_list);
        setExamCenterOriginalList(_list);
        examCenterList.current = _list.map(m => ({ label: m.examCenterName, value: m.docId, sideValue: m?.examCenterCode, isDownloadedIconShow: true }));
        if (getDownloadedInfo) {
         getDownloadedExamCentersFromServer(_list);
        } else {
          setDownloadedExcelCenters([]);
        }
      });
    }
  }, [examDocID])

  // useEffect(() => {

  // }, [selectedFromExamCenter])

  const getDownloadedExamCentersFromServer = () => {
    getDownloadedExamCenterExcel(examDocID, subExamDocID).then(res => {
      let list = res.docs.map(m => ({ docId: m.id, ...m.data() }));
      console.log("list => ", list);
      setDownloadedExcelCenters(list);
      // examCenterList.current
    })
  }


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root": {
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>
        <div className={cssClasses.header}>
          {/* <img src={shuffleIcon} alt="" /> */}
          <h1>Select Exam Center</h1>
          <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>

        <div className={cssClasses.mainContainer}>
          <div className={cssClasses.inputContainer}>

            <div className={cssClasses.inputWrapper} style={{ width: "100%" }}>
              <label>Exam center</label>

              {/* <Dropdown
                list={examCenterList}
                selectedValue={selectedFromExamCenter}
                onChangeSelect={(value) => { console.log(value); setSelectedFromExamCenter(value) }}
              /> */}

              <DropdownCheckBox
                list={examCenterList.current?.map(m => ({ ...m, isDownloadedIconShow: downloadedExcelCenters.find(s => s.docId === m.value)?.isDownloaded || false }))}
                value={selectedFromExamCenter || []}
                isSelectOptionEnable={true}
                isSelectNonDownloadedOptionEnable={getDownloadedInfo}
                onChangeSelect={(_val) => {
                  // console.log("_val => ", _val)
                  setSelectedFromExamCenter(_val)
                }}
                isSearchable={true}
              />
            </div>

          </div>
        </div>

        <div className={cssClasses.footer}>
          {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
          <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Cancel"} clicked={() => handleClose(null)} /></div>
          <div>
            <Button
              style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
              btnName={"Done"}
              disable={!Boolean(selectedFromExamCenter?.length > 0)}
              clicked={() => {
                console.log("dd");
                const _list = examCenterOriginalList.filter(f => selectedFromExamCenter.includes(f.docId));
                console.log("_list => ", _list);
                handleClose(_list)

              }}
            />
          </div>
        </div>
      </Box>
    </Modal>
  )
}

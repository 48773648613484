import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import cssModule from "./PhotoUploadLoadingStepper.module.css";
import Box from "@mui/material/Box";

import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
// import socketIOClient from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../../Subcomponent/Button";
import { getFailedArray, replace } from "../../services/StudentService";
import FailedEntires from "../FailedEntries/FailedEntries";

import Styles from "./FailedModel.module.css"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  outline: 0,
};

const useStyles = makeStyles((theme) => ({
  ButtonStyle: {
    width: "7rem",
    marginLeft: "auto",
    marginRight: "2rem",
    marginBottom: "1rem",
    marginTop: "1rem",
  },

  ProgressStyle: {
    backgroundColor: "#F9FAFB",
    padding: "2rem",
    border: "1px solid #EAECF0",
  },
  verticalLine: {
    borderLeft: "2px solid #D0D5DD",
    height: "30px",
    margin: "9px 0px 12px 13px ",
  },
  gridContainer: {
    display: "flex",
  },
  QuestionStyle: {
    margin: "1.2rem 1.8rem 1.5rem 3.5rem",
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.3rem",
    fontWeight: "800",
  },
  Title: {
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.6rem",
    color: "#344054",
    padding: "1.5rem 0rem 1.7rem 3rem",
    display: "flex",
    alignItems: "center",
  },
  ReplaceDiv: {
    padding: "1.5rem 0",
  },
  gridChild: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0.9rem",
  },
  loader: {
    marginLeft: "2rem",
  },
  TextStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.5rem",
    fontWeight: "800",
    lineHeight: "2.4rem",
    color: "#101828",
  },
  numberStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.4rem",
    fontWeight: "100",
    lineHeight: "2rem",
    color: "#667085",
  },
  iconStyle: {
    color: "#7F56D9",
    width: "4rem",
    height: "5rem",
  },
  tickIcon: {
    // marginLeft:"17px",
  },
  UploadIcons: {
    margin: "0.3rem 0.5rem",
  },

  failedEntriesText: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  },
  wrapperFailedEntries: {
    display: "flex",
    padding: "0rem 3.6rem 2rem 3.6rem",
    fontFamily: "Inter-Regular",
    fontSize: "1.3rem",
    fontWeight: "800",
    lineHeight: "2rem",
    marginTop: "1rem",
  },
  wrapperOpenModal: {
    marginLeft: "2rem",
  },
}));
const dataGridStyles = {
    fontWeight: "400",
    fontSize: "1.4rem",
    lineHeight: "2rem",
    color: "#667085",
    fontFamily:"Inter-Medium",
    border: "none !important",
    '& div[data-colindex="1"]': {
      color: "#101828",
      fontWeight: "500",
    },
    '& div[data-value-colindex="7"]': {
      color: "#fff",
      fontWeight: "500",
    },
    "&  .MuiDataGrid-columnHeaders": {
      background: "#F9FAFB",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader": {
      padding: "0 4rem",
  
      "&:focus": {
        outline: "none",
      },
    },
    "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
    },
    "& .MuiDataGrid-cell": {
      padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
      "&:focus": {
        outline: "none",
      },
    },
    "& .MuiButtonBase-root": {
      fontSize: "2rem",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
    "& .MuiDataGrid-footerContainer": {
      "& .MuiTablePagination-displayedRows ": {
        display: "none",
      },
      "& .MuiDataGrid-selectedRowCount": {
        visibility: "hidden ",
      },
    },
    "& .MuiPaginationRoot": {
      "& .MuiPagination-ul": {
        flexWrap: "nowrap",
        li: {
          "&:first-of-type": {
            flexBasis: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            "> button::after": {
              marginLeft: "10px",
              content: "'previous'",
            },
          },
          "&:last-of-type": {
            flexBasis: "100%",
            display: "flex",
            justifyCcontent: "flex-end",
            alignItems: "center",
            "> button::before": {
              marginRight: "10px",
              content: "'next'",
            },
          },
        },
      },
    },
  };
export default function PhotoUploadLoadingStepper({ open, handleClose, value, totalDataUploading, addedCandidates, duplicateCandidatesCount = 0, failedCandidatesCount, failedCandidatesArray, completed, updatingStudentPhotoCount, uploadingImagesProgress}) {

  const classes = useStyles();

  const [replacingData, setReplacingData] = useState(false);
  const [addedDataCount, setAddedDataCount] = useState(0);
  const [failedEntriesModal, setFailedEntriesModal] = useState(false);
  const [showOkay, setShowOkay] = useState(false);
  const [replaceDataMsg, setReplaceDataMsg] = useState(0);

  function openFailedEntriesModal() {
    setFailedEntriesModal(true);
  }

  function cleanupAndClose() {
    handleClose();
  }
  
  return (
    <>
      <Modal open={open} aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className={classes.Title}>
            {" "}
            <div className={classes.UploadIcons}></div> Uploading Data
          </div>

          <div className={classes.ProgressStyle}>
            <div
              className={classes.gridContainer}
              style={{ opacity: value >= 0 ? 1 : 0.7 }}
            >
              {value > 0 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>Getting Data</span>
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: value >= 1 ? 1 : 0.7 }}
            >
              {value > 1 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <p className={classes.TextStyle}>Uploading Photos</p>
                <p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>Progress {uploadingImagesProgress} %</p>
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: value >= 2 ? 1 : 0.7 }}
            >
              {value > 2 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}
              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>
                  {" "}
                  Updating Data to Database
                </span>
                <div className={classes.numberStyle}>
                  {/* { filesUploading?  value : "Error: Something went wrong!"}  */}
                  Total Photos: {totalDataUploading} 
                  <br />
                  Update Photos:{" "}{updatingStudentPhotoCount} 
                  <div>Failed Entries: {failedCandidatesCount}</div>
                </div>
              </div>
            </div>
          </div>
          {/* { Duplicate Entries ?  */}
          {value >= 3 &&
            <>
              {duplicateCandidatesCount > 0 &&
                <div className={classes.ReplaceDiv}>
                  <div className={classes.gridContainer}>
                    {replacingData === true ? (
                      <>
                        <div className={classes.QuestionStyle}>
                          Replacing entries: {replaceDataMsg} of{" "}
                          {duplicateCandidatesCount}
                        </div>
                        {showOkay === true ? (
                          <div className={classes.ButtonStyle}>
                            <Button
                              style={{
                                color: "#ffffff",
                                backgroundColor: "#6941c6",
                              }}
                              btnName={"OK"}
                              btnIcon={false}
                              clicked={handleClose}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <div className={classes.QuestionStyle}>
                          Replace duplicate Students?
                        </div>

                        <div className={classes.gridChild}>
                          <Button
                            style={{ color: "#6941c6", backgroundColor: "#FFFFFF" }}
                            btnName={"No"}
                            btnIcon={false}
                            // clicked={cleanupAndClose}
                          />
                        </div>
                        <div className={classes.gridChild}>
                          <Button
                            style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                            btnName={"Yes"}
                            btnIcon={false}
                            // clicked={replaceStudents}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              }
              {
                failedCandidatesCount > 0 &&
                <div className={classes.wrapperFailedEntries}>
                  <div className={classes.failedEntriesText}>View Failed Photos
                    <div className={classes.wrapperOpenModal}>
                      <Button
                        style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                        btnName={"View"}
                        btnIcon={false}
                        clicked={openFailedEntriesModal}
                      />
                    </div>
                    <div className={classes.ButtonStyle} style={{marginLeft: "1rem"}}>
                        <Button
                            style={{ color: "#6941c6", backgroundColor: "#ffffff" }}
                            btnName={"Close"}
                            btnIcon={false}
                            clicked={() => {handleClose()}}
                        />
                    </div>
                  </div></div>

              }
              {
                (duplicateCandidatesCount === 0 && failedCandidatesCount > 0) &&
                <div className={classes.ButtonStyle}>
                  <Button
                    style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                    btnName={"OK"}
                    btnIcon={false}
                    clicked={cleanupAndClose}
                  />
                </div>
              }
              {
                (duplicateCandidatesCount === 0 && failedCandidatesCount === 0) &&
                <div className={classes.ButtonStyle}>
                  <Button
                    style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                    btnName={"OK"}
                    btnIcon={false}
                    clicked={cleanupAndClose}
                  />
                </div>
              }
            </>
          }
          <FieldPhotosEntries openModal={failedEntriesModal} closeModal={() => { setFailedEntriesModal(false) }} failedCandidatesArray={failedCandidatesArray} />
        </Box>
      </Modal>

    </>
  );
}

const FieldPhotosEntries = ({openModal, closeModal,failedCandidatesArray}) => {


    const [StudentList, setStudentList] = useState([]);

    useEffect(() => {
        setStudentList(failedCandidatesArray?.map(m=>({id: m.msg.split(" ")[0],...m})))
    }, [failedCandidatesArray])
    

    const columns = [
        { field: 'id', headerName: 'Applicant', width: 150 },
        { field: 'file', headerName: 'Image', width: 100, 
            renderCell: (params) => {

                return (
                    <img src={URL.createObjectURL(params.value)} alt="" style={{width: "50px", height: "60px"}}/>
                )
            }
        },
        { field: 'msg', headerName: 'Reason', width: 400 }
    ];

    return (
        <Modal 
            open={openModal}   
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={Styles.modalWrapper}>
                <Box className={Styles.wrapperContent}>
                {/* <div className={Styles.heading}>Failed Upload Candidates List</div> */}
                <div className={Styles.wrapperTable}>
                    <DataGrid
                        rows={StudentList}
                        columns={columns}
                        pageSize={10}
                        sx={[dataGridStyles]}   
                    />
                </div>
                <div className={Styles.footer}>
                    <div className={Styles.ButtonWrapper}>
                        <Button
                            style={{
                            color: "#ffffff",
                            backgroundColor: "#6941c6",
                            }}
                            btnName={"OK"}
                            btnIcon={false}
                            clicked={closeModal}
                        />
                    </div>
                </div>
                </Box>
            </Box>
        </Modal>
    )
}


import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from '@mui/icons-material/Clear';
import { padding } from '@mui/system';

//* node modules
import debounce from 'lodash.debounce';

const useStyles = makeStyles((theme) => ({
    textField: {
        background: '#FFFFFF',
        border: '1px solid #D0D5DD !important',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        borderRadius: '0.8rem',
        padding: "0 !important",
        width:"100%"
    },
    input: {
        paddingRight: "0rem !important",
        "&.Mui-focused": {
            // border: "unset",
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D0D5DD !important'
            }
        },
        "&:hover": {
            // border:"none",
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D0D5DD !important'
            }
        },
        '& input': {
            padding: '1rem 1.4rem !important',
            lineHeight: '2.4rem',
            fontSize: '1.6rem',
            paddingLeft: "0 !important"
        }
    }
}));

export default function SearchBar({ placeholder, search }) {

    const classList = useStyles();

    const debouncedFetchData = debounce((e) => {
        search(e.target.value);
    }, 500);

    function clearSearchField() {
        document.getElementById("input-with-icon-textfield").value = "";
        search("");
    }

    return (
        <TextField className={classList.textField}
            id="input-with-icon-textfield"
            placeholder={placeholder}
            onChange={debouncedFetchData}
            InputProps={{
                className: classList.input,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon style={{ color: "#667085", fontSize: "2rem" }} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="start">
                        <ClearIcon style={{ color: "#667085", fontSize: "2rem", cursor: "pointer" }} onClick={() => {clearSearchField()}} />
                    </InputAdornment>
                ),
            }}
            variant="outlined"
        />
    )
}

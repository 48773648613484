import * as actionTypes from "../actionType/action-type";

const initialState = { activeMenu: 'exams' }

const ACTIVE_MENU = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ACTIVE_MENU:
            return activeMenu(state, payload);
        default:
            return state;
    }
};

const activeMenu = (state, payload) => {
    let newState = {
        activeMenu: payload,
    };
    state = Object.assign({}, state, newState);
    return state;
};

export default ACTIVE_MENU;

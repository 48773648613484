/* eslint-disable no-loop-func */
import axios from "axios";
import { query, orderBy, limit, getFirestore, collection, getDocs, startAfter, where, addDoc, endBefore, limitToLast, getDoc, updateDoc, doc, setDoc } from "firebase/firestore";

async function getStudents(searchField = "studentFullNameForSearch", lastDoc, studentSearchValue, examDocID) {

  if (searchField === "studentFullNameForSearch" && studentSearchValue) {
    studentSearchValue = studentSearchValue.toLowerCase();
  }

  console.log({ searchField, lastDoc, studentSearchValue, examDocID });
  let students = collection(getFirestore(), 'students');

  if (examDocID) {
    students = collection(getFirestore(), `exams/${examDocID}/EligibleStudents`);
  }

  let _query = query(students, orderBy(searchField, "asc"), limit(20));  //* for first time visit and without search
  if (lastDoc && !studentSearchValue) {
    //* for next docs without Search 
    _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), limit(11))
  }
  if (studentSearchValue && !lastDoc) {
    //* for search first time without last
    _query = query(students, orderBy(searchField, "asc"), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  if (lastDoc && studentSearchValue) {
    //* for search time with last doc
    _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  let getStudents = getDocs(_query);
  
  return getStudents;
}

async function getAllEligibleStudents(examDocID) {
  let students = collection(getFirestore(), `exams/${examDocID}/EligibleStudents`);
  let getStudents = await getDocs(students);

  return getStudents;
}

async function setEligibleStudents(examDocID, studentDocID,postBody) {
  let meritStudentsRef = doc(getFirestore(), `exams/${examDocID}/EligibleStudents`,studentDocID);
  return await setDoc(meritStudentsRef,postBody);
}

function getStudentsForDownload(status) {
  return new Promise((resolve, reject) => {
    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': 'Bearer ' + token };
    // axios.post('http://192.168.2.62:7000/candidates/downloadImages', { status }, { headers: headers }).then((resp) => {
    axios.post(process.env.REACT_APP_DOWNLOAD_NODE_API_URL + '/candidates/downloadImages', { status }, { headers: headers }).then((resp) => {
      console.log('resp.data: ', resp.data);
      resolve(resp.data);
    }).catch((err) => {
      console.log("err => ",err);
      reject({ status: 0, message: err });
    });
  });
}

function downloadImages(blob, status) {
  const a = document.createElement('a');
  a.href = 'data:application/zip;base64,' + blob;
  a.download = 'Students -' + status + '.zip';
  a.click();
}

async function getStudentByApplicantIdFromEligibleCollection(id, examDocID,) {
  // let studentsRef =  doc(getFirestore(), `exams/${examDocID}/EligibleStudents`, id);
  // return await getDoc(studentsRef);
}


export { getStudents, setEligibleStudents, getAllEligibleStudents, downloadImages, getStudentsForDownload, getStudentByApplicantIdFromEligibleCollection }











// getDocs(collectionGroup(getFirestore(), "hallticket")).then(res=>{
//   console.log("res.size => ",res.size);
//   let list = res.docs.filter(f=>f.data()["Full Name"])
//   console.log("list.length => ",list.length);
//   list.forEach(ele=>{
//     let body = { studentFullNameForSearch : ele.data()["Full Name"] }
//     let arr = ele.ref.path.split("/");
//     arr.pop()
//     let path = arr.join("/");
    
//     updateDoc(doc(getFirestore(),path,ele.id),body).then(console.log).catch(console.error)
//   })
// })



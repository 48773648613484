import React from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, {
    stepConnectorClasses,
} from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';


const useStyles = makeStyles((theme) => ({
    label: {

        '& .title': {
            fontWeight: '500',
            fontSize: '1.4rem',
            lineHeight: '2rem',
            fontFamily: '\'Inter-Medium\''
        },
        '& .text': {
            fontSize: '1.4rem',
            lineHeight: '2rem',
        },
        '& .Mui-active': {
            color: "#7F56D9 !important",
        },
        '& .Mui-completed': {
            color: "#7F56D9 !important",
        },
        '& .MuiStepLabel-label': {
            fontFamily: '\'Inter-Regular\''
        }
    },
}));

export default function ProgressBar({ activeStep }) {

    const steps = [{
        title: "Exam Details",
        text: "enter exam details"
    },
    {
        title: "Exam Form",
        text: "enter exam form details"
    },
    {
        title: "Exam Schedule",
        text: "enter exam schedule details"
    },
    ]

    const classes = useStyles();

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
            boxShadow: '',
            backgroundColor: '#F9F5FF !important',
            borderRadius: '50% !important',
            fontSize: '18px',
            padding: '0.1rem 0.8rem !important',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: '1',
            backgroundColor: '#F9F5FF !important',
            borderRadius: '50% !important',
            fontSize: '18px',
            padding: '0.5rem !important',
            width: "2.5rem !important",
            height: "2.5rem !important"
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };

    return (
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
            {steps.map((label) => (
                <Step key={label.title}>
                    <StepLabel className={classes.label} StepIconComponent={QontoStepIcon}>
                        <p className='title'>{label.title}</p>
                        <p className='text'>{label.text}</p>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

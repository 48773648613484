import { initializeApp } from "firebase/app";
import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence } from "firebase/auth";

import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import 'firebase/compat/firestore'
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_BASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRE_BASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId:process.env.REACT_APP_FIRE_BASE_APP_ID,
  // measurementId:process.env.REACT_APP_FIRE_BASE_MEASUREMENT_ID
};
const app = initializeApp(firebaseConfig);
// console.log({app});
setPersistence(getAuth(), browserSessionPersistence);
export default firebase;
import React, { forwardRef, useImperativeHandle, useState } from "react";

//* import css
import Styles from "./ExamForm.module.css";

//* import MUI
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@mui/material/InputAdornment";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from "@mui/lab/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/material";
import Switch from "@mui/material/Switch";
// import { MobileDatePicker } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

// * import CK-Editor 
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//* images
import uploadImageIcon from "../../assets/images/upload-image-icon.svg";

//* import Sub-components
import Dropdown from "../../Subcomponent/Dropdown";
import Button from "../../Subcomponent/Button";

// import service
import { updateExamDetails } from "../../services/ExamService";
import axios from "axios";
import { errorToast, successToast } from "../../services/Toast";
import { useSelector } from "react-redux";
import { uploadFilesAWS } from "../../services/AwsBucket";

const useStyles = makeStyles((theme) => ({
  helperText: {
    fontSize: "1.4rem !important",
    margin: "0.2rem 0 0 0 !important",
  },
  ckeditor: {
    height: "50rem",
    "&.ck.ck-editor": {
      fontSize: "1.4rem",
      marginTop: "0.6rem",
    },
    "&.ck.ck-editor__editable_inline": {
      minHeight: "10rem !important"
    }
  },
  textField: {
    width: "100%",
  },
  input: {
    fontSize: "1.4rem !important",
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "& input": {
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      // border: "unset",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&.MuiSelect-outlined": {
      border: "1px solid #D0D5DD !important",
    },
    "& fieldSet": {
      border: "none",
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
    "& p": {
      fontSize: "1.4rem",
    },
    "&.MuiSelect-select": {
      border: "1px solid #D6BBFB !important",
    },
  },
  datePickerWrapper: {
    width: "100%",
  },
  datePicker: {
    "flex-direction": "row-reverse",
    fontSize: "1.4rem !important",
    "& fieldSet": {
      border: "none",
    },
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "& input": {
      width: "100%",
      fontSize: "1.4rem !important",
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
  },
  calender: {
    "& div": {
      fontSize: "1.4rem !important",
    },
    "& span": {
      fontSize: "1.4rem !important",
    },
    "& button": {
      fontSize: "1.4rem !important",
    },
  },
  mobileDatePickerStyles: {
    "& .MuiTypography-root, .MuiButtonBase-root": {
      fontSize: "1.5rem"
    },
    "& .MuiCalendarPicker-root > div:first-of-type > div:first-of-type": {
      fontSize: "1.5rem"
    },
    "& svg": {
      fontSize: "3rem"
    },
    "& .PrivatePickersYear-yearButton": {
      fontSize: "1.5rem"
    }
  }
}));
const radioButtonStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: "1.4rem!important",
    fontFamily: "'Inter-Medium'",
  },
};
const switchStyle = {
  color: "#7F56D9",
  "& .Mui-checked > .MuiSwitch-thumb": {
    color: "#7F56D9",
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#D6C8F4",
  }
}
const Details = forwardRef(({ helperText, examForm, setExamForm, setHelperText, visibleSaveButton = false, examDocID = false }, ref) => {
  const classes = useStyles();
  const examTypeList = ["Banking", "SSC", "Engineering", "Medical", "CA", "Civil Services"];

  const [isLoading, setIsLoading] = useState(false);
  function selectExamType(type) {
    setExamForm((preValue) => {
      preValue.category = type;
      return { ...preValue };
    });
    setHelperText((prevalue) => {
      prevalue.isTypeFilled = "";
      return { ...prevalue };
    });
  }

  const loggedInUser = useSelector((state) => state.Auth.user);

  function checkField() {
    var isFormComplete = true;
    if (examForm.name === '') {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isNameFilled = 'Please Enter Name *'
        return { ...prevalue }
      })
    }
    if (!examForm?.unitName && examForm.unitName === '') {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isUnitName = 'Please Enter Unit Name *'
        return { ...prevalue }
      })
    }
    // if (examForm.description === '') {
    //     setHelperText((prevalue) => {
    //         prevalue.isDescFilled = 'Please Enter description *'
    //         return { ...prevalue }
    //     })
    // }
    if (!examForm.applicationCloseDate) {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isCloseDateFilled = 'Please Enter application close date *'
        return { ...prevalue }
      })
    }
    if (!examForm.applicationOpenDate) {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isOpenDateFilled = 'Please Enter application open date *'
        return { ...prevalue }
      })
    }
    if (examForm.fee === '') {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isFeeFilled = 'Please Enter fee *'
        return { ...prevalue }
      })
    }
    if (examForm.category === '') {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isTypeFilled = 'Please select exam category *'
        return { ...prevalue }
      })
    }
    if (examForm.image === '') {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isImgUploaded = 'Please upload image *'
        return { ...prevalue }
      })
    }
    if (examForm.examCode === '') {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isExamCodeFilled = 'Please enter exam code *'
        return { ...prevalue }
      })
    }
    if (!examForm.paymentType) {
      isFormComplete = false
      setHelperText((prevalue) => {
        prevalue.isPaymentTypeFilled = 'Please select payment type *'
        return { ...prevalue }
      })
    }
    if (examForm.paymentType !== 'Online' && examForm.paymentType !== '') {
      if (examForm.bankDetails.bankName === '') {
        isFormComplete = false
        setHelperText((prevalue) => {
          prevalue.isBankNameFilled = 'Please enter bank name *';
          return { ...prevalue };
        })
      }
      if (examForm.bankDetails.accName === '') {
        isFormComplete = false
        setHelperText((prevalue) => {
          prevalue.isBankAccNameFilled = 'Please enter account holder name *';
          return { ...prevalue };
        })
      }
      if (examForm.bankDetails.accNo === '') {
        isFormComplete = false
        setHelperText((prevalue) => {
          prevalue.isBankAccNoFilled = 'Please enter account number *';
          return { ...prevalue };
        })
      }
      if (examForm.bankDetails.IFSC === '') {
        isFormComplete = false
        setHelperText((prevalue) => {
          prevalue.isBankIFSCFilled = 'Please enter IFSC code *';
          return { ...prevalue };
        })
      }
      if (examForm.bankDetails.branch === '') {
        isFormComplete = false
        setHelperText((prevalue) => {
          prevalue.isBankBranchFilled = 'Please enter branch name *';
          return { ...prevalue };
        })
      }
    }
    console.log(helperText);
    console.log('isFormComplete: ', isFormComplete);
    return isFormComplete;
  }


  useImperativeHandle(ref, () => ({
    checkField
  }));


  function SaveDetails() {
    var flag = checkField();
    if (flag && examDocID) {
      console.log(examForm, examDocID);
      setIsLoading(true);
      updateExamDetails(examForm, examDocID).then(res => {
        console.log(res);
        setIsLoading(false);
        successToast("Exam Details updated Successfully");
      }).catch(err=>{
        console.log(err);
        setIsLoading(false);
        errorToast("Something went wrong! Try again");
      })
    }
  }

  function uploadImageToServer(e) {
    let file = e.target.files[0];
    console.log(file);
    if (examForm.name === '') {
      console.log("abc")
      setHelperText((preValue) => {
        helperText.isImgUploaded = 'Please enter exam name and then upload image'
        return { ...preValue }
      })
    } else {
      // const formData = new FormData();
      // formData.append("file", file, examForm.examShortName + "." + file.name.split('.').pop());
      // console.log('name: ', examForm.examShortName + "." + file.name.split('.').pop());
      // axios.post("https://hts-cloud-storage.el.r.appspot.com/upload", formData).then((resp) => {
      //   console.log('resp: ', resp);
      //   setExamForm(pre => {
      //     pre.image = resp.data.url;
      //     return Object.assign({}, pre);
      //   });
      //   setHelperText(pre => {
      //     pre.isImgUploaded = "";
      //     return Object.assign({}, pre);
      //   })
      // })
      const imageName = examForm.examShortName + "." + file.name.split('.').pop();
      let fileObjectToUpload = {
        name: file.name,
        size: file.size,
        key: process.env.REACT_APP_bucketRootKey + '/' + imageName,
        arrayBuffer: file,
      };
      console.log(fileObjectToUpload);
      uploadFilesAWS([fileObjectToUpload], (data) => {
        console.log("data => ", data);
        if (data?.result.includes("Files Uploaded")) {
          let img_url = "https://exam-management.s3.ap-south-1.amazonaws.com/exam-management/" + imageName;
          setExamForm(pre => {
            pre.image = img_url;
            return Object.assign({}, pre);
          });
          setHelperText(pre => {
            pre.isImgUploaded = "";
            return Object.assign({}, pre);
          });
        } else {
          errorToast("Something went wrong! Try again");
        }
      }, (err) => {
        console.log("err => ", err);
        errorToast("Something went wrong! Try again");
      }, (progress) => {
        console.log("progress => ", progress);
      }
      );

    }
  }

  return (
    <div className={Styles.firstForm}>
      <div className={Styles.form}>
        <div className={Styles.formTitle}>
          <p>Enter exam details</p>
        </div>
        
        <div className={Styles.inputDiv}>
          <label>
            Exam Name<span style={{ color: "red" }}> *</span>
          </label>
          <TextField
            InputProps={{ className: classes.input }}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
            value={examForm.name}
            onChange={(newValue) => {
              if(!newValue.target.value.includes("_")){
                setExamForm((preValue) => {
                  preValue.name = newValue.target.value;
                  preValue.examNameForSearch = newValue.target.value.toLowerCase();
                  preValue.examShortName = preValue.name.split(" ").join("") + new Date().toLocaleString('default', { month: 'short' }) + new Date().getFullYear();
                  return { ...preValue };
                });
                setHelperText((prevalue) => {
                  prevalue.isNameFilled = "";
                  return { ...prevalue };
                });
              }
            }}
            className={classes.textField}
            error={helperText.isNameFilled !== ""}
            id="outlined-basic"
            sx={[
              {
                "&>div:first-of-type": {
                  border:
                    helperText.isNameFilled !== ""
                      ? "1px solid red !important"
                      : "1px solid #D0D5DD !important",
                },
                width: "100%",
              },
            ]}
            helperText={helperText.isNameFilled}
            variant="outlined"
          />
        </div>
        <div className={Styles.inputDiv}>
          <label>
            Unit Name<span style={{ color: "red" }}> *</span>
          </label>
          <TextField
            InputProps={{ className: classes.input }}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
            value={examForm.unitName}
            onChange={(newValue) => {
              if(!newValue.target.value.includes("_")){
                setExamForm((preValue) => {
                  preValue.unitName = newValue.target.value;
                  return { ...preValue };
                });
                setHelperText((prevalue) => {
                  prevalue.isUnitName = "";
                  return { ...prevalue };
                });
              }
            }}
            className={classes.textField}
            error={helperText.isUnitName !== ""}
            id="outlined-basic"
            sx={[
              {
                "&>div:first-of-type": {
                  border:
                    helperText.isUnitName !== ""
                      ? "1px solid red !important"
                      : "1px solid #D0D5DD !important",
                },
                width: "100%",
              },
            ]}
            helperText={helperText.isUnitName}
            variant="outlined"
          />
        </div>
        <div className={Styles.inputDiv}>
          <label>Description</label>
          <div className={Styles.ckeditor}>
            <CKEditor
              placeholder="Enter description..."
              className={classes.ckeditor}
              config={{
                toolbar: {
                  items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "undo",
                    "redo",
                    "|",
                    "numberedList",
                    "bulletedList",
                  ],
                  shouldNotGroupWhenFull: true
                }
              }}
              editor={ClassicEditor}
              data={examForm.description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setExamForm((preValue) => {
                  preValue.description = data;
                  return { ...preValue };
                });
              }}
              onReady={(editor) => {
                editor.ui.view.element.style.border = "1px solid #D0D5DD";
                editor.ui.view.element.style.borderRadius = "0.8rem";
              }}
              onFocus={(event, editor) => {
                editor.ui.view.editable.element.style.border =
                  "1px solid #D6BBFB";
              }}
            />
          </div>
        </div>
        <div className={Styles.flexDiv}>
          <div className={Styles.halfDiv}>
            <label>
              Application Open Date<span style={{ color: "red" }}> *</span>
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                value={new Date(examForm.applicationOpenDate)}
                minDate={new Date()}
                // maxDate={new Date(examForm.applicationCloseDate)}
                maxDate={new Date(new Date(examForm.applicationCloseDate).setDate(new Date(examForm.applicationCloseDate).getDate() - 1))}
                PaperProps={{ className: classes.calender }}
                PopperProps={{ placement: "bottom-start" }}
                DialogProps={{ className: classes.mobileDatePickerStyles }}
                showToolbar={false}
                InputProps={{
                  className: classes.datePicker,
                  endAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthOutlinedIcon
                        style={{ fontSize: '2rem', marginLeft: '1.5rem', cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("ApplicationOpenDateRef").click();
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={(newValue) => {
                  // console.log('value: ', newValue);
                  // if (newValue) {
                  setExamForm((preValue) => {
                    preValue.applicationOpenDate = newValue == null ? '' : new Date(newValue).toLocaleDateString();
                    return { ...preValue };
                  });
                  setHelperText((prevalue) => {
                    prevalue.isOpenDateFilled = "";
                    return { ...prevalue };
                  });
                  // } 
                  // else {
                  //   setHelperText((prevalue) => {
                  //     prevalue.isOpenDateFilled = "Invalid Date";
                  //     return { ...prevalue };
                  //   });
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    disabled={true}
                    {...params}
                    helperText={helperText.isOpenDateFilled}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.helperText,
                      },
                    }}
                    id={"ApplicationOpenDateRef"}
                    sx={[
                      {
                        "&>div:first-of-type": {
                          border:
                            helperText.isOpenDateFilled !== ""
                              ? "1px solid red !important"
                              : "1px solid #D0D5DD !important",
                        },
                        width: "100%",
                      },
                    ]}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className={Styles.halfDiv}>
            <label>
              Application Close Date<span style={{ color: "red" }}> *</span>
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                value={new Date(examForm.applicationCloseDate)}
                // minDate={new Date(examForm.applicationOpenDate)}
                minDate={new Date(new Date(examForm.applicationOpenDate).setDate(new Date(examForm.applicationOpenDate).getDate() + 1))}
                disabled={examForm.applicationOpenDate === '' ? true : false}
                PopperProps={{ placement: "bottom-start" }}
                PaperProps={{ className: classes.calender }}
                DialogProps={{ className: classes.mobileDatePickerStyles }}
                showToolbar={false}
                InputProps={{
                  className: classes.datePicker,
                  endAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthOutlinedIcon
                        style={{ fontSize: '2rem', marginLeft: '1.5rem', cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("ApplicationCloseDateRef").click();
                        }}
                      />
                    </InputAdornment>
                  ),
                  error: true
                }}
                onChange={(newValue) => {
                  // if (newValue) {
                  setExamForm((preValue) => {
                    preValue.applicationCloseDate = newValue == null ? '' : new Date(newValue).toLocaleDateString();
                    return { ...preValue };
                  });
                  setHelperText((prevalue) => {
                    prevalue.isCloseDateFilled = "";
                    return { ...prevalue };
                  });
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    helperText={helperText.isCloseDateFilled}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.helperText,
                      },
                    }}
                    id={"ApplicationCloseDateRef"}
                    sx={[
                      {
                        "&>div:first-of-type": {
                          border:
                            helperText.isCloseDateFilled !== ""
                              ? "1px solid red !important"
                              : "1px solid #D0D5DD !important",
                        },
                        width: "100%",
                      },
                    ]}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className={Styles.flexDiv}>
          <div className={Styles.halfDiv}>
            <label>
              Exam Fee<span style={{ color: "red" }}> *</span>
            </label>
            <TextField
              type="Number"
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              inputProps={{ min: 0 }}
              value={examForm.fee}
              onChange={(newValue) => {
                if (newValue.target.value < 0) {
                  return;
                }
                setExamForm((preValue) => {
                  preValue.fee = newValue.target.value;
                  return { ...preValue };
                });
                setHelperText((prevalue) => {
                  prevalue.isFeeFilled = "";
                  return { ...prevalue };
                });
              }}
              sx={[
                {
                  "&>div:first-of-type": {
                    border:
                      helperText.isFeeFilled !== ""
                        ? "1px solid red !important"
                        : "1px solid #D0D5DD !important",
                  },
                  width: "100%",
                },
              ]}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              className={classes.textField}
              id="outlined-basic"
              error={helperText.isFeeFilled !== ""}
              helperText={helperText.isFeeFilled}
              variant="outlined"
            />
          </div>
          <div className={Styles.halfDiv}>
            <label>
              Exam Code<span style={{ color: "red" }}> *</span>
            </label>
            <TextField
              type="text"
              InputProps={{
                className: classes.input,
              }}
              value={examForm.examCode}
              onChange={(newValue) => {
                setExamForm((preValue) => {
                  preValue.examCode = newValue.target.value;
                  return { ...preValue };
                });
                setHelperText((prevalue) => {
                  prevalue.isExamCodeFilled = "";
                  return { ...prevalue };
                });
              }}
              sx={[
                {
                  "&>div:first-of-type": {
                    border:
                      helperText.isExamCodeFilled !== ""
                        ? "1px solid red !important"
                        : "1px solid #D0D5DD !important",
                  },
                  width: "100%",
                },
              ]}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              className={classes.textField}
              id="outlined-basic"
              error={helperText.isExamCodeFilled !== ""}
              helperText={helperText.isExamCodeFilled}
              variant="outlined"
            />
          </div>
        </div>
        <div className={Styles.inputDiv}>
          <FormControl style={{ fontSize: "1.4rem" }}>
            <label>Payment type</label>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={examForm.paymentType}
              style={{ display: "unset" }}
              onChange={(e) => {
                setExamForm((preValue) => {
                  preValue.paymentType = e.target.value;
                  if (e.target.value == 'Online' && preValue.bankDetails) {
                    delete preValue.bankDetails
                  } else if (e.target.value !== 'Online' && !preValue.bankDetails) {
                    preValue.bankDetails = {
                      accName: "",
                      bankName: "",
                      branch: "",
                      IFSC: "",
                      accNo: ""
                    }
                  }
                  return { ...preValue };
                });
                setHelperText((prevalue) => {
                  prevalue.isPaymentTypeFilled = "";
                  return { ...prevalue };
                });
              }}
            >
              <FormControlLabel
                value="Online"
                sx={[radioButtonStyle]}
                control={
                  <Radio
                    size="medium"
                    sx={{
                      color: "default",
                      "&.Mui-checked": {
                        color: "#7F56D9",
                      },
                    }}
                  />
                }
                label="Online"
              />
              <FormControlLabel
                value="Offline"
                sx={[radioButtonStyle]}
                control={
                  <Radio
                    size="medium"
                    sx={{
                      color: "default",
                      "&.Mui-checked": {
                        color: "#7F56D9",
                      },
                    }}
                  />
                }
                label="Offline"
              />
              <FormControlLabel
                value="Both"
                sx={[radioButtonStyle]}
                control={
                  <Radio
                    size="medium"
                    sx={{
                      color: "default",
                      "&.Mui-checked": {
                        color: "#7F56D9",
                      },
                    }}
                  />
                }
                label="Both"
              />
            </RadioGroup>
          </FormControl>
          {helperText.isPaymentTypeFilled !== "" && (
            <p style={{ color: "red", marginTop: "0.2rem", fontSize: "1rem" }}>
              {helperText.isPaymentTypeFilled}
            </p>
          )}
        </div>
        {examForm.paymentType !== 'Online' && examForm.bankDetails &&
          <div>
            <div className={Styles.inputDiv}>
              <label>
                Bank Name<span style={{ color: "red" }}> *</span>
              </label>
              <TextField
                InputProps={{ className: classes.input }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
                value={examForm.bankDetails.bankName}
                onChange={(newValue) => {
                  setExamForm((preValue) => {
                    preValue.bankDetails.bankName = newValue.target.value;
                    return { ...preValue };
                  });
                  setHelperText((prevalue) => {
                    prevalue.isBankNameFilled = "";
                    return { ...prevalue };
                  });
                }}
                className={classes.textField}
                error={helperText.isBankNameFilled !== ""}
                id="outlined-basic"
                sx={[
                  {
                    "&>div:first-of-type": {
                      border:
                        helperText.isBankNameFilled !== ""
                          ? "1px solid red !important"
                          : "1px solid #D0D5DD !important",
                    },
                    width: "100%",
                  },
                ]}
                helperText={helperText.isBankNameFilled}
                variant="outlined"
              />
            </div>
            <div className={Styles.flexDiv}>
              <div className={Styles.halfDiv}>
                <label>
                  Account holder name<span style={{ color: "red" }}> *</span>
                </label>
                <TextField
                  InputProps={{ className: classes.input }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  value={examForm.bankDetails.accName}
                  onChange={(newValue) => {
                    setExamForm((preValue) => {
                      preValue.bankDetails.accName = newValue.target.value;
                      return { ...preValue };
                    });
                    setHelperText((prevalue) => {
                      prevalue.isBankAccNameFilled = "";
                      return { ...prevalue };
                    });
                  }}
                  className={classes.textField}
                  error={helperText.isBankAccNameFilled !== ""}
                  id="outlined-basic"
                  sx={[
                    {
                      "&>div:first-of-type": {
                        border:
                          helperText.isBankAccNameFilled !== ""
                            ? "1px solid red !important"
                            : "1px solid #D0D5DD !important",
                      },
                      width: "100%",
                    },
                  ]}
                  helperText={helperText.isBankAccNameFilled}
                  variant="outlined"
                />
              </div>
              <div className={Styles.halfDiv}>
                <label>
                  Account number<span style={{ color: "red" }}> *</span>
                </label>
                <TextField
                  InputProps={{ className: classes.input }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  type={"text"}
                  value={examForm.bankDetails.accNo}
                  onChange={(newValue) => {
                    var numbers = /^[0-9]+$/;
                    if ((newValue.target.value.match(numbers) || newValue.target.value === "") && newValue.target.value.length <= 18) {
                      setExamForm((preValue) => {
                        preValue.bankDetails.accNo = newValue.target.value;
                        return { ...preValue };
                      });
                      setHelperText((prevalue) => {
                        prevalue.isBankAccNoFilled = "";
                        return { ...prevalue };
                      });
                    }
                  }}
                  className={classes.textField}
                  error={helperText.isBankAccNoFilled !== ""}
                  id="outlined-basic"
                  sx={[
                    {
                      "&>div:first-of-type": {
                        border:
                          helperText.isBankAccNoFilled !== ""
                            ? "1px solid red !important"
                            : "1px solid #D0D5DD !important",
                      },
                      width: "100%",
                    },
                  ]}
                  helperText={helperText.isBankAccNoFilled}
                  variant="outlined"
                />
              </div>
            </div>
            <div className={Styles.flexDiv}>
              <div className={Styles.halfDiv}>
                <label>
                  Branch<span style={{ color: "red" }}> *</span>
                </label>
                <TextField
                  InputProps={{ className: classes.input }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  value={examForm.bankDetails.branch}
                  onChange={(newValue) => {
                    setExamForm((preValue) => {
                      preValue.bankDetails.branch = newValue.target.value;
                      return { ...preValue };
                    });
                    setHelperText((prevalue) => {
                      prevalue.isBankBranchFilled = "";
                      return { ...prevalue };
                    });
                  }}
                  className={classes.textField}
                  error={helperText.isBankBranchFilled !== ""}
                  id="outlined-basic"
                  sx={[
                    {
                      "&>div:first-of-type": {
                        border:
                          helperText.isBankBranchFilled !== ""
                            ? "1px solid red !important"
                            : "1px solid #D0D5DD !important",
                      },
                      width: "100%",
                    },
                  ]}
                  helperText={helperText.isBankBranchFilled}
                  variant="outlined"
                />
              </div>
              <div className={Styles.halfDiv}>
                <label>
                  IFSC code<span style={{ color: "red" }}> *</span>
                </label>
                <TextField
                  InputProps={{ className: classes.input }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                    },
                  }}
                  value={examForm.bankDetails.IFSC}
                  type={"text"}
                  onChange={(newValue) => {
                    if (newValue.target.value.length <= 11) {
                      setExamForm((preValue) => {
                        preValue.bankDetails.IFSC = newValue.target.value;
                        return { ...preValue };
                      });
                      setHelperText((prevalue) => {
                        prevalue.isBankIFSCFilled = "";
                        return { ...prevalue };
                      });
                    }
                  }}
                  className={classes.textField}
                  error={helperText.isBankIFSCFilled !== ""}
                  id="outlined-basic"
                  sx={[
                    {
                      "&>div:first-of-type": {
                        border:
                          helperText.isBankIFSCFilled !== ""
                            ? "1px solid red !important"
                            : "1px solid #D0D5DD !important",
                      },
                      width: "100%",
                    },
                  ]}
                  helperText={helperText.isBankIFSCFilled}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        }
        <div className={Styles.inputDiv}>
          <label>
            Choose category<span style={{ color: "red" }}> *</span>
          </label>
          {/* <Dropdown
            isError={helperText.isTypeFilled}
            list={examTypeList}
            selectedValue={examForm.category}
            onChangeSelect={selectExamType}
          /> */}
          <TextField
            InputProps={{ className: classes.input }}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
            value={examForm.category}
            type={"text"}
            onChange={(newValue) => {
              setExamForm((preValue) => {
                preValue.category = newValue.target.value;
                return { ...preValue };
              });
              setHelperText((prevalue) => {
                prevalue.isTypeFilled = "";
                return { ...prevalue };
              });
            }}
            className={classes.textField}
            error={helperText.isTypeFilled !== ""}
            id="outlined-basic"
            sx={[
              {
                "&>div:first-of-type": {
                  border:
                    helperText.isTypeFilled !== ""
                      ? "1px solid red !important"
                      : "1px solid #D0D5DD !important",
                },
                width: "100%",
              },
            ]}
            helperText={helperText.isTypeFilled}
            variant="outlined"
          />
        </div>
        {/* <div className={Styles.inputDiv}>
          <label>
            Show Proforma <span style={{ color: "red" }}> *</span>
          </label>
          <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
            <div>NO</div>
            <Switch
              sx={[switchStyle]}
              edge="end"
              onChange={(e) => {
                let _admin = examForm;
                _admin.isProforma = e.target.checked;
                setExamForm({ ..._admin })
              }}
              checked={examForm.isProforma}
              color="default"
            />
            <div>Yes</div>
          </Box>
        </div> */}
        {/* <div className={Styles.inputDiv}>
          <label>
            Face Recognition <span style={{ color: "red" }}> *</span>
          </label>
          <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
            <div>NO</div>
            <Switch
              sx={[switchStyle]}
              edge="end"
              onChange={(e) => {
                let _admin = examForm;
                _admin.isFaceRecognition = e.target.checked;
                setExamForm({ ..._admin })
              }}
              checked={examForm.isFaceRecognition}
              color="default"
            />
            <div>Yes</div>
          </Box>
        </div> */}
        {/* <div className={Styles.inputDiv}>
          <label>
            Store Thumb Impression <span style={{ color: "red" }}> *</span>
          </label>
          <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
            <div>NO</div>
            <Switch
              sx={[switchStyle]}
              edge="end"
              onChange={(e) => {
                let _admin = examForm;
                _admin.isStoreThumbImpression = e.target.checked;
                setExamForm({ ..._admin })
              }}
              checked={examForm.isStoreThumbImpression}
              color="default"
            />
            <div>Yes</div>
          </Box>
        </div>
        <div className={Styles.inputDiv}>
          <label>
            Store Identity Document <span style={{ color: "red" }}> *</span>
          </label>
          <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
            <div>NO</div>
            <Switch
              sx={[switchStyle]}
              edge="end"
              onChange={(e) => {
                let _admin = examForm;
                _admin.isStoreIdentityDocument = e.target.checked;
                setExamForm({ ..._admin })
              }}
              checked={examForm.isStoreIdentityDocument}
              color="default"
            />
            <div>Yes</div>
          </Box>
        </div> */}

        <div className={Styles.inputDiv}>
          <label>
            Upload Image<span style={{ color: "red" }}> *</span>
          </label>
          {examForm.image === "" &&
            <div
              className={
                helperText.isImgUploaded == ""
                  ? Styles.imageUploadWrapper
                  : Styles.redImageUploadWrapper
              }
              onClick={() => {
                document.getElementById("imageInput").click();
              }}
            // onDrop={(e) => { console.log(e);e.preventDefault() }}
            // onDragEnd={(e) => { e.preventDefault(); console.log(e) }}
            // onDragOver={(e) => { e.preventDefault() }}
            >

              <img src={uploadImageIcon} alt={""} />
              <p className={Styles.uploadImageText}>
                <span className={Styles.uploadImageSpan}>Click to upload </span>or
                drag and drop
              </p>
              <p className={Styles.uploadImageText}>
                SVG, PNG, JPG or GIF (max. 400x400px)
              </p>
            </div>
          }
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            id="imageInput"
            onChange={uploadImageToServer}
          />
          {examForm.image !== "" &&
            // <div>
            //   <img src={examForm.image} alt="" srcset="" />
            // </div>
            <div className={Styles.row}>
              <div className={Styles.column} id={"caption"}>
                <span className={Styles.text}>
                  <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Change Image"} clicked={() => { document.getElementById("imageInput").click() }} />
                </span>
                <img src={examForm.image} alt={""} />
              </div>
            </div>

          }
          {helperText.isImgUploaded !== "" && (
            <p style={{ color: "red", marginTop: "0.2rem", fontSize: "1.4rem" }}>
              {helperText.isImgUploaded}
            </p>
          )}
        </div>
        {visibleSaveButton &&
          <Box display={"flex"} justifyContent={"flex-end"}>
            <div style={{ marginBottom: "1.5rem" }}>
              <Button 
                style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} 
                btnName={"Save"} clicked={SaveDetails} 
                disable={!loggedInUser.tabAccess.exams.some(s => s === 'edit') || isLoading} 
                isLoading={isLoading}
                loadingColor={"#FFFFFF"}
              />
            </div>
          </Box>
        }
      </div>
    </div >
  );
})

export default Details;
function isEmailValid(inputText) {
  let flag = true;
  let helperText = "";
  var mailFormat = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!inputText || inputText === "") {
    flag = false;
    helperText = "Please enter email address.";
  } else if (!String(inputText).toLowerCase().match(mailFormat)) {
    flag = false;
    helperText = "Please enter valid email address.";
  }
  return { flag, helperText };
}

function isPhoneNumberValid(inputText, fieldName) {
  let flag = true;
  let helperText = "";
  if (!inputText || inputText === "") {
    flag = false;
    helperText = `Please enter ${fieldName}.`;
  } else if (inputText.length !== 10) {
    flag = false;
    helperText = `Please enter valid ${fieldName}.`;
  }
  return { flag, helperText };
}

function isCheckBoxFieldValid(inputText, fieldName) {
  let flag = true;
  let helperText = "";
  if (!inputText.length) {
    flag = false;
    helperText = `Please Choose ${fieldName}.`;
  }
  return { flag, helperText };
}

function isRequiredFieldValid(inputText, fieldName) {
  let flag = true;
  let helperText = "";
  if (!inputText || inputText === "") {
    flag = false;
    helperText = `Please enter ${fieldName}.`;
  }
  return { flag, helperText };
}

function isPasswordFieldValid(value) {
  var lowerCaseLetters = /[a-z]/g;
  var upperCaseLetters = /[A-Z]/g;
  var numbers = /[0-9]/g;
  let flag = true;
  let helperText = "";
  if (value === "") {
    helperText = "Please enter the password!";
    flag = false;
  } else if (!value.match(lowerCaseLetters)) {
    helperText = "password must contain 1 lowercase!";
    flag = false;
  }
  // Validate capital letters
  else if (!value.match(upperCaseLetters)) {
    helperText = "password must contain 1 uppercase!";
    flag = false;
  }
  // Validate numbers
  else if (!value.match(numbers)) {
    helperText = "password must contain 1 number!!";
    flag = false;
  }
  // Validate length
  else if (value.length <= 8) {
    helperText = "password must be more than 8 letters!";
    flag = false;
  } else {
    helperText = "";
    flag = true;
  }
  return { flag, helperText };
}

function isConfirmPasswordFieldValid(value, password) {
  let flag = true;
  let helperText = "";
  if (value == password) {
    flag = true;
    helperText = "";
  } else {
    flag = false;
    helperText = "Please Enter Password Same";
  }
  return { flag, helperText };
}

export {
  isEmailValid,
  isPhoneNumberValid,
  isCheckBoxFieldValid,
  isRequiredFieldValid,
  isPasswordFieldValid,
  isConfirmPasswordFieldValid,
};

//* firebase
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { collection, collectionGroup, doc, Firestore, getDoc, getDocs, getFirestore, query, where, documentId } from "firebase/firestore";
import { getFunctions, httpsCallable, } from "firebase/functions"
import action from "../Redux Store/actions/index";
import store from "../Redux Store/store";
import axios from "axios";

function saveUser(_user) {
  store.dispatch(
    action.authLogin(_user)
  );
}

function getUserData(id) {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth();
    const user = await auth.currentUser
    const userRef = doc(getFirestore(), 'admins', id);
    getDoc(userRef).then((resp) => {
      const loggedInUser = resp.data();
      loggedInUser.id = resp.id;
      // console.log('loggedInUser: ', loggedInUser);
      saveUser(loggedInUser);
      resolve(loggedInUser);
    }).catch((err) => {
      console.log('err: ', err);
      reject(err);
    });
  });
}
function getAdmins() {
  const adminsref = collection(getFirestore(), 'admins');
  getDocs(adminsref).then((resp) => {
    console.log('resp: ', resp);
  }).catch((err) => {
    console.log('err: ', err);
  });
}
async function loginWithFirebase(email, password) {
  const auth = getAuth();
  
  let login = await signInWithEmailAndPassword(auth, email, password);
  return login;
}

async function forgetPasswordFirebase(email) {
  const auth = getAuth();
  return await sendPasswordResetEmail(auth, email);
}

async function isAuthenticated() {
  const auth = getAuth();
  const user = await auth.currentUser
  console.log(user);
}
function signout() {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    signOut(auth).then(res=>{
      console.log(res);
      store.dispatch(action.authLogout());
      resolve(res);
    }).catch(err=>{
      console.log(err);
      reject(err);
    }) 
  }) 
}

async function resetAuthPassword(email) {
  // const auth = getAuth();
  // const user = auth.currentUser;
  // console.log("user => ",user);

  return await sendPasswordResetEmail(getAuth(),email)
}
async function updatePassword(postBody) {
  // const functions = getFunctions();
  // console.log(functions);
  // const addMessage = httpsCallable(functions, 'authPasswordChange');
  // return await addMessage(postBody);

  // return axios.post(`${process.env.REACT_APP_NODE_API_URL}candidates/resetAdminPassword`, postBody)
  return axios.post(`${process.env.REACT_APP_RANGA_FORGET_PASSWORD_URL}admin/resetAdminPassword`, postBody)
  // return axios.post(`http://localhost:7000/candidates/resetAdminPassword`, postBody)
}

async function getHallTicketWithPublishedExam(uid) {
  return new Promise((resolve, reject) => {
    let responseArray = [];
    getDocs(query(collectionGroup(getFirestore(), "hallticket"), where("UID", "==", uid))).then(res=>{
      let appliedList = res.docs.map(m=>({...m.data(), docId: m.id, examDocId:m.ref.parent.parent.id}));
      Promise.all(appliedList.map(m => getDoc(doc(getFirestore(), "exams", m.examDocId)))).then(response => {
        const publishedHallTicketExams = response.map(m => ({...m.data(), docId: m.id})).filter(f => f.hallTicketStatus === "published");
        appliedList.forEach((element, index) => {
          if (publishedHallTicketExams.some(s => s.docId === element.examDocId)) {
            responseArray.push({
              ...publishedHallTicketExams.find(s => s.docId === element.examDocId),
              hallTicketDetails: element
            })
          }
        })
        resolve(responseArray)
      })
    }).catch(err=>{
      console.log(err);
      reject(err)
    })
  })
}

export { saveUser, loginWithFirebase, forgetPasswordFirebase, isAuthenticated, signout, getUserData, getAdmins, resetAuthPassword, getHallTicketWithPublishedExam, updatePassword };

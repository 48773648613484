/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
//* images
import filterIcon from "../../assets/images/filter-icon.svg";
import moreVertical from "../../assets/images/more-vertical.svg";
import man from "../../assets/images/man.png";
import woman from "../../assets/images/woman.png";
import user_icon from "../../assets/images/user_icon.png"

//* css
import cssClasses from "./Admins.module.css";

//* import MUI
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from "@mui/material";
import Chip from '@mui/material/Chip';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

//* import Sub-components
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";

//* import Services
import { getAdmins } from "../../services/AdminsService";
import { errorToast } from "../../services/Toast";

//* import modals
import AddAdmin from "../../Modals/AddAdmin/AddAdmin";
import { useSelector } from "react-redux";

const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected":{
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',
  
  '& div[data-colindex="1"]' :{
    color : "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders':{
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator':{
    display :"none"
  },
  "& .MuiDataGrid-checkboxInput":{
    "& svg":{
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows":{
    display: "none"
  },
  "& [title='Go to previous page']:after":{
    content: "'Previous'",
    marginLeft: "1rem",
    paddingRight: '1rem'
  },
  "& [title='Go to next page']:before":{
    content: "'Next'",
    marginRight: "1rem",
    paddingRight: '1rem'
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility:"hidden !important"
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "7.2rem !important",
    minHeight: "7.2rem !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",    
    minHeight: "7.2rem !important",
    maxHeight: "7.2rem !important",
    outline: 'none !important',
  },
  "& .MuiButtonBase-root": {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '0 1rem'
  },
  "& .MuiTablePagination-actions": {
    margin: '0 2rem',
  }
}
const chipStatusStyle = {
  fontSize: "1.5rem",
  textTransform: 'capitalize',
  // fontWeight : "600",
  padding: "0.8rem",
  height: "auto",
  "& .MuiChip-icon": {
    fontSize: '1rem',
  }
}
const grayChip = {
  // border: "1px solid #EEF4FF",
  color: "#344054",
  background: "#F2F4F7",
}
const blueChip = {
  // border: "1px solid #EEF4FF",
  color: "#175CD3",
  background: "#EFF8FF",
}
const purpleChip = {
  // border: "1px solid #EEF4FF",
  color: "#3538CD",
  background: "#EEF4FF",
}
const pinkChip = {
  // border: "1px solid #EEF4FF",
  color: "#C11574",
  background: "#FDF2FA",
}
const greenChip = {
  // border: "1px solid #EEF4FF",
  color: "#027A48",
  background: "#ECFDF3",
}
const redChip = {
  // border: "1px solid #EEF4FF",
  color: "#B42318",
  background: "#FEF3F2",
}

const chipColorsArray = [ blueChip, purpleChip, pinkChip, ]

export default function Admins({ height=false,examDocID=null, subExamDocID=null, reflectToggle }) {

  const adminColumns = [
    { field: 'name', headerName: 'Name', width: 200 , sortable: false,},
    { field: 'email', headerName: 'Email', sortable: false, width: 250 },
    { field: 'phone', headerName: 'Mobile no.', sortable: false, width: 150 },
    { field: 'examAccess', headerName: 'Exam', sortable: false, width: 450,
      renderCell: (params) => {  
        // console.log(Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).length);
        // console.log(params.value);
        return <>
        <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
          {params.value?.map((m,index)=>(
            index < 2 && <Chip key={`chip_${params.id}_${m.label}`} label={m.label} size={"medium"} sx={[{ ...chipStatusStyle, ...chipColorsArray[index] }]} />
          ))}
          {(params.value?.length - 2) > 0 &&
            <Tooltip 
              arrow
              title={
                <List style={{fontSize: "1.2rem", textTransform: 'capitalize'}}>
                  {
                    params.value?.map((m,index)=>(
                      index >= 2 && <ListItem key={`listItem_${m.label}_${index+1}`} disablePadding>{m.label}</ListItem>
                    ))
                  }
                </List>
              }
            >
              
              <Chip key={`chip_${params.id}_${"Just"}`} label={`+ ${params.value?.length - 2}`} size={"medium"} sx={[{ ...chipStatusStyle }]} />
            </Tooltip>
          }
        </Box>
        </>
      },
    },
    // { field: 'examCenterNameAccess', headerName: 'ExamCenter', sortable: false, width: 200 },
    { field: 'isActive', headerName: 'Status', sortable: false, width: 150, 
    renderCell: (params) => {  
      // console.log(Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).length);
      // console.log(params.row[params.field]);
      return <>
        <Chip icon={<CircleIcon style={{ color: params.row[params.field] ? greenChip.color : redChip.color }} />} label={params.row[params.field] ? "Active" : "In-Active"} size={"medium"} sx={[{ ...chipStatusStyle, ...params.row[params.field] ? greenChip : redChip }]} />
      </>
    },
    },
    {
      field: 'tabAccess',
      headerName: 'Tab access',
      sortable: false,
      width: 450,
      renderCell: (params) => {  
        // console.log(Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).length);
        // console.log(params.row[params.field]);
        return <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
          {Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).map((m,index)=>(
            index < 3 && <Chip key={`chip_${params.id}_${m}`} label={m} size={"medium"} sx={[{ ...chipStatusStyle, ...chipColorsArray[index] }]} />
          ))}
          {(Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).length - 3) > 0 &&
            <Tooltip 
              arrow
              title={
                <List style={{fontSize: "1.2rem", textTransform: 'capitalize'}}>
                  {
                    Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).map((m,index)=>(
                      index >= 3 && <ListItem key={`listItem_${m}_${index+1}`} disablePadding>{m}</ListItem>
                    ))
                  }
                </List>
              }
            >
              
              <Chip key={`chip_${params.id}_${"Just"}`} label={`+ ${Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).length -3}`} size={"medium"} sx={[{ ...chipStatusStyle }]} />
            </Tooltip>
          }
        </Box>
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params) => {  
        return <p style={{color:"#7f56d9",cursor:"pointer"}} onClick={() => {onClickEditAdmin(params.id)}}>Edit</p>;
      },
    },
  ]
  
  const loggedInUser = useSelector((state) => state.Auth.user);
  const [adminList, setAdminList] = useState([]);
  const [serverMetaDataList, setServerMetaDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageMaintain, setPageMaintain] = useState(0);
  const [adminSearchValue, setAdminSearchValue] = useState("");

  const [dataGridLoading, setDataGridLoading] = useState(true);
  const [searchField, setSearchField] = useState("nameForSearch");

  const [selectedForEditDocIdAdmin, setSelectedForEditDocIdAdmin] = useState(null);
  const [editAdminModalToggle, setEditAdminModalToggle] = useState(false);

  useEffect(() => {
    setDataGridLoading(true);
    getDataFromServer(searchField,null,adminSearchValue==="" ? undefined : adminSearchValue,false);
  }, [adminSearchValue,searchField, reflectToggle]);


  function getDataFromServer(_searchField,_lastDoc,_studentSearchValue,_merge){
    getAdmins(searchField, _lastDoc, _studentSearchValue, examDocID, subExamDocID).then(res=>{
      let _studentList = convertFirebaseObjToDataGridObj(res.docs.map(m=>({docID:m.id,...m.data()})));
      console.log(_studentList);
      if (_merge) {
        setServerMetaDataList(pre=>[...pre, ...res.docs])
        setAdminList(pre=>[...pre, ..._studentList]);
      }else{
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList(res.docs);
        setAdminList(_studentList);
      }
      setDataGridLoading(false);
    }).catch(err=>{
      console.log(err);
      errorToast(err.toString());
      if (_merge) {
        setServerMetaDataList(pre=>[...pre])
        setAdminList(pre=>[...pre]);
      }else{
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList([]);
        setAdminList([]);
      }
      setDataGridLoading(false);
    })
  }


  //* call on Next Button
  function getMoreData(){
    setDataGridLoading(true);
    getDataFromServer(searchField, serverMetaDataList[serverMetaDataList.length - 1], adminSearchValue === "" ? undefined : adminSearchValue, true);
  }

  function onClickEditAdmin(_docID){
    setSelectedForEditDocIdAdmin(_docID);
    // if (loggedInUser.tabAccess.exams.some(s=> s==='edit')) {
      setEditAdminModalToggle(true);
    // }
  }
  
  return (
    <Box className={cssClasses.mainTabPanelWrapper}>
      <div className={cssClasses.mainTabPanelHeader}>
        <div>
          <p>All Admins</p>
          {/* <p>Total Students Applied.</p> */}
        </div>
        <div>
          {/* <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            tittle={"Message"}
          >
            <img src={moreVertical} alt="3 dots icon" />
          </IconButton> */}
          <div><SearchBar placeholder={"Search Admin"} search={(_value) => setAdminSearchValue(_value)}/></div>
        </div>
      </div>

      {/* <div className={cssClasses.searchAndFilterWrapper} >
        <div>
          <ToggleButtonGroup
            color="primary"
            value={'View All'}
            exclusive
            style={{filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))"}}
            // onChange={handleChange}
          >
            <ToggleButton value="All Students" sx={[toggleButtonStyle]}>All Students</ToggleButton>
            <ToggleButton value="Students Entered" sx={[toggleButtonStyle]}>Students Entered</ToggleButton>
            <ToggleButton value="By Room Wise" sx={[toggleButtonStyle]}>By Room Wise</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div>
          <ToggleButtonGroup
            color="primary"
            value={searchField}
            onChange={(e) => {setSearchField(e.target.value);}}
            exclusive
            style={{filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))"}}
            // onChange={handleChange}
          >
            <ToggleButton value="Full Name" sx={[toggleButtonStyle]}>Name</ToggleButton>
            <ToggleButton value="RollNo" sx={[toggleButtonStyle]}>Roll Number</ToggleButton>
            <ToggleButton value="Applicant" sx={[toggleButtonStyle]}>Application Id</ToggleButton>
          </ToggleButtonGroup>
        </div>
        
        <div>
          <div><SearchBar placeholder={"Search Student"} search={(_value) => setAdminSearchValue(_value)}/></div>
          <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={filterIcon} btnName={"Filters"} /></div>
        </div>
      </div> */}

      <div className={cssClasses.examCenterWrapper} style={{height : height ? height : "unset", flex : height ? "unset" : 1 }}>
        <DataGrid
          rows={dataGridLoading ? [] : adminList}
          columns={adminColumns}
          hideFooterPagination={false}
          hideFooter={false}            
          pageSize={10}
          rowsPerPageOptions={[10]}
          page={pageMaintain}
          sx={[dataGridStyles,{
            " .MuiDataGrid-footerContainer > div:first-of-type:after" : {
              content: '"'+`${pageMaintain+1} Page`+'"',
              paddingLeft: "1rem"
            }
          }]}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          disableVirtualization
          loading={dataGridLoading}
          onPageChange={(_page)=>{
            console.log({_page});
            setPageMaintain(_page)
            if (pageNumber < _page) {
              // * Next When Data need
              setPageNumber(_page);
              getMoreData()
            }else{
              // *previous
            }
          }}
        />
      </div>
      {editAdminModalToggle && 
        <AddAdmin 
          open={editAdminModalToggle} 
          adminDocId={selectedForEditDocIdAdmin}
          handleClose={(flag) => {
            if(flag) {
              getDataFromServer(searchField,null,adminSearchValue==="" ? undefined : adminSearchValue,false);
            }
            setEditAdminModalToggle(false)
          }} 
        /> 
      }
    </Box>
  );
}
function convertFirebaseObjToDataGridObj(_serverList){
  return _serverList.map(m=> ({
    id : m.docID,
    ...m
  }))
}
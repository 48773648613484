import { addDoc, collection, doc, getDoc, getDocs, getFirestore, deleteDoc, query, where, serverTimestamp, updateDoc, startAfter, limit, orderBy, collectionGroup } from "firebase/firestore";
import { dismissLoader } from "./loaderService";
import { errorToast } from "./Toast";
import axios from "axios";

function getAllExams() {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, orderBy('examNameForSearch', "asc"), limit(9))).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            dismissLoader()
            console.log(err);
            errorToast("Something went wrong please try again later")
        })
    })
}
async function getAllExamsWithoutLimit() {
    const examsref = collection(getFirestore(), 'exams');
    return await getDocs(query(examsref, orderBy('examNameForSearch', "asc")))
}
function getAllExamForHallTicket() {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, orderBy('examNameForSearch', "asc"))).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            dismissLoader()
            console.log(err);
            errorToast("Something went wrong please try again later")
        })
    })
}
function addExam(examForm) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        examForm.createdAt = serverTimestamp()
        examForm.lastUpdatedAt = serverTimestamp()
        addDoc(examsref, examForm).then((resp) => {
            resolve(resp)
        })
    })
}

function updateExamDetails(examForm, docID) {
    console.log(examForm, docID);
    return new Promise((resolve, reject) => {
        const examsRef = doc(getFirestore(), 'exams', docID);
        examForm.lastUpdatedAt = serverTimestamp();
        updateDoc(examsRef, examForm).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            dismissLoader()
            reject(err);
            console.log(err);
            errorToast(err)
        })
    })
}

function addExamSchedule(id, schedule) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams/' + id + '/subExam');
        schedule.createdAt = serverTimestamp();
        schedule.lastUpdatedAt = serverTimestamp();
        addDoc(examsref, schedule).then((resp) => {
            resolve(resp)
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}
function updateExamSchedule(examId, docID, schedule) {
    return new Promise((resolve, reject) => {
        const examsRef = doc(getFirestore(), `exams/${examId}/subExam/${docID}`);
        schedule.lastUpdatedAt = serverTimestamp();
        updateDoc(examsRef, schedule).then((resp) => {
            resolve(resp)
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}

function deleteSubExamSchedule(examDocID, docID) {
    return new Promise((resolve, reject) => {
        const examsRef = doc(getFirestore(), `exams/${examDocID}/subExam/${docID}`);
        deleteDoc(examsRef).then((resp) => {
            resolve(resp)
        }).catch(err => {
            console.log(err);
            reject(err);
            errorToast("Something went wrong please try again later")
        })
    })
}

function getExamByExamShortName(examShortName) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, where("examShortName", '==', examShortName))).then((resp) => {
            resolve(resp)
        }).catch(err => {
            reject(err);
        })
    })
}

function getExamScheduleById(id) {
    // console.log(`%c ${id}`,"font-size:20px; color: blue");
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams/' + id + '/subExam');
        getDocs(query(examsref, orderBy("index", "asc"))).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            errorToast("Something went wrong please try again later")
        })
    })
}

function getExamByName(examName) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, where("examShortName", '==', examName))).then((resp) => {
            resolve(resp)
        })
    })
}

function getExams(studentSearchValue, lastDoc) {
    console.log({ studentSearchValue, lastDoc });
    studentSearchValue = studentSearchValue.toLowerCase();
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, orderBy("examNameForSearch", "asc"), startAfter(lastDoc), where("examNameForSearch", ">=", studentSearchValue), where("examNameForSearch", "<=", studentSearchValue + "\uf8ff"), limit(9))).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            console.log('err: ', err);
        })
    })
}

async function getAllSubExamScheduled() {
    const examsref = collectionGroup(getFirestore(), 'subExam');
    return getDocs(examsref)
}

async function createModel(postBody) {
    return await axios.post(process.env.REACT_APP_CREATE_MODEL + "/createModel", postBody);
    //  return await axios.post("http://192.168.2.62:5000/createModel",postBody);
}

export { getAllExams, getAllExamsWithoutLimit, getAllExamForHallTicket, addExam, updateExamDetails, getExamByName, deleteSubExamSchedule, getExamByExamShortName, addExamSchedule, updateExamSchedule, getExamScheduleById, getExams, getAllSubExamScheduled, createModel }

import React, { useState, useEffect, useRef } from "react";
// import "./ExamCenters.css";
import Box from "@mui/material/Box";
import Button from "../../Subcomponent/Button";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Dropdown from "../../Subcomponent/Dropdown";

import cssClasses from "./AddExamCenter.module.css";
import GoogleMapReact from 'google-map-react';

//* Import Service
import { AddDocExamCenter, updateExamCenter, getAllExamCentersByCode, getAllExamCentersByName } from "../../services/ExamCenterService";
import { successToast, errorToast } from "../../services/Toast";
import { presentLoader, dismissLoader } from "../../services/loaderService";

const useStyles = makeStyles((theme) => ({
  Modal: {
    borderRadius: "10px 10px 10px 10px",
  },
  textFieldRoot: {
    width: "100%"
  },
  textField: {
    fontSize: "1.4rem !important",
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "&.Mui-error":{
      border: "1px solid red !important"
    },
    "& input": {
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      // border: "unset",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&.MuiSelect-outlined": {
      border: "1px solid #D0D5DD !important",
    },
    "& fieldSet": {
      border: "none",
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
    "& p": {
      fontSize: "1.4rem",
    },
    "&.MuiSelect-select": {
      border: "1px solid #D6BBFB !important",
    },
  },
  helperText: {
    fontSize: "1.4rem !important",
    margin: "0.2rem 0 0 0 !important",
    color : "#ff0000"
  },

  modalHeader: {
    fontFamily: "Inter-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "30px",
    /* or 150% */
    display: "flex",
    alignItems: "center",
    /* Gray/700 */
    color: "#344054",
    // background: "#FFFFFF",
    padding: "2rem 3.2rem 2rem 3.2rem",
  },
  modalContent: {
    padding: "3.1rem",
    background: "#F9FAFB",
    overflowY: "auto",
  },
  textName: {
    marginBottom: "0.6rem",
    fontFamily: "Inter-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    /* identical to box height, or 143% */

    /* Gray/700 */

    color: "#344054",
  },
  fieldBlockOne: {
    display: "inline-block",
    width: "100%",
    marginBottom: "2.6rem",
  },
  fieldBlock: {
    // display:"inline-block",
    width: "50%",
    marginBottom: "2.6rem",
  },
  fieldClassroomBlock: {
    display: "inline-block",
    width: "calc(50% - 1rem)",
    marginRight: "1rem",
  },
  fieldSeatBlock: {
    display: "inline-block",
    width: "50%",
  },
  fieldChooseType: {
    display: "inline-block",
    width: "100%",
    marginBottom: "2.6rem",
    marginTop: "2.6rem",
  },
  
  MenuItem: {
    width: "100%",
    fontSize: "1.4rem !important",
    fontFamily: "Inter-Medium",
    background: "#FFF",
  },
  wrapperButton: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    justifyContent: 'flex-end'
  },
  Button: {
    display: "inline-block",
    width: "20%",
    marginLeft: "2rem",
  },
}));

export default function AddExamCenter({ open, handleClose, examCenterObject=null, docID=null, isEditable=false }) {

  const mapEle = useRef(null);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const [isLoading, setIsLoading] = useState(false)
  const [examCenterNameForEdit, setExamCenterNameForEdit] = useState('');
  
  const [addExamCenter, setAddExamCenter] = useState({
    examCenterName: "",
    examCenterNameForSearch: "",
    examCenterCode: "",
    // numberOfClassroom: "",
    numberOfSeats: "",
    // chooseType: "",
    address: "",
    pincode: "",
    City: "",
    state: "",
  });
  const [error, setError] = useState({
    examCenterName: "",
    // numberOfClassroom: "",
    numberOfSeats: "",
    // chooseType: "",
    examCenterCode: "",
    address: "",
    pincode: "",
    City: "",
    state: "",
  });
  useEffect(() => {
    if(examCenterObject){
      let _examCenterObject = Object.assign({}, examCenterObject);
      delete _examCenterObject.docID;
      let _body = addExamCenter;
      Object.keys(_examCenterObject).forEach(ele=>{
        _body[ele] = _examCenterObject[ele];
      })
      setExamCenterNameForEdit(examCenterObject?.examCenterName);
      setAddExamCenter({..._body});
    }
  }, [examCenterObject]);

  const addExamCenterOnchange = (key, event) => {
    // console.log(event.target.value);
    if (key === "City" || key === "state") {
      event.target.value = event.target.value.replace(/[^a-zA-Z ]/g, "").replace(/(\..*)\./g, "$1");
    } else if ( key === "numberOfClassroom" || key === "numberOfSeats" || key === "pincode" ) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
    }
    if (key === "examCenterName") {
      setAddExamCenter((pre) => {
        pre["examCenterNameForSearch"] = event.target.value.toLowerCase();
        return { ...pre };
      });
    }
    setAddExamCenter((pre) => {
      pre[key] = event.target.value;
      return { ...pre };
    });
  };
  const validateExamCenterName = () => {
    let error_obj = error;
    if (addExamCenter.examCenterName.length === 0) {
      error_obj.examCenterName = "Please Enter Exam Center";
      setError({ ...error_obj });
      return false;
    } else {
      error_obj.examCenterName = "";
      setError({ ...error_obj });
      return true;
    }
  };
  const validateNumberOfSeats = () => {
    let error_obj = error;
    if (addExamCenter.numberOfSeats.length === 0) {
      error_obj.numberOfSeats = "Please Enter Number Fields";
      setError({ ...error_obj });
      return false;
    } else {
      error_obj.numberOfSeats = "";
      setError({ ...error_obj });
      return true;
    }
  };
  const validateNumberOfClassroom = () => {
    let error_obj = error;
    if (addExamCenter.numberOfClassroom.length === 0) {
      error_obj.numberOfClassroom = "Please Enter Number Fields";
      setError({ ...error_obj });
      return false;
    } else {
      error_obj.numberOfClassroom = "";
      setError({ ...error_obj });
      return true;
    }
  };
  const validateChooseType = () =>{
    if(addExamCenter.chooseType && addExamCenter.chooseType!==""){
      setError(pre => {
        pre.chooseType = "";
        return {...pre};
      });
      return true;
    } else {
      setError(pre => {
        pre.chooseType = "Please Select Chose Type.";
        return {...pre};
      });
      return false;
    }
  }
  const validateAddress = () =>{
    if(addExamCenter.address && addExamCenter.address!==""){
      setError(pre => {
        pre.address = "";
        return {...pre};
      });
      return true;
    } else {
      setError(pre => {
        pre.address = "Please Enter address.";
        return {...pre};
      });
      return false;
    }
  }
  const validatePinCode = () =>{
    if(addExamCenter.pincode && addExamCenter.pincode!==""){
      setError(pre => {
        pre.pincode = "";
        return {...pre};
      });
      return true;
    } else {
      setError(pre => {
        pre.pincode = "Please Enter pincode.";
        return {...pre};
      });
      return false;
    }
  }
  const validateCity = () =>{
    if(addExamCenter.City && addExamCenter.City!==""){
      setError(pre => {
        pre.City = "";
        return {...pre};
      });
      return true;
    } else {
      setError(pre => {
        pre.City = "Please Enter City.";
        return {...pre};
      });
      return false;
    }
  }
  const validateExamCenterCode = () =>{
    if(addExamCenter.examCenterCode && addExamCenter.examCenterCode!==""){
      setError(pre => {
        pre.examCenterCode = "";
        return {...pre};
      });
      return true;
    } else {
      setError(pre => {
        pre.examCenterCode = "Please Enter exam center code.";
        return {...pre};
      });
      return false;
    }
  }
  const validateState = () =>{
    if(addExamCenter.state && addExamCenter.state!==""){
      setError(pre => {
        pre.state = "";
        return {...pre};
      });
      return true;
    } else {
      setError(pre => {
        pre.state = "Please Enter state.";
        return {...pre};
      });
      return false;
    }
  }
  
  const validateSave = () => {
    const validator = [
      validateExamCenterName(),
      validateNumberOfSeats(),
      validateExamCenterCode(),
      // validateNumberOfClassroom(),
      // validateChooseType(),
      validateAddress(),
      validatePinCode(),
      validateCity(),
      validateState()
    ];
    let isValid = true;
    validator.forEach((validator) => {
      if (!validator) {
        isValid = false;
      }
    });
    return isValid;
  };
  const checkAllFields = () => {
    if (validateSave()) {
      // getAllExamCentersByCode(addExamCenter.examCenterCode).then(result =>{
        setIsLoading(true);
        
        getAllExamCentersByName(addExamCenter.examCenterName.trim()).then(result =>{
        if (docID && (result.empty || addExamCenter.examCenterName === examCenterNameForEdit)) {
          updateServerExamCenter();
        }else if (!docID && result.empty) {
          addToServer();
        } else {
          setError(pre=>{
            // pre.examCenterCode = "That exam center code already is taken."
            pre.examCenterName = "That exam center name already is taken."
            return {...pre}
          })
          setIsLoading(false);
        }
      }).catch(err=>{
        console.log(err);
        errorToast("Something went wrong! Try again");
        setIsLoading(false);
      })      
    }
  };
  function addToServer(){
    presentLoader();
    let postBody = Object.assign({},addExamCenter);
    postBody.examCenterName = postBody.examCenterName.trim();
    postBody.examCenterNameForSearch = postBody.examCenterNameForSearch.trim();
    AddDocExamCenter(postBody).then(res=>{
      console.log(res);
      successToast("Exam center added successfully.")
      handleClose(true);
      dismissLoader();
      setIsLoading(false);
    }).catch(err=>{
      console.log(err);
      errorToast("Something went wrong! Try again");
      dismissLoader();
      setIsLoading(false);
    })
  }

  function updateServerExamCenter() {
    presentLoader();
    let postBody = Object.assign({},addExamCenter);
    postBody.examCenterName = postBody.examCenterName.trim();
    postBody.examCenterNameForSearch = postBody.examCenterNameForSearch.trim();
    updateExamCenter(docID,postBody).then(res=>{
      console.log(res);
      successToast("Exam center updated Successfully.")
      handleClose(true);
      dismissLoader();
      setIsLoading(false);
    }).catch(err=>{
      console.log(err);
      errorToast("Something went wrong! Try again");
      dismissLoader();
      setIsLoading(false);
    })
  }

  const classList = useStyles();

  useEffect(() => {
    // console.log("mapEle",mapEle);
    navigator.geolocation.getCurrentPosition((res) => {
      setLatitude(res.coords.latitude);
      setLongitude(res.coords.longitude);
      console.log(res.coords);
    });
    if (mapEle && mapEle.current) {
      let _latitude = "";
      let _longitude = "";
      navigator.geolocation.getCurrentPosition((res) => {
        _longitude = res.coords.longitude;
        _latitude = res.coords.latitude;
        setLatitude(res.coords.latitude);
        setLongitude(res.coords.longitude);
        renderMap(_latitude, _longitude);
      });
      console.log(latitude, _longitude);
    }
  }, [mapEle]);
  function renderMap(_latitude, _longitude) {
    let map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: _latitude, lng: _longitude },
      zoom: 8,
    });
  }
  
  return (
    <Modal
      className={classList.Modal}
      open={open}
      // open={true}
      onClose={() => {handleClose(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root":{
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
      
    >
      <Box className={cssClasses.ModalWrapper}>
        <div className={classList.modalHeader}>Add Exam Center</div>
        <div className={classList.modalContent}>
          <div className={cssClasses.wrapperContent}>
            <div className={cssClasses.fieldBlock}>
              <div className={classList.textName}>Exam Center Name <span style={{color: "red"}}> *</span></div>
              <TextField
                className={classList.textFieldRoot}
                id="outlined-basic"
                value={addExamCenter.examCenterName}
                onChange={(e) => {
                  addExamCenterOnchange("examCenterName", e);
                }}
                helperText={error.examCenterName}
                error={error.examCenterName !== ""}
                InputProps={{
                  readOnly: !isEditable,                  
                  className: classList.textField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classList.helperText,
                  },
                }}
              />
            </div>
            <div className={cssClasses.fieldBlock}>
              <div className={classList.textName}>Exam Center Code <span style={{color: "red"}}> *</span></div>
              <TextField
                className={classList.textFieldRoot}
                id="outlined-basic"
                value={addExamCenter.examCenterCode}
                helperText={error.examCenterCode}
                error={error.examCenterCode !== ""}
                onChange={(e) => {
                  if ((/^[A-Za-z0-9 ]+$/.test(e.target.value) || e.target.value === "") && e.target.value.length < 50) {
                    addExamCenterOnchange("examCenterCode", e);
                  }
                }}
                InputProps={{
                  readOnly: !isEditable,                  
                  className: classList.textField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classList.helperText,
                  },
                }}
              />
            </div>
            <div className={cssClasses.fieldBlock}>
              <div className={classList.textName}>Total Capacity <span style={{color: "red"}}> *</span> </div>
              <TextField
                className={classList.textFieldRoot}
                id="outlined-basic"
                value={addExamCenter.numberOfSeats}
                helperText={error.numberOfSeats}
                error={error.numberOfSeats !== ""}
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    addExamCenterOnchange("numberOfSeats", e)
                  }
                }}
                InputProps={{
                  readOnly: !isEditable,
                  className: classList.textField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classList.helperText,
                  },
                }}
              />
            </div>
            {/* <div className={cssClasses.fieldBlock}>
              <div className={classList.textName}>Choose Type</div>
              <Dropdown 
                list={list} 
                selectedValue={addExamCenter.chooseType} 
                isError={error.chooseType!==""}
                onChangeSelect={(_newValue) => {
                  setAddExamCenter(pre=>{
                    pre.chooseType = _newValue;
                    return {...pre};
                  });
                  setError(pre=>{
                    pre.chooseType = "";
                    return {...pre};
                  })
                }}
              />
              <div className={classList.helperText}>{error.chooseType}</div>
            </div> */}
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
              <div className={cssClasses.mapInputWrapper}>
                <div className={cssClasses.fieldBlock}>
                  <div className={classList.textName}>Address <span style={{color: "red"}}> *</span></div>
                  <TextField
                    className={classList.textFieldRoot}
                    id="outlined-basic"
                    value={addExamCenter.address}
                    helperText={error.address}
                    error={error.address !== ""}
                    onChange={(e) => addExamCenterOnchange("address", e)}
                    InputProps={{
                      readOnly: !isEditable,                  
                      className: classList.textField
                    }}
                    FormHelperTextProps={{
                      classes: {
                        root: classList.helperText,
                      },
                    }}
                  />
                </div>
                <div className={cssClasses.fieldBlock}>
                  <div className={classList.textName}>Pin Code <span style={{color: "red"}}> *</span></div>
                  <TextField
                    className={classList.textFieldRoot}
                    id="outlined-basic"
                    value={addExamCenter.pincode}
                    helperText={error.pincode}
                    error={error.pincode !== ""}
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        addExamCenterOnchange("pincode", e)
                      }
                    }}
                    InputProps={{
                      readOnly: !isEditable,
                      className: classList.textField
                    }}
                    FormHelperTextProps={{
                      classes: {
                        root: classList.helperText,
                      },
                    }}
                  />
                </div>
                <div className={cssClasses.fieldBlock}>
                  <div className={classList.textName}>City <span style={{color: "red"}}> *</span></div>
                  <TextField
                    className={classList.textFieldRoot}
                    id="outlined-basic"
                    value={addExamCenter.City}
                    helperText={error.City}
                    error={error.City !== ""}
                    onChange={(e) => addExamCenterOnchange("City", e)}
                    InputProps={{
                      readOnly: !isEditable,                  
                      className: classList.textField
                    }}
                    FormHelperTextProps={{
                      classes: {
                        root: classList.helperText,
                      },
                    }}
                  />
                </div>
                <div className={cssClasses.fieldBlock}>
                  <div className={classList.textName}>State <span style={{color: "red"}}> *</span></div>
                  <TextField
                    className={classList.textFieldRoot}
                    id="outlined-basic"
                    value={addExamCenter.state}
                    helperText={error.state}
                    error={error.state !== ""}
                    onChange={(e) => addExamCenterOnchange("state", e)}
                    InputProps={{
                      readOnly: !isEditable,                  
                      className: classList.textField
                    }}
                    FormHelperTextProps={{
                      classes: {
                        root: classList.helperText,
                      },
                    }}
                  />
                </div>
              </div>
              <div className={cssClasses.mapBox}>
                {/* <div ref={mapEle} style={{...classList.mapWrapper,borderRadius:`${1}px`}} id="map"></div> */}
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "AIzaSyA7oXyOOkbiqr3IAgqdyTRhy_Bd4-0F2nA" }}
                  defaultCenter={{
                    lat: latitude,
                    lng: longitude
                  }}
                  defaultZoom={15}
                >
                  
                </GoogleMapReact>
              </div>
            </Box>
          </div>
        </div>
        <div className={classList.wrapperButton}>
          <div className={classList.Button}>
            <Button
              style={{ color: "#667085", backgroundColor: "#fff" }}
              btnIcon={""}
              btnName={"Cancel"}
              clicked={() => {handleClose(false)}}
            />
          </div>
          {!docID && 
            <div className={classList.Button}>
              <Button
                className={classList.Button}
                style={{ color: "#fff", backgroundColor: "#7F56D9" }}
                btnIcon={""}
                btnName={"Save"}
                clicked={checkAllFields}
                disable={isLoading}
              />
            </div>
          }
          {docID && 
            <div className={classList.Button}>
              <Button
                className={classList.Button}
                style={{ color: "#fff", backgroundColor: "#7F56D9" }}
                btnIcon={""}
                btnName={"Update"}
                clicked={checkAllFields}
                disable={!isEditable || isLoading}
              />
            </div>
          }
          
        </div>
      </Box>
    </Modal>
  );
}

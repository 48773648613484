import React, { useEffect, useState } from "react";

//* Import MUI
import { Box } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

//* Import SubComponent
import Dropdown from "../../Subcomponent/Dropdown";

//* Import css
import cssClasses from "./AdminStatus.module.css";

//* imports services
import { getAdminsFromExam } from "../../services/AdminsService";

function AdminStatus({examDocID, examName}) {

  const [adminList, setAdminList] = useState([]);
  const [adminListData, setAdminListData] = useState([]);

  const [historyLogs, setHistoryLogs] = useState([]);
  const [selectedAdminForHistory, setSelectedAdminForHistory] = useState("");

  useEffect(() => {
    if (examDocID) {
      getAdminsFromExam(examDocID).then(res => {
        let _list = res.docs.map(m => ({...m.data(),id: m.id}));
        setAdminList(res.docs.map(m=>({label: m.data().name, value: m.id})))
        setAdminListData([..._list]);
        console.log(_list);
      });
    }
  }, [examDocID]);

  useEffect(() => {
    if (selectedAdminForHistory!=="") {
      let _logs = adminListData.find(f=>f.id === selectedAdminForHistory)?.dataLogs;
      _logs.reverse();
      setHistoryLogs([..._logs]);
    }
  }, [selectedAdminForHistory])
  
  


  return (
    <Box className={cssClasses.mainTabPanelWrapper}>
      <div className={cssClasses.panelHeader}>
        <label>Select Admin</label>
        <div className={cssClasses.dropDownWrapper}>
          <div style={{minWidth: "20rem"}}>
            <Dropdown 
              placeHolder={"Select Exam"}
              list={adminList} 
              selectedValue={selectedAdminForHistory} 
              onChangeSelect={(_newValue) => {
                setSelectedAdminForHistory(_newValue);
              }}
              marginTop={"0"}
            />
          </div>
          <div style={{marginTop:"0.6rem"}}>
            {/* <Button style={{ color: "#FFFFFF", backgroundColor: "#6941C6" }} btnName={"Generate"} /> */}
          </div>
        </div>
        <Box flex={1} width={"100%"}>
          <div>
            <Timeline>
              {
                historyLogs.map((logItem,index) => (
                  <TimelineItem key={`TimeLine_${index}`}>
                    <TimelineSeparator>
                      {index===0 ? <TimelineDot variant="outlined" color="secondary"/> : <TimelineDot/>}                     
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }} style={{fontSize:"1.5rem"}}> 
                      <p>Action : {logItem.action}</p>
                      <p>Sub-exam : {logItem.subExam}</p>
                      <p>Exam Center : {logItem.examCenter}</p>
                      <p>Date : {logItem.logDate}</p>
                      <p>Time : {logItem.logTime}</p>
                    </TimelineContent>
                  </TimelineItem>
                ))
              }
            </Timeline>
          </div>
        </Box>
      </div>
    </Box>
  );
}

export default AdminStatus;

import React, { useEffect, useRef, useState } from 'react'
import Styles from '../../Components/ExamDetails/ExamForm.module.css'
import BackBtn from '../../assets/images/back-button.svg'
import { Link, useNavigate } from 'react-router-dom'
import ProgressBar from '../../Subcomponent/ProgressBar'
import Button from '../../Subcomponent/Button'
import { makeStyles } from "@material-ui/core/styles"
import ExamForm from '../../Components/ExamDetails/ExamForm'
import Details from '../../Components/ExamDetails/Details'
import ExamSchedule from '../../Components/ExamDetails/ExamSchedule'
import { addExam, addExamSchedule, getExamByName, updateExamDetails } from '../../services/ExamService'
import { errorToast, successToast } from '../../services/Toast'
import { Cities } from "../../DefaultData/Cities"
import { dismissLoader, presentLoader } from '../../services/loaderService'

const useStyles = makeStyles((theme) => ({
    helperText: {
        fontSize: "1.4rem !important",
        margin: "0.2rem 0 0 0 !important"

    },
    ckeditor: {
        "&.ck.ck-editor": {
            fontSize: '1.4rem',
            marginTop: '0.6rem',
        }
    },
    textField: {
        width: "100%",
    },
    input: {
        fontSize: '1.4rem !important',
        marginTop: "0.6rem",
        width: "100%",
        background: '#FFFFFF',
        border: '1px solid #D0D5DD !important',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
        borderRadius: '0.8rem',
        '& input': {
            padding: '1rem 1.4rem !important'
        },
        "&.Mui-focused": {
            // border: "unset",
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D6BBFB !important',
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;"
            }
        },
        "&.MuiSelect-outlined": {
            border: '1px solid #D0D5DD !important'
        },
        "& fieldSet": {
            border: 'none'
        },
        "&:hover": {
            // border:"none",
            '& .MuiOutlinedInput-notchedOutline': {
                // border: '1px solid #D0D5DD !important'
            }
        },
        "& p": {
            fontSize: "1.4rem"
        },
        "&.MuiSelect-select": {
            border: '1px solid #D6BBFB !important',
        }
    },
    datePickerWrapper: {
        width: "100%"
    },
    datePicker: {
        "flex-direction": "row-reverse",
        fontSize: '1.4rem !important',
        "& fieldSet": {
            border: 'none'
        },
        marginTop: "0.6rem",
        width: "100%",
        background: '#FFFFFF',
        border: '1px solid #D0D5DD !important',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
        borderRadius: '0.8rem',
        '& input': {
            width: "100%",
            fontSize: '1.4rem !important',
            fontSize: "1.4rem !important",
            padding: '1rem 1.4rem !important'
        },
        "&.Mui-focused": {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D6BBFB !important',
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;"
            }
        },
        "&:hover": {
            // border:"none",
            '& .MuiOutlinedInput-notchedOutline': {
                // border: '1px solid #D0D5DD !important'
            }
        },
    },
    "calender": {
        "& div": {
            fontSize: "1.4rem !important"
        },
        "& span": {
            fontSize: "1.4rem !important"
        },
        "& button": {
            fontSize: "1.4rem !important",
        },
    }
}))

export default function AddExam() {
    const classes = useStyles();
    const examFormRef = useRef();
    const detailsRef = useRef();
    const examScheduleRef = useRef();

    const radioButtonStyle = {
        ".MuiFormControlLabel-label": {
            fontSize: '1.4rem!important',
            fontFamily: '\'Inter-Medium\''
        },
    };
    const navigate = useNavigate()
    const [examSchedule, setExamSchedule] = useState([]);
    const [examForm, setExamForm] = useState({
        name: "",
        unitName: "",
        examNameForSearch: "",
        description: "",
        applicationOpenDate: "",
        applicationCloseDate: "",
        hallTicketStatus:"pending",
        fee: "",
        category: "",
        image: "",
        examCode: "",
        paymentType: "",
        appliedStudentCount: 0,
        examCenterAddedCount: 0,
        formFields: [
            {
                fieldType: "Single Line Text",
                fieldName: "Full Name",
                isRequired: true,
                isEditable: false,
                id: "1"
            },
            {
                fieldType: "Multiple choice",
                fieldName: "Gender",
                isRequired: true,
                isEditable: false,
                options: ["Male", "Female", "Transgender"],
                id: "2"
            },
            {
                fieldType: "Multiple choice",
                fieldName: "Disability",
                isRequired: true,
                isEditable: false,
                options: ["Yes", "No"],
                id: "3"
            },
            {
                fieldType: "Upload Image",
                fieldName: "Photo",
                isRequired: true,
                isEditable: false,
                id: "4"
            },
            {
                fieldType: "Dropdown",
                fieldName: "City",
                isRequired: true,
                isEditable: true,
                options: Cities,
                id: "5"
            },
        ],
        isStoreThumbImpression: false,
        isStoreIdentityDocument: false,
        examDate: "",
    })
    const [helperText, setHelperText] = useState({
        isNameFilled: "",
        isUnitName: "",
        isOpenDateFilled: "",
        isCloseDateFilled: "",
        isFeeFilled: '',
        isTypeFilled: "",
        isImgUploaded: "",
        isPaymentTypeFilled: "",
        isExamCodeFilled: "",
        isBankNameFilled: "",
        isBankBranchFilled: "",
        isBankAccNoFilled: "",
        isBankAccNameFilled: "",
        isBankIFSCFilled: "",
    })
    const [activeStep, setActiveStep] = useState(0);

    function next() {
        var isFormComplete = true
        if (activeStep === 0) {
            isFormComplete = detailsRef.current.checkField();
            console.log("detailsRef.current.checkField()", isFormComplete);
        } else if (activeStep === 1) {
            //! examForm function
            isFormComplete = !examFormRef.current.checkField().globalFlag;
            console.log(isFormComplete);
        }
        if (isFormComplete) {
            // setExamForm((prevalue) => {
            //     prevalue.name = prevalue.name.trim()
            //     prevalue.examShortName = prevalue.name + new Date().toLocaleString('default', { month: 'short' }) + new Date().getFullYear()
            //     return { ...prevalue }
            // })
            if (activeStep === 1) {
                presentLoader()
                getExamByName(examForm.examShortName).then((resp) => {
                    if (resp.docs.length > 0) {
                        dismissLoader()
                        setActiveStep(0)
                        setHelperText((prevalue) => {
                            prevalue.isNameFilled = "Exam with same name already exists"
                            return { ...prevalue }
                        })
                        errorToast("Exam with same name already exists")
                    } else {
                        addExam(examForm).then((resp) => {
                            setExamForm((prevalue) => {
                                prevalue.id = resp.id
                                return { ...prevalue }
                            })
                            dismissLoader()
                            const count = activeStep + 1
                            setActiveStep(count)
                        })
                    }
                })
            } else if (activeStep == 0) {
                const count = activeStep + 1
                setActiveStep(count)
                console.log('examForm: ', examForm);
            } else {
                const count = activeStep + 1
                setActiveStep(count)
            }
        }
    }

    function back() {
        const count = activeStep - 1
        setActiveStep(count)
    }

    function submitForm() {
        if (examScheduleRef.current.validateSchedule()) {
            if (examSchedule.length > 0) {
                let count = 0
                examSchedule.forEach((element, index) => {
                    presentLoader()
                    addExamSchedule(examForm.id, element).then((resp) => {
                        let arr = []
                        examSchedule.forEach(element => {
                            arr.push(element.examDates.sort(function (a, b) { return new Date(a.date) - new Date(b.date); })[0].date)
                        });
                        setExamForm((prevalue) => { prevalue.examDate = arr.sort(function (a, b) { return new Date(a) - new Date(b); })[0]; return { ...prevalue } })
                        updateExamDetails(examForm, examForm.id).then((resp) => {
                            if (count === examSchedule.length - 1) {
                                dismissLoader()
                                successToast("Exam Added Successfully")
                                navigate("/home/exams")
                            }
                            count++
                        })
                    })
                });
            } else {
                successToast("Exam Added Successfully")
                navigate("/home/exams")
            }
        }
    }

    return (
        <div>
            <div className={Styles.examFormContainer}>
                <div className={Styles.header}>
                    <Link to='/home/exams'>
                        <div className={Styles.arrowBtnWrapper}>
                            <img className={Styles.arrowBtn} src={BackBtn} />
                            <p className={Styles.arrowBtnName}>Back to Dashboard</p>
                        </div>
                    </Link>
                    <div className={Styles.title}>
                        <p>Add exam details</p>
                    </div>
                </div>
                <div className={Styles.formWrapper}>
                    <div className={Styles.progressBar}>
                        <ProgressBar activeStep={activeStep} />
                    </div>
                    {activeStep === 0 &&
                        <Details helperText={helperText} examForm={examForm} setExamForm={setExamForm} setHelperText={setHelperText} ref={detailsRef} />
                    }
                    {activeStep === 1 &&
                        <ExamForm helperText={helperText} examForm={examForm} setExamForm={setExamForm} setHelperText={setHelperText} ref={examFormRef} />
                    }
                    {activeStep === 2 &&
                        <ExamSchedule examSchedule={examSchedule} setExamSchedule={setExamSchedule} applicationCloseDate={examForm.applicationCloseDate} ref={examScheduleRef} />
                    }
                </div>

            </div>
            <div className={activeStep > 0 ? Styles.footerFirst : Styles.footer}>
                {
                    activeStep > 0 &&
                    <div >
                        <Button style={{ color: "#000", backgroundColor: "#fff" }} btnName={"Back"} padding={'1rem 4.75rem'} clicked={back} />
                    </div>
                }
                {activeStep == 2 ?
                    <div >
                        <Button style={{ color: "#fff", backgroundColor: "#7F56D9" }} btnName={"Submit"} padding={'1rem 4.75rem'} clicked={submitForm} />
                    </div>
                    :
                    <div >
                        <Button style={{ color: "#fff", backgroundColor: "#7F56D9" }} btnName={"\xa0Next\xa0"} padding={'1rem 4.75rem'} clicked={next} />
                    </div>
                }
            </div>
        </div>
    )
}

/* eslint-disable no-loop-func */
import { query, orderBy, limit, getFirestore, collection, getDocs, startAfter, where, addDoc, endBefore, limitToLast, getDoc, updateDoc, doc, setDoc } from "firebase/firestore";

import axios from 'axios';
const batchUpdateSize = 1000;
let duplicateArray = [];
let REACT_APP_BUCKET_UPLOAD_API = process.env.REACT_APP_IMG_UPLOAD_URL;

async function getStudents(searchField = "studentFullNameForSearch", lastDoc, studentSearchValue, examDocID, subExamDocID) {

  if (searchField === "studentFullNameForSearch" && studentSearchValue) {
    studentSearchValue = studentSearchValue.toLowerCase();
  }

  console.log({ searchField, lastDoc, studentSearchValue, examDocID, subExamDocID });
  let students = collection(getFirestore(), 'students');

  // if (examDocID && subExamDocID) {
  //   students = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/Applicants`);
  // }

  if (examDocID) {
    students = collection(getFirestore(), `exams/${examDocID}/Applicants`);
  }

  let _query = query(students, orderBy(searchField, "asc"), limit(20));  //* for first time visit and without search
  if (lastDoc && !studentSearchValue) {
    //* for next docs without Search
    _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), limit(11))
  }
  if (studentSearchValue && !lastDoc) {
    //* for search first time without last
    _query = query(students, orderBy(searchField, "asc"), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  if (lastDoc && studentSearchValue) {
    //* for search time with last doc
    _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  let getStudents = getDocs(_query);
  
  return getStudents;
}

function getToken() {
  sessionStorage.removeItem('token');
  return new Promise((resolve, reject) => {
    axios.get(process.env.REACT_APP_NODE_API_URL + 'candidates/getToken').then((resp) => {
      console.log('resp: ', resp);
      sessionStorage.setItem('token', resp.data.token);
      resolve(resp.data.token);
    }).catch((err) => {
      console.log('err: ', err);
      reject(err);
    });
  });
}
async function uploadStudents(studentsJson, examId) {
  return new Promise(async (resolve, reject) => {
  const token = sessionStorage.getItem('token');
  const headers = { 'Authorization': 'Bearer ' + token };
  let arrayLength = studentsJson.length;
  let batchSize = Math.ceil(arrayLength / batchUpdateSize);
  for (let i = 0; i < batchSize; i++) {
    if (i < batchSize - 1) {
      const resp = await addData(studentsJson.slice(i * batchUpdateSize, (i + 1) * batchUpdateSize), headers, false, examId).catch((error) => {
        console.log(error);
        reject(error);
      });
      console.log(resp);
      // resolve(resp)
      const duplicateCandidates = studentsJson.filter((student) => { return resp.duplicateArray.some( f => f.Applicant === student.Applicant) });
      console.log('duplicateCandidates: ', duplicateCandidates);
      duplicateArray = duplicateCandidates;
      console.log('duplicateArray: ', duplicateArray);
    } else {
      // ? last batch
      const resp = await addData(studentsJson.slice(i * batchUpdateSize, arrayLength), headers, true, examId).catch((error) => {
        console.log(error);
        reject(error);
      });
      console.log(resp);
      resolve(resp)
      const duplicateCandidates = studentsJson.filter((student) => { return resp.duplicateArray.some( f => f.Applicant === student.Applicant) });
      console.log('duplicateCandidates: ', duplicateCandidates);
      duplicateArray = duplicateCandidates;
      console.log('duplicateArray: ', duplicateArray);
    }
  }
  });
}

function addData(studentsJson, headers, isLast, examId) {
  return new Promise((resolve, reject) => {
    // ! change url here
    axios.post(process.env.REACT_APP_NODE_API_URL + 'candidates/addCandidates',
      { allCandidates: studentsJson, isLast, examId }, { headers: headers }).then((resp) => {
        if (resp.data.batchFinished) {
          console.log('resp: ', resp);
          resolve(resp.data);
        }
        // if (resp.data.status === 1) {
        //   resolve(resp.data);
        // }
      }).catch((err) => {
        console.log('err: ', err);
        reject({ status: 0, message: err });
      });
  });
}
function getFailedArray() {
  return new Promise((resolve, reject) => {
    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': 'Bearer ' + token };
    axios.get(process.env.REACT_APP_NODE_API_URL + 'candidates/getFailedArray', { headers: headers }).then((resp) => {
      console.log(resp);
      resolve(resp.data);
    }).catch((err) => {
      console.log('err: ', err);
      reject(err);
    });
  });
}
async function replace(examId) {
  const token = sessionStorage.getItem('token');
  const headers = { 'Authorization': 'Bearer ' + token };
  let arrayLength = duplicateArray.length;
  let batchSize = Math.ceil(arrayLength / batchUpdateSize);
  for (let i = 0; i < batchSize; i++) {
    if (i < batchSize - 1) {
      await replaceStudents(duplicateArray.slice(i * batchUpdateSize, (i + 1) * batchUpdateSize), headers, false, examId).catch((error) => {
        console.log(error);
      });
    } else {
      // ? last batch
      await replaceStudents(duplicateArray.slice(i * batchUpdateSize, arrayLength), headers, true, examId).catch((error) => {
        console.log(error);
      });
    }
  }
}
function replaceStudents(studentsArr, headers, isLast, examId) {
  console.log({ allCandidates: studentsArr, isLast, examId: examId });
  return new Promise((resolve, reject) => {
    // axios.post('http://192.168.2.62:7000/candidates/replaceCandidates',
    axios.post(process.env.REACT_APP_NODE_API_URL + 'candidates/replaceCandidates', { allCandidates: studentsArr, isLast, examId: examId }, { headers: headers }).then((resp) => {
      console.log('resp.data: ', resp.data);
      if (resp.data.batchFinished) {
        resolve(resp.data);
      }
    }).catch((err) => {
      reject({ status: 0, message: err });
    });
  });
}

async function getAllStudentsByExam(id) {
  const students = collection(getFirestore(), 'exams/' + id + '/Applicants');
  return await getDocs(students)
}

function uploadStudentPhotos(examId, imageToUpload) {
  return new Promise((resolve, reject) => {
    const students = collection(getFirestore(), 'exams/' + examId + '/Applicants');
    getDocs(students, where('Applicant', '==', imageToUpload.name.split(".")[0])).then(async (resp) => {
      let student;
      resp.docs.forEach((elem) => {
        student = elem.data();
        student.id = elem.id;
      });
      if (student.id) {
        const formData = new FormData();
        const imageName = examId + '_' + student.id + '.' + imageToUpload.name.split(".").pop();
        formData.append("file", imageToUpload, imageName);
        axios.post(REACT_APP_BUCKET_UPLOAD_API, formData, { headers: { "Content-type": "application/json", }, }).then((res) => {
          console.log('res: ', res);
          student.Photo = res.data.url;
          // delete student.Applicant;      // ? delete applicantion id because its uniqueness is not maintained in db;
          const collectionRef = doc(getFirestore(), 'exams/' + examId + '/Applicants', student.id);
          updateDoc(collectionRef, student).then((response) => {
            console.log(`exams/${examId}/Applicants/${student.id} response: `, response);

            //* update student image in exams/${examId}/Applicants/${student.id}
            const hallTicketCollectionRef = doc(getFirestore(), 'exams/' + examId + '/hallticket', student.id);
            updateDoc(hallTicketCollectionRef, {studentPhoto: res.data.url}).then((result) => {
              console.log(`exams/${examId}/hallticket/${student.id} result: `, result);
              resolve(student);
            }).catch(error=>{
              console.log(error);
              reject(student);
            });

          }).catch((err) => {
            console.log('err: ', err);
            reject(student);
          });

        }).catch((err) => {
          console.log('err: ', err);
          reject(student);
        });
      }
    });
  });
}

async function getAllRegisteredStudent() {
  const students = doc(getFirestore(), '/StudentCount', "1");
  return await getDoc(students)
}

async function getStudentByApplicant(examId,Applicant,_file) {
  return new Promise((resolve, reject) => {
    const students = collection(getFirestore(), 'exams/' + examId + '/EligibleStudents');
    getDocs(query(students, where('Applicant', '==', Applicant))).then(res=>{
      if (res.empty) {
        resolve({ status: "error" ,msg:Applicant+" Applicant not found in database.",file:_file})
      }else{
        let obj = {id:res.docs[0].id, ...res.docs[0].data()};
        // console.log(obj);
        resolve({status: "success", data : obj,file:_file});
      }
    }).catch(err=>{
      // reject(err);
      resolve({ status: "error" ,msg:Applicant+" "+err.toString(),file:_file})
    });
  })
}

async function updateStudentImage(examId,studentUID,imgUrl) {
  console.log({examId,studentUID,imgUrl});
  return new Promise((resolve, reject) => {
  const collectionRef = doc(getFirestore(), 'exams/' + examId + '/EligibleStudents', studentUID);
  updateDoc(collectionRef, {Photo:imgUrl}).then((response) => {
    console.log(`exams/${examId}/EligibleStudents/${studentUID} response: `, response);

    //* update student image in exams/${examId}/EligibleStudents/${studentUID}
    const hallTicketCollectionRef = doc(getFirestore(), 'exams/' + examId + '/hallticket', studentUID);
    updateDoc(hallTicketCollectionRef, {studentPhoto: imgUrl}).then((result) => {
      console.log(`exams/${examId}/hallticket/${studentUID} result: `, result);
     // resolve(studentUID);
     resolve({ status:"success", Applicant:studentUID});
    }).catch(error=>{
      console.log(error);
      // resolve(studentUID);
      resolve({ status:"error", Applicant:studentUID});
    });

  }).catch((err) => {
    console.log('err: ', err);
    resolve({ status:"error", Applicant:studentUID});
  });
})
}

async function getStudentByDocId(examDocID, studentDocID) {
  let studentsRef = doc(getFirestore(), `exams/${examDocID}/EligibleStudents`,studentDocID);
  return await getDoc(studentsRef);
}

async function setStudentInStudentsCollection(studentDocID, postBody) {
  let studentsRef = doc(getFirestore(), `students`, studentDocID);
  return await setDoc(studentsRef, postBody);
}

export { getStudents, getToken, uploadStudents, getFailedArray, replace, getAllStudentsByExam, uploadStudentPhotos, getAllRegisteredStudent, getStudentByApplicant, updateStudentImage, getStudentByDocId, setStudentInStudentsCollection }











// getDocs(collectionGroup(getFirestore(), "hallticket")).then(res=>{
//   console.log("res.size => ",res.size);
//   let list = res.docs.filter(f=>f.data()["Full Name"])
//   console.log("list.length => ",list.length);
//   list.forEach(ele=>{
//     let body = { studentFullNameForSearch : ele.data()["Full Name"] }
//     let arr = ele.ref.path.split("/");
//     arr.pop()
//     let path = arr.join("/");
    
//     updateDoc(doc(getFirestore(),path,ele.id),body).then(console.log).catch(console.error)
//   })
// })



export const Cities = [
    "Bangalore",
    "Hubli-Dharwad",
    "Mysore",
    "Gulbarga",
    "Mangalore",
    "Belgaum",
    "Davanagere",
    "Bellary",
    "Bijapur",
    "Shimoga",
    "Tumkur",
    "Raichur",
    "Bidar",
    "Hospet",
    "Gadag-Betageri",
    "Robertsonpet",
    "Hassan",
    "Bhadravati",
    "Chitradurga",
    "Udupi",
    "Kolar",
    "Mandya",
    "Chikmagalur",
    "Gangavati",
    "Bagalkot",
    "Ranebennuru"
]
import React, { useEffect, useRef, useState } from 'react'

//* import MUI
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@mui/material/TextField";
import { CircularProgress, List, ListItem, Tooltip, Typography } from "@mui/material";

//* import sub component
import Button from "../../Subcomponent/Button";
import Dropdown from "../../Subcomponent/Dropdown"

//* import Images
import shuffleIcon from "../../assets/images/shuffle.svg";
import crossImage from "../../assets/images/cross.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

//* import Css
import cssClasses from "./ExamAccessAddFromExcelModal.module.css";

//* imports services
import { AddDocExamCenter, getAllExamCenters, getAllExamCentersByName, getAllExamCentersInExam } from '../../services/ExamCenterService';
import uploadFileCloud from "../../assets/images/uploadFileCloud.svg";
import { dismissLoader, presentLoader } from '../../services/loaderService';
import { errorToast } from '../../services/Toast';
import * as XLSX from "xlsx";
import { getMeritStudentByApplicantId } from '../../services/Merit';
import { DataGrid } from '@mui/x-data-grid';
import ProgressBarDataUploadForExamCenter from '../ProgressBarDataUploadForExamCenter/ProgressBarDataUploadForExamCenter';
import { getAllExams, getAllExamsWithoutLimit, getExamScheduleById } from '../../services/ExamService';
import { getAllAdmins, updateAdmin } from '../../services/AdminsService';
import { groupBy } from 'lodash';


export default function ExamAccessAddFromExcelModal({ open, handleClose }) {

    const [examOriginalList, setExamOriginalList] = useState([]);
    const [adminOriginalList, setAdminOriginalList] = useState([]);

    const [failedEntiresModalToggle, setFailedEntiresModalToggle] = useState(false);
    const [invalidExcelStudent, setInvalidExcelStudent] = useState([]);

    const [reflector, setReflector] = useState(Date.now());

    const totalExamCenterCount = useRef(0);
    const addedAdminCount = useRef(0);
    const failedExamCenterArray = useRef([]);

    useEffect(() => {
        if (!examOriginalList.length) {
            // getExamCentersFromServer();
            getAdminsFromServer();
            getExamAndSubExamFromServer();
        }
    }, [])

    // async function getExamCentersFromServer() {
    //     getAllExamCenters().then(res => {
    //         let _list = res.docs.map(m => ({ docId: m.id, ...m.data() }));
    //         console.log("Exam Center => ", _list);
    //         setExamCenterOriginalList(_list);
    //     })
    // }

    async function getAdminsFromServer() {
        getAllAdmins().then(async res => {
            let _list = res.docs.map(m => ({ docId: m.id, ...m.data() }));
            console.log("getAllAdmins => ", _list);
            setAdminOriginalList(_list);
        })
    }

    async function getExamAndSubExamFromServer() {
        getAllExamsWithoutLimit().then(async res => {
            let _list = res.docs.map(m => ({ docId: m.id, ...m.data() }));

            for (const _examItem of _list) {
                const getExamScheduleByIdResponse = await getExamScheduleById(_examItem?.docId);
                if (getExamScheduleByIdResponse?.empty === false) {
                    let _sub_list = getExamScheduleByIdResponse.docs.map(m => ({ docId: m.id, ...m.data() }));
                    _examItem.subExam = _sub_list;
                }
            }
            
            for (const _examItem of _list) {
                const getAllExamCentersInExamResponse = await getAllExamCentersInExam(_examItem?.docId);
                if (getAllExamCentersInExamResponse?.empty === false) {
                    let _center_list = getAllExamCentersInExamResponse.docs.map(m => ({ docId: m.id, ...m.data() }));
                    _examItem.examCenter = _center_list;
                }
            }

            console.log("setExamOriginalList => ", _list);
            setExamOriginalList(_list);
        })
    }

    function goToSelectFile() {
        if (!examOriginalList.length || !adminOriginalList?.length) {
            return true;
        }


        presentLoader();
        const domEle = document.createElement("input");
        domEle.type = "file";
        domEle.multiple = true;
        domEle.accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
        domEle.click();
        domEle.onchange = (e) => {
            const file = e?.target.files[0];
            if (!file.name.includes(".xlsx")) {
                errorToast("Please select only Excel format file.");
                dismissLoader();
            } else {
                mergeExcels(file);
            }
        }
    }

    async function mergeExcels(fileItem) {
        try {
            const readExcelData = await readExcel(fileItem);
            // console.log("readExcelData => ", readExcelData);

            if (readExcelData?.length) {
                const validateExcelFlag = validateExcel(readExcelData);
                if (validateExcelFlag) {
                    goToAddAdminsToServer(readExcelData);
                } else {
                    setFailedEntiresModalToggle(true);
                    errorToast("Invalid data in excel sheet. Fix it and try again!");
                }
                dismissLoader();
            } else {
                const _err = "Exam Centers not found in excel.";
                throw _err;
            }
        } catch (error) {
            console.error("Err => ", error);
            errorToast(error);
            dismissLoader();
        }
    }

    function validateExcel(_readExcelData) {
        let validateExcelFlag = true;
        let invalidExcelArr = [];

        for (let i = 0; i < _readExcelData.length; i++) {
            const element = _readExcelData[i];
            let errorArr = [];

            if (!element?.email) {
                validateExcelFlag = false;
                errorArr.push(`Please enter email.`);
            } else if (!adminOriginalList.some(f => f?.email === element?.email)) {
                validateExcelFlag = false;
                errorArr.push(`That Admin not found in database.`);
            }

            const examObject = examOriginalList.find(f => f?.name === element?.exam);

            if (!element?.exam) {
                validateExcelFlag = false;
                errorArr.push(`Please enter exam.`);
            } else if (!examObject) {
                validateExcelFlag = false;
                errorArr.push(`That Exam not found in database.`);
            }
            
            if (element?.subexam) {
                let _subExam = examObject?.subExam?.find(f => f?.examName === element?.subexam);
                if (!_subExam) {
                    validateExcelFlag = false;
                    errorArr.push(`Sub-Exam not found in database.`);
                }
            }
            
            if (element?.center) {
                let _examCenter = examObject?.examCenter?.find(f => f?.examCenterName === element?.center);
                if (!_examCenter) {
                    validateExcelFlag = false;
                    errorArr.push(`Exam center not found in database.`);
                }
            }

            // if (!element?.examCenterCode) {
            //     validateExcelFlag = false;
            //     errorArr.push(`Please enter examCenterCode.`);
            // }


            if (errorArr.length > 0) {
                invalidExcelArr.push({
                    ...element,
                    row: i + 1,
                    errorArr
                })
            }

        }

        setInvalidExcelStudent([...invalidExcelArr]);
        return validateExcelFlag;
    }

    async function goToAddAdminsToServer(_list = []) {

        const groupByAdminName = groupBy(_list, (_i) => _i.email);
        const _structuredList = Object.keys(groupByAdminName).map((adminKey) => {
            let _obj = {
                email: adminKey,
                adminUid: adminOriginalList.find(f => f?.email === adminKey)?.docId
            }

            const groupByExamName = groupBy(groupByAdminName[adminKey], (_i) => _i.exam);
            _obj.examAccess = Object.keys(groupByExamName).map((examKey) => {
                const serverExamObject = examOriginalList.find(f => f?.name === examKey);
                let _examObj = {
                    label: examKey,
                    value: serverExamObject?.docId,
                    subExamList: groupByExamName[examKey]?.filter(f => f?.subexam)?.map((_subExamExcelObj) => ({
                        label: _subExamExcelObj?.subexam,
                        value: serverExamObject?.subExam?.find(f => f.examName === _subExamExcelObj?.subexam)?.docId,
                        isAccess: true
                    })),
                    examCenterList: groupByExamName[examKey]?.filter(f => f?.center)?.map((_subExamExcelObj) => ({
                        label: _subExamExcelObj?.center,
                        value: serverExamObject?.examCenter?.find(f => f.examCenterName === _subExamExcelObj?.center)?.docId,
                        isAccess: true
                    }))
                }

                return _examObj;
            })

            return _obj;
        });
        console.log("_structuredList -> ", _structuredList);

        totalExamCenterCount.current = _structuredList.length;
        setReflector(Date.now());
        for (const adminAccessItem of _structuredList) {
            const postBody = {
                examAccess: adminAccessItem?.examAccess
            };
            try {
                const updateAdminResponse = await updateAdmin(adminAccessItem?.adminUid, postBody);
                addedAdminCount.current = addedAdminCount.current + 1;
                setReflector(Date.now());
            } catch (error) {
                console.error("error => ", error);
                const _err = error?.message || error?.toString() || error;
                adminAccessItem.errorArr = [_err];
                failedExamCenterArray.current.push(adminAccessItem);
                setReflector(Date.now());
            }
        }
    }

    function readExcel(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {

                var data = e.target.result;
                data = new Uint8Array(data);
                var workbook = XLSX.read(data, {
                    type: "array",
                    cellText: false,
                    cellDates: true,
                });
                var roa;
                workbook.SheetNames.forEach(function (sheetName) {
                    roa = XLSX.utils.sheet_to_row_object_array(
                        workbook.Sheets[sheetName],
                        { raw: false, dateNF: "yyyy-mm-dd" }
                    );
                });
                resolve(roa);
            };
            reader.readAsArrayBuffer(file);
        })
    }

    return (
        <>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={[
                    {
                        ".MuiBackdrop-root": {
                            background: "rgba(193, 201, 210, 0.7)"
                        }
                    }
                ]}
                disableAutoFocus={true}
            >
                <Box className={cssClasses.modalWrapper}>
                    <div className={cssClasses.header}>
                        {/* <img src={shuffleIcon} alt="" /> */}
                        <h1>Upload Exam Access Excel Sheet</h1>
                        {(totalExamCenterCount.current === 0 || Boolean((addedAdminCount.current + failedExamCenterArray.current.length) === totalExamCenterCount.current)) &&
                            <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={handleClose} />
                        }
                    </div>

                    <div
                        className={cssClasses.mainContainer}
                        style={{
                            display: 'flex',
                            alignItems: totalExamCenterCount.current === 0 ? 'center' : "unset",
                            justifyContent: 'center'
                        }}
                    >
                        {totalExamCenterCount.current === 0 ?
                            <div className={cssClasses.inputContainer}>
                                <img src={uploadFileCloud} alt="" style={{ cursor: "pointer" }} onClick={() => { goToSelectFile() }} />
                                <div style={{ marginTop: "2rem" }}>
                                    <Button
                                        style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
                                        // btnIcon={uploadIcon}
                                        btnName={"Download\xa0User\xa0Access\xa0Excel\xa0Template"}
                                        clicked={() => {
                                            const worksheet = XLSX.utils.json_to_sheet([{
                                                email: null,
                                                exam: null,
                                                subexam: null,
                                                center: null
                                            }]);
                                            const workBook = new XLSX.utils.book_new();
                                            XLSX.utils.book_append_sheet(workBook, worksheet, `Users`);
                                            XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
                                            XLSX.writeFile(workBook, `User access template.xlsx`);
                                        }}
                                    // disable={!loggedInUser.tabAccess.examCenter.some(s => s === 'add')}
                                    />
                                </div>
                            </div>
                            :
                            <Box flex={1} display={"flex"} flexDirection={"column"}>
                                <Box display={"flex"} flexDirection={"row"} gap={"2rem"} alignItems={"start"}>
                                    {Boolean((addedAdminCount.current + failedExamCenterArray.current.length) === totalExamCenterCount.current) ?
                                        <CheckCircleIcon sx={{ color: "#7F56D9", width: "30px", height: "30px" }} />
                                        :
                                        <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
                                    }
                                    <Box>
                                        <Typography style={TextStyle}>Uploading Data to Database</Typography>
                                        <Box display={"flex"} flexDirection={"column"} gap={"2rem"} mt={"3rem"}>
                                            <div style={numberStyle}>
                                                <div>Total Entries: {totalExamCenterCount.current}</div>
                                            </div>
                                            <div style={numberStyle}>
                                                <div>Updating Entries: {addedAdminCount.current}</div>
                                            </div>
                                            <div style={numberStyle}>
                                                <div>Failed Entries: {failedExamCenterArray.current.length}</div>
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </div>

                    <div className={cssClasses.footer}>
                        {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
                        <div>
                            <Button
                                style={{ color: "#344054", backgroundColor: "#ffffff" }}
                                btnName={"Cancel"}
                                clicked={() => handleClose(null)}
                                disable={
                                    totalExamCenterCount.current === 0 ? false :
                                        !Boolean((addedAdminCount.current + failedExamCenterArray.current.length) === totalExamCenterCount.current)
                                }
                            />
                        </div>
                        {(totalExamCenterCount.current > 0) &&
                            <>
                                {Boolean((addedAdminCount.current + failedExamCenterArray.current.length) === totalExamCenterCount.current) &&
                                    <div>
                                        <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Done"} clicked={() => handleClose()} />
                                    </div>
                                }
                                {Boolean(((addedAdminCount.current + failedExamCenterArray.current.length) === totalExamCenterCount.current) && failedExamCenterArray.current.length > 0) &&
                                    <div>
                                        <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"View Failed Entries"}
                                            clicked={() => {
                                                setFailedEntiresModalToggle(true);
                                                setInvalidExcelStudent(failedExamCenterArray.current);
                                            }}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Box>
            </Modal>

            <FailedEntires
                openModal={Boolean(invalidExcelStudent.length)}
                closeModal={() => {
                    handleClose();
                    setFailedEntiresModalToggle(false);
                }}
                failedCandidatesArray={invalidExcelStudent}
            />
        </>
    )
}

function FailedEntires({ openModal, closeModal, failedCandidatesArray }) {
    const columns = [
        { field: 'row', headerName: 'Row In Excel', width: 150 },
        { field: 'email', headerName: 'Admin Email', width: 250 },
        { field: 'exam', headerName: 'Exam Name', width: 200 },
        { field: 'subexam', headerName: 'Sub-Exam Name', width: 200 },
        { field: 'center', headerName: 'Exam Center Name', width: 200 },
        {
            field: 'errorArr', headerName: 'Reason', width: 300,
            renderCell: (params) => {
                // console.log(Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).length);
                // console.log(params.value);
                return (<>
                    <Tooltip
                        followCursor
                        arrow
                        title={
                            <List style={{ fontSize: "1.2rem", textTransform: 'capitalize' }}>
                                {
                                    params.value?.map((m, index) => (
                                        <ListItem key={`listItem_${m}_${index + 1}`} disablePadding>{m}</ListItem>
                                    ))
                                }
                            </List>
                        }
                    >
                        <Typography whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} fontSize={"1.4rem"}>{Array.isArray(params.value) ? params.value.join(" ") : params.value}</Typography>
                    </Tooltip>
                </>)
            }
        }
    ];
    return (
        <Modal open={openModal} aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={cssClasses.modalWrapper} width={"min(108rem, 90%)"}>
                <Box className={cssClasses.wrapperContent} display={"flex"} flexDirection={"column"} flex={1}>
                    {/* <div className={cssClasses.heading}>Failed Upload Candidates List</div> */}
                    <div className={cssClasses.wrapperTable} style={{ flex: 1 }}>
                        <DataGrid
                            rows={failedCandidatesArray.map((m, i) => ({ ...m, id: i }))}
                            columns={columns}
                            pageSize={10}
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSelector
                            disableDensitySelector
                            disableSelectionOnClick
                            disableVirtualization
                            sx={[dataGridStyles]}
                            rowsPerPageOptions={[10]}
                        />

                    </div>
                    <div className={cssClasses.footer}>
                        <div className={cssClasses.ButtonWrapper}>
                            <Button
                                style={{
                                    color: "#ffffff",
                                    backgroundColor: "#6941c6",
                                }}
                                btnName={"OK"}
                                btnIcon={false}
                                clicked={closeModal}
                            />
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal>
    );
}
const dataGridStyles = {
    fontWeight: "400",
    fontSize: "1.4rem",
    lineHeight: "2rem",
    color: "#667085",
    fontFamily: "Inter-Medium",
    border: "none !important",
    '& div[data-colindex="1"]': {
        color: "#101828",
        fontWeight: "500",
    },
    '& div[data-value-colindex="7"]': {
        color: "#fff",
        fontWeight: "500",
    },
    "&  .MuiDataGrid-columnHeaders": {
        background: "#F9FAFB",
    },
    "& .MuiDataGrid-columnSeparator": {
        display: "none",
    },
    "& .MuiDataGrid-columnHeader": {
        padding: "0 4rem",

        "&:focus": {
            outline: "none",
        },
    },
    "&  .MuiDataGrid-row ": {
        //   maxHeight: "72px !important",
        //   minHeight: "72px !important",
    },
    "& .MuiDataGrid-cell": {
        padding: "0 4rem",
        //   minHeight: "72px !important",
        //   maxHeight: "72px !important",
        "&:focus": {
            outline: "none",
        },
    },
    "& .MuiButtonBase-root": {
        fontSize: "2rem",
    },
    "& .MuiSvgIcon-root": {
        fontSize: "2rem",
    },
    "& .MuiDataGrid-footerContainer": {
        "& .MuiTablePagination-displayedRows ": {
            display: "none",
        },
        "& .MuiDataGrid-selectedRowCount": {
            visibility: "hidden ",
        },
    },
    "& .MuiPaginationRoot": {
        "& .MuiPagination-ul": {
            flexWrap: "nowrap",
            li: {
                "&:first-of-type": {
                    flexBasis: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    "> button::after": {
                        marginLeft: "10px",
                        content: "'previous'",
                    },
                },
                "&:last-of-type": {
                    flexBasis: "100%",
                    display: "flex",
                    justifyCcontent: "flex-end",
                    alignItems: "center",
                    "> button::before": {
                        marginRight: "10px",
                        content: "'next'",
                    },
                },
            },
        },
    },
};

const TextStyle = {
    fontFamily: "Inter-Regular",
    fontSize: "1.5rem",
    fontWeight: "800",
    lineHeight: "2.4rem",
    color: "#101828",
}
const numberStyle = {
    fontFamily: "Inter-Regular",
    fontSize: "1.4rem",
    fontWeight: "100",
    lineHeight: "2rem",
    color: "#667085",
}
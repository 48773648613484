/* eslint-disable import/no-anonymous-default-export */
import * as actionTypes from "../actionType/action-type";

// * For Auth ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const authLogin = (payload) => {
  console.log(payload);
  return {
    type: actionTypes.AUTH_LOGIN,
    payload,
  };
};

const authCheck = () => {
  return {
    type: actionTypes.AUTH_CHECK,
  };
};
const authLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};


// * For Loading ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const isLoading = (payload) => {
  return {
    type: actionTypes.IS_LOADING,
    payload,
  };
};


// * For Header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const activeMenu = (payload) => {
  return {
    type: actionTypes.ACTIVE_MENU,
    payload,
  };
};

export default {
  authLogin,
  authLogout,
  authCheck,
  isLoading,
  activeMenu
};

import React, { useEffect, useState } from 'react'

//* import MUI
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@mui/material/TextField";
import { List, ListItem, Tooltip, Typography } from "@mui/material";

//* import sub component
import Button from "../../Subcomponent/Button";
import Dropdown from "../../Subcomponent/Dropdown"

//* import Images
import shuffleIcon from "../../assets/images/shuffle.svg";
import crossImage from "../../assets/images/cross.svg";

//* import Css
import cssClasses from "./SelectExcelForDownloadStudentExcelSheet.module.css";

//* imports services
import { getAllExamCentersInExam } from '../../services/ExamCenterService';
import uploadFileCloud from "../../assets/images/uploadFileCloud.svg";
import { dismissLoader, presentLoader } from '../../services/loaderService';
import { errorToast } from '../../services/Toast';
import * as XLSX from "xlsx";
import { getMeritStudentByApplicantId } from '../../services/Merit';
import { DataGrid } from '@mui/x-data-grid';
import { chain } from 'lodash';


export default function SelectExcelForDownloadStudentExcelSheet({ open, handleClose, examDocID = null, subExamDocID = null, subExamName }) {

  const [examCenterOriginalList, setExamCenterOriginalList] = useState([]);

  const [failedEntiresModalToggle, setFailedEntiresModalToggle] = useState(false);
  const [invalidExcelStudent, setInvalidExcelStudent] = useState([]);

  useEffect(() => {
    if (examDocID) {
      getAllExamCentersInExam(examDocID).then(res => {
        let _list = res.docs.map(m => ({ docId: m.id, ...m.data() }));
        console.log(_list);
        setExamCenterOriginalList(_list);
      })
    }
  }, [examDocID])

  function goToSelectFile() {
    presentLoader();
    const domEle = document.createElement("input");
    domEle.type = "file";
    domEle.multiple = true;
    domEle.accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    domEle.click();
    domEle.onchange = (e) => {
      const file = e?.target.files[0];
      if (!file.name.includes(".xlsx")) {
        errorToast("Please select only Excel format file.");
        dismissLoader();
      } else {
        mergeExcels(file);
      }
    }
  }

  async function mergeExcels(fileItem) {
    try {
      const readExcelData = await readExcel(fileItem);
      console.log("readExcelData => ", readExcelData);

      if (readExcelData?.length) {
        const validateExcelFlag = validateExcel(readExcelData);
        if (validateExcelFlag) {
          let createBatchGetStudentResponse = await createBatchGetStudent(readExcelData.map(m => m?.Applicant));
          console.log("createBatchGetStudentResponse => ", createBatchGetStudentResponse);

          createBatchGetStudentResponse = createBatchGetStudentResponse.map(m => ({
            UID: m.id, ...m.data(),
            examCenterName: readExcelData?.find(f => f?.Applicant === m.data()?.Applicant)?.examCenterName || "",
            City: readExcelData?.find(f => f?.Applicant === m.data()?.Applicant)?.District || ""
          }))

          let groupByData = chain(createBatchGetStudentResponse).groupBy("Applicant").map((value, key) => ({ Applicant: key, arr: value })).value();
          console.log("groupByData => ", groupByData);

          let removeDuplicatesApplicant = [];
          groupByData.forEach((element) => {
            // console.log(element)
            if (element?.arr?.length === 1) {
              removeDuplicatesApplicant.push(element?.arr[0]);
            } else if (element?.arr?.length > 1) {
              let _obj = element?.arr?.find(f => f.isVerified === true);
              if (_obj) {
                removeDuplicatesApplicant.push(_obj);
              } else {
                let adminAdded = element?.arr?.find(f => !f?.addedFromEXE);
                if (adminAdded) {
                  removeDuplicatesApplicant.push(adminAdded);
                } else {
                  let getOneAddedFromExe = element?.arr?.find(f => f?.addedFromEXE);
                  if (getOneAddedFromExe) {
                    removeDuplicatesApplicant.push(getOneAddedFromExe);
                  } else {
                    console.log("Exception Case => ", element);
                  }
                }
              }
            }
          })
          console.log("removeDuplicatesApplicant => ", removeDuplicatesApplicant);

          await downloadStudentsExcelByDocsData(0, removeDuplicatesApplicant);
          handleClose();
        } else {
          setFailedEntiresModalToggle(true);
          errorToast("Invalid data in excel sheet. Fix it and try again!");
        }
        dismissLoader();
      } else {
        const _err = "Applicant's not found in excel.";
        throw _err;
      }
    } catch (error) {
      console.error("Err => ", error);
      errorToast(error);
      dismissLoader();
    }
  }

  function validateExcel(_readExcelData) {
    let validateExcelFlag = true;
    let invalidExcelArr = [];

    for (let i = 0; i < _readExcelData.length; i++) {
      const element = _readExcelData[i];
      let errorArr = [];
      if (!element?.Applicant) {
        validateExcelFlag = false;
        errorArr.push(`Please enter Applicant.`);
      }

      if (!element?.District) {
        validateExcelFlag = false;
        errorArr.push(`Please enter District.`);
      }

      if (!element?.examCenterName) {
        validateExcelFlag = false;
        errorArr.push(`Please enter examCenterName.`);
      } else if (!examCenterOriginalList.some(f => f?.examCenterName === element?.examCenterName)) {
        validateExcelFlag = false;
        errorArr.push(`ExamCenterName not found in Exam.`);
      }

      invalidExcelArr.push({
        ...element,
        row: i + 1,
        errorArr
      })
    }

    setInvalidExcelStudent([...invalidExcelArr]);
    return validateExcelFlag;
  }

  async function createBatchGetStudent(applicantArray) {
    let resultArr = [];
    return await new Promise(async (resolve, reject) => {
      for (let item of applicantArray) {
        try {
          let reap = await getMeritStudentByApplicantId(examDocID, subExamDocID, item);
          if (!reap.empty) {
            console.log("Reap => ", reap.docs[0]);
            // resultArr.push(reap.docs[0]);
            resultArr.push(...reap.docs);
          }
        } catch (error) {
          console.error("SS => ", error);
          throw error;
        }
      }
      resolve(resultArr);
    })
  }

  function readExcel(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {

        var data = e.target.result;
        data = new Uint8Array(data);
        var workbook = XLSX.read(data, {
          type: "array",
          cellText: false,
          cellDates: true,
        });
        var roa;
        workbook.SheetNames.forEach(function (sheetName) {
          roa = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
            { raw: false, dateNF: "yyyy-mm-dd" }
          );
        });
        resolve(roa);
      };
      reader.readAsArrayBuffer(file);
    })
  }

  async function downloadStudentsExcelByDocsData(paginationCount, _list = []) {
    // let _list = responseDocs.docs.map(m => ({ UID: m.id, ...m.data() }));

    if (_list?.length === 0) {
      errorToast("Student data not found.");
      dismissLoader();
      return;
    }

    let keyObject = {};
    _list.forEach((ele) => {
      Object.assign(keyObject, ele)
    })

    let _studentList = [];
    _list.forEach((element) => {
      let obj = {};

      Object.keys(keyObject).forEach((ele) => {
        obj[ele] = element[ele]
      });

      delete obj.ET;
      delete obj.ET_IsQualified;
      delete obj.ET_height_height;
      delete obj.ET_height_remarks;
      delete obj.ET_height_result;
      delete obj.PST_IsQualified;
      delete obj.PST;
      delete obj.chest_no;
      delete obj.dataURL;
      delete obj.examType;
      delete obj.idCardBase64;
      delete obj.idCardImage;
      delete obj.invoice;
      delete obj.lastUpdateAt;
      delete obj.name;
      delete obj.realTimeBase64;
      delete obj.realTimePhoto;
      delete obj.selectedID_Card;
      delete obj.showInGrid;
      delete obj.uploadedForm;
      delete obj.checkingOfficer;
      delete obj.checkingOfficerDesignation;
      delete obj.isSubmitted;
      delete obj.attendanceDateTime;
      delete obj.isWildCardEntry;
      obj.Status = "pending";
      obj.isVerified = false;

      let currentSubExamHistory = {
        subExamName: subExamName,
        fingerprintImage1: element?.fingerprintImage1 ? element.fingerprintImage1 : "",
        fingerprintImage2: element?.fingerprintImage2 ? element.fingerprintImage2 : "",
        realTimePhoto: element?.realTimePhoto ? element.realTimePhoto : "",
        idCardImage: element?.idCardImage ? element.idCardImage : "",
        attendanceDateTime: element?.attendanceDateTime ? element.attendanceDateTime : ""
      }
      if (obj.hasOwnProperty("previousSubExamHistory") && Array.isArray(obj.previousSubExamHistory)) {
        obj.previousSubExamHistory.push(currentSubExamHistory);
      } else {
        obj.previousSubExamHistory = [currentSubExamHistory];
      }

      obj.previousSubExamHistory = JSON.stringify(obj.previousSubExamHistory);

      _studentList.push(obj);
    });

    _studentList = createChunkArrayCustomIndex(_studentList, 100000);

    let index = 1;
    for (const _studentListItemArray of _studentList) {
      const worksheet = XLSX.utils.json_to_sheet(_studentListItemArray);
      const workBook = new XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, worksheet, `Student List`);
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.writeFile(workBook, `${subExamName} Merged Students List ${index}.xlsx`);
      index++;
    }
  }


  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={[
          {
            ".MuiBackdrop-root": {
              background: "rgba(193, 201, 210, 0.7)"
            }
          }
        ]}
        disableAutoFocus={true}
      >
        <Box className={cssClasses.modalWrapper}>
          <div className={cssClasses.header}>
            {/* <img src={shuffleIcon} alt="" /> */}
            <h1>Upload Excel Sheet</h1>
            <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>

          <div
            className={cssClasses.mainContainer}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div className={cssClasses.inputContainer}>
              <img src={uploadFileCloud} alt="" onClick={() => { goToSelectFile() }} />
            </div>
          </div>

          <div className={cssClasses.footer}>
            {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
            <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Cancel"} clicked={() => handleClose(null)} /></div>
            {/* <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Done"} disable={!Boolean(selectedFromExamCenter)} clicked={() => handleClose(examCenterOriginalList.find(f => f.docId === selectedFromExamCenter))} /></div> */}
          </div>
        </Box>
      </Modal>

      <FailedEntires
        openModal={Boolean(invalidExcelStudent.length)}
        closeModal={() => {
          handleClose();
          setFailedEntiresModalToggle(false);
        }}
        failedCandidatesArray={invalidExcelStudent}
      />
    </>
  )
}

function createChunkArrayCustomIndex(arr, _index) {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / _index)
    // const chunkIndex = Math.floor(index/20)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

function FailedEntires({ openModal, closeModal, failedCandidatesArray }) {
  const [failedCandidatesUpload, setFailedCandidateUpload] = useState([]);
  const columns = [
    { field: 'row', headerName: 'Row In Excel', width: 200 },
    { field: 'Applicant', headerName: 'Applicant', width: 150 },
    {
      field: 'errorArr', headerName: 'Reason', width: 300,
      renderCell: (params) => {
        // console.log(Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).length);
        // console.log(params.value);
        return (<>
          <Tooltip
            followCursor
            arrow
            title={
              <List style={{ fontSize: "1.2rem", textTransform: 'capitalize' }}>
                {
                  params.value?.map((m, index) => (
                    <ListItem key={`listItem_${m}_${index + 1}`} disablePadding>{m}</ListItem>
                  ))
                }
              </List>
            }
          >
            <Typography fontSize={"1.4rem"}>{params.value}</Typography>
          </Tooltip>
        </>)
      }
    }
  ];
  useEffect(() => {

    let failedCandidatesUpload = failedCandidatesArray.map((m, index) => ({
      ...m,
      id: m.Applicant, // ? applicant id here
    }));

    setFailedCandidateUpload(failedCandidatesUpload);
  }, [failedCandidatesArray]);
  return (
    <Modal open={openModal} aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={cssClasses.modalWrapper}>
        <Box className={cssClasses.wrapperContent} display={"flex"} flexDirection={"column"} flex={1}>
          {/* <div className={cssClasses.heading}>Failed Upload Candidates List</div> */}
          <div className={cssClasses.wrapperTable} style={{ flex: 1 }}>
            <DataGrid
              rows={failedCandidatesUpload.map((m, i) => ({ ...m, id: i }))}
              columns={columns}
              pageSize={10}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              disableVirtualization
              sx={[dataGridStyles]}
            />

          </div>
          <div className={cssClasses.footer}>
            <div className={cssClasses.ButtonWrapper}>
              <Button
                style={{
                  color: "#ffffff",
                  backgroundColor: "#6941c6",
                }}
                btnName={"OK"}
                btnIcon={false}
                clicked={closeModal}
              />
            </div>
          </div>
        </Box>
      </Box>
    </Modal>
  );
}
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: "Inter-Medium",
  border: "none !important",
  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& div[data-value-colindex="7"]': {
    color: "#fff",
    fontWeight: "500",
  },
  "&  .MuiDataGrid-columnHeaders": {
    background: "#F9FAFB",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    padding: "0 4rem",

    "&:focus": {
      outline: "none",
    },
  },
  "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiButtonBase-root": {
    fontSize: "2rem",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
  },
  "& .MuiDataGrid-footerContainer": {
    "& .MuiTablePagination-displayedRows ": {
      display: "none",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden ",
    },
  },
  "& .MuiPaginationRoot": {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
      li: {
        "&:first-of-type": {
          flexBasis: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          "> button::after": {
            marginLeft: "10px",
            content: "'previous'",
          },
        },
        "&:last-of-type": {
          flexBasis: "100%",
          display: "flex",
          justifyCcontent: "flex-end",
          alignItems: "center",
          "> button::before": {
            marginRight: "10px",
            content: "'next'",
          },
        },
      },
    },
  },
};
import React, { useState, useRef, useEffect } from "react";

//* images
import uploadIconBlack from "../../assets/images/uploadIconBlack.svg";
import addIcon from "../../assets/images/add icon.svg";

//* import component
import Admins from "../../Components/Admins/Admins";

//* import sub-component
import Button from "../../Subcomponent/Button";

//* Import Modals
import AddAdmin from "../../Modals/AddAdmin/AddAdmin"

//* Import css
import cssClasses from "./AdminScreen.module.css";

//* Import store
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux Store/actions";
import ExamAccessAddFromExcelModal from "../../Modals/ExamAccessAddFromExcelModal/ExamAccessAddFromExcelModal";

export default function AdminScreen() {

  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.Auth.user);

  const [addAdminModalToggle, setAddAdminModalToggle] = useState(false)
  const [ExamAccessAddFromExcelModalToggle, setExamAccessAddFromExcelModalToggle] = useState(false);

  const [reflectToggle, setReflectToggle] = useState(1);

  useEffect(() => {
    dispatch(actions.activeMenu('admins'));
  }, [])

  return (
    <div className={cssClasses.pageWrapper}>
      <div className={cssClasses.headerWrapper}>
        <div className={cssClasses.headingWrapper}>
          <p>All Admins</p>
          <p>View your Admins and there information.</p>
        </div>
        <div className={cssClasses.headerButtonWrapper}>
          <div>
            <Button
              style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
              btnIcon={addIcon} btnName={"Add\xa0Admins"}
              clicked={() => { setAddAdminModalToggle(true) }}
              disable={!loggedInUser.tabAccess.admins.some(s => s === 'add')}
            />
          </div>
          <div>
            <Button
              style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
              btnIcon={uploadIconBlack} btnName={"User\xa0Access"}
              clicked={() => { setExamAccessAddFromExcelModalToggle(true) }}
              disable={!loggedInUser.tabAccess.admins.some(s => s === 'add')}
            />
          </div>
        </div>
      </div>

      <div className={cssClasses.breaker}></div>

      <Admins reflectToggle={reflectToggle} />

      {addAdminModalToggle &&
        <AddAdmin
          open={addAdminModalToggle}
          handleClose={(flag) => {
            if (flag) {
              setReflectToggle(pre => pre + 1);
            }
            setAddAdminModalToggle(false)
          }}
        />
      }

      {ExamAccessAddFromExcelModalToggle &&
        <ExamAccessAddFromExcelModal
          open={true}
          handleClose={() => {
            setExamAccessAddFromExcelModalToggle(false);
            setReflectToggle(Date.now());
          }}
        />
      }
    </div>
  )
}

import React, { useState, useEffect } from "react";
import { Box, Modal } from "@material-ui/core";
import Styles from "./FailedEntries.module.css";
import { DataGrid } from "@mui/x-data-grid";
import Button from "../../Subcomponent/Button";
import Typography from '@mui/material/Typography'
import { List, ListItem, Tooltip } from "@mui/material";

const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: "Inter-Medium",
  border: "none !important",
  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& div[data-value-colindex="7"]': {
    color: "#fff",
    fontWeight: "500",
  },
  "&  .MuiDataGrid-columnHeaders": {
    background: "#F9FAFB",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    padding: "0 4rem",

    "&:focus": {
      outline: "none",
    },
  },
  "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiButtonBase-root": {
    fontSize: "2rem",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
  },
  "& .MuiDataGrid-footerContainer": {
    "& .MuiTablePagination-displayedRows ": {
      display: "none",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden ",
    },
  },
  "& .MuiPaginationRoot": {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
      li: {
        "&:first-of-type": {
          flexBasis: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          "> button::after": {
            marginLeft: "10px",
            content: "'previous'",
          },
        },
        "&:last-of-type": {
          flexBasis: "100%",
          display: "flex",
          justifyCcontent: "flex-end",
          alignItems: "center",
          "> button::before": {
            marginRight: "10px",
            content: "'next'",
          },
        },
      },
    },
  },
};
export default function FailedEntires({ openModal, closeModal, failedCandidatesArray }) {
  const [failedCandidatesUpload, setFailedCandidateUpload] = useState([]);
  const columns = [
    { field: 'row', headerName: 'Row In Excel', width: 200 },
    { field: 'id', headerName: 'Applicant', width: 150 },
    { field: 'Full Name', headerName: 'Full Name', width: 200 },
    { field: 'reason', headerName: 'Reason', width: 300,
      renderCell: (params) => {  
        // console.log(Object.keys(params.row[params.field]).filter(f=>params.row[params.field][f].length).length);
        // console.log(params.value);
        return (<>
          <Tooltip 
            followCursor
            arrow
            title={
              <List style={{fontSize: "1.2rem", textTransform: 'capitalize'}}>
                {
                  params.value.split(",")?.map((m,index)=>(
                    <ListItem key={`listItem_${m}_${index+1}`} disablePadding>{m}</ListItem>
                  ))
                }
              </List>
            }
          >
            <Typography fontSize={"1.4rem"}>{params.value}</Typography>
          </Tooltip>
        </>)
      }
    }
  ];
  useEffect(() => {

    let failedCandidatesUpload = failedCandidatesArray.map((m, index) => ({
      ...m,
      id: m.Applicant, // ? applicant id here
    }));

    setFailedCandidateUpload(failedCandidatesUpload);
  }, [failedCandidatesArray]);
  return (
    <Modal open={openModal} aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={Styles.modalWrapper}>
        <Box className={Styles.wrapperContent}>
          {/* <div className={Styles.heading}>Failed Upload Candidates List</div> */}
          <div className={Styles.wrapperTable}>
            <DataGrid
              rows={failedCandidatesUpload}
              columns={columns}
              pageSize={10}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              disableVirtualization
              sx={[dataGridStyles]}
            />

          </div>
          <div className={Styles.footer}>
            <div className={Styles.ButtonWrapper}>
              <Button
                style={{
                  color: "#ffffff",
                  backgroundColor: "#6941c6",
                }}
                btnName={"OK"}
                btnIcon={false}
                clicked={closeModal}
              />
            </div>
          </div>
        </Box>
      </Box>
    </Modal>
  );
}

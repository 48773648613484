import { query, orderBy, limit, getFirestore, collection, getDocs, startAfter, where, doc, setDoc, limitToLast,addDoc,serverTimestamp } from "firebase/firestore";


async function setHallTicket(collectionId, studentId, obj) {
    const ticket = doc(getFirestore(), 'exams/' + collectionId + '/hallticket', studentId)
    return await setDoc(ticket, obj);
}

async function addLogs(collectionId, obj) {
    obj.createdAt = serverTimestamp()
    const ticket = collection(getFirestore(), 'exams/' + collectionId + '/hallticketLogs')
    return await addDoc(ticket, obj);
}

async function getHallTicket(collectionId) {
    const ticket = collection(getFirestore(), 'exams/' + collectionId + '/hallticket')
    return await getDocs(ticket)
}


async function getHallTicketsUsingParams(searchField = "studentFullNameForSearch", lastDoc, studentSearchValue, examDocID, subExamDocID) {

  if (searchField === "studentFullNameForSearch" && studentSearchValue) {
    studentSearchValue = studentSearchValue.toLowerCase();
  }

  console.log({ searchField, lastDoc, studentSearchValue, examDocID, subExamDocID });

  let students = collection(getFirestore(), 'students');

  // if (examDocID && subExamDocID) {
  //   students = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/Applicants`);
  // }

  if (examDocID) {
    students = collection(getFirestore(), `exams/${examDocID}/hallticket`);
  }

  let _query = query(students, orderBy(searchField, "asc"), limit(20));  //* for first time visit and without search
  if (lastDoc && !studentSearchValue) {
    //* for next docs without Search
    _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), limit(11))
  }
  if (studentSearchValue && !lastDoc) {
    //* for search first time without last
    _query = query(students, orderBy(searchField, "asc"), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  if (lastDoc && studentSearchValue) {
    //* for search time with last doc
    _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  let getStudents = getDocs(_query);
  return getStudents;
  
}

async function getLogsOfHallTicketByExamID(examID) {
  console.log(examID);
  const logs = collection(getFirestore(), 'exams/' + examID + '/hallticketLogs')
  return await getDocs(query(logs, orderBy('createdAt', "desc")))
}

export { setHallTicket, getHallTicket,addLogs, getHallTicketsUsingParams, getLogsOfHallTicketByExamID }

import React, { useEffect, useState } from 'react'

//* import MUI
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@mui/material/TextField";

//* import sub component
import Button from "../../Subcomponent/Button";
import Dropdown from "../../Subcomponent/Dropdown"

//* import Images
import shuffleIcon from "../../assets/images/shuffle.svg";
import crossImage from "../../assets/images/cross.svg";

//* import Css
import cssClasses from "./ShiftStudentsModal.module.css";

//* imports services
import { getAllExamCentersInExam } from '../../services/ExamCenterService';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    fontSize: "1.4rem !important",
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "&.Mui-error":{
      border: "1px solid red !important"
    },
    "& input": {
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      // border: "unset",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&.MuiSelect-outlined": {
      border: "1px solid #D0D5DD !important",
    },
    "& fieldSet": {
      border: "none",
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
    "& p": {
      fontSize: "1.4rem",
    },
    "&.MuiSelect-select": {
      border: "1px solid #D6BBFB !important",
    },
  },
  helperText: {
    fontSize: "1.4rem !important",
    margin: "0.2rem 0 0 0 !important",
  },
}));
export default function ShiftStudentsModal({ open, handleClose, examDocID=null }) {

  const classList = useStyles();

  const ExamsList = ["a","b","c"];

  // const [selectedExam, setSelectedExam] = useState("a");
  const [examCenterList, setExamCenterList] = useState([]);
  const [examCenterOriginalList, setExamCenterOriginalList] = useState([]);

  const [selectedFromExamCenter, setSelectedFromExamCenter] = useState("");
  const [selectedToExamCenter, setSelectedToExamCenter] = useState("");
  const [studentCount, setStudentCount] = useState(0);

  useEffect(() => {
    console.log({examDocID});
    if (examDocID) {
      getAllExamCentersInExam(examDocID).then(res=>{
        let _list = res.docs.map(m=>({docId : m.id, ...m.data()}));
        setExamCenterOriginalList(_list);
        setExamCenterList(_list.map(m=>({label: m.examCenterName,value: m.docId , sideValue: m?.examCenterCode})));
      })
    }
  }, [examDocID])

  // useEffect(() => {
    
  // }, [selectedFromExamCenter])
  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root":{
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>
        <div className={cssClasses.header}>
          {/* <img src={shuffleIcon} alt="" /> */}
          <h1>Shift Students</h1>
          <img src={crossImage} alt="" style={{cursor:"pointer"}} onClick={handleClose}/>
        </div>

        <div className={cssClasses.mainContainer}>
          <div className={cssClasses.inputContainer}>
            <div className={cssClasses.inputWrapper}>
              <label>Select exam center from</label>
              <Dropdown
                list={examCenterList}
                selectedValue={selectedFromExamCenter}
                onChangeSelect={(value) => {console.log(value);setSelectedFromExamCenter(value)}}
              />
            </div>

            <div className={cssClasses.inputWrapper}>
              <label>Select exam center to</label>
              <Dropdown
                list={examCenterList.filter(f=>f.value !== selectedFromExamCenter)}
                selectedValue={selectedToExamCenter}
                onChangeSelect={(value) => {console.log(value);setSelectedToExamCenter(value)}}
              />
            </div>

            <div className={cssClasses.inputWrapper}>
              <label>Enter No. of Students</label>
              <TextField
                  InputProps={{ min : 0,className: classList.input }}
                  inputProps={{min:0}}
                  className={classList.textField}
                  id="outlined-basic"
                  FormHelperTextProps={{
                    classes: {
                      root: classList.helperText,
                    },
                  }}
                  type={"number"}
                  value={studentCount}
                  onChange={(e) => {
                    setStudentCount(e.target.value);
                  }}
                  helperText={""}
                  error={false}
                />
            </div>



            {/* <div className={cssClasses.inputWrapper}>
              <label>Students Sifting Type</label>
              <Dropdown
                list={ExamsList.map(m=>({label:m,value:m}))}
                selectedValue={selectedExam}
                onChangeSelect={(value) => {console.log(value);setSelectedExam(value)}}
              />
            </div> */}

          </div>
        </div>

        <div className={cssClasses.footer}>
          {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
          <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Cancel"} clicked={handleClose} /></div>
          <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Done"} /></div>
        </div>
      </Box>
    </Modal>
  )
}

import action from "../Redux Store/actions";
import store from '../Redux Store/store';

function presentLoader() {
    store.dispatch(
        action.isLoading(true)
    );
}

function dismissLoader() {
    store.dispatch(
        action.isLoading(false)
    );
}

export { presentLoader, dismissLoader }
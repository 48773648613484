import React, { useEffect, useState } from "react";
//* images
import filterIcon from "../../assets/images/filter-icon.svg";
import moreVertical from "../../assets/images/more-vertical.svg";
import man from "../../assets/images/man.png";
import woman from "../../assets/images/woman.png";
import uploadFileCloud from "../../assets/images/uploadFileCloud.svg";
import user_icon from "../../assets/images/user_icon.png"

//* css
import cssClasses from "./EligibleStudents.module.css";

//* import MUI
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from "@mui/material";
import Chip from '@mui/material/Chip';

//* import Sub-components
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";

//* import Services
import { getStudents, setEligibleStudents, getAllEligibleStudents, getStudentsForDownload, downloadImages } from "../../services/EligibleStudentsService"
import { errorToast, successToast } from "../../services/Toast";
import { dismissLoader, presentLoader } from "../../services/loaderService";

import * as XLSX from "xlsx";
// const ExcelJS = require('exceljs');
// import * as ExcelJS from "exceljs"
// import * as imageToBase64 from "image-to-base64";
import { Workbook } from 'exceljs';
import * as fileSaver from 'file-saver';
import { collection, getCountFromServer, getFirestore } from "firebase/firestore";

const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected": {
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
    paddingRight: '1rem'
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
    paddingRight: '1rem'
  },
  "& .MuiButtonBase-root": {
    borderRadius: '0'
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "hidden !important"
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "7.2rem !important",
    minHeight: "7.2rem !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",
    minHeight: "7.2rem !important",
    maxHeight: "7.2rem !important",
    outline: 'none !important',
  },
  "& .MuiButtonBase-root": {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '0 1rem'
  },
  "& .MuiTablePagination-actions": {
    margin: '0 2rem',
  }
}

export default function EligibleStudents({ height=false, examDocID=null, examName="" }) {
  
  const [isDataPresent, setIsDataPresent] = useState(false);

  const [totalMeritStudent, setTotalMeritStudent] = useState(0);
  const [applicantsList, setApplicantsList] = useState([]);
  const [serverMetaDataList, setServerMetaDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageMaintain, setPageMaintain] = useState(0);
  const [studentSearchValue, setStudentSearchValue] = useState("");

  const [dataGridLoading, setDataGridLoading] = useState(true);
  const [searchField, setSearchField] = useState("studentFullNameForSearch");

  const onClickDoneApplicant = (_applicant) => {
    console.log(_applicant);
  }

  useEffect(() => {
    setDataGridLoading(true);
    getDataFromServer(searchField, null, studentSearchValue === "" ? undefined : studentSearchValue, false);
  }, [studentSearchValue, searchField]);

  useEffect(() => {
    if (examDocID) {
      getCountFromServer(collection(getFirestore() ,`exams/${examDocID}/EligibleStudents`)).then((getCountFromServerResponse) => {
        // console.log("getCountFromServerResponse => ", getCountFromServerResponse);
        setTotalMeritStudent(getCountFromServerResponse.data().count);
      })
    }
  }, [])
  
  function getDataFromServer(_searchField, _lastDoc, _studentSearchValue, _merge) {
    getStudents(searchField, _lastDoc, _studentSearchValue, examDocID).then(res => {
      let _studentList = convertFirebaseObjToDataGridObj(res.docs.map(m => ({ docID: m.id, ...m.data() })));
      console.log(_studentList);

      if (!isDataPresent) {
        setIsDataPresent(!res.empty)
      }

      if (_merge) {
        setServerMetaDataList(pre => [...pre, ...res.docs])
        setApplicantsList(pre => [...pre, ..._studentList]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList(res.docs);
        setApplicantsList(_studentList);
      }
      setDataGridLoading(false);
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      if (_merge) {
        setServerMetaDataList(pre => [...pre])
        setApplicantsList(pre => [...pre]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList([]);
        setApplicantsList([]);
      }
      setDataGridLoading(false);
    })
  }


  //* call on Next Button
  function getMoreData() {
    setDataGridLoading(true);
    getDataFromServer(searchField, serverMetaDataList[serverMetaDataList.length - 1], studentSearchValue === "" ? undefined : studentSearchValue, true);
  }

  function fileSelectForEligibleStudentsList(e) {
    let _file = e.target.files[0];
    if (_file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        var excelData = event.target.result;
        excelData = new Uint8Array(excelData);
        var workbook = XLSX.read(excelData, {
          type: "array",
          cellText: false,
          cellDates: true,
        });

        var arrayOfRows;
        workbook.SheetNames.forEach(function (sheetName) {
          arrayOfRows = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
            { raw: false, dateNF: "yyyy-mm-dd" }
          );
        });
        console.log(arrayOfRows);
        if (arrayOfRows.length > 0) {
          eligibleStudentsServiceAddToFirebase(arrayOfRows)
        } else {
          errorToast("Data not found in file.")
        }
      };
      reader.readAsArrayBuffer(_file);
    }
  }

  async function eligibleStudentsServiceAddToFirebase(meritStudentExcelList) {
    presentLoader();
    let setCount = 1;
    console.log({meritStudentExcelList});
    meritStudentExcelList.forEach(async (elementMeritStudent) => {

      let studentDocID = elementMeritStudent?.UID;
      let postBody = elementMeritStudent
      // console.log({studentDocID,postBody});
      //! check duplicate if want
      if(studentDocID.trim() !== "" && studentDocID ){
        setEligibleStudents(examDocID, studentDocID.trim(), postBody).then(res=>{
          console.log(res);
          
          if (meritStudentExcelList.length === setCount) {
            successToast("Eligible students list upload successfully.");
            getDataFromServer(searchField, null, studentSearchValue === "" ? undefined : studentSearchValue, false);
            dismissLoader()
          }
          setCount++;
        }).catch(err=>{
          console.log(err,elementMeritStudent);
          if (meritStudentExcelList.length === setCount) {
            successToast("Eligible students list upload successfully.");
            getDataFromServer(searchField, null, studentSearchValue === "" ? undefined : studentSearchValue, false);
            dismissLoader()
          }
          setCount++;
        })
      } else{
        if (meritStudentExcelList.length === setCount) {
          successToast("Eligible students list upload successfully.");
          getDataFromServer(searchField, null, studentSearchValue === "" ? undefined : studentSearchValue, false);
          dismissLoader()
        }
        setCount++;
      }
      
    })
  }
  

  function downloadStudents() {
    presentLoader();
    getAllEligibleStudents(examDocID).then(res=>{

      if (res.empty) {
        errorToast("Student data not found.");
        dismissLoader();
        return;
      }

      let _list = res.docs.map(m => ({UID : m.id, ...m.data()}));
      // console.log({_list});

      // let keys = Object.keys(Object.assign({}, ..._list));
      // // console.log(keys);
      
      // let _studentList = [];
      // _list.forEach((element) => {
      //   let obj = {};
      //   keys.forEach((ele) => {
      //     obj[ele] = element[ele]
      //   });
      //   _studentList.push(obj);
      // });

      // console.log(_studentList);

      const worksheet = XLSX.utils.json_to_sheet(_list);
      const workBook = new XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, worksheet, `Eligible List`);
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.writeFile(workBook, `${examName} Eligible Students List.xlsx`);

      dismissLoader();
    }).catch(err=>{
      console.log(err);
      errorToast(err.toString());
      dismissLoader();
    })
  }

  async function downloadResultExcelSheet() {
    presentLoader();
    getAllEligibleStudents(examDocID).then(res=>{

      if (res.empty) {
        errorToast("Student data not found.");
        dismissLoader();
        return;
      }

      let _list = res.docs.map(m => ({UID : m.id, ...m.data()}));
      let keys = ["sNo", "Applicant", "Full Name", "Gender", "MobileNo", "Attendance", "Date", "CenterName", "ExamName", "AppPhoto", "LivePhoto", "FPPhoto"];

      // sNo
      // Applicant
      // Full Name
      // Gender
      // MobileNo        phone
      // Attendance      realTimePhoto && fingerprintImage
      // Date            examDate
      // CenterName      examCenterName
      // ExamName        {examName}
      // AppPhoto        Photo
      // LivePhoto       realTimePhoto
      // FPPhoto         fingerprintImage
      
      let _studentList = [];
      _list.forEach((element,index) => {
        let obj = {
          "sNo":        (index+1).toString(),
          "Applicant":  element.Applicant,
          "Full Name":  element["Full Name"],
          "Gender":     element.Gender,
          "MobileNo":   element.phone,
          // "Attendance": (element.realTimePhoto && element.realTimePhoto !== "" && element.fingerprintImage && element.fingerprintImage !== "") ? "P" : "A",
          "Attendance": (element.realTimePhoto && element.realTimePhoto !== "") ? "P" : "A",
          "Date":       element.examDate,
          "CenterName": element.examCenterName,
          "ExamName":   examName ? examName : element.examName,
          // "AppPhoto":   (element?.Photo && element?.Photo !== "") ? process.env.REACT_APP_bucketAccessRootPath+element?.Photo : "",
          // "LivePhoto":  (element?.realTimePhoto && element?.realTimePhoto !== "") ? process.env.REACT_APP_bucketAccessRootPath+element?.realTimePhoto : "",
          // "FPPhoto":    (element?.fingerprintImage && element?.fingerprintImage !== "") ?process.env.REACT_APP_bucketAccessRootPath+element?.fingerprintImage : ""
          "AppPhoto":   "",
          "LivePhoto":  "",
          "FPPhoto":    "",
        };
        _studentList.push(obj);
      });

      console.log(_studentList);

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30; 
      worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(keys);

      _studentList.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });

      // const myBase64Image = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAQtSURBVFiFtZfbbxRVHMc/Z2Z3Z9vddrfLau9ggIrGKCViJPDAG8FIQuIF44soJvpSCVa0tURSEi4lMfFCfDGo8R8wRkOEREMiCcTogxKFiBFr5dIA7ZZetjszZ87xYXch2un0bJVfcpKdM9/z+332N+fyO4IabKhvKHN5Yviz64Xi+qLrJ7Uu9wsBTiLmNaWd3/NL0q8ffPvdY6Y+hamwf9fOwXN/XN+r9e0xGo2SARqNRmAB+ab0eHdXtqt38J3x/w1gd0/P0V9Hxl6sPgdBgO95BCqYo3USNp3NTZNL23KnOvPJl7f3Hrj8nwAG+1/d9MMvoydERel5Lr7vm3CTa6xTG9feu+uVPfuPzKexF3KydNn935U8WV8O7hkHB5h1pbh4aeyx3h1PjRz75tsfwzRWlIMDAwPNhanSEgClAnzfMw5eNc+XjIwW3pjvfSxq8PjNm4eqv+9py7Gn50nyuSyWHc6tAsWN8QLvfXKM789eBMCJx1i+rH3rfDEiMzBd8h8BSNU5vD+0m9bWFuKOgx2Lh7a449Da2sLQmztY++ByADpbspMvvTZwYVEAnpQ5gOef2YwVT4IVmbDbJgQ7X3gcgExD/fUoaSSADsqTdOWKzmqPGQCQz2UqLEItGqBqtr3gYpljQhi5NgO4kxYJ0Jh2RpRS5HPZRQdI1cevRL2PnFVrVjVvyjYmvkrVJ9cBYJhWAEsItaF7+U8tDflno3RGZ4GeHJaImI0sgSyaESh/SjQ/3LggqJGzQM4AYCfMz0+tR01khgD+l0D5E9j1ZgAq+NREZvYJtLaZHHGxKutRzoB0o4JPcXd3Rgix4MZhlAEhRIA38QS6cv7HUuU2T3Tk7GaT4MYAAOKu1V8wc3MbWkoA7JAFpGUBNbtRtK8/bezXVHgrhtaC4a8vgWgjFgc0BC74/kXRtWVFrf5q3gmFEBpZLOIWYPoqTI9CaRIIqc/uBIDeu+9jTv21EicDyVy5ORm8E+e7Znb2Hq3V34KfQB853Ma12UNIdZ8u+auEFBm0Rrsz0F7eooM/xxi/egMtIJlNT8TSdRdEMnGOjsY9qb7ByK04EkD37z3DlLcO61+J0hp95RoE5awrrRnzSnMOa8uyqOvIn2748MiGmgF031s/UwweuNXh+VAqoX0Jrg+VxVC1QGs8FaDQuEoh1e0yINWeP9vw0QerjQH0/n1bGXU/R1T+7fgEzMzOxxpqs0oy7ftoyjen9EMrtqQOH5xzYwqfhFPyQBVNjxVqDg5QZ8VoiCfKPjSosYlDYbpwAD9oA8B1oViqOXjVkpZNonKEy6LfEaYJrwdUuRbUtQZvysLStvJ8Of8bAAnbxpMKoYLQui56H5CR9eRce3QN4rmnEdu3QdIBwF5gpYdmQMeYFr5qRJpfwwA4fhJ9/OQ/umzLQiAQidh02JC/AaETktmE9IJ5AAAAAElFTkSuQmCC";
      // const imageId2 = workbook.addImage({
      //   base64: myBase64Image,
      //   extension: 'png',
      // });
      // worksheet.addImage(imageId2, "B6:B6");

      let appPhotoColumnRowID = "";
      // let appPhotoColumn = [];
      let appPhotoColumn = _list.map(element => (element?.Photo && element?.Photo !== "") ? process.env.REACT_APP_bucketAccessRootPath+element?.Photo : "");
      
      let livePhotoColumnRowID = "";
      // let livePhotoColumn = [];
      let livePhotoColumn = _list.map(element => (element?.realTimePhoto && element?.realTimePhoto !== "") ? process.env.REACT_APP_bucketAccessRootPath+element?.realTimePhoto : "");
      
      let FPPhotoColumnRowID = "";
      // let FPPhotoColumn = [];
      let FPPhotoColumn = _list.map(element => (element?.fingerprintImage && element?.fingerprintImage !== "") ?process.env.REACT_APP_bucketAccessRootPath+element?.fingerprintImage :  "");
      
      

      worksheet.getRow(1).eachCell((cell,colNumber) => {
        if (cell.value === "AppPhoto") {
          appPhotoColumnRowID = cell.address.split("")[0];
          // appPhotoColumn = worksheet.getColumn(colNumber).values.filter(f=>(f && f!=="AppPhoto") || f==="");
        }

        if (cell.value === "LivePhoto") {
          livePhotoColumnRowID = cell.address.split("")[0];
          // livePhotoColumn = worksheet.getColumn(colNumber).values.filter(f=>(f && f!=="LivePhoto") || f==="");
        }
        if (cell.value === "FPPhoto") {
          FPPhotoColumnRowID = cell.address.split("")[0];
          // FPPhotoColumn = worksheet.getColumn(colNumber).values.filter(f=>(f && f!=="FPPhoto") || f==="");
        }
      })
      
      console.log(appPhotoColumnRowID,appPhotoColumn);

      console.log(livePhotoColumnRowID,livePhotoColumn);

      console.log(FPPhotoColumnRowID,FPPhotoColumn);

      // const [posts, photos, todos] = await 
      Promise.allSettled([ imageArrayPromise(appPhotoColumn), imageArrayPromise(livePhotoColumn), imageArrayPromise(FPPhotoColumn)]).then(res=>{
        const [AppPhotos, LivePhotos, FPPhotos] = res;   
        AppPhotos.value.forEach((ele,index)=>{
          if (ele && ele !== "") {
            const imageId = workbook.addImage({
              base64: ele,
              extension: 'png',
            });
            worksheet.addImage(imageId, `${appPhotoColumnRowID}${index+2}:${appPhotoColumnRowID}${index+2}`);
          }
        });
        LivePhotos.value.forEach((ele,index)=>{
          if (ele && ele !== "") {
            const imageId = workbook.addImage({
              base64: ele,
              extension: 'png',
            });
            worksheet.addImage(imageId, `${livePhotoColumnRowID}${index+2}:${livePhotoColumnRowID}${index+2}`);
          }
        });
        FPPhotos.value.forEach((ele,index)=>{
          if (ele && ele !== "") {
            const imageId = workbook.addImage({
              base64: ele,
              extension: 'png',
            });
            worksheet.addImage(imageId, `${FPPhotoColumnRowID}${index+2}:${FPPhotoColumnRowID}${index+2}`);
          }
        });
        console.log("Add Images Complete");
        // worksheet.getRow(1).fill = {
        //   fgColor:{argb:'FFFFFF00'},
        //   bgColor:{argb:'FF0000FF'}
        // }

        try {
          workbook.xlsx.writeBuffer().then((buffer) => {
            console.log("Downloading Start");
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',});
            fileSaver.saveAs(blob, `${examName} Eligible Students List.xlsx`);
            return buffer;
          }).catch((ee) => {
            errorToast("Something went wrong! Try again");
            dismissLoader();
            console.log("dd",ee)
          });
        } catch (e) {
          console.log(e);
          errorToast("Something went wrong! Try again");
          dismissLoader();
        }

      }).catch(err=>{
        console.log(err);
        dismissLoader();
        errorToast("Something went wrong! Try again");
      })

           
      dismissLoader();
    }).catch(err=>{
      console.log(err);
      errorToast(err.toString());
      dismissLoader();
    })
  }

  function imageArrayPromise(imgArr) {
    let promises = []
    imgArr.forEach(ele=>{
      promises.push(getBase64FromImageUrl(ele))
    });
    return new Promise((resolve, reject) => {
      Promise.all(promises).then(res=>{
        console.log(res);
        resolve(res)
      }).catch(err=>{
        reject(err)
      });
    })
  }

  function getBase64FromImageUrl(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpeg");
        // 
        // console.log("dataURL",dataURL);
        resolve(dataURL)
      };
      img.onerror = function (err) {
        console.log(err);
        resolve("")
      }
      
      if (url === "") {
        resolve("");
      } else {
        img.crossOrigin = '';
        img.crossOrigin='anonymous'
        img.src = url;

        // img.setAttribute('crossOrigin', 'anonymous');
        // setTimeout(() => {
        //   resolve("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAQtSURBVFiFtZfbbxRVHMc/Z2Z3Z9vddrfLau9ggIrGKCViJPDAG8FIQuIF44soJvpSCVa0tURSEi4lMfFCfDGo8R8wRkOEREMiCcTogxKFiBFr5dIA7ZZetjszZ87xYXch2un0bJVfcpKdM9/z+332N+fyO4IabKhvKHN5Yviz64Xi+qLrJ7Uu9wsBTiLmNaWd3/NL0q8ffPvdY6Y+hamwf9fOwXN/XN+r9e0xGo2SARqNRmAB+ab0eHdXtqt38J3x/w1gd0/P0V9Hxl6sPgdBgO95BCqYo3USNp3NTZNL23KnOvPJl7f3Hrj8nwAG+1/d9MMvoydERel5Lr7vm3CTa6xTG9feu+uVPfuPzKexF3KydNn935U8WV8O7hkHB5h1pbh4aeyx3h1PjRz75tsfwzRWlIMDAwPNhanSEgClAnzfMw5eNc+XjIwW3pjvfSxq8PjNm4eqv+9py7Gn50nyuSyWHc6tAsWN8QLvfXKM789eBMCJx1i+rH3rfDEiMzBd8h8BSNU5vD+0m9bWFuKOgx2Lh7a449Da2sLQmztY++ByADpbspMvvTZwYVEAnpQ5gOef2YwVT4IVmbDbJgQ7X3gcgExD/fUoaSSADsqTdOWKzmqPGQCQz2UqLEItGqBqtr3gYpljQhi5NgO4kxYJ0Jh2RpRS5HPZRQdI1cevRL2PnFVrVjVvyjYmvkrVJ9cBYJhWAEsItaF7+U8tDflno3RGZ4GeHJaImI0sgSyaESh/SjQ/3LggqJGzQM4AYCfMz0+tR01khgD+l0D5E9j1ZgAq+NREZvYJtLaZHHGxKutRzoB0o4JPcXd3Rgix4MZhlAEhRIA38QS6cv7HUuU2T3Tk7GaT4MYAAOKu1V8wc3MbWkoA7JAFpGUBNbtRtK8/bezXVHgrhtaC4a8vgWgjFgc0BC74/kXRtWVFrf5q3gmFEBpZLOIWYPoqTI9CaRIIqc/uBIDeu+9jTv21EicDyVy5ORm8E+e7Znb2Hq3V34KfQB853Ma12UNIdZ8u+auEFBm0Rrsz0F7eooM/xxi/egMtIJlNT8TSdRdEMnGOjsY9qb7ByK04EkD37z3DlLcO61+J0hp95RoE5awrrRnzSnMOa8uyqOvIn2748MiGmgF031s/UwweuNXh+VAqoX0Jrg+VxVC1QGs8FaDQuEoh1e0yINWeP9vw0QerjQH0/n1bGXU/R1T+7fgEzMzOxxpqs0oy7ftoyjen9EMrtqQOH5xzYwqfhFPyQBVNjxVqDg5QZ8VoiCfKPjSosYlDYbpwAD9oA8B1oViqOXjVkpZNonKEy6LfEaYJrwdUuRbUtQZvysLStvJ8Of8bAAnbxpMKoYLQui56H5CR9eRce3QN4rmnEdu3QdIBwF5gpYdmQMeYFr5qRJpfwwA4fhJ9/OQ/umzLQiAQidh02JC/AaETktmE9IJ5AAAAAElFTkSuQmCC")
        // }, 1000);

        // imageToBase64(url).then((response) => {
        //     console.log(response); // "cGF0aC90by9maWxlLmpwZw=="
        //     resolve(response)
        // }).catch(
        // (error) => {
        //   console.log(error); // Logs an error if there was one
        //   reject(error)
        // })
      }
    })
}


  // function downloadResultExcelSheets(ev) {
  //   presentLoader();
  //   getStudentsForDownload(ev.target.innerText.trim().toLowerCase()).then((resp) => {
  //     console.log('resp: ', resp);
  //     // let studentList = [];
  //     if (resp.students.length > 0) {
  //       let keys = Object.keys(Object.assign({}, ...resp.students));

  //       let _studentList = [];
  //       resp.students.forEach((element) => {
  //         let obj = {};
  //         keys.forEach((ele) => {
  //           obj[ele] = element[ele] ? element[ele] : "";
  //         });
  //         obj.Qualified = element.Status ? element.Status.charAt(0).toUpperCase() + element.Status.slice(1) : "" ;
  //         obj.AppliedAs = element.AppliedAs ? element.AppliedAs : "";
  //         let _duplicateObj = Object.assign({}, obj);
  //         console.log({_duplicateObj});
  //         // delete _duplicateObj.Photo;
  //         _studentList.push(_duplicateObj);
  //       }); 

  //       // resp.students.forEach((data) => {
  //       //   console.log('data: ', data);
  //       //   const obj = {
  //       //     Applicant: data.Applicant,
  //       //     CandidateName: data.CandidateName,
  //       //     DoB: data.DoB,
  //       //     MobileNo: data.MobileNo,
  //       //     EmailID: data.EmailID,
  //       //     Qualified: data.Status.charAt(0).toUpperCase() + data.Status.slice(1),
  //       //     CandId: data.CandID,
  //       //     RollNo: data.RollNo,
  //       //     SNO: data.SNO,
  //       //     AppliedAs: data.AppliedAs,
  //       //     ExamCentre: data.ExamCentre,
  //       //     ExamDistrict: data.ExamDistrict,
  //       //     ExamDate: data.ExamDate,
  //       //     // ApplicantBarCode: data.ApplicantBarCode,
  //       //     // DistrictBarCode: data.DistrictBarCode,
  //       //     // QrCode: data.QrCode,
  //       //     Pincode: data.Pincode,
  //       //     UploadedForm: data.uploadedForm
  //       //   };
  //       //   studentList.push(obj);
  //       // });
  //       const worksheet = XLSX.utils.json_to_sheet(_studentList);
  //       const workBook = new XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workBook, worksheet, "Students List");
  //       XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  //       XLSX.writeFile(workBook, `${examName} Result List.xlsx`);

  //       downloadImages(resp.blob, ev.target.innerText.trim());
  //     } else {
  //       errorToast("Student data not found.");
  //     }
  //     dismissLoader();
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     dismissLoader();
  //     errorToast("Something went wrong! Try again");
  //   });
  // }

  return (
    <Box className={cssClasses.mainTabPanelWrapper}>
      {/* { !isDataPresent ? 
        <>
          <div className={cssClasses.uploadFileWrapper}>
            <img src={uploadFileCloud} alt="" onClick={() => {document.getElementById("fileSelectForEligibleStudentsList").click()}}/>
            <p>No data found please upload</p>
            <input hidden
              type="file"
              name=""
              id="fileSelectForEligibleStudentsList"
              onChange={fileSelectForEligibleStudentsList}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </>
        :
        <> */}
          <div className={cssClasses.mainTabPanelHeader}>
            <div>
              <p>Eligible Students List</p>
              <p>Total Eligible Students - <span>{totalMeritStudent}</span></p>
            </div>
            <div>
              <div><Button  style={{ color: "#FFFFFF", backgroundColor: "#6941C6" }} btnName={"Download\xa0Eligible\xa0Students\xa0list"} clicked={() => {downloadStudents()}}/></div>
              {/* <div><Button  style={{ color: "#FFFFFF", backgroundColor: "#6941C6" }} btnName={"Download\xa0Result\xa0Sheet"} clicked={downloadResultExcelSheet}/></div> */}
            </div>
          </div>
          <div className={cssClasses.searchAndFilterWrapper}>
            <div>
              <ToggleButtonGroup
                color="primary"
                value={searchField}
                onChange={(e) => { setSearchField(e.target.value); }}
                exclusive
                style={{filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))"}}
                // onChange={handleChange}
              >
                <ToggleButton value="studentFullNameForSearch" sx={[toggleButtonStyle]}>Name</ToggleButton>
                <ToggleButton value="Gender" sx={[toggleButtonStyle]}>Gender</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div>
              <div><SearchBar placeholder={"Search"} search={(_value) => setStudentSearchValue(_value)} /></div>
              {/* <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={filterIcon} btnName={"Filters"} /></div> */}
            </div>
          </div>
          <div className={cssClasses.examCenterWrapper} style={{height : height ? height : "unset", flex : height ? "unset" : 1 }}>
            <DataGrid
              rows={dataGridLoading ? [] : applicantsList}
              columns={ applicantColumns }
              hideFooterPagination={false}
              hideFooter={false}
              pageSize={10}
              rowsPerPageOptions={[10]}
              page={pageMaintain}
              sx={[dataGridStyles,{
                " .MuiDataGrid-footerContainer > div:first-of-type:after" : {
                  content: '"'+`${pageMaintain+1} Page`+'"',
                  paddingLeft: "1rem"
                }
              }]}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableSelectionOnClick
              disableVirtualization
              loading={dataGridLoading}
              onPageChange={(_page) => {
                console.log({ _page });
                setPageMaintain(_page)
                if (pageNumber < _page) {
                  // * Next When Data need
                  setPageNumber(_page);
                  getMoreData()
                } else {
                  // *previous
                }
              }}
            />
          </div>
        {/* </>
      } */}
    </Box>
  );
}
//   return _serverList.map(m=> ({
//     id : m.docID,
//     fileName : { img: m.hasOwnProperty("uploadImage") ? m?.uploadImage : "", name:m["Full Name"], email:m?.email, onErrorImg: m?.GenderName?.toLowerCase() === "male" ? man : woman },
//     studentID : m?.Applicant,
//     examName : "",
//     roomNumber : m.RollNo,
//     registrationDate : "",
//     invoice : "",
//     payment: "", //pending
//     ...m
//   }))
// }

function convertFirebaseObjToDataGridObj(_serverList){
  console.log(_serverList);
  return _serverList.map(m=> ({
    id : m.docID,
    fileName : { 
      img: m.hasOwnProperty("profilePicture") ? m?.profilePicture : m.hasOwnProperty("Photo") ? m?.Photo : "", 
      name: m["Full Name"], 
      email: m?.email || m?.Email, 
      onErrorImg: user_icon
    },
    gender : m?.gender || m?.Gender,
    examName : "",
    name:m["Full Name"], 
    email:m?.email || m?.Email,
    phone : m?.phone,
    ...m,
  }))
}



const applicantColumns = [
  { field: 'fileName', headerName: 'Photo', width: 100 , sortable: false,
    renderCell: (params) => (
      <div className={cssClasses.imageCellWrapper}>
        <img src={process.env.REACT_APP_bucketAccessRootPath + params.value.img} alt="" onError={(e) => { e.target.onerror = null; e.target.src = params.value.onErrorImg;}} />
      </div>
    )
  },
  { field: 'Full Name', headerName: 'Full Name', sortable: false, width: 250 },
  { field: 'Applicant', headerName: 'Applicant No.', sortable: false, width: 200 },
  { field: 'gender', headerName: 'Gender', sortable: false, width: 200 },
  { field: 'email', headerName: 'Email', sortable: false, width: 250,
    renderCell : (params) => {
      return (
        <div>{params['value'] ? new Array(params['value']?.split('@')[0].length).join('*')+"@"+params['value']?.split('@')[1] : "_"}</div>
      )
    }
  },
  { field: 'phone', headerName: 'Phone', sortable: false, width: 200,
    renderCell : (params) => {
      return (
        <div>{(params['value']!=="" && params['value']) ? "******"+params['value']?.toString().substring(6,10) : "_"}</div>
      )
    }
  },
  // { field: 'registrationDate', headerName: 'Registration Date', sortable: false, width: 150 },
  // { field: 'invoice', headerName: 'Invoice', sortable: false, width: 150 },
  // { field: 'id', headerName: '', width: 70 },
]
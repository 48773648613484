import React from 'react'
import { MenuItem, Select } from '@mui/material';
import { makeStyles, withStyles } from "@material-ui/core/styles"
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    dropdown: {
        width: "100%",
        marginTop: "0.6rem",
        border: '1px solid #D0D5DD !important',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
        borderRadius: '0.8rem',
        padding: '1rem 1.4rem !important',
        fontSize: "1.4rem",
        "& fieldSet": {
            border: 'none'
        },
        "&:hover": {
            border: "1px solid #D0D5DD !important"
        },
        "& div:first-child": {
            padding: "0"
        },
        "&.Mui-focused": {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D6BBFB !important',
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;"
            }
        },
    },
    dropdownMenu: {
        "& li": {
            fontSize: "1.4rem",
        }
    },
    dropdownIcon: {
        width: '2rem !important',
        height: '2rem !important'
    },
    dropdownInput: {
        fontSize: "1.4rem !important",
    },
    textField: {
        width: "100%",
    },
    input: {
        fontSize: '1.4rem !important',
        marginTop: "0.6rem",
        width: "100%",
        background: '#FFFFFF',
        border: '1px solid #D0D5DD !important',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
        borderRadius: '0.8rem',
        '& input': {
            padding: '1rem 1.4rem !important'
        },
        "&.Mui-focused": {
            // border: "unset",
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D6BBFB !important',
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;"
            }
        },
        "&.MuiSelect-outlined": {
            border: '1px solid #D0D5DD !important'
        },
        "& fieldSet": {
            border: 'none'
        },
        "&:hover": {
            // border:"none",
            '& .MuiOutlinedInput-notchedOutline': {
                // border: '1px solid #D0D5DD !important'
            }
        },
        "& p": {
            fontSize: "1.4rem"
        },
        "&.MuiSelect-select": {
            border: '1px solid #D6BBFB !important',
        }
    },
    itemVal: {
        display: "inline-block",
        width: "80%",
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    itemCount: {
        display: "inline-block",
        width: "20%",
        textAlign: "right"
    },
}))

const errorObj = {
    border: '1px solid #ff0000 !important',
    "&:hover": {
        border: "1px solid #ff0000 !important"
    },
    "&.Mui-focused": {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #ff0000 !important',
            boxShadow: "0px 1px 2px rgba(255, 0, 0, 0.05), 0px 0px 0px 4px #F4EBFF;"
        }
    },
}

export default function Dropdown({list,selectedValue,onChangeSelect,placeHolder,isError=false, loading = false,readOnly=false, disable=false }) {
    const classes = useStyles()
    return (
        <Select
            placeholder={placeHolder}
            classes={{
                icon: classes.dropdownIcon
            }}
            disabled={disable}
            readOnly={readOnly}
            inputProps={{ className: classes.dropdownInput }}
            MenuProps={{ className: classes.dropdownMenu }}
            className={classes.dropdown}
            id="demo-simple-select"
            value={selectedValue}
            // onChange={(e)=>onChangeSelect(e.target.value)}
            onChange={(e) => {
                onChangeSelect(e.target.value)
            }}
            sx={[isError ? {...errorObj} : {}]}
        >
            {!loading &&
                list.map((element, index) => (
                    <MenuItem key={`${index}_${element.value ? element.value : element}_${Date.now().toString()}`} value={element.value ? element.value : element} >
                        <div className={element.sideValue ? classes.itemVal : ''}>
                            {element.label ? element.label : element}
                        </div>
                        {element.sideValue &&   
                            <div className={classes.itemCount}>
                                {element.sideValue}
                            </div>
                        }
                    </MenuItem>
                ))
            }
            {loading &&
                <MenuItem key={0 + "Default"} value={selectedValue}>
                    {selectedValue}
                </MenuItem>
            }
            {loading &&
                <MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size={"3rem"} />
                </MenuItem>
            }
        </Select>
    )
}

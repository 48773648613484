import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";

//* import css
import Styles from "./ExamForm.module.css";

//* import MUI
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { MobileDatePicker } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Box, InputAdornment, Switch } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

//* images
import addIconWhite from "../../assets/images/add-icon-white.svg";

//* import Sub-components
import Button from "../../Subcomponent/Button";

// * import Service
import { getExamScheduleById, addExamSchedule, updateExamSchedule, deleteSubExamSchedule, updateExamDetails } from "../../services/ExamService";
import { successToast, errorToast } from "../../services/Toast"

//* import node npm
import * as _ from 'lodash';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  helperText: {
    fontSize: "1rem !important",
    margin: "0.2rem 0 0 0 !important",
    color: "red"
  },
  ckeditor: {
    "&.ck.ck-editor": {
      fontSize: "1.4rem",
      marginTop: "0.6rem",
    },
  },
  textField: {
    width: "100%",
  },
  input: {
    fontSize: "1.4rem !important",
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "& input": {
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      // border: "unset",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&.MuiSelect-outlined": {
      border: "1px solid #D0D5DD !important",
    },
    "& fieldSet": {
      border: "none",
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
    "& p": {
      fontSize: "1.4rem",
    },
    "&.MuiSelect-select": {
      border: "1px solid #D6BBFB !important",
    },
  },
  datePickerWrapper: {
    width: "100%",
  },
  datePicker: {
    "flex-direction": "row-reverse",
    fontSize: "1.4rem !important",
    "& fieldSet": {
      border: "none",
    },
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "& input": {
      width: "100%",
      fontSize: "1.4rem !important",
      fontSize: "1.4rem !important",
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow:
          "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;",
      },
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
  },
  calender: {
    "& div": {
      fontSize: "1.4rem !important",
    },
    "& span": {
      fontSize: "1.4rem !important",
    },
    "& button": {
      fontSize: "1.4rem !important",
    },
  },
  mobileDatePickerStyles: {
    "& .MuiTypography-root, .MuiButtonBase-root": {
      fontSize: "1.5rem"
    },
    "& .MuiCalendarPicker-root > div:first-of-type > div:first-of-type": {
      fontSize: "1.5rem"
    },
    "& svg": {
      fontSize: "3rem"
    }
  },
  mobileTimePickerStyles: {
    "& .PrivatePickersToolbar-root > .MuiTypography-root:first-of-type": {
      fontSize: "1.5rem"
    },
    "& svg": {
      fontSize: "3rem"
    },
    "& .MuiClockPicker-root": {
      fontSize: "1.6rem"
    },
    "& .MuiDialogActions-root .MuiButton-root": {
      fontSize: "1.5rem"
    },
    "& .PrivateTimePickerToolbar-ampmSelection .MuiButton-root .MuiTypography-root": {
      padding: "5px",
    },
    "& .PrivateTimePickerToolbar-ampmSelection .MuiButton-root .MuiTypography-root.Mui-selected": {
      background: "#F4EBFF",
      borderRadius: "1rem",
    },
    "& .PrivatePickersToolbar-dateTitleContainer > .MuiIconButton-root": {
      display: "none"      
    }
  }
}));

const switchStyle = {
  color: "#7F56D9",
  "& .Mui-checked > .MuiSwitch-thumb": {
    color: "#7F56D9",
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#D6C8F4",
  }
}

const ExamSchedule = forwardRef(({ examSchedule, setExamSchedule, applicationCloseDate, visibleSaveButton = false, examDocID = false, setSubExams }, ref) => {

  const classes = useStyles();
  let params = useParams();
  let examShortName = params.examShortName;
  const loggedInUser = useSelector((state) => state.Auth.user);

  const [isLoading, setIsLoading] = useState(false);

  async function SaveDetails() {
    let flag = validateSchedule();
    console.log(flag, examDocID);
    if (flag && examDocID) {
      let count = 0;
      setIsLoading(true);
      examSchedule.forEach((element,index) => {
        let _ele = Object.assign({}, element);
        if (_ele.hasOwnProperty("docID")) {
          // Update
          delete _ele.docID;
          delete _ele.added;
          updateExamSchedule(examDocID, element.docID, _ele).then(res => {
            console.log("Update res => ", res);
            count++;
            if (examSchedule.length === count) {
              successToast("Exam schedule saved successfully.");
              setSubExams([...examSchedule]);
              setIsLoading(false);
              addExamTimes()
            }
          })
        } else {
          // add
          addExamSchedule(examDocID, _ele).then(res => {
            console.log("add res => ", res.id);
            setExamSchedule(pre=>{
              pre[index].docID = res.id;
              return [...pre]
            })
            count++;
            if (examSchedule.length === count) {
              successToast("Exam schedule saved successfully.");
              setSubExams([...examSchedule]);
              setIsLoading(false);
              addExamTimes()
            }
          })
        }
      })
    }
  }
  function addExamTimes() {
    let arr = []
    examSchedule.forEach(element => {
      arr.push(element.examDates.sort(function (a, b) { return new Date(a.date) - new Date(b.date); })[0].date)
    });
    let examDate = arr.sort(function (a, b) { return new Date(a) - new Date(b); })[0]
    console.log(examDate);
    updateExamDetails({ examDate }, examDocID).then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    })
  }

  function deleteSubExam(_index, _deleteSubExamDocID) {
    console.log(_index, _deleteSubExamDocID);
    deleteSubExamSchedule(examDocID, _deleteSubExamDocID).then(res => {
      console.log(res);
      let preValue = examSchedule;
      preValue.splice(_index, 1);
      setExamSchedule([...preValue]);
      setSubExams([...preValue]);
    }).catch(err => {
      console.log(err);
    })


    // let preValue = examSchedule; 
    // preValue.splice(_index, 1);
    // setExamSchedule([...preValue])
  }

  useImperativeHandle(ref, () => ({
    validateSchedule
  }));

  useEffect(() => {
    if (examShortName && examDocID) {
      getExamScheduleById(examDocID).then(res => {
        let _examSchedule = res.docs.map(m => ({ docID: m.id, ...m.data() }));
        console.log({ _examSchedule });
        setExamSchedule([..._examSchedule]);
      });
    }
  }, [])


  function validateSchedule() {
    let isFormValid = true
    const exams = _.uniqBy(examSchedule.filter((e) => e.examName !== ''), function (e) {
      return e.examName.toLowerCase().trim();
    });
    if (examSchedule.length !== exams.length) {
      isFormValid = false
    }
    examSchedule.forEach(element => {
      const dates = _.uniqBy(element.examDates.filter((e) => e.date !== ''), function (e) {
        return e.date;
      });
      if (element.examDates.length !== dates.length) {
        isFormValid = false
      }
      const subjects = _.uniqBy(element.subjects.filter((e) => e.subjectName !== ''), function (e) {
        return e.subjectName.toLowerCase().trim();
      });
      if (element.subjects.length !== subjects.length) {
        isFormValid = false
      }
      element.subjects.forEach(_ele => {
        let q = element.subjects.filter(f => f.subjectExamDate === _ele.subjectExamDate && f.subjectExamTime === _ele.subjectExamTime)
        if (q.length > 1) {
          isFormValid = false;
        }
      })
      element.examDates.forEach(date => {
        const slots = _.uniqBy(date.slots.filter((e) => e.time !== ''), function (e) {
          return new Date(e.time).toLocaleTimeString();
        });
        if (date.slots.length !== slots.length) {
          isFormValid = false
        }
      });
    });
    return isFormValid
  }

  return (
    <div className={Styles.secondForm}>
      <div className={Styles.form}>
        <div className={Styles.formTitle}>
          <p>Create Exam Schedule</p>
          <div style={{ marginLeft: "1.5rem" }}>
            <Button
              style={{ color: "#fff", backgroundColor: "#7F56D9" }}
              btnIcon={addIconWhite}
              btnName={"Add\xa0Exam"}
              clicked={() => {
                let arr = examSchedule
                arr.push({
                  examName: "",
                  subjects: [],
                  examDates: [
                    {
                      date: "",
                      slots: [{
                        time: ""
                      }]
                    }
                  ],
                  index: (arr.length > 0) ? (Math.max.apply(Math, arr.map(function (o) { return o.index; })) + 1) : 0
                });
                setExamSchedule([...arr])
              }}
            />
          </div>
        </div>
        <div className={Styles.inputRow}>
          {examSchedule.map((item, index) => (
            <div key={index + "Exam Name"} className={Styles.cardWrapper}>
              <div className={Styles.inputDiv}>
                <div style={{ display: "flex", alignItems: 'center', marginBottom: "1rem" }}>
                  {/* <label>
                    Exam Name<span style={{ color: "red" }}> *</span>
                  </label> */}
                  <div style={{
                    // marginLeft: "1rem",
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: "space-between"
                  }}>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                      <Button
                        style={{ color: "#fff", backgroundColor: "#7F56D9" }}
                        btnIcon={addIconWhite}
                        btnName={"Add Subject"}
                        clicked={() => {
                          let arr = examSchedule[index].subjects
                          arr.push({
                            subjectName: "",
                            subjectExamDate: examSchedule[index].subjects.length===0 ? examSchedule[index].examDates[0].date : '',
                            subjectExamTime: "",
                          });
                          setExamSchedule((preValue) => {
                            preValue[index].subjects = arr
                            return [...preValue]
                          })
                          console.log(examSchedule)
                        }}
                      />
                      <DeleteOutlineOutlinedIcon className={Styles.deleteIcon} onClick={() => {
                        if (examDocID && examShortName && item?.docID) {
                          deleteSubExam(index, item.docID);
                        } else {
                          let preValue = examSchedule;
                          preValue.splice(index, 1);
                          setExamSchedule([...preValue])
                        }
                      }} />
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                      <label>
                        Show Proforma <span style={{ color: "red" }}> *</span>
                      </label>
                      <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
                        <div>NO</div>
                        <Switch
                          sx={[switchStyle]}
                          edge="end"
                          onChange={(e) => {
                            let Pre = [...examSchedule];
                            Pre[index].isProforma = e.target.checked;
                            setExamSchedule([...Pre]);
                          }}
                          checked={item?.isProforma}
                          color="default"
                        />
                        <div>Yes</div>
                      </Box>
                    </Box>

                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "1.2rem", width: "40%" }}>
                    {/* <div className={Styles.whiteRoundBtn}>
                      <img style={{ width: '1.5rem' }} src={addIconWhite} alt=""/>
                    </div> */}
                    <div >
                      <div className={Styles.whiteRoundBtn} style={{marginLeft: 0}}>
                        <label>
                          Exam Name<span style={{ color: "red" }}> *</span>
                        </label>
                        <img style={{ width: '1.5rem' }} src={addIconWhite} alt=""/>
                      </div>
                      <TextField
                        InputProps={{ className: classes.input }}
                        FormHelperTextProps={{
                          classes: {
                            root: classes.helperText,
                          },
                        }}
                        value={item.examName}
                        placeholder="Exam Name"
                        onChange={(newValue) => {
                          if(!newValue.target.value.includes("_")) {
                            setExamSchedule((preValue) => {
                              preValue[index].examName = newValue.target.value;
                              return [...preValue];
                            });
                            // setHelperText((prevalue) => { prevalue.isNameFilled = ''; return [...preValue] })
                          }
                        }}
                        error={true}
                        className={classes.textField}
                        id="outlined-basic"
                        sx={[
                          {
                            "&>div:first-of-type": {
                              border:
                                item.examName==='' || examSchedule.filter((ele) => { return ele.examName.toLowerCase().trim()===item.examName.toLowerCase().trim() }).length > 1
                                  ? "1px solid red !important"
                                  : "1px solid #D0D5DD !important",
                            },
                            width: "100%",
                          },
                        ]}
                        helperText={item.examName==='' ? "Please enter exam name" : examSchedule.filter((ele, i) => { return i <= index && ele.examName.toLowerCase().trim()===item.examName.toLowerCase().trim() }).length > 1 ? "Exam name already exists" : ""}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div style={{ width: "60%" }}>
                    {examSchedule[index].examDates.map((item, dateIndex) => (
                      <div style={{ display: "flex" }} key={`examDates_${dateIndex}`}>
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                            <label>
                              Exam Date<span style={{ color: "red" }}> *</span>
                            </label>
                            {dateIndex===0 ?
                              <div onClick={() => {
                                let arr = examSchedule[index].examDates
                                arr.push({
                                  date: "",
                                  slots: [
                                    {
                                      time: ""
                                    }
                                  ]
                                })
                                setExamSchedule((preValue) => {
                                  console.log('preValue: ', preValue);
                                  preValue[index].examDates = arr
                                  return [...preValue]
                                })
                              }}
                                className={Styles.roundBtn}><img style={{ width: '1.5rem' }} src={addIconWhite} /></div>
                              :
                              <DeleteOutlineOutlinedIcon className={Styles.deleteIcon} onClick={() => {
                                let preValue = examSchedule;
                                preValue[index].examDates.splice(dateIndex, 1);
                                setExamSchedule([...preValue])
                                // setExamSchedule((preValue) => {
                                //   preValue[index].examDates.splice(dateIndex, 1)
                                //   return [...preValue]
                                // })
                              }} />
                            }
                          </div>
                          <div style={{ display: "flex" }}>
                            <div style={{ marginRight: "1.2rem" }}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                  mask="fr"
                                  value={
                                    new Date(item.date)
                                  }
                                  minDate={index===0 ? new Date(new Date(applicationCloseDate).setDate(new Date(applicationCloseDate).getDate() + 1)) : new Date(examSchedule[0].examDates[0].date)}
                                  PopperProps={{ placement: "bottom-start" }}
                                  PaperProps={{ className: classes.calender }}
                                  DialogProps={{className: classes.mobileDatePickerStyles}}
                                  showToolbar={false}
                                  InputProps={{
                                    className: classes.datePicker,
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <CalendarMonthOutlinedIcon 
                                          style={{fontSize: '2rem',marginLeft: '1.5rem',cursor: "pointer"}}
                                          onClick={() => {
                                            document.getElementById(`examSchedule_${index}_examDate_${dateIndex}`).click();
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                    error: true,
                                  }}
                                  onChange={(newValue) => {
                                    setExamSchedule((preValue) => {
                                      if (dateIndex===0 && preValue[index].subjects.length > 0) {
                                        preValue[index].subjects[0].subjectExamDate = newValue !== null ? new Date(newValue).toLocaleDateString() : ""
                                      }
                                      preValue[index].examDates[
                                        dateIndex
                                      ].date = newValue !== null ? new Date(newValue).toLocaleDateString() : "";
                                      return [...preValue];
                                    });
                                    // setHelperText((prevalue) => { prevalue.isExamDateFilled = ''; return [...preValue] })
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      error={true}
                                      helperText={item.date==='' ? "Please select exam date" : examSchedule[index].examDates.filter((ele, i) => i <= dateIndex && ele.date===item.date).length > 1 ? "Exam date already exists" : ""}
                                      FormHelperTextProps={{
                                        classes: {
                                          root: classes.helperText,
                                        },
                                      }}
                                      id={`examSchedule_${index}_examDate_${dateIndex}`}
                                      sx={[
                                        {
                                          "&>div:first-of-type": {
                                            border:
                                              item.date==='' || examSchedule[index].examDates.filter((ele, i) => i <= dateIndex && ele.date===item.date).length > 1
                                                ? "1px solid red !important"
                                                : "1px solid #D0D5DD !important",
                                          },
                                          width: "100%",
                                        },
                                      ]}
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                        <div>
                          {examSchedule[index].examDates[dateIndex].slots.map((item, slotIndex) => (
                            <div style={{ marginBottom: "2rem" }} key={`examDatesSlots_${slotIndex}`}>
                              <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                                <label>
                                  Exam Time<span style={{ color: "red" }}> *</span>
                                </label>
                                {slotIndex===0 ?
                                  <div onClick={() => {
                                    let arr = examSchedule[index].examDates[dateIndex].slots
                                    arr.push({
                                      time: ""
                                    })
                                    setExamSchedule((preValue) => {
                                      preValue[index].examDates[dateIndex].slots = arr
                                      return [...preValue]
                                    })
                                  }} className={Styles.roundBtn}><img style={{ width: '1.5rem' }} src={addIconWhite} alt={""}/></div>
                                  :
                                  <DeleteOutlineOutlinedIcon className={Styles.deleteIcon} onClick={() => {
                                    let preValue = examSchedule;
                                    preValue[index].examDates[dateIndex].slots.splice(slotIndex, 1);
                                    setExamSchedule([...preValue])
                                    // setExamSchedule((preValue) => {
                                    //   preValue[index].examDates[dateIndex].slots.splice(slotIndex, 1)
                                    //   return [...preValue]
                                    // })
                                  }} />
                                }
                              </div>
                              <div style={{ display: "flex" }}>
                                <div>                            
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileTimePicker
                                      value={
                                        new Date(item.time)
                                      }
                                      PopperProps={{ placement: "bottom-start" }}
                                      PaperProps={{ className: classes.calender }}
                                      DialogProps={{className: classes.mobileTimePickerStyles}}
                                      InputProps={{
                                        className: classes.datePicker,
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            <AccessTimeOutlinedIcon 
                                              style={{fontSize: '2rem',marginLeft: '1.5rem', cursor: "pointer"}}
                                              onClick={() => {
                                                document.getElementById(`examSchedule_${index}_examDate_${dateIndex}_examDatesSlots_${slotIndex}`).click();
                                              }}
                                            />
                                          </InputAdornment>
                                        ),
                                        error: true,
                                      }}
                                      onChange={(newValue) => {
                                        if (newValue) {
                                          setExamSchedule((preValue) => {
                                            preValue[index].examDates[dateIndex].slots[slotIndex].time = new Date(newValue).toString();
                                            return [...preValue];
                                          });
                                        } else {
                                          setExamSchedule((preValue) => {
                                            preValue[index].examDates[dateIndex].slots[slotIndex].time = "";
                                            return [...preValue];
                                          });
                                        }
                                        // setHelperText((prevalue) => { prevalue.isExamDateFilled = ''; return [...preValue] })
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          helperText={item.time==='' ? "Please select exam time" : examSchedule[index].examDates[dateIndex].slots.filter((ele, i) => i <= slotIndex && new Date(ele.time).toLocaleTimeString()===new Date(item.time).toLocaleTimeString()).length > 1 ? "Exam time already exists" : ""}
                                          FormHelperTextProps={{
                                            classes: {
                                              root: classes.helperText,
                                            },
                                          }}
                                          id={`examSchedule_${index}_examDate_${dateIndex}_examDatesSlots_${slotIndex}`}
                                          error={true}
                                          sx={[
                                            {
                                              "&>div:first-of-type": {
                                                border:
                                                  item.time === '' || examSchedule[index].examDates[dateIndex].slots.filter((ele, i) => i <= slotIndex && new Date(ele.time).toLocaleTimeString()===new Date(item.time).toLocaleTimeString()).length > 1
                                                    ? "1px solid red !important"
                                                    : "1px solid #D0D5DD !important",
                                              },
                                              width: "100%",
                                            },
                                          ]}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div style={{ marginTop: "0.6rem", marginRight: "1.2rem" }}>
                    <Button
                      style={{ color: "#fff", backgroundColor: "#7F56D9" }}
                      btnIcon={addIconWhite}
                      btnName={"Sub exam"}
                      clicked={() => {
                        console.log(
                          "preValue[index]: ",
                          examForm[index]
                        );
                        setExamSchedule((preValue) => {
                          preValue[index].subExams.push({
                            subExamName: "",
                            subExamDate: "",
                            subjects: [],
                          });
                          return [...preValue];
                        });
                      }}
                    />
                  </div> */}
                </div>
              </div>
              <div className={Styles.inputDiv}>
                {examSchedule[index].subjects.map(
                  (item, subjectIndex) => (
                    <div
                      style={{
                        marginLeft: "3.5rem",
                        display: "flex",
                        alignItems: 'center',
                        marginBottom: "2.6rem",
                      }}
                      key={subjectIndex + "Subject"}
                    >
                      <div style={{ marginRight: "1.6rem", width: "40%" }}>
                        <div>
                          <label>
                            Subject<span style={{ color: "red" }}> *</span>
                          </label>
                          <TextField
                            InputProps={{ className: classes.input }}
                            FormHelperTextProps={{
                              classes: {
                                root: classes.helperText,
                              },
                            }}
                            placeholder="Subject Name"
                            value={item.subjectName}
                            onChange={(newValue) => {
                              setExamSchedule((preValue) => {
                                preValue[index].subjects[
                                  subjectIndex
                                ].subjectName = newValue.target.value;
                                return [...preValue];
                              });
                              // setHelperText((prevalue) => { prevalue.isNameFilled = ''; return [...preValue] })
                            }}
                            error={true}
                            className={classes.textField}
                            id="outlined-basic"
                            sx={[
                              {
                                "&>div:first-of-type": {
                                  border:
                                    item.subjectName === '' || examSchedule[index].subjects.filter((ele, i) => i <= subjectIndex && ele.subjectName.toLowerCase().trim() === item.subjectName.toLowerCase().trim()).length > 1
                                      ? "1px solid red !important"
                                      : "1px solid #D0D5DD !important",
                                },
                                width: "100%",
                              },
                            ]}
                            helperText={item.subjectName === '' ? "Please enter subject name" : examSchedule[index].subjects.filter((ele, i) => i <= subjectIndex && ele.subjectName.toLowerCase().trim()===item.subjectName.toLowerCase().trim()).length > 1 ? "Subject name already exists" : ""}
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "60%" }}>
                        <div>
                          <label>
                            Date<span style={{ color: "red" }}> *</span>
                          </label>
                          <div>
                            <div style={{ marginRight: "1.2rem" }}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                  disabled={subjectIndex === 0 ? true : false}
                                  value={
                                    subjectIndex === 0 ? new Date(examSchedule[index].examDates[0].date) : new Date(item.subjectExamDate)
                                  }
                                  minDate={new Date(examSchedule[index].examDates[0].date)}
                                  PopperProps={{ placement: "bottom-start" }}
                                  DialogProps={{className: classes.mobileDatePickerStyles}}
                                  PaperProps={{ className: classes.calender }}
                                  showToolbar={false}
                                  InputProps={{
                                    className: classes.datePicker,
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <CalendarMonthOutlinedIcon 
                                          style={{fontSize: '2rem',marginLeft: '1.5rem', cursor: "pointer"}}
                                          onClick={() => {
                                            document.getElementById(`examSchedule_${index}_Subject_Date_${subjectIndex}`).click();
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                    error: true,
                                  }}
                                  onChange={(newValue) => {
                                    setExamSchedule((preValue) => {
                                      preValue[index].subjects[
                                        subjectIndex
                                      ].subjectExamDate = newValue !== null ? new Date(newValue).toLocaleDateString() : "";
                                      return [...preValue];
                                    });
                                    // setHelperText((prevalue) => { prevalue.isExamDateFilled = ''; return [...preValue] })
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      helperText={item.subjectExamDate==='' ? "Please enter subject date" : ""}
                                      FormHelperTextProps={{
                                        classes: {
                                          root: classes.helperText,
                                        },
                                      }}
                                      id={`examSchedule_${index}_Subject_Date_${subjectIndex}`}
                                      error={true}
                                      sx={[
                                        {
                                          "&>div:first-of-type": {
                                            border:
                                              item.subjectExamDate===''
                                                ? "1px solid red !important"
                                                : "1px solid #D0D5DD !important",
                                          },
                                          width: "100%",
                                        },
                                      ]}
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                        <div>
                          <label>
                            Time<span style={{ color: "red" }}> *</span>
                          </label>
                          <div style={{ display: "flex" }}>
                            <div>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileTimePicker
                                  value={
                                    new Date(item.subjectExamTime)
                                  }
                                  // minDate={new Date(applicationCloseDate)}
                                  PopperProps={{ placement: "bottom-start" }}
                                  PaperProps={{ className: classes.calender }}
                                  DialogProps={{className: classes.mobileTimePickerStyles}}
                                  InputProps={{
                                    className: classes.datePicker,
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <AccessTimeOutlinedIcon
                                          style={{fontSize: '2rem',marginLeft: '1.5rem', cursor: "pointer"}}
                                          onClick={() => {
                                            document.getElementById(`examSchedule_${index}_Subject_Time_${subjectIndex}`).click();
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                    error: true,
                                  }}
                                  onChange={(newValue) => {
                                    if (newValue) {
                                      setExamSchedule((preValue) => {
                                        preValue[index].subjects[subjectIndex].subjectExamTime = new Date(newValue).toString();
                                        return [...preValue];
                                      });
                                    } else {
                                      setExamSchedule((preValue) => {
                                        preValue[index].subjects[subjectIndex].subjectExamTime = "";
                                        return [...preValue];
                                      });
                                    }
                                    // setHelperText((prevalue) => { prevalue.isExamDateFilled = ''; return [...preValue] })
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      helperText={item.subjectExamTime==='' ? "Please select subject time" : examSchedule[index].subjects.filter((ele, i) => i <= subjectIndex && ele.subjectExamDate===item.subjectExamDate && new Date(ele.subjectExamTime).toLocaleTimeString()===new Date(item.subjectExamTime).toLocaleTimeString()).length > 1 ? "Subject time already exists" : ""}
                                      FormHelperTextProps={{
                                        classes: {
                                          root: classes.helperText,
                                        },
                                      }}
                                      id={`examSchedule_${index}_Subject_Time_${subjectIndex}`}
                                      sx={[
                                        {
                                          "&>div:first-of-type": {
                                            border:
                                              item.subjectExamTime==='' || examSchedule[index].subjects.filter((ele, i) => i <= subjectIndex && ele.subjectExamDate===item.subjectExamDate && new Date(ele.subjectExamTime).toLocaleTimeString()===new Date(item.subjectExamTime).toLocaleTimeString()).length > 1
                                                ? "1px solid red !important"
                                                : "1px solid #D0D5DD !important",
                                          },
                                          width: "100%",
                                        },
                                      ]}
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>

                      </div>
                      <DeleteOutlineOutlinedIcon 
                        className={Styles.deleteIcon}
                        style={{marginTop: '1.8rem'}}
                        onClick={() => {
                        let preValue = examSchedule;
                        preValue[index].subjects.splice(subjectIndex, 1);
                        setExamSchedule([...preValue])
                        // setExamSchedule((preValue) => {
                        //   preValue[index].subjects.splice(subjectIndex, 1)
                        //   return [...preValue]
                        // })
                      }} />
                    </div>
                  )
                )}
              </div>
              <Box display={"flex"} flexDirection={"row"}>
                <div className={Styles.inputDiv}>
                  <label>
                    Store Thumb Impression <span style={{ color: "red" }}> *</span>
                  </label>
                  <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
                    <div>NO</div>
                    <Switch
                      sx={[switchStyle]}
                      edge="end"
                      onChange={(e) => {
                        let Pre = [...examSchedule];
                        Pre[index].isStoreThumbImpression = e.target.checked;
                        setExamSchedule([ ...Pre ])
                      }}
                      checked={item?.isStoreThumbImpression}
                      color="default"
                    />
                    <div>Yes</div>
                  </Box>
                </div>
                <div className={Styles.inputDiv} style={{marginLeft: "3rem"}}>
                  <label>
                    Store Identity Document <span style={{ color: "red" }}> *</span>
                  </label>
                  <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} style={{ fontSize: "1.5rem" }} ml={2}>
                    <div>NO</div>
                    <Switch
                      sx={[switchStyle]}
                      edge="end"
                      onChange={(e) => {
                        let Pre = [...examSchedule];
                        Pre[index].isStoreIdentityDocument = e.target.checked;
                        setExamSchedule([ ...Pre ])
                      }}
                      checked={item?.isStoreIdentityDocument}
                      color="default"
                    />
                    <div>Yes</div>
                  </Box>
                </div>
              </Box>
            </div>
          ))}
        </div>
        {visibleSaveButton &&
          <Box display={"flex"} justifyContent={"flex-end"}>
            <div style={{ marginBottom: "1.5rem" }}>
              <Button 
                style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} 
                btnName={"Save"} clicked={SaveDetails} 
                disable={!loggedInUser.tabAccess.exams.some(s=> s==='edit') || isLoading || examSchedule.length === 0}
                isLoading={isLoading}
                loadingColor={"#FFFFFF"}
              />
            </div>
          </Box>
        }
      </div>
    </div >
  );
})

export default ExamSchedule;
import React,{ useEffect, useState } from "react";

//* images

//* import sub-component
import Button from "../../Subcomponent/Button";
import TabsMenu from "../../Subcomponent/TabsMenu";
import Dropdown from "../../Subcomponent/Dropdown";

//* import component
import HallTicket from "../../Components/HallTicket/HallTicket";

//* Import css
import cssClasses from "./HallTicketScreen.module.css";

//* import MUI
import Box from '@mui/material/Box';
import { makeStyles } from "@material-ui/core/styles";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
// import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from "@mui/lab";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

//* Import store
import { useDispatch } from "react-redux";
import actions from "../../Redux Store/actions";

//* Import Services
import { presentLoader, dismissLoader } from "../../services/loaderService"
import { getAllExamForHallTicket } from "../../services/ExamService";
import { getLogsOfHallTicketByExamID } from "../../services/HallTicketService";

const tabs = [
  {text:"Generate Hall Ticket",value:"Generate Hall Ticket"},
  {text:"History Of Generates",value:"History Of Generates"},
]

// const examList11 = [
//   {label: "UPSC", value:"UPSC"},
//   {label: "MPSC", value:"MPSC"},
//   {label: "JEE", value:"JEE"},
//   {label: "MHT-CET", value:"MHT-CET"},
// ] 

export default function HallTicketWrapper() {

  const dispatch = useDispatch();
 
  const [examList, setExamList] = useState([]);
  const [examListWithDetails, setExamListWithDetails] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedExamDocId, setSelectedExamDocId] = useState(null);

  const [historyLogs, setHistoryLogs] = useState([]);
  const [selectedExamDocIdForHistory, setSelectedExamDocIdForHistory] = useState(null);
  const [selectedExamForHistory, setSelectedExamForHistory] = useState("");
  

  useEffect( async () => {
    dispatch(actions.activeMenu('hall-tickets'));
    presentLoader();
    await getExamsFromServer();
  }, []);

  async function getExamsFromServer() {
    getAllExamForHallTicket().then(res=>{
      if(!res.empty) {
        let _exams = res.docs.map(m=>({ label: m.data().name, value: m.data().examShortName, docID : m.id }));
  
        console.log({_exams});
        setExamListWithDetails([...res.docs.map(m=>({ ...m.data(), docID : m.id }))])
        setExamList([..._exams]);
        setSelectedExam(_exams[0].value);
        setSelectedExamDocId(res.docs[0].id);
        dismissLoader();
      } else{
        dismissLoader();      
      }
    })
  }
  
  function onChangeSelectExam(_newValue) {
    setSelectedExam(_newValue);
    setSelectedExamDocId(examList.find(f=>f.value === _newValue)?.docID);
  }

  useEffect(() => {
    if (selectedExamDocIdForHistory) {
      getLogsOfHallTicketByExamID(selectedExamDocIdForHistory).then(res=>{
        let _list = res.docs.map(m=>({date:new Date(m.data().createdAt.toDate()).toDateString(), ...m.data()}))
        setHistoryLogs([..._list]);
        console.log(_list);
      })
    }
  }, [selectedExamDocIdForHistory])
  

  return (
    <>
      <div className={cssClasses.pageWrapper}>
        <div className={cssClasses.headerWrapper}>
          <div className={cssClasses.headingWrapper}>
            <p>Generate Hall Ticket</p>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
          </div>
        </div>

        <div className={cssClasses.breaker}></div>

        <Box className={cssClasses.TabsWrapper}>
          <TabContext value={selectedTab}>
            <TabsMenu 
              selectedColor={"#6941C6"} 
              selectedBackgroundColor={"#ffffff"}
              stylesForTab={{
                color: '#344054',
                fontSize: '1.6rem',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '2.4rem',
                padding: "0.8rem 1.2rem",
                textTransform: 'unset'
              }}
              stylesForWrapper={{
                padding:"1.5rem",
                background:"#FFFFFF",
              }}
              borderColor={"#6941C6"}
              list={tabs}
              value={selectedTab}
              handleChange={(e,value) => {setSelectedTab(value)}}
            />
            <TabPanel className={cssClasses.tabPanel} value={"Generate Hall Ticket"} index={0}>
              <Box className={cssClasses.mainTabPanelWrapper}>
                <div className={cssClasses.panelHeader}>
                  {/* <label>Generate Hall Tickets</label> */}
                  <label>Select Exam</label>
                  <div className={cssClasses.dropDownWrapper}>
                    <div>
                      <Dropdown 
                        placeHolder={"Select Exam"}
                        list={examList} 
                        selectedValue={selectedExam} 
                        onChangeSelect={(_newValue) => {onChangeSelectExam(_newValue)}}
                        marginTop={"0"}
                      />
                    </div>
                    <div style={{marginTop:"0.6rem"}}>
                      {/* <Button style={{ color: "#FFFFFF", backgroundColor: "#6941C6" }} btnName={"Generate"} /> */}
                    </div>
                  </div>
                  <HallTicket examDocID={selectedExamDocId} examObject={examListWithDetails?.find(f=>f?.docID === selectedExamDocId)}/>
                </div>
              </Box>
            </TabPanel>
            <TabPanel className={cssClasses.tabPanel} value={"History Of Generates"} index={1}>
              <Box className={cssClasses.mainTabPanelWrapper}>
                <div className={cssClasses.panelHeader}>
                  <label>Select Exam</label>
                  <div className={cssClasses.dropDownWrapper}>
                    <div>
                      <Dropdown 
                        placeHolder={"Select Exam"}
                        list={examList} 
                        selectedValue={selectedExamForHistory} 
                        onChangeSelect={(_newValue) => {
                          setSelectedExamForHistory(_newValue);
                          setSelectedExamDocIdForHistory(examList.find(f=>f.value === _newValue)?.docID);
                        }}
                        marginTop={"0"}
                      />
                    </div>
                    <div style={{marginTop:"0.6rem"}}>
                      {/* <Button style={{ color: "#FFFFFF", backgroundColor: "#6941C6" }} btnName={"Generate"} /> */}
                    </div>
                  </div>
                  <div>                                       
                    <Timeline>
                      {
                        historyLogs.map((logItem,index) => (
                          <TimelineItem key={`TimeLine_${index}`}>
                            <TimelineSeparator>
                              {index===0 ? <TimelineDot variant="outlined" color="secondary"/> : <TimelineDot/>}                     
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }} style={{fontSize:"1.5rem"}}> 
                              <p>{logItem.date}</p>
                              <p>{logItem.action}</p>
                              <p>Admin : {logItem.userName}</p>
                            </TimelineContent>
                          </TimelineItem>
                        ))
                      }
                    </Timeline>
                  </div>
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>

      </div>
    </>
  )
}

import React, { useState, useEffect, useMemo } from "react";

// * import mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid } from '@mui/x-data-grid';

//* import Sub components 
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";

//* import css
import cssClasses from "./AddExamCentersInExam.module.css";

//* import Images
import crossImage from "../../assets/images/cross.svg";


//* Import Service
import { getAllExamCenters, setExamCenterInExam, getAllExamCentersInExam } from "../../services/ExamCenterService";
import { updateExamDetails } from "../../services/ExamService";
import { successToast, errorToast } from "../../services/Toast";
import { presentLoader, dismissLoader } from "../../services/loaderService"

import _ from "lodash"
import { Checkbox } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  Modal: {
    borderRadius: "10px 10px 10px 10px",
  },
}));
const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected": {
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',
  
  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem !important",
      height: "2rem !important",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
    paddingRight: '1rem'
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
    paddingRight: '1rem'
  },
  "& .MuiButtonBase-root": {
    borderRadius: '0'
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "hidden !important"
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "7.2rem !important",
    minHeight: "7.2rem !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",    
    minHeight: "7.2rem !important",
    maxHeight: "7.2rem !important",
    outline: 'none !important',
  },
  "& .MuiButtonBase-root": {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '0 1rem'
  },
  "& .MuiTablePagination-actions": {
    margin: '0 2rem',
  },
  "& .MuiDataGrid-checkboxInput": {
    background: 'none !important',
    border: 'none !important'
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none !important"
  },
  "& .MuiDataGrid-cellCheckbox .MuiSvgIcon-root, .MuiDataGrid-columnHeaderCheckbox .MuiSvgIcon-root, .css-i4bv87-MuiSvgIcon-root": {
    width: "2.5rem !important",
    height: "2.5rem !important"
  },
  "& .MuiDataGrid-cell--editing .MuiInputBase-input": {
    fontWeight: "400",
    fontSize: "1.4rem",
    lineHeight: "2rem",
    color: "#667085",
    fontFamily: 'Inter-Medium',
  }
}

function AddExamCentersInExam({ open, handleClose, examDocID = null, examName = "" }) {
  const classList = useStyles();

  const [examCentersOriginalList, setExamCentersOriginalList] = useState([]);
  const [examCenterList, setExamCenterList] = useState([]);
  const [selectedExamCenterList, setSelectedExamCenterList] = useState([])
  const [alreadySelected, setAlreadySelected] = useState([])

  const [dataGridLoading, setDataGridLoading] = useState(true);
  const [searchField, setSearchField] = useState("examCenterName");
  const [examCenterSearchValue, setExamCenterSearchValue] = useState("");

  const saveButtonDisableToggle = useMemo(() => CheckSelectedData(), [examCentersOriginalList, examCenterList, selectedExamCenterList]);

  const columns = [
    {
      field: 'examCenterName', headerName: 'Exam Center Name', sortable: false, width: 300,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              sx={{ width: "2rem", height: "2rem" }}
              style={{ width: "2rem", height: "2rem" }}
              checked={selectedExamCenterList.filter((ele) => { return ele.id === params.row.id }).length > 0}
              disabled={alreadySelected.filter((ele) => { return ele.id === params.row.id }).length > 0}
              onChange={(e) => {
                console.log(e.target.checked)
                if (e.target.checked) {
                  setSelectedExamCenterList([...selectedExamCenterList, params.row])
                } else {
                  setSelectedExamCenterList((pre) => {
                    let index = pre.findIndex((e) => { return e.id === params.row.id })
                    pre.splice(index, 1)
                    return [...pre]
                  })
                }
              }}
            />
            <p>{params.row.examCenterName}</p>
          </>
        )
      }
    },
    { field: 'City', headerName: 'City', sortable: false, width: 150 },
    { field: 'examCenterCode', headerName: 'Exam Center Code', sortable: false, width: 145 },
    { field: 'numberOfSeats', headerName: 'Max Capacity Of Seats', sortable: false, width: 145 },
    // { field: 'capacity', headerName: 'Provide capacity of Seats', editable: true,type: 'number',sortable: false, width: 180 },
    {
      field: 'capacity',
      headerName: 'Provide Capacity Of Seats',
      sortable: false,
      width: 180,
      renderCell: (params) => {
        return (<>
          <input type="number"
            value={params.row[params.field]} 
            className={cssClasses.inputCapacity}
            min={0}
            readOnly={alreadySelected.some(s => s.docId === params.row.docId)}
            disabled={alreadySelected.some(s => s.docId === params.row.docId)}
            onChange={(e) => {
              let _value = +e.target.value;
              setExamCentersOriginalList(pre => {
                let index = pre.findIndex(f => f.id === params.id);
                if (index >= 0) {
                  pre[index][params.field] = _value;
                }
                return [...pre];
              });
              setExamCenterList(pre => {
                let index = pre.findIndex(f => f.id === params.id);
                if (index >= 0) {
                  pre[index][params.field] = _value;
                }
                return [...pre];
              });
              setSelectedExamCenterList(pre => {
                let index = pre.findIndex(f => f.id === params.id);
                if (index >= 0) {
                  pre[index][params.field] = _value;
                }
                return [...pre];
              });
            }}
            style={{
              borderColor: params.row["capacity"] > params.row["numberOfSeats"] ? "red" : ""
            }}
          />
        </>);
      },
    },
    { field: 'address', headerName: 'Exam Center Location', sortable: false, width: 500 },
  ]

  useEffect(() => {
    // console.log({examDocID});
    if (examDocID && !examCentersOriginalList.length && !examCenterList.length && examCenterSearchValue === "") {
      getAllExamCentersFromServer()
    } else if (examCenterSearchValue !== "") {
      let _list = examCentersOriginalList.filter(f => f.examCenterName?.toLowerCase()?.includes(examCenterSearchValue?.toLowerCase()));
      setExamCenterList([..._list]);
    } else {
      setExamCenterList([...examCentersOriginalList]);
    }
  }, [examCenterSearchValue])

  function getAllExamCentersFromServer() {
    getAllExamCenters().then(res => {
      let _examCenterList = convertFirebaseObjToDataGridObj(res.docs.map(m => ({ docId: m.id, ...m.data(), numberOfSeats: +m.data()?.numberOfSeats, lastUpdateAtLocal: m.data().lastUpdateAt.toDate() })));
      
      // for (let index = 0; index < 50; index++) {
      //   const element = Object.assign({},_examCenterList[0]);
      //   element.id = "ss"+index;
      //   element.examCenterName = element.examCenterName+"___"+index;
      //   _examCenterList.push(element)
      // }

      setExamCentersOriginalList([..._examCenterList])
      setExamCenterList([..._examCenterList])
      setDataGridLoading(false);
      console.log(_examCenterList);
    })
    getAllExamCentersInExam(examDocID).then(res => {
      let list = res.docs.map(m => ({ docId: m.id, ...m.data() }));
      setAlreadySelected([...list]);
    })
  }
  
  const onClickViewCenter = (_center) => {
    console.log({ _center });
  }

  // useEffect(() => {
  //   console.log("selectedExamCenterList => ",selectedExamCenterList);
  // }, [selectedExamCenterList])
  
  function CheckSelectedData() {
    if (!selectedExamCenterList.filter(f => f.capacity > f.numberOfSeats).length && selectedExamCenterList.length) {
      let _arr = selectedExamCenterList.map(m => {
        let obj = _.cloneDeep(m);
        delete obj.id;
        return obj;
      })
      // console.log(_arr);
      const sum = _arr.reduce((accumulator, object) => {
        return accumulator + object.capacity;
      }, 0);

      // console.log("TotalCapacity",sum);

      return true;
      
    } else {
      // console.log("Error for => ",selectedExamCenterList.filter(f=>f.capacity > f.numberOfSeats));
      return false;
    }
  }

  function setExamCentersInServers() {
    let count = 0;
    let flag = CheckSelectedData();
    console.log({ flag });
    if (flag) {
      let totalExamCenterListCount = selectedExamCenterList.length + alreadySelected.length;
      presentLoader();
      selectedExamCenterList.forEach((element) => {
        setExamCenterInExam(element.docId, examDocID, element).then(res => {
          count++;
          if (count === selectedExamCenterList.length) {
            updateExamDetails({ examCenterAddedCount: totalExamCenterListCount }, examDocID).then(result => {
              successToast("Exam center's added successfully.");
              handleClose(true);
              dismissLoader();
            }).catch(err => {
              console.log(err);
              errorToast("Something went wrong! Try again");
              dismissLoader();
            })
          }
        }).catch(err => {
          console.log(err);
          errorToast("Something went wrong! Try again");
          dismissLoader();
        })
      });
    }
  }

  return (
    <Modal
      className={classList.Modal}
      open={open}
      // open={true}
      onClose={() => { handleClose(false) }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root": {
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>
        <div className={cssClasses.header}>
          <h1>Add exam centers in {examName} Exam</h1>
          <div><SearchBar placeholder={"Search"} search={(_value) => setExamCenterSearchValue(_value)} /></div>
          <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={() => { handleClose(false) }} />
        </div>

        <div className={cssClasses.mainContainer}>
          <Box className={cssClasses.mainTabPanelWrapper}>
            {/* <div className={cssClasses.mainTabPanelHeader}>
              <div>
                <p>Exam Centers</p>
              </div>
              <div>
                <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={crossArrow} btnName={"Move\xa0Students"} clicked={() => {setShiftStudentsModalToggle(true);}}/></div>
                { examDocID &&
                  <div><Button style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }} btnIcon={addIcon} btnName={"Add\xa0Exam\xa0Center"} clicked={() => {setAddExamCenterInExamModalToggle(true);}}/></div>
                }
              </div>
            </div> */}

            <div className={cssClasses.searchAndFilterWrapper}>
              {/* <div>
                <ToggleButtonGroup
                  color="primary"
                  value={searchField}
                  onChange={(e) => {setSearchField(e.target.value);}}
                  exclusive
                  style={{filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))", display:"none"}}
                  // onChange={handleChange}
                >
                  <ToggleButton value="examCenterName" sx={[toggleButtonStyle]}>Name</ToggleButton>
                  <ToggleButton value="By Exam date" sx={[toggleButtonStyle]}>By Exam date</ToggleButton>
                  <ToggleButton value="By etc" sx={[toggleButtonStyle]}>By etc</ToggleButton>
                </ToggleButtonGroup>
              </div> */}
              {/* <div> */}
                {/* <div><SearchBar placeholder={"Search"} search={(_value) => setExamCenterSearchValue(_value)}/></div> */}
                {/* <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={filterIcon} btnName={"Filters"} /></div> */}
              {/* </div> */}
            </div>

            <div className={cssClasses.examCenterWrapper} style={{ height: "50rem" }}>
              <DataGrid
                rows={dataGridLoading ? [] : examCenterList}
                columns={columns}
                hideFooterPagination={false}
                hideFooter={false}            
                pageSize={10}
                rowsPerPageOptions={[10]}
                // page={pageMaintain}
                sx={[dataGridStyles]}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                disableVirtualization
                // checkboxSelection
                // keepNonExistentRowsSelected
                loading={dataGridLoading}
                
              // isRowSelectable={(params) => {
              //   // console.log(params); 
              //   return !alreadySelected.some(s => s.docId === params.row.docId)
              // }}

              // onSelectionModelChange={(newSelectionModel) => {
              //   console.log(newSelectionModel);
              // const selectedIDs = new Set(newSelectionModel);
              // const selectedRowData = examCentersOriginalList.filter((row) =>
              //   selectedIDs.has(row.id.toString())
              // );
              // // console.log(selectedRowData);
              // setSelectedExamCenterList([...selectedRowData])
              // }}
                // onCellEditStop={(p,e,d)=>{
                //   console.log({p,e,d});
                // }}
                // onCellEditCommit={(p,e,d)=>{
                //   console.log({p,e,d});
                // }}
                // onCellEditCommit={(p,e,d)=>{
                //   console.log({p,e,d});
                // }}
              />
            </div>

          </Box>
        </div>

        <div className={cssClasses.footer}>
          <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Cancel"} clicked={() => { handleClose(false) }} /></div>
          <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Save"} disable={!saveButtonDisableToggle} clicked={setExamCentersInServers} /></div>
        </div>
      </Box>
      
    </Modal>
  )
}

export default AddExamCentersInExam;

function convertFirebaseObjToDataGridObj(_serverList) {
  return _serverList.map(m => ({
    id: m.docId,
    capacity: +m.numberOfSeats,
    ...m
  }))
}
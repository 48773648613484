/* eslint-disable no-unused-vars */
import React, { useState, useEffect, PureComponent } from "react";
import Styles from "./Dashboard.module.css";
import Button from "../../Subcomponent/Button";
import ThreeDots from "../../assets/images/more-vertical.svg";
import SucessIcon from "../../assets/images/sucessIcon.svg";
import LossIcon from "../../assets/images/lossIcon.svg";

//* Import store
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux Store/actions";

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { getAllSubExamScheduled } from "../../services/ExamService";
import { getAllRegisteredStudent } from "../../services/StudentService";
import { getAllExamCenters } from "../../services/ExamCenterService";
const AreaChartCss = {
  width: "50%",
  height: "50%",
};
export default function Dashboard() {

  const dispatch = useDispatch();

  let studentStatus = [
    {
      title: "Total Student Registered",
      count: "0",
      percent: 40,
      status: "profit",
      data: [
        {
          date: "02/03/2021",
          count: 200,
        },
        {
          date: "05/03/2021",
          count: 1250,
        },
        {
          date: "07/03/2021",
          count: 5000,
        },
        {
          date: "08/03/2021",
          count: 6000,
        },
      ],
    },
    // {
    //   title: "Applied for Exam",
    //   count: "0",
    //   percent: 40,
    //   status: "loss",
    //   data: [
    //     {
    //       date: "02/03/2021",
    //       count: 200,
    //     },
    //     {
    //       date: "05/03/2021",
    //       count: 3000,
    //     },
    //     {
    //       date: "07/03/2021",
    //       count: 500,
    //     },
    //     {
    //       date: "08/03/2021",
    //       count: 2500,
    //     },
    //   ],
    // },
    {
      title: "Total Exam Centers",
      count: "0",
      percent: 20,
      status: "profit",
      data: [
        {
          date: "02/03/2021",
          count: 800,
        },
        {
          date: "05/03/2021",
          count: 3000,
        },
        {
          date: "07/03/2021",
          count: 8000,
        },
        {
          date: "08/03/2021",
          count: 700,
        },
      ],
    },
  ];
  let secondArray = [
    {
      title: "Total exam scheduled",
      total: "0",
      percent: 100,
      status: "profit",
    },
    // {
    //   title: "Active now",
    //   total: "316",
    //   percent: 100,
    //   status: "profit",
    // },
  ];
  const [status, setStat] = useState(studentStatus);
  const [statisticsCount, setStatisticsCount] = useState({
    "Total exam scheduled": 0,
    "Active now": 0,
    "Total Student Registered": 0,
    "Total Exam Centers": 0,
    "Applied for Exam": 0,
  })
  // const gradientOffset = () => {
  //   const dataMax = Math.max(...studentStatus[0].data.map((i) => i.count));
  //   const dataMin = Math.min(...studentStatus[0].data.map((i) => i.count));

  //   if (dataMax <= 0) {
  //     return 0;
  //   }
  //   if (dataMin >= 0) {
  //     return 1;
  //   }

  //   return dataMax / (dataMax - dataMin);
  // };
  // const off = gradientOffset();

  useEffect(() => {
    dispatch(actions.activeMenu('dashboard'));
    getAllSubExamScheduledFromServer();
    getTotalStudentRegisteredCount();
    getTotalExamCentersCount();
  }, [])

  function getAllSubExamScheduledFromServer() {
    getAllSubExamScheduled().then(res=>{
      let _list = res.docs.map(m=>({ path:m.ref.path ,...m.data()}))
      let _statisticsCount = statisticsCount;
      _statisticsCount["Total exam scheduled"] = _list.length;
      setStatisticsCount({..._statisticsCount});
    }).catch((err) => {
      console.log(err);
    })
  }
  function getTotalStudentRegisteredCount() {
    getAllRegisteredStudent().then(res=>{
      console.log(res.exists());
      console.log(res.data());
      let _statisticsCount = statisticsCount; 
      _statisticsCount["Total Student Registered"] = res.data()?.studentCount ? res.data()?.studentCount : 0;
      setStatisticsCount({..._statisticsCount});
    }).catch((err) => {
      console.log(err);
    })
  }
  function getTotalExamCentersCount() {
    getAllExamCenters().then(res=>{
      let _list = res.docs.map(m=>({ path:m.ref.path ,...m.data()}))
      let _statisticsCount = statisticsCount; 
      _statisticsCount["Total Exam Centers"] = _list.length;
      setStatisticsCount({..._statisticsCount});
    }).catch((err) => {
      console.log(err);
    })
  }
  
  // useEffect(()=>{
  //   console.log(statisticsCount);
  // },[statisticsCount])
  


  const statusCard = status.map((each,index) => {
    return (
      <div className={Styles.wrapperCard} key={`ParentEach_${index+11}_${each.title}`}>
        <div className={Styles.wrapperCardTitle}>
          <div className={Styles.cardTitle}>{each.title}</div>
          {/* <img className={Styles.threeDots} src={ThreeDots} alt="" onClick={()=>{console.log(each)}}></img> */}
        </div>
        <div className={Styles.wrapperLeftRightCard}>
          <div className={Styles.TotalRate}>
            <div className={Styles.count}>{statisticsCount[each.title]}</div>
            {/* <div className={Styles.wrapperRates}>
              <img
                src={each.status === "profit" ? SucessIcon : LossIcon}
                alt=""
              ></img>
              <div
                className={
                  each.status === "profit" ? Styles.percent : Styles.loss
                }
              >
                {each.percent}%
              </div>
              <div className={Styles.lastMonthText}>vs last month</div>
            </div> */}
          </div>
          {/* <div className={Styles.wrapperGraph}>
            <AreaChart
              sx={[AreaChartCss]}
              width={128}
              height={64}
              data={each.data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <XAxis dataKey="date" hide />
              <YAxis hide />
              <Tooltip />
              <defs>
                <linearGradient
                  id={each.status === "profit" ? "profit" : "loss"}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={
                      each.status === "profit" ? "#12B76A" : "#F04438"
                    }
                    stopOpacity={0.5}
                  />
                  <stop
                    offset="95%"
                    stopColor={
                      each.status === "profit" ? "#12B76A" : "#F04438"
                    }
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="count"
                stroke={each.status === "profit" ? "#12B76A" : "#F04438"}
                fill={
                  each.status === "profit" ? "url(#profit)" : "url(#loss)"
                }
              />
            </AreaChart>
          </div> */}
        </div>
      </div>
    );
  });
  const secondCard = secondArray.map((each,index) => {
    return (
      
      <div className={Styles.wrapperCard} key={`Each_${index}_${each.title}`}>
        <div className={Styles.wrapperCardTitle}>
          {" "}
          <div className={Styles.cardTitle}>{each.title}</div>
          {/* <img className={Styles.threeDots} src={ThreeDots} alt=""></img> */}
        </div>
        <div className={Styles.TotalRateSideBySide}>
          <div className={Styles.count}>{statisticsCount[each.title]}</div>
          <div className={Styles.wrapperProfit}>
            {/* <img src={each.status === "profit" ? SucessIcon : LossIcon} alt="" />
            <div className={each.status === "profit" ? Styles.percentWithBackground : Styles.lossWithBackground}>
              {each.percent}%
            </div> */}
          </div>
        </div>
      </div>
     
    );
  });
  return (
    <>
      <div className={Styles.wrapperContent}>
        <div className={Styles.wrapperTitle}>
          <div className={Styles.title}>Welcome to Dashboard</div>
          {/* <div className={Styles.subTitle}>
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </div> */}
        </div>
        <div className={Styles.content}>
          {statusCard}
          {secondCard}
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { Box } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  dropdown: {
    width: "100%",
    marginTop: "0.6rem",
    border: '1px solid #D0D5DD !important',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
    borderRadius: '0.8rem',
    padding: '1rem 1.4rem !important',
    fontSize: "1.4rem",
    "& fieldSet": {
      border: 'none'
    },
    "&:hover": {
      border: "1px solid #D0D5DD !important"
    },
    "& div:first-child": {
      padding: "0"
    },
    "&.Mui-focused": {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #D6BBFB !important',
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;"
      }
    },
  },
  dropdownMenu: {
    "& li .MuiTypography-root": {
      fontSize: "1.4rem",
    }
  },
  dropdownIcon: {
    width: '2rem !important',
    height: '2rem !important'
  },
  dropdownInput: {
    fontSize: "1.4rem !important",
  },
  textField: {
    width: "100%",
  },
  input: {
    fontSize: '1.4rem !important',
    marginTop: "0.6rem",
    width: "100%",
    background: '#FFFFFF',
    border: '1px solid #D0D5DD !important',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
    borderRadius: '0.8rem',
    '& input': {
      padding: '1rem 1.4rem !important'
    },
    "&.Mui-focused": {
      // border: "unset",
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #D6BBFB !important',
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;"
      }
    },
    "&.MuiSelect-outlined": {
      border: '1px solid #D0D5DD !important'
    },
    "& fieldSet": {
      border: 'none'
    },
    "&:hover": {
      // border:"none",
      '& .MuiOutlinedInput-notchedOutline': {
        // border: '1px solid #D0D5DD !important'
      }
    },
    "& p": {
      fontSize: "1.4rem"
    },
    "&.MuiSelect-select": {
      border: '1px solid #D6BBFB !important',
    }
  },
  itemVal: {
    display: "inline-block",
    width: "80%",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  itemCount: {
    display: "inline-block",
    width: "20%",
    textAlign: "right"
  },
}))

const errorObj = {
  border: '1px solid #ff0000 !important',
  "&:hover": {
    border: "1px solid #ff0000 !important"
  },
  "&.Mui-focused": {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #ff0000 !important',
      boxShadow: "0px 1px 2px rgba(255, 0, 0, 0.05), 0px 0px 0px 4px #F4EBFF;"
    }
  },
}

function DropdownCheckBox({ isError = false, value, list, onChangeSelect, customSx = {}, customDropdownMenu = {}, isSearchable = false, isSelectOptionEnable = false, isSelectNonDownloadedOptionEnable }) { // value, list

  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event) => {
    const { target: { value } } = event;
    onChangeSelect(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // function isSelectNonDownloadedOptionChecked() {
  //   let _l = list.filter(m => m?.isDownloadedIconShow === true);
  //   if (_l?.length > 0 && value?.length > 0) {
  //     let _l2 = _l.filter(f => value?.includes(f?.value));
  //     return !Boolean(_l?.length === _l2?.length);
  //   } else {
  //     return false;
  //   }
  // }
  function isSelectNonDownloadedOptionChecked() {
    let _l = list.filter(m => !m?.isDownloadedIconShow);
    if (_l?.length > 0 && value?.length > 0) {
      let notPresentInValue = _l.filter(f => !value?.includes(f?.value));

      // return !Boolean(_l?.length === _l2?.length);
      return Boolean(notPresentInValue?.length === 0);
    } else {
      return false;
    }
  }

  return (
    <FormControl style={{ width: "100%" }}>
      {/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel> */}
      <Select
        // labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onChange={handleChange}
        // input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => list.filter(m => selected.find(s => m.value === s)).map(m => m.label).join(", ")}
        classes={{
          icon: classes.dropdownIcon
        }}
        inputProps={{ className: classes.dropdownInput }}
        MenuProps={{ className: classes.dropdownMenu, sx: customDropdownMenu }}
        className={classes.dropdown}
        sx={[isError ? { ...errorObj } : {}, customSx]}
      >
        {/* { isSearchable &&
          <input
            type="text"
            value={searchValue}
            onChange={(e) => {
              e.preventDefault();
              setSearchValue(e?.target?.value || "");
            }}
          />
        } */}
        { isSelectOptionEnable &&
          <MenuItem sx={{ justifyContent: 'space-between' }} 
            onClick={() => {
              if (Boolean((value?.length > 0) && (value?.length === list?.length))) {
                onChangeSelect([]);
              } else {
                onChangeSelect(list.map(m => m.value));
              }
            }}
          >
            <Box display={"flex"} flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <Checkbox checked={Boolean((value?.length > 0) && (value?.length === list?.length))} size={"large"} />
              <ListItemText primary={"Select All"} />
            </Box>
          </MenuItem>
        }
        { isSelectNonDownloadedOptionEnable &&
          <MenuItem sx={{ justifyContent: 'space-between' }} 
            onClick={() => {
              if (isSelectNonDownloadedOptionChecked()) {
                onChangeSelect([]);
              } else {
                onChangeSelect(list?.filter(f => !f?.isDownloadedIconShow)?.map(m => m.value));
              }
            }}
          >
            <Box display={"flex"} flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <Checkbox checked={isSelectNonDownloadedOptionChecked()} size={"large"} />
              <ListItemText primary={"Select All Non Downloaded Excel"} />
            </Box>
          </MenuItem>
        }
        {list?.filter(f => f?.label?.toLowerCase().includes(searchValue.toLowerCase())).map((menuItem, index) => (
          <MenuItem key={menuItem + index} value={menuItem.value} sx={{ justifyContent: 'space-between' }}>
            <Box display={"flex"} flexDirection={"row"} gap={"10px"} alignItems={"center"} width={"80%"}>
              <Checkbox checked={value.some(s => s === menuItem.value)} size={"large"} />
              <ListItemText
                primary={menuItem.label}
                sx={{
                  "& .MuiTypography-root": {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }
                }}
              />
              {/* <div>
                {menuItem.label ? menuItem.label : menuItem}
              </div> */}
            </Box>
            {menuItem.sideValue &&
              <Box display={"flex"} flexDirection={"row"} gap={"10px"} alignItems={"center"} justifyContent={"flex-end"} width={"20%"}>
                {menuItem.isDownloadedIconShow && <DownloadDoneIcon color="success" style={{ width: '2rem', height: '2rem' }} />}
                {menuItem.sideValue}
              </Box>
            }

          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropdownCheckBox;

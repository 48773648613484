import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

export default function Button({ style, btnIcon, btnName, clicked, disable=false,padding=false, isLoading=false , type=null, loadingColor=false}) {
    const cssClasses = {
        button: {
            padding: padding ? padding : '1rem 1.6rem',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        buttonWrapper: {
            // border: '1px solid #D0D5DD',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '0.8rem',
            width:"100%",
            cursor:"pointer"
        },
        image: {
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '1.4rem',
            height: '1.4rem',
            marginRight: '1rem'
        },
        btnText: {
            fontWeight: '500',
            fontSize: '1.4rem',
            lineHeight: '2rem',
            verticalAlign: 'middle',
            display: 'inline-block',
            margin: '0',
        },
        loadingCircle : {
            width: '2.5rem',
            height: '2.5rem',
            marginLeft: '2rem',
            color: loadingColor ? loadingColor :'rgb(255, 255, 255)'
        }
    }

    return (
        <button type={type} style={{ ...cssClasses.buttonWrapper, color: style.color, backgroundColor: style.backgroundColor, opacity: disable ? "0.7" : "1", pointerEvents : disable ? "none" : "auto",  border: style.backgroundColor.toLowerCase().includes("fff") ? "1px solid #D0D5DD" : "none"  }} onClick={clicked}>
            {btnIcon ? 
                <div style={cssClasses.button}>
                    <img style={{ ...cssClasses.image, color: style.color }} src={btnIcon} alt={""}/>
                    <p style={cssClasses.btnText}>{btnName}</p>
                    { isLoading && <CircularProgress style={cssClasses.loadingCircle}/>}
                </div>
                :
                <div style={cssClasses.button}>
                    <p style={cssClasses.btnText}>{btnName}</p>
                    { isLoading && <CircularProgress style={cssClasses.loadingCircle}/>}
                </div>
            }
        </button>
    )
}
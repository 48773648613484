/* eslint-disable no-loop-func */
import { query, orderBy, limit, getFirestore, collection, getDocs, startAfter, where, addDoc, endBefore, limitToLast, getDoc, updateDoc, doc, setDoc, deleteDoc, serverTimestamp } from "firebase/firestore";

async function getMeritStudents(searchField = "studentFullNameForSearch", lastDoc, studentSearchValue, examDocID, subExamDocID) {

  if (searchField === "studentFullNameForSearch" && studentSearchValue) {
    studentSearchValue = studentSearchValue.toLowerCase();
  }
  if (searchField === "Gender" && studentSearchValue) {
    studentSearchValue = studentSearchValue.charAt(0).toUpperCase() + studentSearchValue.slice(1).toLowerCase()
  }

  console.log({ searchField, lastDoc, studentSearchValue, examDocID, subExamDocID });

  let meritStudentsRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`);

  let _query = query(meritStudentsRef, orderBy(searchField, "asc"), limit(20));  //* for first time visit and without search
  if (lastDoc && !studentSearchValue) {
    //* for next docs without Search
    _query = query(meritStudentsRef, orderBy(searchField, "asc"), startAfter(lastDoc), limit(11))
  }
  if (studentSearchValue && !lastDoc) {
    //* for search first time without last
    _query = query(meritStudentsRef, orderBy(searchField, "asc"), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  if (lastDoc && studentSearchValue) {
    //* for search time with last doc
    _query = query(meritStudentsRef, orderBy(searchField, "asc"), startAfter(lastDoc), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  let getStudents = getDocs(_query);
  return getStudents;
}

async function getAllMeritStudents(examDocID, subExamDocID) {
  let meritStudentsRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`);
  return await getDocs(meritStudentsRef);
}

async function getAllMeritStudentsWithLimit(examDocID, subExamDocID) {
  let meritStudentsRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`);
  // return await getDocs(meritStudentsRef);
  return await getDocs(query(meritStudentsRef, limit(10000)));
}

async function deleteMeritStudent(examDocID, subExamDocID, uuid) {
  // let meritStudentsRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`);
  // return await getDocs(meritStudentsRef);
  // return await getDocs(query(meritStudentsRef, limit(100)));
  return await deleteDoc(doc(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`, uuid));
}

async function setDuplicateMeritStudent(examDocID, subExamDocID, studentDocID, postBody) {
  let meritStudentsRef = doc(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/deleteDuplicate`, studentDocID);
  return await setDoc(meritStudentsRef, postBody);
}

async function getAllMeritStudentsByExamCenterName(examDocID, subExamDocID, examCenterName) {
  let meritStudentsRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`);
  let _query = query(meritStudentsRef, where('examCenterName', '==', examCenterName))
  return await getDocs(_query);
}

async function getAllQualifiedMeritStudents(examDocID, subExamDocID) {
  let meritStudentsRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`);
  return await getDocs(query(meritStudentsRef, where("attendanceDateTime", "!=", "")));
}

async function getAllMeritStudentsByUploadedForm(examDocID, subExamDocID) {
  let meritStudentsRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`);
  return await getDocs(query(meritStudentsRef, where('uploadedForm', '!=', '')));
}

async function setMeritStudent(examDocID, subExamDocID, studentDocID, postBody) {
  let meritStudentsRef = doc(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`, studentDocID);
  return await setDoc(meritStudentsRef, postBody);
}

async function getStudentByEmailIDFromParentCollection(email) {
  let studentsRef = collection(getFirestore(), `students`);
  return await getDocs(query(studentsRef, where('email', '==', email)));
}

async function getStudentByIDEligibleCollection(id, examDocID,) {
  // let studentsRef =  doc(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`, id);
  let studentsRef = doc(getFirestore(), `exams/${examDocID}/EligibleStudents`, id);
  return await getDoc(studentsRef);
}


async function getMeritStudentById(examDocID, subExamDocID, id) {
  let meritStudentsRef = doc(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`, id);
  return await getDoc(meritStudentsRef);
}




async function setStudentInEligibleCollection(id, examDocID, postBody) {
  let studentsRef = doc(getFirestore(), `exams/${examDocID}/EligibleStudents`, id);
  return await setDoc(studentsRef, postBody);
}

async function setStudentInHallTicketCollection(id, examDocID, postBody) {
  let studentsRef = doc(getFirestore(), `exams/${examDocID}/hallticket`, id);
  return await setDoc(studentsRef, postBody);
}

async function setStudentInMeritCollection(id, examDocID, subExamDocID, postBody) {
  let studentsRef = doc(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`, id);
  return await setDoc(studentsRef, postBody);
}
async function getMeritStudentsForExcelDownloadByPagination(lastDoc, examDocID, subExamDocID, docLimit) {

  let meritStudentsRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`);
  let _query = query(meritStudentsRef, orderBy('Applicant', "asc"), limit(docLimit));

  if (lastDoc) {
    _query = query(meritStudentsRef, orderBy('Applicant', "asc"), startAfter(lastDoc), limit(docLimit))
  }

  let getStudents = await getDocs(_query);
  return getStudents;
}
async function getMeritStudentByApplicantId(examDocID, subExamDocID, id) {
  // let meritStudentsRef = doc(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`, id);
  let meritStudentsRef = query(collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/meritStudents`), where("Applicant", "==", id))
  return await getDocs(meritStudentsRef);
}


//* downloadedExamCenterExcel Collection
async function getDownloadedExamCenterExcel(examDocID, subExamDocID) {
  const getDownloadedExamCenterExcelRef = collection(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/downloadedExamCenterExcel`);
  return await getDocs(getDownloadedExamCenterExcelRef);
}

async function setDownloadedExamCenterExcel(id, examDocID, subExamDocID) {
  const postBody = {
    isDownloaded: true,
    downloaderDate: serverTimestamp()
  }
  let centerRef = doc(getFirestore(), `exams/${examDocID}/subExam/${subExamDocID}/downloadedExamCenterExcel`, id);
  return await setDoc(centerRef, postBody);
}


export {
  getMeritStudents,
  getAllMeritStudents,
  getAllMeritStudentsWithLimit,
  deleteMeritStudent,
  setDuplicateMeritStudent,
  getAllMeritStudentsByExamCenterName,
  getAllQualifiedMeritStudents,
  setMeritStudent,
  getAllMeritStudentsByUploadedForm,
  getStudentByEmailIDFromParentCollection,
  getStudentByIDEligibleCollection,
  setStudentInMeritCollection,
  setStudentInHallTicketCollection,
  setStudentInEligibleCollection,
  getMeritStudentById,
  getMeritStudentsForExcelDownloadByPagination,
  getMeritStudentByApplicantId,

  getDownloadedExamCenterExcel,
  setDownloadedExamCenterExcel
}
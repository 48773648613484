import { query, orderBy, limit, getFirestore, collection, getDocs, startAfter, where, getDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

async function getAdmins(searchField = "nameForSearch", lastDoc, studentSearchValue, examDocID, subExamDocID) {

  if(searchField === "nameForSearch" && studentSearchValue) {
    studentSearchValue = studentSearchValue.toLowerCase();
  }

  console.log({ searchField, lastDoc, studentSearchValue, examDocID, subExamDocID });
  let students = collection(getFirestore(), 'admins');

  let _query = query(students, orderBy(searchField, "asc"), limit(20));  //* for first time visit and without search
  if (lastDoc && !studentSearchValue) {
    //* for next docs without Search
    _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), limit(11))
  }
  if (studentSearchValue && !lastDoc) {
    //* for search first time without last
    _query = query(students, orderBy(searchField, "asc"), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  if (lastDoc && studentSearchValue) {
    //* for search time with last doc
    _query = query(students, orderBy(searchField, "asc"), startAfter(lastDoc), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  }
  let getStudentsList = getDocs(_query);
  return getStudentsList;
}

async function getAllAdmins() {
  let students = collection(getFirestore(), 'admins');
  let _query = query(students, orderBy("nameForSearch", "asc"));
  let getStudentsList = getDocs(_query);
  return getStudentsList;
}

async function adminAuthCreate(email,password) {
  return await createUserWithEmailAndPassword(getAuth(),email,password)
}

async function setAdmin(uid,postBody) {
  const adminRef = doc(getFirestore(), "admins", uid)
  return await setDoc(adminRef, postBody);
}

async function getAdminByID(docID) {
  const adminRef = doc(getFirestore(), "admins", docID)
  return await getDoc(adminRef);
}

async function updateAdmin(docID, postBody) {
  const docRef = doc(getFirestore(), 'admins', docID);
  return await updateDoc(docRef, postBody);
}

async function getAdminsFromExam(examId) {
  let adminRef = collection(getFirestore(), `exams/${examId}/admins`);
 let getAdminList = getDocs(adminRef);
  return getAdminList;
}

export { getAdmins, getAllAdmins, adminAuthCreate, setAdmin, getAdminByID, updateAdmin, getAdminsFromExam }
import React, { useEffect, useState } from "react";
//* images
import filterIcon from "../../assets/images/filter-icon.svg";
import moreVertical from "../../assets/images/more-vertical.svg";
import man from "../../assets/images/man.png";
import woman from "../../assets/images/woman.png";
import user_icon from "../../assets/images/user_icon.png"

//* css
import cssClasses from "./Applicants.module.css";

//* import MUI
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from "@mui/material";
import Chip from '@mui/material/Chip';

//* import Sub-components
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";

//* import Services
import { getStudents } from "../../services/StudentService"
import { errorToast } from "../../services/Toast";

const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected": {
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
    paddingRight: '1rem'
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
    paddingRight: '1rem'
  },
  "& .MuiButtonBase-root": {
    borderRadius: '0'
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "hidden !important"
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "7.2rem !important",
    minHeight: "7.2rem !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",
    minHeight: "7.2rem !important",
    maxHeight: "7.2rem !important",
    outline: 'none !important',
  },
  "& .MuiButtonBase-root": {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '0 1rem'
  },
  "& .MuiTablePagination-actions": {
    margin: '0 2rem',
  }
}
const chipStatusStyle = {
  fontSize: "1.5rem",
  textTransform: 'capitalize',
  // fontWeight : "600",
  padding: "0.8rem",
  height: "auto",
  "& .MuiChip-icon": {
    fontSize: '1rem',
  }
}
const blueChip = {
  // border: "1px solid #EEF4FF",
  color: "#175CD3",
  background: "#EFF8FF",
}
const purpleChip = {
  // border: "1px solid #EEF4FF",
  color: "#3538CD",
  background: "#EEF4FF",
}
const pinkChip = {
  // border: "1px solid #EEF4FF",
  color: "#C11574",
  background: "#FDF2FA",
}
const greenChip = {
  // border: "1px solid #EEF4FF",
  color: "#027A48",
  background: "#ECFDF3",
}
const redChip = {
  // border: "1px solid #EEF4FF",
  color: "#B42318",
  background: "#FEF3F2",
}
export default function Applicants({ height=false,examDocID=null, subExamDocID=null }) {

  const ExamApplicantColumns = [
    { field: 'fileName', headerName: 'File Name', width: 300 , sortable: false,
      renderCell: (params) => (
        <div className={cssClasses.imageCellWrapper}>
          <img src={process.env.REACT_APP_bucketAccessRootPath + params.value.img} alt="" onError={(e) => { e.target.onerror = null; e.target.src = params.value.onErrorImg; }} />
          <div>
            <p>{params.value.name}</p>
            {params.value.email && <p>{new Array(params.value.email?.split('@')[0].length).join('*')+"@"+params.value.email?.split('@')[1]}</p>}
          </div>
        </div>
      )
    },
    { field: 'gender', headerName: 'Gender', sortable: false, width: 131 },
    // { field: 'examName', headerName: 'Exam Name', sortable: false, width: 250 },
    { field: 'createdAt', headerName: 'Registration Date', sortable: false, width: 150, 
      renderCell : (params) => {
        // console.log(params['value']);
        return (
          <div>
            {
              params['value'] ? 
                params['value'].hasOwnProperty("nanoseconds") ?
                  params['value']?.nanoseconds === 0 ?  
                  new Date(params['value'].seconds * 1000)?.toDateString() : new Date(params['value'].seconds * 1000)?.toDateString()
                : new Date(params['value']).toDateString()
              : "-"
            }
          </div>
        )
      }
    },
    { field: 'paymentMethod', headerName: 'Payment Method',sortable: false , width: 150 },
    {
      field: 'paymentStatus',
      headerName: 'Payment',
      width: 150,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          
          const api = params.api;
          const thisRow = {};
  
          api.getAllColumns().filter((c) => c.field !== '__check__' && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)),);
          return onClickDoneApplicant(thisRow);
        };
          
        // return <p onClick={onClick} className={ params.row[params.field] === "pending" ? cssClasses.pendingButton : cssClasses.doneButton}>{params.row[params.field]}</p>;
        return <Chip label={params['value']} size={"medium"} sx={[{ ...chipStatusStyle, ...params['value'] ==="done" ? greenChip : params['value'] ==="pending" ? redChip : blueChip }]} />;
      },
    },
    { field: 'invoice', headerName: 'Invoice', sortable: false, width: 150 },
  ]
  
  const applicantColumns = [
    // { field: 'UID', headerName: 'UID', sortable: false, width: 250 },
    { field: 'fileName', headerName: 'Photo', width: 100 , sortable: false,
      renderCell: (params) => (
        <div className={cssClasses.imageCellWrapper}>
          <img src={params.value.img} alt="" onError={(e) => { e.target.onerror = null; e.target.src = params.value.onErrorImg;}} />
        </div>
      )
    },
    { field: 'Full Name', headerName: 'Full Name', sortable: false, width: 250 },
    { field: 'gender', headerName: 'Gender', sortable: false, width: 200 },
    { field: 'email', headerName: 'Email', sortable: false, width: 250,
      renderCell : (params) => {
        return (
          <div>{params['value'] ? new Array(params['value']?.split('@')[0].length).join('*')+"@"+params['value']?.split('@')[1] : "_"}</div>
        )
      }
    },
    { field: 'phone', headerName: 'Phone', sortable: false, width: 200,
      renderCell : (params) => {
        return (
          <div>{(params['value']!=="" && params['value']) ? "******"+params['value']?.toString().substring(6,10) : "_"}</div>
        )
      }
    },
    // { field: 'registrationDate', headerName: 'Registration Date', sortable: false, width: 150 },
    // { field: 'invoice', headerName: 'Invoice', sortable: false, width: 150 },
    // { field: 'id', headerName: '', width: 70 },
  ]

  const [applicantsList, setApplicantsList] = useState([]);
  const [serverMetaDataList, setServerMetaDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageMaintain, setPageMaintain] = useState(0);
  const [studentSearchValue, setStudentSearchValue] = useState("");

  const [dataGridLoading, setDataGridLoading] = useState(true);
  const [searchField, setSearchField] = useState("studentFullNameForSearch");

  const onClickDoneApplicant = (_applicant) => {
    console.log(_applicant);
  }

  useEffect(() => {
    setDataGridLoading(true);
    getDataFromServer(searchField, null, studentSearchValue === "" ? undefined : studentSearchValue, false);
  }, [studentSearchValue, searchField]);

  function getDataFromServer(_searchField, _lastDoc, _studentSearchValue, _merge) {
    getStudents(searchField, _lastDoc, _studentSearchValue, examDocID, subExamDocID).then(res => {
      let _studentList = convertFirebaseObjToDataGridObj(res.docs.map(m => ({ docID: m.id, ...m.data() })));
      console.log(_studentList);
      if (_merge) {
        setServerMetaDataList(pre => [...pre, ...res.docs])
        setApplicantsList(pre => [...pre, ..._studentList]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList(res.docs);
        setApplicantsList(_studentList);
      }
      setDataGridLoading(false);
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      if (_merge) {
        setServerMetaDataList(pre => [...pre])
        setApplicantsList(pre => [...pre]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList([]);
        setApplicantsList([]);
      }
      setDataGridLoading(false);
    })
  }


  //* call on Next Button
  function getMoreData() {
    setDataGridLoading(true);
    getDataFromServer(searchField, serverMetaDataList[serverMetaDataList.length - 1], studentSearchValue === "" ? undefined : studentSearchValue, true);
  }
  
  return (
    <Box className={cssClasses.mainTabPanelWrapper}>
      <div className={cssClasses.mainTabPanelHeader}>
        <div>
          <p>All Students Applied</p>
          <p>Total Students Applied.</p>
        </div>
        <div>
          {/* <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            tittle={"Message"}
          >
            <img src={moreVertical} alt="3 dots icon" />
          </IconButton> */}
          <div><SearchBar placeholder={"Search Student"} search={(_value) => setStudentSearchValue(_value)} /></div>
        </div>
      </div>

      {/* <div className={cssClasses.searchAndFilterWrapper} >
        <div>
          <ToggleButtonGroup
            color="primary"
            value={'View All'}
            exclusive
            style={{filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))"}}
            // onChange={handleChange}
          >
            <ToggleButton value="All Students" sx={[toggleButtonStyle]}>All Students</ToggleButton>
            <ToggleButton value="Students Entered" sx={[toggleButtonStyle]}>Students Entered</ToggleButton>
            <ToggleButton value="By Room Wise" sx={[toggleButtonStyle]}>By Room Wise</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div>
          <ToggleButtonGroup
            color="primary"
            value={searchField}
            onChange={(e) => { setSearchField(e.target.value); }}
            exclusive
            style={{ filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))" }}
          // onChange={handleChange}
          >
            <ToggleButton value="Full Name" sx={[toggleButtonStyle]}>Name</ToggleButton>
            <ToggleButton value="RollNo" sx={[toggleButtonStyle]}>Roll Number</ToggleButton>
            <ToggleButton value="Applicant" sx={[toggleButtonStyle]}>Application Id</ToggleButton>
          </ToggleButtonGroup> 
        </div> 

        <div>
          <div><SearchBar placeholder={"Search Student"} search={(_value) => setStudentSearchValue(_value)} /></div>
          <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={filterIcon} btnName={"Filters"} /></div>
        </div>
      </div> */}

      <div className={cssClasses.examCenterWrapper} style={{ height: height ? height : "unset", flex: height ? "unset" : 1 }}>
        <DataGrid
          rows={dataGridLoading ? [] : applicantsList}
          columns={ examDocID ? ExamApplicantColumns : applicantColumns}
          // hideFooterPagination={false}
          hideFooter={false}
          pageSize={10}
          rowsPerPageOptions={[10]}
          page={pageMaintain}
          sx={[dataGridStyles,{
            " .MuiDataGrid-footerContainer > div:first-of-type:after" : {
              content: '"'+`${pageMaintain+1} Page`+'"',
              paddingLeft: "1rem"
            }
          }]}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          disableVirtualization
          loading={dataGridLoading}
          onPageChange={(_page) => {
            console.log({ _page });
            setPageMaintain(_page)
            if (pageNumber < _page) {
              // * Next When Data need
              setPageNumber(_page);
              getMoreData()
            } else {
              // *previous
            }
          }}
        />
      </div>
    </Box>
  );
}
//   return _serverList.map(m=> ({
//     id : m.docID,
//     fileName : { img: m.hasOwnProperty("uploadImage") ? m?.uploadImage : "", name:m["Full Name"], email:m?.email, onErrorImg: m?.GenderName?.toLowerCase() === "male" ? man : woman },
//     studentID : m?.Applicant,
//     examName : "",
//     roomNumber : m.RollNo,
//     registrationDate : "",
//     invoice : "",
//     payment: "", //pending
//     ...m
//   }))
// }

function convertFirebaseObjToDataGridObj(_serverList){
  return _serverList.map(m=> ({
    id : m.docID,
    fileName : { 
      img: m.hasOwnProperty("profilePicture") ? m?.profilePicture : m.hasOwnProperty("Photo") ? m?.Photo : "", 
      name: m["Full Name"], 
      email: m?.email || m?.Email, 
      onErrorImg: user_icon 
    },
    gender : m?.gender || m?.Gender,
    examName : "",
    name:m["Full Name"], 
    email:m?.email || m?.Email,
    phone : m?.phone,
    ...m,
  }))
}

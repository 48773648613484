import React, { useEffect, useState } from 'react';

//* images
import filterIcon from "../../assets/images/filter-icon.svg";
import avatar from "../../assets/images/Avatar.svg";
import uploadFileCloud from "../../assets/images/uploadFileCloud.svg";
import html2pdf from "html2pdf.js";

//* css
import cssClasses from "./HallTicket.module.css";

//* import MUI
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

//* import Sub-components
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";
import DateComponent from '../../Subcomponent/DateComponent';
import HallTicketPDF from '../HallTicketPDF/HallTicketPDF';
//* Import Services
import { getHallTicket, getHallTicketsUsingParams } from '../../services/HallTicketService';
import { errorToast, successToast } from '../../services/Toast';
import { updateExamDetails } from '../../services/ExamService';
import { dismissLoader, presentLoader } from '../../services/loaderService';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
  },
  "& .MuiButtonBase-root": {
    borderRadius: '0'
  }
}

export default function HallTicket({ height = false, examDocID = null, subExamDocID = null, examObject = null }) {
  
  const hallTicketColumns = [
    // { field: 'fileName', headerName: 'File Name', width: 300 , sortable: false,
    // renderCell: (params) => <div className={cssClasses.imageCellWrapper}><img src={params.value.img} alt="" /><div><p>{params.value.name}</p><p>{params.value.email}</p></div></div>
    // },
    { field: 'Full Name', headerName: 'Full Name', sortable: false, width: 200 },
    { field: 'hallTicketNumber', headerName: 'HallTicket number', sortable: false, width: 200 },
    { field: 'examName', headerName: 'Exam Name', sortable: false, width: 250 },
    { field: 'examCenterName', headerName: 'Exam Center Name', sortable: false, width: 250 },
    // { field: 'registrationDate', headerName: 'Registration Date', sortable: false, width: 150 },
    { field: 'examDate', headerName: 'Exam Date/ Shift', sortable: false, width: 150 },
    {
      field: '',
      headerName: 'Download Hall Ticket',
      sortable: false,
      width: 170,
      renderCell: (params) => {
        return <p className={cssClasses.downloadButton} onClick={() => downloadPdf(params)}>{"Download Now"}</p>;
      },
    },
  ]
  const [hallTicketList, setHallTicketList] = useState([]);
  const [serverMetaDataList, setServerMetaDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageMaintain, setPageMaintain] = useState(0);
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [hallTicketToDownload, setHallTicketToDownload] = useState(null)
  const [dataGridLoading, setDataGridLoading] = useState(true);
  const [searchField, setSearchField] = useState("studentFullNameForSearch");

  useEffect(() => {
    if (examDocID && studentSearchValue === '') {
      setPageNumber(0);
      setPageMaintain(0);
      setStudentSearchValue("");
      setDataGridLoading(true);
      setDataGridLoading(true);
      getDataFromServer(searchField, null, undefined, false);
    }
    if (studentSearchValue !== "") {
      setDataGridLoading(true);
      getDataFromServer(searchField, null, studentSearchValue === "" ? undefined : studentSearchValue, false);
    }
  }, [examDocID, studentSearchValue, searchField]);

  function downloadPdf(params) {
    // console.log('params: ', params);
    setHallTicketToDownload(params.row);
    // var id = "demoPDF";
    // const element = document.getElementById(id);
    // const opt = {
    //   margin: [0.2, 0.2],
    //   filename: `${params.row["Full Name"]} Result Sheet.pdf`,
    //   html2canvas: {
    //     useCORS: false,
    //     allowTaint: true,
    //     scale: 2,
    //   },
    //   image: { type: "jpg", quality: 0.5 },
    //   jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    //   pagebreak: { avoid: ".avoid" },
    // };
    // html2pdf().from(element).set(opt).save().then((res) => {
    //   console.log(res);
    // }).catch((err) => {
    //   errorToast("Something went wrong! Try again")
    //   console.log(err);
    // });
  }

  useEffect(() => {
    console.log("searchField => ",searchField);
  },[searchField])

  // useEffect(() => {
  //   if(hallTicketToDownload) {
  //     var id = "demoPDF";
  //     const element = document.getElementById(id);
  //     const opt = {
  //       margin: [0.2, 0.2],
  //       filename: `${hallTicketToDownload["Full Name"]} Result Sheet.pdf`,
  //       html2canvas: {
  //         useCORS: false,
  //         allowTaint: true,
  //         scale: 2,
  //       },
  //       image: { type: "jpg", quality: 0.5 },
  //       jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
  //       pagebreak: { avoid: ".avoid" },
  //     };
  //     html2pdf().from(element).set(opt).save().then((res) => {
  //       console.log(res);
  //     }).catch((err) => {
  //       errorToast("Something went wrong! Try again")
  //       console.log(err);
  //     });
  //   }
  // }, [hallTicketToDownload])
  

  function getDataFromServer(_searchField, _lastDoc, _studentSearchValue, _merge) {
    getHallTicketsUsingParams(_searchField, _lastDoc, _studentSearchValue, examDocID, subExamDocID).then(res => {
      let _studentList = convertFirebaseObjToDataGridObj(res.docs.map(m => ({ docID: m.id, ...m.data() })));
      console.log(_studentList);
      if (_merge) {
        setServerMetaDataList(pre => [...pre, ...res.docs])
        setHallTicketList(pre => [...pre, ..._studentList]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList(res.docs);
        setHallTicketList(_studentList);
      }
      setDataGridLoading(false);
    }).catch(err => {
      // console.log(err);
      errorToast(err.toString());
      if (_merge) {
        setServerMetaDataList(pre => [...pre])
        setHallTicketList(pre => [...pre]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        setServerMetaDataList([]);
        setHallTicketList([]);
      }
      setDataGridLoading(false);
    })
  }

  function getMoreData() {
    setDataGridLoading(true);
    getDataFromServer(searchField, serverMetaDataList[serverMetaDataList.length - 1], studentSearchValue === "" ? undefined : studentSearchValue, true);
  }


  const hallTicketPublish = () => {
    presentLoader();
    updateExamDetails({hallTicketStatus : "published"}, examDocID).then(res=> {
      console.log(res);
      successToast("Hall tickets published successfully.");
      dismissLoader();
    }).catch(err=>{
      console.log(err);
      errorToast("Something went wrong! Try again");
    })
  }

  function onSearchValueChange(_value) {
    setStudentSearchValue(_value)
  }

  const [filterDate, setFilterDate] = useState({ from: "", to: "" });

  return (
    <Box className={cssClasses.mainTabPanelWrapperForMultiExamMeritList} style={{ marginTop: "1rem" }}>
      {/* When Data Not Present */}
      {/* <>
        <div className={cssClasses.uploadFileWrapper}>
          <img src={uploadFileCloud} alt=""/>
          <p>No data found please upload</p>
        </div>
      </> */}

      {/* * When Data Present */}
      <>
        <div className={cssClasses.searchAndFilterWrapper}>
          <div>
            {/* <SearchBar placeholder={"Search"} search={SearchHandler}/> */}
            { examObject?.hallTicketStatus &&
              <div>
                <Button  style={{ color: "#FFFFFF", backgroundColor: "#6941C6" }} btnName={ (examObject?.hallTicketStatus === "published") ? "Re-Publish\xa0Hall\xa0Ticket" : "Publish\xa0Hall\xa0Ticket"} 
                  clicked={() => {
                    hallTicketPublish()
                  }}
                  disable={hallTicketList.length === 0}
                />
              </div>
            }
          </div>
          <div>
            <div className={cssClasses.filterDateWrapperWrapper}>
              {/* <DateComponent 
                value={filterDate.from} 
                minDate={new Date()} 
                onChangeDate={(newDate) => {
                  setFilterDate(pre=> {
                    pre.from = newDate;
                    return {...pre};
                  });
                }}
                helperText={""}
              />
              <p>To</p>
              <DateComponent 
                value={filterDate.to} 
                minDate={filterDate.from} 
                onChangeDate={(newDate) => {
                  setFilterDate(pre=> {
                    pre.to = newDate;
                    return {...pre};
                  });
                }}
                helperText={""}
                isDisabled={filterDate.from === ""}
              /> */}

            <div>
              <ToggleButtonGroup
                color="primary"
                value={searchField}
                onChange={(e) => { setSearchField(e.target.value); }}
                exclusive
                style={{ filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))" }}
              // onChange={handleChange}
              >
                <ToggleButton value="studentFullNameForSearch" sx={[toggleButtonStyle]}>Name</ToggleButton>
                {/* <ToggleButton value="RollNo" sx={[toggleButtonStyle]}>Roll Number</ToggleButton> */}
                <ToggleButton value="hallTicketNumber" sx={[toggleButtonStyle]}>Application Id</ToggleButton>
              </ToggleButtonGroup> 
            </div> 

            </div>
            <div><SearchBar placeholder={"Search"} search={(_value) => { onSearchValueChange(_value) }} /></div>
            {/* <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={filterIcon} btnName={"Filters"} /></div> */}
          </div>
        </div>
        <div className={cssClasses.examCenterWrapper} style={{ height: height ? height : "unset", flex: height ? "unset" : 1 }}>
          <DataGrid
            rows={dataGridLoading ? [] : hallTicketList}
            columns={hallTicketColumns}
            hideFooterPagination={false}
            hideFooter={false}
            pageSize={10}
            rowsPerPageOptions={[10]}
            page={pageMaintain}
            sx={[dataGridStyles,{
              " .MuiDataGrid-footerContainer > div:first-of-type:after" : {
                content: '"'+`${pageMaintain+1} Page`+'"',
                paddingLeft: "1rem"
              }
            }]}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            disableVirtualization
            loading={dataGridLoading}
            onPageChange={(_page) => {
              // console.log({_page});
              setPageMaintain(_page)
              if (pageNumber < _page) {
                // * Next When Data need
                setPageNumber(_page);
                getMoreData()
              } else {
                // *previous
              }
            }}
          />
        </div>
      </>
      {hallTicketToDownload &&
        <HallTicketPDF hallTicket={hallTicketToDownload} examDocID={examDocID} setObject={() => {setHallTicketToDownload(null)}} />
      }
    </Box>
  )
}

function convertFirebaseObjToDataGridObj(_serverList) {
  return _serverList.map(m => ({
    id: m.docID,
    examCenterName: m?.examCenter?.name,
    ...m
  }))
}
const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected": {
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
/* eslint-disable no-loop-func */
import React, { useState, useRef, useEffect } from "react";

//* images
import uploadIconBlack from "../../assets/images/uploadIconBlack.svg";
import addIcon from "../../assets/images/add icon.svg";

//* import component
import Applicants from "../../Components/Applicants/Applicants";


//* Import css
import cssClasses from "./StudentsScreen.module.css";

//* import MUI
import { makeStyles } from "@material-ui/core/styles";

//* Import store
import { useDispatch, useSelector } from "react-redux";
import actions from "../../Redux Store/actions";

const useStyles = makeStyles((theme) => ({

}));

export default function StudentsScreen() {

  const dispatch = useDispatch();

  // const classList = useStyles();
  // const excel = useRef(null);
  // const loggedInUser = useSelector((state) => state.Auth.user);

  useEffect(() => {
    dispatch(actions.activeMenu('students'));
  }, [])
  

  return (
    <>
      <div className={cssClasses.pageWrapper}>
        <div className={cssClasses.headerWrapper}>
          <div className={cssClasses.headingWrapper}>
            <p>All Students</p>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
          </div>
          {/* <div className={cssClasses.headerButtonWrapper}>
            {loggedInUser.tabAccess.student.some(s => s === 'add') &&
              <>
                <div>
                  <Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnIcon={uploadIconBlack} btnName={"Upload\xa0Student\xa0Data"} clicked={() => { document.getElementById("fileSelectForUploadStudentData").click(); }} />
                  <input hidden type="file" name="" ref={excel} onChange={readExcel} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                </div>
                <div>
                  <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnIcon={addIcon} btnName={"Add\xa0Student"} clicked={() => { }} />
                </div>
              </>
            }
          </div> */}
        </div>

        <div className={cssClasses.breaker}></div>

        <Applicants />

      </div>

    </>
  )
}
